@import "@core/scss/core.scss";
@import "./assets/scss/styles";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~quill/dist/quill.snow.css";

ngx-intl-tel-input .iti {
	width: 100% !important;
}

ngx-intl-tel-input > .iti > .iti__flag-container > .country-dropdown {
	width: 353px !important;
}

//.datatable-header {
//	min-width: 100%;
//}

//.datatable-body {
//	min-width: 100%;
//}

.content-nav {
	min-height: 60px;
}

.b-top {
	border-top: 1px solid $border-color;
}

.b-btm {
	border-bottom: 1px solid $border-color;
}

.b-right {
	border-right: 1px solid $border-color;
}

.b-left {
	border-left: 1px solid $border-color;
}

.navbar-container h4 {
	margin-top: 0.2rem;
}

.datatable-body {
	min-width: 100%;
}

.color-title {
	color: $title-color;
}

.card-row-padding {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.card-margin {
	margin: 0.5rem !important;
}

@media only screen and (max-width: 576px) {
	.card-row-padding {
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.card-margin {
		margin: 0.1rem !important;
	}
}

.mt-half {
	margin-top: 0.5rem;
}

.mb-half {
	margin-bottom: 0.5rem;
}

.ml-half {
	margin-left: 0.5rem;
}

.mr-half {
	margin-right: 0.5rem;
}

.m-half {
	margin: 0.5rem;
}

.pt-half {
	padding-top: 0.5rem;
}

.pb-half {
	padding-bottom: 0.5rem;
}

.pl-half {
	padding-left: 0.5rem;
}

.pr-half {
	padding-right: 0.5rem;
}

.p-half {
	padding: 0.5rem;
}

// .modal-title {
// 	font-weight: 600;
// }

.header-bg {
	background-color: #fdfdfd;
}

.ql-editor {
	min-height: 110px;
	overflow-wrap: anywhere; //word without space
}

.img-preview {
	border: dashed 1px $primary;
	background-color: $white;
	color: $primary;
	border-radius: 10px !important;
	min-height: 108px;
	min-width: 180px;
}

.img-cover {
	object-fit: cover;
}

/* multi level modal overlay - zindex step */
// .modal + .modal {
// 	z-index:1055;
//   }

// .modal-backdrop + .modal-backdrop{
// 	z-index:1050;
// }
/* multi level modal overlay - changes end */
.btn.btn-outline-tertiary {
	border: 1px solid #d8d6de !important;
	background-color: $card-bg;
	color: $primary !important;
}

.btn-outline-tertiary:not(:disabled):not(.disabled).active,
.btn-outline-tertiary:not(:disabled):not(.disabled):active,
.btn-outline-tertiary:not(:disabled):not(.disabled):focus {
	background-color: rgba($color: $primary, $alpha: 0.2);
	color: $primary !important;
	border: 1px solid $primary !important;
}

.btn-outline-tertiary:hover:not(.disabled):not(:disabled) {
	//background-color: rgba($color: $primary, $alpha: 0.2);
	color: $primary !important;
	box-shadow: 0 8px 25px -8px rgba($primary, 1);
}

.pull-right {
	@extend .float-right;
}

.pull-left {
	@extend .float-left;
}
.ql-snow .ql-tooltip{
    left: 10px !important;
	top: 30px;
}

.dark-layout {
    .ql-snow .ql-tooltip{
        background: $theme-dark-card-bg;
        color: $theme-dark-body-color;
        outline: none !important;
        border: 1px solid $theme-dark-border-color !important;
        box-shadow: none !important;
    }    
}
.ql-snow .ql-editor a{
    background-color: transparent !important;
}