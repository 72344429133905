// ================================================================================================
// 	File Name: bordered-layout.scss
// 	Description: SCSS file for Bordered layout.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

@import "../bootstrap-extended/include";
// Bootstrap includes
@import "../components/include"; // Components includes

.bordered-layout {
	&.dark-layout {
		&.fixed-layout{
			.header-navbar {
				&:not(.bg-card){
					&:not(.bg-white){
						&.floating-nav{
							border-top: 0px solid transparent !important;
							border-bottom: 0px solid transparent !important;
						}
					}
				}
				&:not(.bg-transparent){
					&.floating-nav{
						border: 1px solid transparent !important;
						border-top: 0px solid transparent !important;
						border-bottom: 0px solid transparent !important;
					}
				}
				&.bg-card {
					&.floating-nav{
						border: 1px solid $white !important;
					}
				}
				&.bg-white {
					&.floating-nav{
						border: 1px solid $white !important;
					}
				}
			}
		}
		.header-navbar {
			border: 1px solid $theme-dark-border-color !important;

			&.floating-nav {
				border: 1px solid $theme-dark-border-color !important;
			}

			&.fixed-top {
				border-bottom: 0px solid $theme-dark-border-color !important;
				background: $body-bg;
				border-right: 0px !important;
			}
		}

		.main-menu {
			border-right: 0px solid $theme-dark-border-color !important;
		}

		.dropdown-menu {
			border: 1px solid $theme-dark-border-color !important;
		}

		.main-menu .navigation,
		.main-menu {
			// background: $body-bg;
			background: $theme-dark-card-bg;
		}

		.card {
			border: 1px solid $theme-dark-border-color !important;
			&.primary-border{
				border: 1px solid $primary !important;
			}
			&.border-0{
				border: 0px !important;
			}
		}

		// .bs-stepper:not(.wizard-modern):not(.checkout-tab-steps),
		// .bs-stepper.wizard-modern .bs-stepper-content {

		// }
		.footer-fixed {
			.footer {
				// border-top: 1px solid $theme-dark-border-color !important;
			}
		}

		&.hasSubmenu {
			.side-pannel {
				&.activeSidebar {
					border: 1px solid $theme-dark-border-color !important;
				}
			}
		}

		.white-box {
			border: 1px solid $theme-dark-border-color !important;
		}

		.sla-list-sec {
			.sla-grid-list {
				.grid-row {
					.grid-item {
						.card {
							border: 1px solid $theme-dark-border-color !important;
						}
					}
				}
			}
		}

		// Add incident //
		.add-incident {
			.left-sec {
				border-right: 1px solid $theme-dark-border-color !important;
			}

			.middle-sec {
				border-right: 1px solid $theme-dark-border-color !important;
			}

			.right-sec {
			}
		}

		// End of Add Incident //
		// Incident Edit //
		.incident-edit-card {
			&.card {
				border: 0px !important;
			}
		}

		// End of Incident Edit //
		.filters-option{
			.filters-collapse{
				.card{
					border: 0px !important;
				}
			}
		}
	}

	.header-navbar {
		box-shadow: none;
		border: 1px solid $border-color;

		&.floating-nav {
			border: 1px solid $border-color;
		}

		&.fixed-top {
			border-bottom: 1px solid $border-color;
			background: $body-bg;
			box-shadow: none !important;
		}
	}

	.main-menu {
		border-right: 1px solid $border-color;
		box-shadow: none;

		&.menu-light .navigation > li {
			&.open:not(.menu-item-closing),
			&.sidebar-group-active {
				> a {
					background: darken($white, 7%);
				}
			}
		}
	}

	.dropdown-menu {
		border: 1px solid $border-color !important;
		box-shadow: none;
	}

	.main-menu .navigation,
	.main-menu {
		// background: $body-bg;
		background: $white;
	}

	.card,
	.bs-stepper:not(.wizard-modern):not(.checkout-tab-steps),
	.bs-stepper.wizard-modern .bs-stepper-content {
		//border: 1px solid $border-color;
		box-shadow: none;
	}

	.footer {
		// box-shadow: none !important;
	}

	.footer-fixed {
		.footer {
			// border-top: 1px solid $border-color;
		}
	}

	// sidebar for Toggle menu
	&.hasSubmenu {
		.side-pannel {
			&.activeSidebar {
				box-shadow: none !important;
				border: 1px solid $border-color;
			}
		}
	}

	.white-box {
		box-shadow: none !important;
		border: 1px solid $border-color;
	}

	// End of Sidebar for Toggle menu
	// SLA List view Section
	.sla-list-sec {
		.sla-grid-list {
			.grid-row {
				.grid-item {
					.card {
						box-shadow: none !important;
						border: 1px solid $border-color !important;
					}
				}
			}
		}
	}

	// End of SLA List view Section
	// Action Bar //
	// .action-bar {
	//   box-shadow: none !important;
	//   border: 1px solid $border-color !important;
	// }
	// End of Action Bar //
	&.fluent-layout {
		a.navbar-brand {
			height: 48px;
		}
	}

	.card-pagination {
		box-shadow: none !important;
		border-top: 1px solid $border-color !important;
	}

	// Card View //
	.card-view {
		.inner-card-view {
			.single-card {
				box-shadow: none !important;
				border: 1px solid $border-color !important;
			}
		}
	}

	// End of Card View //
	.main-content {
		border: 1px solid $border-color;
		box-shadow: none !important;

		&.border-0{
			border: 0px !important;
		}
		.left-sec {
			border-right: 1px solid $border-color;
			box-shadow: none !important;

			.side-pannel {
				box-shadow: none !important;
				// border: 1px solid $border-color !important;
				border-right: none !important;
			}
		}

		.action-bar {
			box-shadow: none !important;
			border-bottom: 1px solid $border-color !important;
		}

		.action-section {
			box-shadow: none !important;
			// border: 1px solid $border-color;
			border: none !important;
			// border-left: 1px solid $border-color;
		}
	}

	// NGX-datatable //
	.ngx-datatable {
		&.bootstrap {
			&.core-bootstrap {
				.visible {
					.datatable-footer {
						box-shadow: none !important;
						border-top: 1px solid $border-color !important;
					}
				}
			}
		}
	}

	// End of NGX-datatable //

	// Add incident //
	.add-incident {
		.left-sec {
			box-shadow: none !important;
			border-right: 1px solid $border-color !important;
		}

		.middle-sec {
			box-shadow: none !important;
			border-right: 1px solid $border-color !important;
		}

		.right-sec {
			box-shadow: none !important;
		}
	}

	// End of Add Incident //
}
