// Dark layout overrides

.dark-layout {
  .popover {
    &.bs-popover-top {
      .arrow:after {
        border-top-color: $theme-dark-card-bg;
      }
    }

    &.bs-popover-right {
      .arrow:after {
        border-right-color: $theme-dark-card-bg;
      }
    }

    &.bs-popover-bottom {
      .arrow:after {
        border-bottom-color: $primary;
      }
    }

    &.bs-popover-left {
      .arrow:after {
        border-left-color: $theme-dark-card-bg;
      }
    }

    &.bs-popover-top {
      .arrow:before {
        border-top-color: $theme-dark-border-color;
      }
    }

    &.bs-popover-right {
      .arrow:before {
        border-right-color: $theme-dark-border-color;
      }
    }

    &.bs-popover-bottom {
      .arrow:before {
        border-bottom-color: $primary;
      }
    }

    &.bs-popover-left {
      .arrow:before {
        border-left-color: $theme-dark-border-color;
      }
    }
  }

	.ng-select:not(.placeholder-txt),
	.ng-select div:not(.placeholder-txt),
	.ng-select input:not(.placeholder-txt),
	.ng-select span:not(.placeholder-txt) {
	  color: $theme-dark-body-color;
	}

  .title-color {
    color: $theme-dark-body-color;
  }

  .subtitle-color {
    color: $theme-dark-body-color;
  }

  .sekeleton-card {
    background-color: $theme-dark-card-bg;
  }

  .loader {
    background: $theme-dark-table-header-bg no-repeat !important;

    &.progress {
      &::before {
        background-image: linear-gradient(90deg, rgba($color: darken($color: $theme-dark-card-bg, $amount: 0.15), $alpha: 1.0), rgba($color: darken($color: $theme-dark-card-bg, $amount: 0.15), $alpha: 1.0), rgba($color: darken($color: $theme-dark-card-bg, $amount: 0.15), $alpha: 1.0)) !important;
      }
    }
  }

  .grid-header {
    background-color: $theme-dark-card-bg;
  }
}
