// ================================================================================================
// 	File Name: semi-dark-layout.scss
// 	Description: SCSS file for semi dark layout.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

@import '../bootstrap-extended/include'; // Bootstrap includes
@import '../components/include'; // Components includes

.semi-dark-layout {
  /************ Main Menu **************/

  // main menu
  .main-menu {
    background-color: $theme-dark-card-bg;
    &.menu-light{
      background: $theme-dark-card-bg;
    }
    	// border color
	.border,
	.border-top,
	.border-right,
	.border-bottom,
	.border-left {
		border-color: $theme-dark-border-color !important;
	}

    .shadow-bottom {
      background: linear-gradient(
        180deg,
        $theme-dark-card-bg 44%,
        rgba($theme-dark-card-bg, 0.51) 73%,
        rgba($theme-dark-card-bg, 0)
      );
    }
    &:not(.expanded) {
      .navigation {
        .sidebar-group-active {
          > a {
            background-color: $theme-dark-body-bg !important;
          }
        }
      }
    }
    .vertical-menu {
      .main-menu-content {
        // Angular Custom Scrollbar //
        .ng-scrollbar {
          &.custom-scrollbar {
            --scrollbar-thumb-color: rgba(255, 255, 255, 0.35);
            --scrollbar-thumb-hover-color: rgba(255, 255, 255, 0.5);
            --scrollbar-track-color: rgba(255, 255, 255, 0.15);
          }
        }
        // End of Angular Custom Scrollbar //
      }
    }
  }

  // main menu content
  .main-menu{
    .main-menu-content {
      .navigation-main {
        li {
          a {
            color: $theme-dark-body-color;
            svg {
              fill: $theme-dark-headings-color !important;
              path {
                fill: $theme-dark-headings-color !important;
              }
            }
          }
        }
        background-color: $theme-dark-card-bg;
        .navigation-header {
          color: $theme-dark-text-muted-color;
        }
        .nav-item {
          a {
            color: $theme-dark-body-color;
          }
          &.open:not(.sidebar-group-active) {
           > a {
              background-color: $theme-dark-body-bg;
            }
          }
          a:after,
          a {
            color: $theme-dark-body-color !important;
          }
          .menu-content {
            background-color: $theme-dark-card-bg;
            .active {
              .menu-item {
                color: $white;
              }
            }
            li:not(.active) {
              a {
                background-color: $theme-dark-card-bg;
              }
            }
          }
        }
        .active {
          > a {
            color: $white !important;
          }
          .menu-title,
          i {
            color: $white;
          }
        }
        .sidebar-group-active {
          a {
            background: $theme-dark-card-bg;
            border-radius: 4px;
          }
        }
        .menu-content {
          background-color: $theme-dark-card-bg;
          .active {
            z-index: 1;
            a {
              background-color: transparent;
            }
          }
        }
      }
    }
  }


  // footer menu content
  // .footer-menu-content {
  //   .navigation-main {
  //     background-color: $theme-dark-card-bg;
  //     .navigation-header {
  //       color: $theme-dark-text-muted-color;
  //     }
  //     .nav-item {
  //       a {
  //         color: $theme-dark-body-color;
  //       }
  //       &.open {
  //         a {
  //           background-color: $theme-dark-body-bg;
  //         }
  //       }
  //       a:after,
  //       a {
  //         color: $theme-dark-body-color;
  //       }
  //       .menu-content {
  //         background-color: $theme-dark-card-bg;
  //         .active {
  //           .menu-item {
  //             color: $white;
  //           }
  //         }
  //         li:not(.active) {
  //           a {
  //             background-color: $theme-dark-card-bg;
  //           }
  //         }
  //       }
  //     }
  //     .active {
  //       .menu-title,
  //       i {
  //         color: $white;
  //       }
  //     }
  //     .sidebar-group-active {
  //       a {
  //         background: $theme-dark-card-bg;
  //         border-radius: 4px;
  //       }
  //     }
  //     .menu-content {
  //       background-color: $theme-dark-card-bg;
  //       .active {
  //         z-index: 1;
  //         a {
  //           background-color: transparent;
  //         }
  //       }
  //     }
  //   }
  // }
	.footer-menu-content {
		.navigation {
			background-color: $theme-dark-card-bg !important;
			color: $theme-dark-headings-color !important;
			li {
				a {
					color: $theme-dark-headings-color !important;
          svg {
            fill: $theme-dark-headings-color !important;
            path {
              fill: $theme-dark-headings-color !important;
            }
          }
				}
				span {
					color: $theme-dark-headings-color !important;
				}
			}
			.product-togale {
				.header-menu-item {
					.btn {
						svg {
							stroke: $theme-dark-headings-color !important;
						}
						span {
							color: $theme-dark-headings-color !important;
						}
					}
				}
			}
		}
	}
  // menu collapsed active item color
  .menu-collapsed {
    .main-menu:not(.expanded) {
      .navigation-main {
        li.active {
          > a {
            background: $theme-dark-body-bg;
            // color: $white;
          }
        }
      }
    }
  }
}
