// Perfect scrollbar
@import "perfect-scrollbar/css/perfect-scrollbar";
@import "node-waves/src/scss/waves.scss";
@import "@circlon/angular-tree-component/css/angular-tree-component.css";
// Core styles
@import "./base/bootstrap";
@import "./base/bootstrap-extended";
@import "./base/bootstrap-extended/forms";
@import "./base/colors";
@import "./base/components";
@import "./base/themes/dark-layout";
@import "./base/themes/bordered-layout";
@import "./base/themes/semi-dark-layout";
// @import "./base/themes/fluent-layout.scss";
@import "./base/themes/fixed-layout.scss";
@import "./base/core/colors/palette-gradient";
@import "./base/plugins/forms/form-validation";
// Added for form validation style support
@import "./base/components/include";
@import "@core/scss/angular/libs/select.component.scss";
@import "@core/scss/angular/libs/quill-editor.component";
@import "@core/scss/angular/libs/ratings.component.scss";
// Vue Specific
@import "./angular/index";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "@core/scss/angular/libs/flatpickr.component.scss";
@import "@core/scss/angular/libs/datatables.component.scss";
@import "@core/scss/angular/libs/form-wizard.component.scss";
@import "@core/scss/angular/libs/date-time-picker.component.scss";
@import "@core/scss/angular/libs/sweet-alerts.component.scss";
@import "@core/scss/angular/libs/toastr.component.scss";
@import "@core/scss/angular/libs/noui-slider.component.scss";
// @import "@core/scss/base/pages/app-calendar.scss";
// @import "~@fullcalendar/daygrid/main.css";
// Responsive Mixin //
@mixin max-mq($max-screen-size) {
	@media (max-width: ($max-screen-size +"px")) {
		@content;
	}
}

@mixin min-mq($min-screen-size) {
	@media (min-width: ($min-screen-size +"px")) {
		@content;
	}
}

@mixin max-height($max-screen-size) {
	@media (max-height: ($max-screen-size +"px")) {
		@content;
	}
}

// End of Responsive Mixin //

// Animation
@-webkit-keyframes heartbeat {
	from {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: center center;
		transform-origin: center center;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	10% {
		-webkit-transform: scale(0.91);
		transform: scale(0.91);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	17% {
		-webkit-transform: scale(0.98);
		transform: scale(0.98);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	33% {
		-webkit-transform: scale(0.87);
		transform: scale(0.87);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	45% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}
@keyframes heartbeat {
	from {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: center center;
		transform-origin: center center;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	10% {
		-webkit-transform: scale(0.91);
		transform: scale(0.91);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	17% {
		-webkit-transform: scale(0.98);
		transform: scale(0.98);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	33% {
		-webkit-transform: scale(0.87);
		transform: scale(0.87);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	45% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}

@-webkit-keyframes jello-horizontal {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	30% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		-webkit-transform: scale3d(1.15, 0.85, 1);
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@keyframes jello-horizontal {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	30% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		-webkit-transform: scale3d(1.15, 0.85, 1);
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@-webkit-keyframes scale-in-hor-left {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		opacity: 1;
	}
}

@keyframes scale-in-hor-left {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 0% 0%;
		transform-origin: 0% 0%;
		opacity: 1;
	}
}

@-webkit-keyframes fade-in-top {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in-top {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@-webkit-keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}

@-webkit-keyframes scale-out-hor-left {
	0% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		opacity: 1;
	}
}

@keyframes scale-out-hor-left {
	0% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
		opacity: 1;
	}
}

@-webkit-keyframes scale-in-hor-right {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
		opacity: 1;
	}
}

@keyframes scale-in-hor-right {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
		opacity: 1;
	}
}

@-webkit-keyframes scale-in-ver-bottom {
	0% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		opacity: 1;
	}
}

@keyframes scale-in-ver-bottom {
	0% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 0% 100%;
		transform-origin: 0% 100%;
		opacity: 1;
	}
}

@-webkit-keyframes scale-in-ver-top {
	0% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
		opacity: 1;
	}
}

@keyframes scale-in-ver-top {
	0% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
		opacity: 1;
	}
}

@-webkit-keyframes scale-out-ver-top {
	0% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
		opacity: 1;
		display: none;
	}
}

@keyframes scale-out-ver-top {
	0% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
		opacity: 1;
	}
	100% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
		opacity: 1;
		display: none;
	}
}

// Animation
// End of Tag select //
body .swal2-container {
	z-index: 2004;
}
body {
	.app-content {
		.inner-main-content {
			.header-navbar-shadow {
				display: none !important;
			}
		}
	}
}
// Comman Class Css //
.head1 {
	font-size: $size-28-px !important;
	font-weight: 500;
	line-height: 1.5 !important;
	color: $headings-color !important;
	&.line-height-1 {
		line-height: 1 !important;
	}
}

.head2 {
	font-size: $size-24-px !important;
	font-weight: 500;
	line-height: 1.5 !important;
	color: $headings-color !important;
	&.line-height-1 {
		line-height: 1 !important;
	}
}

.head3 {
	font-size: $size-20-px !important;
	font-weight: 500;
	line-height: 1.5 !important;
	color: $headings-color !important;
	&.line-height-1 {
		line-height: 1 !important;
	}
}

.head4 {
	font-size: $size-18-px !important;
	font-weight: 500;
	line-height: 1.5 !important;
	color: $headings-color !important;
	&.line-height-1 {
		line-height: 1 !important;
	}
}

.head5 {
	font-size: $size-16-px !important;
	font-weight: 500;
	line-height: 1.5 !important;
	color: $headings-color !important;
	&.line-height-1 {
		line-height: 1 !important;
	}
}

.head6 {
	font-size: $size-14-px !important;
	font-weight: 500;
	line-height: 1.5 !important;
	color: $headings-color !important;
	&.line-height-1 {
		line-height: 1 !important;
	}
}

.reg-txt {
	font-size: $size-14-px !important;
	font-weight: 400;
	line-height: 1.5 !important;
	color: $body-color;
	&.line-height-1 {
		line-height: 1 !important;
	}
}

.sm-txt {
	font-size: $size-12-px !important;
	font-weight: 400;
	line-height: 1.5 !important;
	&.line-height-1 {
		line-height: 1 !important;
	}
	//   color: $body-color;
}

.ex-sm-txt {
	font-size: $size-10-px !important;
	font-weight: 400;
	line-height: 1.5 !important;
	&.line-height-1 {
		line-height: 1 !important;
	}
}

.over-line-large {
	font-size: $size-14-px !important;
	font-weight: 400;
	line-height: 1.5 !important;
	&.line-height-1 {
		line-height: 1 !important;
	}
}

.over-line-default {
	font-size: $size-12-px !important;
	font-weight: 400;
	line-height: 1.5 !important;
	&.line-height-1 {
		line-height: 1 !important;
	}
}

// Customize Head and body text //
body {
	.tooltip {
		z-index: 2003;
	}
}
.body {
	@extend .reg-txt;
}

.vertical-layout {
	&.menu-collapsed {
		.main-menu {
			width: 63px !important;
			&.expanded {
				width: 260px !important;
			}

			.main-menu-content {
				.navigation-main {
					li {
						a {
							padding: 9px 9px !important;
						}
					}
				}
			}
		}
	}
	.main-menu {
		.navbar-header {
			padding: 0.45rem 1rem 0.45rem 0.35rem;
			.navbar-nav {
				height: 100%;
				.nav-item {
					height: 100%;
					&.nav-toggle {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}
		&.expanded {
			width: 260px !important;
			.main-menu-content {
				.navigation-main {
					.navigation-header {
						svg {
							display: none;
						}
					}
				}
			}
		}
		.vertical-menu {
			.navbar-header {
				.navbar-nav {
					.nav-item {
						.navbar-brand {
							margin-top: 0;
							height: 100%;
							.brand-logo {
								img {
									max-width: 150px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.is-loading {
	position: relative !important;
	overflow: hidden;

	.loadding {
		height: 100%;
		position: absolute;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba($color: $gray-800, $alpha: 0.75);
		z-index: 10;
		&::before {
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: -1;
			content: "";
			backdrop-filter: blur(5px);
		}
		.spinner-border {
			color: #0078d4 !important;
		}
	}
}
.vertical-layout {
	.header-navbar {
		&.bg-transparent {
			background-color: $card-bg !important;
		}
		&.self-service-navbar {
			left: 0px;
			width: 100% !important;
			// padding: 0px 5%;
			.self-service-nav {
				.navbar-collapse {
					.navbar-nav {
						.nav-item {
							.nav-link {
								padding-left: 12px !important;
								padding-right: 12px !important;
								color: $title-color;
								font-size: $size-12-px !important;
								@include max-mq(1300) {
									padding-left: 10px !important;
									padding-right: 10px !important;
								}
							}

							&.active {
								color: $primary;
								position: relative;

								.nav-link {
									color: $primary !important;
									font-weight: 500;
								}

								&::before {
									position: absolute;
									content: "";
									width: 80%;
									height: 2px;
									border-radius: 2px 2px 0px 0px;
									margin: 0px auto;
									background-color: $primary;
									bottom: -12px;
									left: 10%;
								}
							}
						}
					}
				}
			}

			.self-service-navbar-brand {
				display: block !important;
			}
		}
	}

	.app-content {
		&.self-service-content {
			margin-left: 0px;

			.inner-main-content {
				// padding: 1rem 5% !important;
			}
		}
	}

	.h1,
	h1 {
		@extend .head1;
	}

	.h2,
	h2 {
		@extend .head2;
	}

	.h3,
	h3 {
		@extend .head3;
	}

	.h4,
	h4 {
		@extend .head4;
	}

	.h5,
	h5 {
		@extend .head5;
	}

	.h6,
	h6 {
		@extend .head6;
	}

	.small,
	small {
		@extend .sm-txt;
	}

	.p,
	p {
		@extend .reg-txt;
	}

	.text-muted {
		color: $text-muted;
	}
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

// Customize Head and body text //

// Angular Custom Scrollbar //
.ng-scrollbar {
	&.custom-scrollbar {
		--scrollbar-color: transparent;
		--scrollbar-container-color: transparent;
		--scrollbar-track-color: rgba(0, 0, 0, 0.15);
		--scrollbar-thumb-color: rgba(0, 0, 0, 0.25);
		--scrollbar-thumb-hover-color: rgba(0, 0, 0, 0.4);
		--scrollbar-border-radius: 4px;
		--scrollbar-size: 4px;
		--scrollbar-hover-size: 10px;
		--scrollbar-padding: 2px;
		--scroll-view-margin: 0;
		--scroll-view-color: transparent;

		&:hover {
			.ng-scrollbar-wrapper {
				.scrollbar-control {
					&[scrollable="false"] {
						.ng-scrollbar-thumb {
							display: block !important;
						}
					}

					opacity: 1;
					// &:hover {
					//   .ng-scrollbar-track {
					//     width: calc(var(--vertical-scrollbar-size) * 2) !important;
					//   }
					// }
				}
			}
		}

		.ng-scrollbar-wrapper {
			.ng-scroll-viewport-wrapper {
				.ng-scroll-viewport {
					width: 100%;

					.ng-scroll-content {
						padding-right: 0px;
						// min-height: 100%;
						// max-height: calc(100vh - 3.5rem);
						// min-height: calc(100% - 3.5rem);
						// display: contents;
					}
				}
			}
			&[deactivated="true"] {
				.ng-scroll-viewport-wrapper {
					.ng-scroll-viewport {
						overflow: visible !important;
					}
				}
			}
			.scrollbar-control {
				opacity: 0;
			}

			scrollbar-x {
				&.scrollbar-control {
					display: flex;
					align-items: flex-end;
				}
			}

			scrollbar-y {
				&.scrollbar-control {
					display: flex;
					justify-content: flex-end;
				}
			}
		}
		&.grid-full-height {
			.ng-scrollbar-wrapper {
				.ng-scroll-viewport-wrapper {
					.ng-scroll-viewport {
						.ng-scroll-content {
							min-height: 100%;
							display: grid;
						}
					}
				}
			}
		}
		&.max-full-width {
			.ng-scrollbar-wrapper {
				.ng-scroll-viewport-wrapper {
					.ng-scroll-viewport {
						.ng-scroll-content {
							max-width: 100%;
						}
					}
				}
			}
		}
		&.contain-initial {
			.ng-scrollbar-wrapper {
				.ng-scroll-viewport-wrapper {
					.ng-scroll-viewport {
						width: 100%;
						contain: initial !important;
					}
				}
			}
		}
	}
}

// End of Angular Custom Scrollbar //

// Browser Scroll Bar //
.browser-scrollbar {
	scrollbar-width: none;
	scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.15);
	overflow: auto;

	&:hover {
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			height: 6px !important;
			width: 6px !important;
			opacity: 1 !important;
			transition: all 0.45s ease-in;
		}

		&::-webkit-scrollbar-track {
			height: 6px !important;
			width: 6px !important;
			opacity: 1 !important;
			transition: all 0.45s ease-in;
		}

		&::-webkit-scrollbar-thumb {
			height: 6px !important;
			width: 6px !important;
			background-color: rgba(0, 0, 0, 0.4);
			opacity: 1 !important;
			transition: all 0.45s ease-in;
		}
	}

	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
		transition: background-color 0.2s linear, opacity 0.2s linear;
		-webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
		opacity: 0;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		height: 0px;
		width: 0px;
		background-color: rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		opacity: 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		opacity: 0;
		width: 0px;
		opacity: 0;
	}
}
// End of Browser Scroll Bar//
// Menu NGX Scroll Bar //
.main-menu-content {
	.ng-scrollbar {
		&.custom-scrollbar {
			&.main-menu-scroll {
				.ng-scroll-viewport-wrapper {
					.ng-scroll-content {
						width: inherit;
						.navigation {
							&.navigation-main {
								li {
									&:first-of-type {
										margin-top: $size-4-px;
									}
									&.nav-item {
										&:first-of-type {
											margin-top: $size-4-px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.navigation-main {
		&.navigation {
			li.nav-item {
				a {
					svg {
						transform: none !important;
					}
				}
			}
		}
	}
}
// Menu NGX Scroll Bar //
.modal {
	z-index: 2003 !important;
}

.modal-backdrop {
	z-index: 2002 !important;
}

a[type="button"] {
	-webkit-appearance: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
	display: none;
}

// For flex full Height //
.full-body-height {
	height: calc(100vh - #{$navbar-height} - 2rem - 1rem);
	display: flex;
	flex-direction: column;
	.full-body-height {
		height: 100% !important;
	}
	.initial-height {
		flex: initial;
	}

	.flex-height {
		flex: 1%;
	}
	.inherit-height {
		height: calc(100vh - #{$navbar-height} - 2rem - 1rem) !important;
	}
}

// End of For flex full Height //

// NGX-datatable //
.ngx-datatable {
	&.bootstrap {
		&.core-bootstrap {
			&.hide-footer {
				.datatable-footer {
					display: none !important;
				}
			}

			.visible {
				.datatable-header {
					height: 30px !important;
					width: 100% !important;
					.datatable-header-inner {
						.datatable-row-center {
							.datatable-header-cell {
								padding: 0.5rem 1rem !important;
								line-height: 16px !important;
								font-size: $size-10-px !important;
								font-weight: 600 !important;

								.sort-btn {
									margin-top: -3px;
								}

								.datatable-header-cell-wrapper {
									.datatable-header-cell-label {
										line-height: 16px !important;
										font-size: $size-10-px !important;
										text-transform: uppercase;
										color: $title-color;
									}
								}
							}
						}
					}
				}

				.datatable-body {
					width: 100% !important;
					@extend .browser-scrollbar;

					.datatable-row-wrapper {
						.datatable-body-row {
							width: 100% !important;

							.datatable-body-cell {
								.datatable-body-cell-label {
									font-size: $size-12-px !important;

									> * {
										font-size: $size-12-px !important;
									}
								}
							}
						}
					}
				}

				.datatable-footer {
					width: inherit;
					border-radius: 0px;
					overflow: hidden;
					flex: initial;
					border-top-left-radius: 0px;
					border-top-right-radius: 0px;

					.datatable-footer-inner {
						height: 40px !important;

						@include max-mq(767) {
							height: 80px !important;
						}

						> div {
							display: flex !important;
							align-items: center;
							padding-top: 0 !important;
							border-top: 0 !important;

							@include max-mq(767) {
								flex-wrap: wrap;
								> div {
									&:nth-child(2) {
										order: 3;
										width: 100%;
										display: flex;
										align-items: center;
										justify-content: center;
										margin: 4px 0px;

										.datatable-pager {
											display: flex;
											justify-content: center;
											margin-right: 0px !important;
										}
									}
								}
							}

							label {
								margin-bottom: 0 !important;
							}
						}

						.datatable-pager {
							margin: 2px 1rem;
							@include max-mq(600) {
								display: flex;
								justify-content: flex-end;
								margin-right: 1rem !important;
							}

							.pager {
								height: 30px;

								li {
									margin: 1px 0 !important;

									&:not(.disabled) {
										&.active {
											a {
												width: 28px;
												height: 28px;
												padding: 3px;
											}
										}
									}

									a {
										height: 20px;
										min-width: 24px;
									}
								}
							}
						}

						.page-count {
							display: none;
						}
					}
				}
			}
		}
	}
}

// End of NGX-datatable //
// NGX-datatable grid skeleton //
app-grid-skeleton {
	.sekeleton-card {
		.grid-header {
			height: 30px !important;
			.grid-head-txt {
				padding: 0.5rem 1rem !important;
				line-height: 16px !important;
				font-size: $size-10-px !important;
				text-transform: uppercase;
				color: $title-color;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 200px;
				display: block;
			}
		}
	}
}

// NGX-datatable grid skeleton //
.bookmark-btn {
	color: $primary;
}

.modal-btn-group {
	padding: $size-12-px $size-14-px;
}

.tooltip-discription {
	display: flex;
	align-items: center;

	.info-icon {
		background-color: $text-muted;
		width: 14px;
		height: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
	}

	&:hover {
		.info-icon {
			background-color: $primary;
		}
	}
}

// End of Comman Class Css //

// Custom Scroll //
.ps > .ps__rail-x,
.ps > .ps__rail-y {
	opacity: 0.6 !important;
	z-index: 999;
	left: auto !important;
	right: 0 !important;
}

//.ps > .ps__rail-x,
//.ps > .ps__rail-y {
//opacity: 0.6 !important;
//z-index: 999;
//left: auto !important;
//right: 0 !important;
//}
//.ps {
//	overflow: hidden !important;
//	overflow-anchor: none;
//	-ms-overflow-style: none;
//	touch-action: auto;
//	-ms-touch-action: auto;
//}

/*
 * Scrollbar rail styles
 */
/*.ps__rail-x {
	display: none;
	opacity: 0;
	transition: background-color 0.2s linear, opacity 0.2s linear;
	-webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
	height: 15px;
	/* there must be 'bottom' or 'top' for ps__rail-x 
	bottom: 0px;
	/* please don't change 'position' 
	position: absolute;
}

.ps__rail-y {
	display: none;
	opacity: 0;
	transition: background-color 0.2s linear, opacity 0.2s linear;
	-webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
	width: 15px;
	/* there must be 'right' or 'left' for ps__rail-y 
	right: 0;
	/* please don't change 'position' 
	position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
	display: block;
	background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
	opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
	background-color: #eee;
	opacity: 0.9;
}
*/
// End of Custom Scroll

// Custom CSS //
.vertical-layout {
	// main App Content //
	&.vertical-menu-modern {
		.footer {
			&.self-service-footer {
				border-top: 0px;
				margin-left: 0px !important;
				padding: 0.8rem 5% !important;
			}
		}
	}

	// main App Content //
	.app-content.content {
		min-height: 100vh;
		height: 100% !important;
		overflow: auto;

		.inner-main-content {
			padding: 0rem 1rem;
		}

		&.self-service-content {
			margin-left: 0px;

			.inner-main-content {
				// padding: 1rem 5% !important;
				// Update Height For WorkSpace //
				app-work-space {
					.full-body-height {
						&.work-space {
							height: calc(100vh - 7.35rem);
						}
					}
				}
				//End of Update Height For WorkSpace //
			}
		}
	}

	&.menu-collapsed {
		&.vertical-overlay-menu {
			.main-menu {
				@include max-mq(1199) {
					width: 260px !important;
				}
			}
		}
	}

	// End of main App Content //
	// Header Navbar //
	.header-navbar {
		.navbar-brand {
			display: none;
		}

		&.floating-nav {
			z-index: 15;
		}

		.navbar-container {
			.navbar-nav {
				.nav-item {
					& > a {
						-webkit-appearance: none;
					}
				}
			}
		}

		&.bg-white {
			.navbar-container {
				.navbar-nav {
					.nav-item {
						& > a {
							color: #6e6b7b !important;

							span {
								color: #6e6b7b !important;
							}

							svg {
								color: #6e6b7b !important;
							}

							i {
								color: #6e6b7b !important;
							}
						}
					}
				}
			}
		}
	}

	// End of Header Navbar
	// Side Menu //
	.main-menu {
		height: 100%;
		.vertical-menu {
			height: 100%;
			vertical-menu {
				display: flex;
				flex-direction: column;
				height: 100%;
				.navbar-header {
					flex: initial;
				}
				.main-menu-content {
					flex: 1%;
					padding-bottom: 1rem;
				}
				.footer-menu-content {
					flex: initial;
					.navigation {
						position: unset;
					}
					ul.getStart {
						position: unset;
						bottom: 0;
					}
				}
			}
			app-msp-menu {
				&.menus {
					height: 100%;
					display: flex;
					flex-direction: column;
					.navbar-header {
						flex: initial;
					}
					.main-menu-content {
						flex: 1%;
					}
				}
			}
		}
		&.support-menu {
			.support-menu {
				&.vertical-menu {
					height: 100%;
					app-support-menu {
						&.menus {
							height: 100%;
							display: flex;
							flex-direction: column;
							.navbar-header {
								flex: initial;
							}
							.main-menu-content {
								flex: 1%;
							}
						}
					}
				}
			}
		}
		&.menu-dark {
			.vertical-menu {
				.footer-menu-content {
					.navigation {
					}
				}
			}
		}

		&.expanded {
			.vertical-menu {
				.footer-menu-content {
					.navigation {
						.product-togale {
							.header-menu-item {
								span {
									display: inline-flex;
								}
							}
						}
					}
				}
			}
		}

		&:not(.expanded) {
			.vertical-menu {
				.footer-menu-content {
					.navigation {
						.product-togale {
							.header-menu-item {
								margin-bottom: 0 !important;

								span {
									@include min-mq(1200) {
										display: none;
									}
								}
							}
						}

						.navigation-header {
							margin-left: 2.1rem !important;
						}
					}
				}
			}
		}

		.vertical-menu {
			.main-menu-content {
				// height: calc(100% - 4.5rem - 9.5rem) !important;
			}

			.footer-menu-content {
				.navigation {
					// background-color: transparent;
					// margin-left: -0.4rem;
					// li {
					// 	a {
					// 		padding: 7px 16px 7px 16px !important;
					// 	}
					// }
					&.navigation-main {
						li {
							&:first-of-type {
								margin-top: 0px !important;
							}
						}
					}
					&.getStart {
						@include max-mq(1200) {
							bottom: -8px;
						}
					}

					.product-togale {
						.header-menu-item {
							margin-bottom: 0 !important;
							margin: 2px 14px;

							.btn {
								padding: 0.786rem 1rem;
								color: #625f6e;
								line-height: 1.45;
								font-weight: normal;

								svg {
									width: 16px;
									height: 20px;
									margin-right: 1.1rem;
								}
							}
						}
					}

					.navigation-header {
						margin-top: 1rem;
						margin-left: 2rem;
					}
				}
			}
		}
	}

	// End of Side Menu //
	// Company Product list //
	@-webkit-keyframes slide-right {
		0% {
			-webkit-transform: translateX(-340px);
			transform: translateX(-340px);
		}
		100% {
			-webkit-transform: translateX(0);
			transform: translateX(0);
		}
	}
	@keyframes slide-right {
		0% {
			-webkit-transform: translateX(-340px);
			transform: translateX(-340px);
		}
		100% {
			-webkit-transform: translateX(0);
			transform: translateX(0);
		}
	}

	.company-product {
		height: 100%;
		width: 340px;
		position: fixed;
		left: 0;
		top: 0;
		background-color: $white;
		z-index: 9999;
		box-shadow: rgba($color: $dark-black, $alpha: 0.22) 0px 25.6px 57.6px 0px,
			rgba($color: $dark-black, $alpha: 0.18) 0px 4.8px 14.4px 0px;
		display: none;

		&.active {
			display: block;
			-webkit-animation: slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
			animation: slide-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
		}

		.product-list-head {
			.product-nav {
				.header-menu-link {
					.menulink-navmenu {
						padding: 0;
						width: 34px;
						height: 34px;
						margin: 5px;
						//@extend .flex-center;
						border-radius: 100%;
						position: absolute;
						right: 0;
						background-color: rgba($color: $dark-black, $alpha: 0.22);

						svg {
							stroke: $gray-800;
							//
							width: 18px;
							height: 22px;
						}
					}
				}
			}
		}

		.product-list-main {
			.product-list-heading {
				margin: 20px 0 20px 18px;

				h3 {
					color: #252423;
					font-size: 22px;
					line-height: normal;
				}
			}

			.product-detail-list {
				padding: 5px 8px;

				.product-ul {
					padding-left: 0;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					list-style: none;

					.product-li {
						height: 48px;

						a {
							display: flex;
							align-items: center;
							height: 100%;

							&:hover {
								border-color: #f4f4f4 !important;
								box-shadow: 0 8px 16px 0 rgba($color: $dark-black, $alpha: 0.1);
								transition: border-color 0.167s linear, box-shadow 0.167s linear;
							}

							.product-img {
								img {
									width: 26px;
									height: auto;
									margin: 0px 10px;
								}
							}

							.product-name {
								span {
									color: #252423;
									font-size: 16px;
								}
							}
						}
					}
				}
			}
		}
	}

	// End of Company Product list //
	// Custom Export Data
	.custom-table {
		.mat-table {
			thead {
				.mat-header-row {
					height: 42px;

					.mat-header-cell {
						background-color: #f3f2f7;
						font-size: 14px;
					}
				}
			}

			tbody {
				.mat-row {
					height: 40px;

					.mat-cell {
						font-size: 12px;
					}
				}
			}
		}
	}

	// End of Custom Export Data
}

// Toggle menu
.toggle-btn {
	padding: 10px 2px 8px !important;

	.mat-icon {
		width: 20px;
		font-size: 20px;
	}
}

// Has Notification //
.has-notification {
	&.fluent-layout {
		.app-content {
			&.content {
				.inner-main-content {
					.full-body-height {
						height: calc(100vh - 50px - 38px);
					}
				}
			}
		}
	}

	&.fixed-layout {
		.header-navbar {
			top: calc(38px);
		}

		.app-content {
			&.content {
				padding: calc(1rem + 4rem) 0 0;

				.inner-main-content {
					.full-body-height {
						height: calc(100vh - 4rem - 38px);
					}
				}
			}
		}
	}

	.vertical-layout {
		display: flex;
		flex-direction: column;

		.notification-bar {
			position: relative;
			z-index: 999;
			&.modal-open {
				position: relative;
			}
		}
	}

	.app-content {
		&.content {
			min-height: calc(100vh - 38px);
			padding: calc(1rem + 4rem + 1rem) 0 0;

			.inner-main-content {
				.full-body-height {
					height: calc(100vh - 4rem - 3rem - 38px);
				}
			}
		}
	}

	.main-menu {
		// height: calc(100% - 38px);
	}

	.header-navbar {
		top: calc(38px + 1rem);
		margin-top: 0px;
	}
}

// End of Has Notification //

.mat-menu-content {
	padding-top: 4px !important;
	padding-bottom: 4px !important;

	.mat-menu-item {
		line-height: 42px !important;
		height: 42px !important;
		padding: 0 14px !important;
	}
}

// End of Toggle menu

// Sub Header menu Toggle
.subheader {
	// height: 45px;
	// width: 100%;
	// padding: 6px 4px;
	// position: relative;
	// z-index: 1;
	// display: flex;
	// align-items: center;
	// background-color: $white;
	// box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
	// margin-bottom: 0.8rem;
	// border-radius: 4px;
	height: 30px;
	width: 30px;
	padding: 6px 0px;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	background-color: transparent;
	//border-radius: 4px;
	border-radius: 4.357rem;

	.toggale-submenu {
		.submenu-toggle {
			padding: 0;
			width: 29px;
			height: 40px;
			border-radius: 0;

			&:hover {
				// background-color: darken($color: #e6e6e6, $amount: 5);
			}

			svg {
				width: 22px;
				height: 22px;
			}
		}
	}
}

// End of Sub Header menu Toggle
// Multiple DropDown //
.custom-dropdown {
	border: 2px solid $primary;
	display: flex;
	// column-gap: 1rem;
	border-radius: 4px;
	padding: 4px;

	> * {
		margin-right: 1rem;

		&:last-of-type {
			margin-right: 0rem;
		}
	}

	@include max-mq(767) {
		flex-direction: column;
		padding: 8px 4px;
	}

	.form-group {
		margin-bottom: 0;
		display: flex;
		align-items: center;
		@include max-mq(767) {
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
		}

		.ng-select {
			&.has-value {
				.ng-select-container {
					// background-color: #e4e4e4;
				}
			}

			.ng-select-container {
				background-color: #f5f5f5;
				border-color: transparent !important;
			}

			&.ng-select-focused {
				&:not(.ng-select-opened) {
					.ng-select-container {
						box-shadow: none !important;
					}
				}
			}

			&.ng-select-opened {
				.ng-select-container {
					background-color: #f5f5f5;
					border-color: transparent !important;
				}
			}
		}

		.form-control {
			min-width: 240px;
			border-color: transparent;
			background-color: #f6f6f6;
			color: #2d2d2d;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%238C98F2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
			background-position: calc(100% - 12px) 11px, calc(100% - 20px) 13px, 100% 0;
			background-size: 18px 14px, 18px 14px;
			background-repeat: no-repeat;
			-webkit-appearance: none;
			-moz-appearance: none;
			padding-right: 1.5rem;
			@include max-mq(1023) {
				min-width: 184px;
			}

			&.has-value {
				background-color: #ddd;
			}

			&::-webkit-calendar-picker-indicator {
				opacity: 0;
			}
		}

		label {
			margin-left: 1rem;
			text-transform: capitalize;
			font-size: 14px;
			margin-bottom: 0;
			@include max-mq(767) {
				margin-left: 0.5rem;
				margin-top: 0.6rem;
				margin-bottom: 0.6rem;
			}
		}
	}
}

// End of Multiple DropDown //
// Stage Represantaion //
.custom-feature {
	padding: 0px 1.5rem;

	.custom-main {
		width: 100%;
		max-width: 100%;

		.custom-row {
			// display: flex;
			// justify-content: space-around;
			display: grid;
			justify-content: center;
			grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
			@include max-mq(767) {
				flex-direction: column;
				display: flex;
			}

			.dropdown {
				border-radius: 0px;
				padding: 0px;
				margin: 0px;
				padding-left: 0;
				padding-right: 0;
				margin-right: 0;
				margin-left: 0;
				width: 100%;
				@include max-mq(767) {
					display: flex;
					// column-gap: 1rem;
					margin: 12px;
					> * {
						margin-right: 1rem;

						&:last-of-type {
							margin-right: 0rem;
						}
					}
				}

				&:last-of-type {
					.multi-level {
						// left: auto !important;
						// right: 1em !important;
						@include max-mq(767) {
							left: 0 !important;
							right: auto !important;
						}

						.custom-down {
							// right: 12px !important;
							// left: auto !important;
							@include max-mq(767) {
								left: 7px !important;
								right: auto !important;
							}
						}

						.dropdown-menu {
							left: auto !important;
							right: 100% !important;
							border-radius: 6px !important;
						}
					}
				}

				hr {
					width: 100%;
					margin-bottom: -9px;
					border: 0;
					border-top: 1px solid $border-color;
					margin-top: 10px !important;
					// @include max-mq(1400){
					//   width: 180px;
					// }
					// @include max-mq(1250){
					//   width: 148px;
					// }
					// @include max-mq(992){
					//   width: 108px;
					// }
					@include max-mq(767) {
						border-top: 0px solid $close-color;
						margin-top: 0px !important;
						border-left: 2px solid $close-color;
						height: 86px;
						position: absolute;
						left: 12px;
						top: 10px;
					}
				}

				.dot {
					height: $size-18-px;
					width: $size-18-px;
					line-height: $size-16-px;
					display: flex !important;
					font-size: 10px;
					background: white;
					border-radius: 100%;
					border: 1px solid $border-color;
					position: relative;
					display: grid;
					align-items: center;
					justify-content: center;
					grid-template-columns: 100%;
					grid-template-rows: 100%;
					color: $white;

					&::after {
						opacity: 0;
					}

					i {
						color: $white;
						line-height: normal;
					}
				}

				.multi-level {
					list-style: none;
					margin: 0;
					padding: 0px;
					min-width: 10em;
					@include max-mq(767) {
						margin-top: 22px !important;
					}

					.custom-down {
						position: absolute;
						top: -17px;
						left: 4px;
						transform: rotate(45deg);
						-webkit-transform: rotate(45deg);
						border: solid $body-color;
						border-width: 0 1px 1px 0;
						display: inline-block;
						padding: 3px;
						@include max-mq(767) {
							left: 7px;
						}
					}

					.dropdown-item {
						&:active {
							color: $white;
						}
					}

					> .dropdown-item {
						padding: 0;
					}

					.dropdown-divider {
						display: none;
					}

					.dropdown-submenu {
						position: relative;

						&:hover {
							.dropdown-menu {
								display: block;
							}
						}

						> .dropdown-item {
							&::after {
								display: block;
								content: " ";
								float: right;
								width: 0;
								height: 0;
								border-color: transparent;
								border-style: solid;
								border-width: 5px 0 5px 5px;
								border-left-color: #ccc;
								margin-top: 5px;
								margin-right: -10px;
							}
						}

						.dropdown-menu {
							top: 0;
							left: 100%;
							margin-top: 0px;
							margin-left: -1px;
							border-radius: 0 6px 6px 6px;
						}
					}
				}
			}
		}
	}
}

// End of Stage Represantaion //

// sidebar for Toggle menu
body {
	.side-pannel {
		// width: 0;
		// transition: 800ms ease all;
		transition: 1200ms ease left;
		display: none;
		left: -240px;
	}

	&.hasSubmenu {
		.vertical-layout {
			&.vertical-menu-modern {
				&.navbar-hidden {
					.side-pannel {
						&.activeSidebar {
							top: #{$content-padding};
						}
					}
				}

				&.navbar-static {
					.side-pannel {
						&.activeSidebar {
							top: calc(#{$content-padding} + #{$navbar-height});
						}
					}
				}

				&.navbar-sticky {
					.side-pannel {
						&.activeSidebar {
							top: calc(#{$content-padding} + #{$navbar-height});
						}
					}
				}

				&.menu-collapsed {
					.app-content {
						margin-left: calc(#{$toggle-bar-width} + #{$menu-collapsed-width} + 1rem);
					}

					.footer {
						margin-left: calc(#{$toggle-bar-width} + #{$menu-collapsed-width} + 1rem);

						&.self-service-footer {
							border-top: 0px;
							margin-left: 0px !important;
							padding: 0.8rem 5% !important;
						}
					}
				}
			}
		}

		.side-pannel {
			.form-group {
				.custom-control {
					margin-bottom: 0.5rem;
				}
			}

			&.activeSidebar {
				width: $toggle-bar-width;
				height: calc(100% - #{$content-padding} - #{$navbar-height} - #{$floating-nav-margin} - 1.5rem);
				display: block;
				background: $white;
				position: fixed;
				z-index: 1;
				box-shadow: $box-shadow;
				left: calc(63px + 1rem);
				padding: 1.5rem 1rem;
				top: calc(#{$content-padding} + #{$navbar-height} + #{$floating-nav-margin});
				transition: 350ms ease all;
				-webkit-animation: scale-in-hor-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
				animation: scale-in-hor-left 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
				border-radius: 0.357rem;
				@include max-mq(1199) {
					left: 0;
					top: calc(#{$content-padding} + #{$navbar-height} + 4.6rem);
					overflow-y: auto;
				}
				@include max-mq(567) {
					top: calc(#{$content-padding} + #{$navbar-height} + 4rem);
				}
			}
		}
	}
}

// End of Sidebar for Toggle menu
.white-box {
	background-color: $white;
	border-radius: 4px;
	//display: inline-block;
	display: inline-table;
	//margin-bottom: 1rem;
	//border-radius: 0.357rem;
	border-radius: 4.357rem;
	box-shadow: $box-shadow;
}

// Custom List Down
.custom-listDown {
	width: 100%;

	.dropdown {
		width: inherit;

		&.show {
			.dropdown-toggle {
				&::after {
					transform: rotate(0deg);
				}
			}
		}

		.dropdown-toggle {
			width: inherit;
			text-align: left;
			padding-left: 2rem;

			&::after {
				left: 4px !important;
				right: auto !important;
				position: absolute !important;
				top: 13px;
				transform: rotate(-90deg);
			}
		}

		.dropdown-menu {
			position: unset !important;
			transform: translate(0px, 0px) !important;
			border: none !important;
			box-shadow: none !important;
			background-color: transparent !important;
			margin-top: 0;
			width: 100%;

			a.dropdown-item {
				padding: 0.45rem 1.28rem;
				font-weight: 500;
				padding-left: 3.4rem;
				position: relative;
				border-radius: 0.5px;

				&.selected {
					background-color: $primary;
					color: $white !important;
				}

				&:hover {
					span {
						display: block;
					}
				}

				span {
					position: absolute;
					right: 0;
					top: 5px;
					display: none;
				}
			}
		}
	}
}

// End of Custom List Down

// Asset Life Cycle
.asset-lifeCycle-main {
	padding-bottom: 9rem;
	position: relative;

	&.vertical-component {
		padding-bottom: 0rem;

		.life-cycle-line {
			.cycle-line {
				width: 12px;
				height: 80vh;
				@include max-mq(767) {
					height: 100vh;
				}
				@include max-mq(567) {
					height: 80vh;
				}

				.selected-cycle-line {
					width: 100%;
				}
			}

			.lifeCycle-data-list {
				.popover {
					.popover-body {
						border: 0px !important;
					}
				}

				.data-list {
					.data {
						left: 24px;
						display: grid;
						grid-template-columns: repeat(2, auto);
						grid-column-gap: 1rem;

						&:last-of-type {
							top: auto !important;
							bottom: 2px;
							left: 24px !important;
							right: auto;

							&:hover {
								&::before {
									left: -28px !important;
									bottom: 0px !important;
								}

								.detail-data {
									bottom: 40px;
									top: auto;
									right: auto !important;
									left: 0;
								}
							}

							&::before {
								left: -22px !important;
								bottom: 4px !important;
								top: auto !important;
							}
						}

						span.date {
							grid-column-start: 1;
						}

						.extra-detail {
							margin-top: 0rem;
							grid-row-start: 1;
							grid-column-start: 2;
						}

						&:hover {
							&::before {
								top: -6px;
								left: -28px;
							}
						}

						&::before {
							left: -22px;
							top: 0;
						}
					}
				}
			}
		}
	}

	.life-cycle-line {
		.cycle-line {
			height: 12px;
			background-color: #e6e6e6;
			border-radius: 40px;

			.selected-cycle-line {
				background-color: $primary;
				height: 100%;
				border-radius: 40px;
			}
		}

		.lifeCycle-data-list {
			position: relative;

			.data-list {
				.data {
					position: absolute;
					top: 8px;
					display: flex;
					flex-direction: column;
					text-align: center;

					.detail-data {
						display: none;
						position: absolute;
						min-width: 260px;
						max-width: 340px;
						background-color: $white;
						top: 40px;
						text-align: left;
						padding: $size-14-px;
						box-shadow: $box-shadow;
						border-radius: 10px;
						z-index: 9;

						.detail-head {
							display: flex;
							justify-content: space-between;
							margin-bottom: 0.8rem;

							span {
								font-weight: 500;
								text-transform: uppercase;
							}
						}

						.detail-info {
							.list {
								display: grid;
								gap: $size-8-px;

								.single-list {
									display: flex;
									align-items: center;
									justify-content: flex-start;
									column-gap: 0.8rem;

									> * {
										margin-right: 0.8rem;

										&:last-of-type {
											margin-right: 0rem;
										}
									}

									.badge {
										svg {
											height: 12px;
											width: 13px !important;
										}
									}

									span {
									}
								}
							}
						}
					}

					&:last-of-type {
						right: 0;
						left: auto !important;

						&:hover {
							&::before {
								right: 0px;
							}

							.detail-data {
								right: 0;
							}
						}

						&::before {
							right: 5px;
							left: auto !important;
						}

						&::after {
							right: 5px;
							left: auto !important;
						}
					}

					&.right-data {
						&:hover {
							&::before {
								right: 0px;
							}

							.detail-data {
								right: 0;
							}
						}
					}

					@include max-mq(1400) {
						> span {
							font-size: 12px;
							@include max-mq(1200) {
								font-size: 11px;
								font-weight: 500;
								line-height: 14px;
							}
						}
					}

					.extra-detail {
						text-align: left;
						margin-top: 0.8rem;
					}

					&.selected {
						&::before {
							background-color: $white;
						}

						&::after {
							position: absolute;
							content: "";
							width: 8px;
							height: 8px;
							background-color: $white;
							border-radius: 100%;
							top: -18px;
						}
					}

					&:hover {
						cursor: pointer;

						&::before {
							width: 20px;
							height: 20px;
							top: -24px;
							left: -6px;
							-webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
							animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
						}

						.detail-data {
							display: block;
							-webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
							animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
						}
					}

					&::before {
						position: absolute;
						content: "";
						width: 8px;
						height: 8px;
						background-color: $primary;
						border-radius: 100%;
						top: -18px;
					}

					&::after {
						position: absolute;
						content: "";
						width: 8px;
						height: 8px;
						background-color: $primary;
						border-radius: 100%;
						top: -18px;
					}
				}
			}
		}
	}
}

// end of Asset Life Cycle

// Custom Image Uploader
.upload-file-sec {
	display: flex;
	align-items: center;
	justify-content: space-between;
	// column-gap: 2rem;
	> * {
		margin-right: 2rem;

		&:last-of-type {
			margin-right: 0rem;
		}
	}

	@include max-mq(1023) {
		flex-direction: column;
		// row-gap: 1.5rem;
		> * {
			margin-bottom: 1.5rem;

			&:last-of-type {
				margin-bottom: 0rem;
			}
		}
	}

	.custom-img-uploader {
		//max-width: 260px;
		//height: 260px;
		width: 100%;
		height: 100%;
		position: relative;
		@include max-mq(1023) {
			max-width: 220px;
			height: 220px;
		}

		&.hasCropedImg {
			.uploaded-image {
				&.hasImg {
					display: flex;
				}

				&:hover {
					.remove-btn {
						display: flex;
					}
				}

				img {
					width: 100%;
					height: 100%;
					//border-radius: 5px;
					margin: auto;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}

		.uploaded-image {
			display: none;
			position: absolute;
			z-index: 9;
			top: 0;
			left: 0;
			width: 100%;

			.remove-btn {
				width: 28px;
				height: 28px;
				border-radius: 100%;
				background-color: $danger;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
				top: -12px;
				right: -12px;
				position: absolute;
				display: none;
			}
		}

		> div {
			height: 100%;

			.file-drop-zone {
				border: dashed 1px $primary;
				//border-radius: 5px;
				background-color: rgba($primary, 0.05) !important;
				height: 100%;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				// row-gap: 0.5rem;
				display: block;

				> * {
					margin-bottom: 0.5rem;

					&:last-of-type {
						margin-bottom: 0rem;
					}
				}

				.upload-txt {
					display: grid !important;
					flex-direction: column;
					margin-top: -18%;
					align-items: end !important;
				}

				&:before {
					content: "";
					//	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-download'%3E%3Cpath d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'%3E%3C/path%3E%3Cpolyline points='7 10 12 15 17 10'%3E%3C/polyline%3E%3Cline x1='12' y1='15' x2='12' y2='3'%3E%3C/line%3E%3C/svg%3E");
					font-size: 20px;
					position: absolute;
					top: 50%;
					width: 40px;
					height: 40px;
					display: block !important;
					left: 55%;
					margin-left: -40px;
					line-height: 1;
					z-index: 2;
					color: $primary;
					text-indent: 0;
				}
			}

			.file-drop-over {
				border: dotted 3px $danger;
			}

			.file-drop-over-another {
				border: dotted 3px $success;
			}
		}

		.dragarea {
			border: dashed 2px $primary;
			border-radius: 5px;
			background-color: rgba($primary, 0.05) !important;
			min-height: 5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			width: 100%;
			height: 100%;

			div {
				a {
					display: block;
					margin-top: 0.5rem;
				}
			}
		}

		.droparea {
			@extend .dragarea;
		}
	}

	.upload-file-list {
		width: 100%;

		.table {
			td,
			th {
				@include max-mq(767) {
					padding: 0.7rem 0.7rem;
				}
			}
		}
	}
}

// Image Cropped Modal
.file-upload-modal {
	.modal-dialog {
		min-height: calc(100% - 5rem) !important;
		margin: 2.5rem auto;
	}

	.img-crop-modal {
		.funtion-btn-group {
			padding: 0.5rem 0rem;
			margin-bottom: 1rem;
			display: flex;
			// column-gap: 1rem;
			> * {
				margin-right: 1rem;

				&:last-of-type {
					margin-right: 0rem;
				}
			}

			@include max-mq(567) {
				// column-gap: 0.25rem;
				> * {
					margin-right: 0.25rem;

					&:last-of-type {
						margin-right: 0rem;
					}
				}
			}

			.btn {
				padding: 0.5rem 0.8rem;
				@include max-mq(567) {
					padding: 0.5rem 0.6rem;
				}

				img {
					max-width: 18px;
				}
			}
		}

		.img-cropp-sec {
			display: flex;
			// column-gap: 2rem;
			> * {
				margin-right: 2rem;

				&:last-of-type {
					margin-right: 0rem;
				}
			}

			@include max-mq(767) {
				flex-direction: column;
				> * {
					margin-bottom: 1rem;

					&:last-of-type {
						margin-bottom: 0rem;
					}
				}
			}

			&.noCroped {
				.cropper {
					// display: none;
					height: 100% !important;
					width: 100% !important;
					opacity: 0 !important;
				}
			}

			.cropped-img {
				width: 154px;
				height: 154px;
				display: table;

				img {
					max-width: 100%;
					height: auto;
				}

				@include max-mq(767) {
					width: 100%;
					margin-top: 1.5rem !important;
				}

				.cropped-btn-grouped {
					@include max-mq(767) {
						text-align: left !important;
					}

					.btn {
						@include max-mq(767) {
							margin-right: 1rem !important;
						}
					}
				}
			}

			.image-cropper {
				padding: 0;
			}
		}
	}
}

// End of Images Cropped Modal
// End of custom Image Uploader

//  Select Feature\
.selct-feature {
	// display: grid;
	// grid-template-columns: 340px;
	// column-gap: 2.5rem;
	// @include max-mq(1023) {
	//   grid-template-columns: 100%;
	// }
	&.status-picker {
	}

	&.group-list-feature {
		&.status-list-picker {
			.form-group {
				.ng-select {
					.ng-select-container {
						.ng-value-container {
							.ng-value {
								.group-lists {
									flex-direction: row !important;

									.right-arrow {
										display: block !important;
									}

									.custom-squre {
										margin-left: 0 !important;
									}
								}
							}
						}
					}

					.ng-dropdown-panel {
						.ng-dropdown-panel-items {
							.ng-option {
								.group-lists {
									.custom-squre {
										margin-left: 0 !important;
									}

									span {
										white-space: break-spaces;
									}
								}
							}
						}
					}
				}
			}
		}

		.group-lists {
			font-weight: 500;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			margin-bottom: 0px;

			.avatar-group {
				margin-right: $size-8-px;
			}

			span {
			}
		}

		.select-sec {
			.form-group {
				.ng-select {
					.ng-select-container {
						.ng-value-container {
							.ng-value {
								.group-lists {
									display: flex;
									flex-direction: row-reverse;

									.right-arrow {
										display: none;
									}

									.avatar-group {
										order: 1;

										.avatar {
											margin-right: 0;
										}
									}

									.avatar {
										margin-right: $size-8-px;

										img {
											width: $size-14-px;
											height: $size-14-px;
										}
									}
								}
							}
						}
					}

					.ng-dropdown-panel {
						.ng-dropdown-panel-items {
							max-height: calc(36px * 6);

							@include max-mq(1023) {
								max-height: calc(36px * 4);
								min-height: calc(36px * 4);
							}

							.ng-option {
								.group-lists {
									.right-arrow {
										margin-left: auto;
									}
								}
							}
						}
					}
				}
			}
		}

		.detail-wrapper {
			border-radius: $size-8-px;
			box-shadow: 0px 16px 24px -4px rgba(17, 24, 38, 0.12);
			min-width: 220px;

			.detail-sec {
				padding: 0px;

				.single-user-list {
					.user-list-sec {
						.group-name {
							padding: $size-12-px;

							.avatar-group {
								margin-right: $size-8-px;
							}

							> span {
								font-size: $size-14-px;
								font-weight: 500;
								color: $body-color;
							}
						}

						.group-list {
							min-height: 168px;
							display: grid;

							.single-user {
								padding: $size-8-px $size-10-px;
								cursor: pointer;

								.custom-control {
									display: flex;
									align-items: center;
									padding-left: 0;

									.avatar {
										margin-right: $size-8-px;
									}

									.custom-control-label {
										font-size: $size-14-px;
										font-weight: 500;

										.user-info {
											display: flex;
											flex-direction: column;

											.user-level {
												// min-width: 18px;
												// min-height: 18px;
												// width: 18px;
												// height: 18px;
												// display: inline-flex;
												// align-items: center;
												// justify-content: center;
												// border-radius: 100%;
												// background-color: rgba($color: $primary, $alpha: 0.2);
												color: $primary;
												font-size: $size-10-px;
												font-weight: 500;
												margin-left: $size-4-px;
												// margin-top: $size-4-px;
												text-transform: capitalize;
											}

											.user-name {
												color: $title-color;
												font-weight: 500;
												margin-right: $size-4-px;
												font-size: $size-12-px;
											}

											.user-expertise {
												// margin-top: $size-4-px;
												line-height: 1;
												font-size: $size-10-px;
												color: $primary;
											}
										}

										&::before,
										&::after {
											left: auto;
											right: 0;
											top: 3px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.select-sec {
		position: relative;
		// max-width: 340px;
		@include max-mq(1023) {
			max-width: 100%;
			width: 100%;
		}

		.form-group {
			.form-label {
			}

			.ng-select {
				.ng-select-container {
					&.ng-has-value {
						.ng-value-container {
							.ng-value {
								a {
									display: none;
								}
							}
						}
					}
				}

				.ng-dropdown-panel {
					.ng-dropdown-panel-items {
						max-height: calc(36px * 4);

						.ng-option {
							display: flex;
							justify-content: space-between;

							span {
								width: 100%;
							}

							&.ng-option-selected {
								&.ng-option-marked {
									a {
										color: $primary !important;
									}
								}
								a {
									color: $primary !important;
								}
							}

							&:hover {
								a {
									display: block;
								}
							}

							a {
								margin-right: 0.7rem;
								font-size: 11px;
								font-weight: 500;
								display: none;
								color: $primary;
							}
						}
					}
				}
			}
		}
	}

	.detail-wrapper {
		width: fit-content;
		// margin-top: 1.6357rem;
		-webkit-animation: scale-out-hor-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
		animation: scale-out-hor-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
		display: none;
		position: absolute;
		z-index: 99;
		@include max-mq(1023) {
			width: 100%;
			position: absolute;
			// margin-top: 2rem;
			-webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
			animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
		}

		&.rightSec {
			left: 100%;
			top: 36px;
			-webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
			animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
			@include max-mq(1023) {
				left: 0;
				top: calc(100% - 0rem + calc(36px * 4));
				right: auto;
				bottom: auto;
			}
		}

		&.leftSec {
			right: 100%;
			top: 60px;
			-webkit-animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
			animation: scale-in-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
			@include max-mq(1023) {
				left: 0;
				top: calc(100% - 0rem + calc(36px * 4));
				right: auto;
				bottom: auto;
			}

			.detail-sec {
				.remove-btn {
					right: 0;
					left: -12px;
				}
			}
		}

		&.topSec {
			-webkit-animation: scale-in-ver-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
			animation: scale-in-ver-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
			bottom: 100%;
			@include max-mq(1023) {
				left: 0;
				top: calc(100% - 0rem + calc(36px * 4));
				right: auto;
				bottom: auto;
			}
		}

		&.bottomSec {
			-webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
			animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
			top: calc(100% - 0rem + calc(36px * 6));
			width: 100%;
			z-index: 9999;
			@include max-mq(1023) {
				left: 0;
				top: calc(100% - 0rem + calc(36px * 4));
				right: auto;
				bottom: auto;
			}
		}

		&.show {
			display: block;
		}

		.detail-sec {
			border: 1px solid $border-color;
			border-radius: $size-8-px;
			padding: 2rem;
			box-shadow: $box-shadow;
			position: relative;
			background-color: $white;

			&:hover {
				.remove-btn {
					display: flex;
				}
			}

			.remove-btn {
				width: 32px;
				height: 32px;
				border-radius: 100%;
				background-color: $red;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 20px;
				color: $white;
				position: absolute;
				top: -12px;
				right: -12px;
				display: none;
			}

			.header-sec {
				.main-head {
					h3 {
						font-size: 18px;
						font-weight: 600;
						color: $dark-black;
						margin-bottom: 0.5rem;
					}
				}

				.sub-head {
					margin-bottom: 1.5rem;
					text-transform: uppercase;
					font-weight: 500;
				}
			}

			.list-sec {
				display: flex;
				// column-gap: 4rem;
				> * {
					margin-right: 4rem;

					&:last-of-type {
						margin-right: 0rem;
					}
				}

				@include max-mq(767) {
					flex-direction: column;
					// row-gap: 2rem;
					> * {
						margin-bottom: 2rem;

						&:last-of-type {
							margin-bottom: 0rem;
						}
					}
				}

				.hours-list {
					@include max-mq(1023) {
						width: 100%;
					}

					.list-head {
						color: $dark-black;
						font-weight: 600;
						margin-bottom: 1rem;
					}

					.list-table {
						.table {
							thead {
								tr {
									th {
										background-color: transparent !important;
										border: none !important;
										padding: 0.55rem 0rem;
										padding-right: 0.8rem;
										font-size: 12px;
										font-weight: 500;
										text-transform: inherit;
									}
								}
							}

							tbody {
								tr {
									td {
										border: none !important;
										padding: 0.55rem 0rem;
										padding-right: 0.8rem;
										font-size: 12px;
										font-weight: 500;
										text-transform: inherit;

										.badge {
											font-size: 100%;
											padding: 0.4rem 0.6rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// End of Select Feature

// Editable select Feature
.editable-select {
	.form-group {
		.form-label {
		}

		.ng-select {
			.ng-select-container {
				&.ng-has-value {
					.ng-value-container {
						.ng-value {
						}
					}
				}
			}

			.ng-dropdown-panel {
				.ng-dropdown-panel-items {
					.ng-option {
						padding: 0.5rem 0.8rem;

						&:last-of-type {
							padding: 1rem 0.8rem;
							font-weight: 600;
							color: $dark-black;
							border-top: 1px solid $border-color;
						}
					}
				}
			}
		}
	}
}

// Add Profile Modal
.add-profile {
	.modal-dialog {
		.modal-content {
			.modal-body {
				.add-profile-form {
					.modal-btn-group {
						display: flex;
						justify-content: flex-end;
						// column-gap: 1.5rem;
						margin: 1.5rem 0rem 1rem;

						> * {
							margin-right: 1.5rem;

							&:last-of-type {
								margin-right: 0rem;
							}
						}
					}
				}
			}
		}
	}
}

// End of Add Profile Modal
// End of Editable select Feature

.q-datepicker {
	ngb-datepicker {
		left: 0 !important;
		right: 0 !important;
		width: 100%;

		.ngb-dp-header {
			width: 94%;
		}

		.ngb-dp-months {
			.ngb-dp-month {
				width: 100%;

				.ngb-dp-weekday {
					width: 3rem;
					margin-right: 0.5rem;
				}

				.ngb-dp-week {
					border-bottom: none;

					.ngb-dp-day {
						width: 3rem;

						.custom-day {
							text-align: center;
							padding: 0.5rem 0.25rem;
							display: inline-block;
							width: 3rem;
						}

						margin-right: 0.5rem;

						div {
							width: 100%;
						}
					}
				}
			}
		}
	}
}

// SLA Main Page
.sla-sec {
	position: relative;

	.sla-inner-sec {
		display: grid;
		grid-template-columns: 20% auto;
		column-gap: 1rem;
		row-gap: 1rem;
		@include max-mq(1250) {
			grid-template-columns: 25% auto;
		}
		@include max-mq(1023) {
			grid-template-columns: 100%;
		}
	}
}

.pso-sec {
	.main-inner {
		.main-repeat-sec {
			.main-repeat-inner {
				.main-event {
					.custom-scrollbar {
						.ng-scroll-content {
							height: 100%;

							.pso-event-form {
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
}

.sla-section {
	.sla-left-sec {
		.sla-left-inner {
			padding: 0rem 1rem;
			border-radius: 6px;
			height: fit-content;
			min-height: 100%;

			.left-inner {
				//padding: $size-14-px 0px;
				//border-bottom: 2px solid $border-color !important;
				&:last-of-type {
					border-bottom: none;
				}

				&.optinal-sec {
					.menu-link {
						margin-bottom: 0px;
						padding: $size-14-px 0px;

						&.active {
							&::before {
								top: 6px;
							}
						}
					}
				}

				.menu-link {
					display: flex;
					margin-bottom: $size-18-px;
					cursor: pointer;

					> * {
						margin-right: 1rem;

						&:last-of-type {
							margin-right: 0rem;
						}
					}

					&:last-of-type {
						margin-bottom: 0;
					}

					&.active {
						position: relative;

						.info-sec {
							div {
								color: $primary;
							}
						}

						.icon-sec {
							color: $primary;
						}

						&::before {
							position: absolute;
							content: "";
							width: 2px;
							height: 46px;
							background-color: $primary;
							left: -1rem;
							top: -0.8rem;
						}
					}

					.icon-sec {
						font-size: 1.2rem;
					}

					.info-sec {
						.sub-txt {
							margin-bottom: $size-4-px;
							line-height: 1rem;
							font-size: $size-12-px;
						}

						.main-txt {
							font-size: 1rem;
							font-weight: 500;
							@include max-mq(1400) {
								font-size: 1rem;
							}
						}
					}
				}
			}
		}
	}

	// SLA Inner Pages //
	.profile-inner-card {
		.card-body {
			padding: 2rem;

			&.p-0 {
				padding: 0px !important;
			}

			@include max-mq(567) {
				padding: 1.5rem 1rem;
			}

			.nav-tabs {
				&.OlaUc-tab {
					.nav-item {
						margin-right: 2rem;
						@include max-mq(567) {
							margin-right: 1rem;
						}

						.nav-link {
							font-size: 1.14rem;
							border-radius: 6px;
							display: flex;
							column-gap: 0.5rem;
							@include max-mq(567) {
								font-size: 1rem;
							}

							&.active {
								background-color: $primary;
								color: $white;
							}
						}
					}
				}
			}
		}

		.profile-head-sec {
			&.request-main-head {
				display: flex;
				justify-content: space-between;
			}

			.main-head {
				h3 {
					font-size: 1.3rem;
					font-weight: 600;
					margin-bottom: 1rem;
					color: $title-color;
				}
			}

			.sub-head {
				p {
				}
			}
		}

		.profile-inner-main {
			.profile-main {
				.main-inner {
					.main-repeat-sec {
						.main-repeat-inner {
							.main-event {
								padding-top: 2rem;
								// overflow-x: hidden;
								padding-bottom: 2rem;
								border-top: 2px dashed $primary;

								&:first-of-type {
									border-top: 0;
								}

								.badge-pill {
									margin-bottom: 2rem;
								}

								.sub-event {
									padding: 1.5rem 0rem;
									border-bottom: 2px dashed $border-color;

									&.adjust-event {
										display: grid;
										column-gap: 1rem;
										grid-template-columns: 320px auto;
										border-bottom: none;
										padding-right: 50px;
										position: relative;
										@include max-mq(1200) {
											grid-template-columns: 100%;
											row-gap: 1rem;
										}

										.icon-btn-group {
											position: absolute;
											right: 0;
											top: 1.5rem;
											@include max-mq(1200) {
												top: auto;
												bottom: 1.5rem;
											}
										}

										.row {
											grid-column-start: 1;
											grid-column-end: -1;
											@include max-mq(1023) {
												margin-top: 0 !important;
											}

											.col-12 {
												.inner-event {
													@include max-mq(650) {
														flex-direction: column;
														align-items: baseline;
														row-gap: 1rem;
													}

													.event-select {
														@include max-mq(600) {
															width: 100%;
														}

														.date-input-group {
															@include max-mq(600) {
																width: 100%;
															}
														}
													}
												}
											}
										}

										.event-select {
											display: flex;
											align-items: baseline;
											margin-bottom: 0;
											column-gap: 1rem;
											@include max-mq(600) {
												column-gap: 1rem;
												flex-direction: column;
											}

											&.custom-date-picker {
												align-items: baseline;
											}

											label {
												margin-bottom: 0;
												white-space: nowrap;
												@include max-mq(600) {
													margin-bottom: 0.5rem;
												}
											}

											.form-group {
												margin-bottom: 0;
												width: 100%;
												@include max-mq(600) {
													min-width: 100% !important;
												}

												.form-control {
													@include max-mq(600) {
														min-width: 100% !important;
														max-width: 100% !important;
													}
												}
											}
										}
									}

									.adjust-sec {
										display: grid;
										grid-template-columns: 25% auto;
										align-items: center;
										column-gap: 1rem;

										.adjust-select {
										}

										.releted-select {
										}
									}

									&:first-of-type {
										.inner-event {
											.icon-btn {
												.btnDelete {
												}

												.btnReset {
												}
											}
										}
									}

									.level-head {
										font-size: 0.9rem;
										font-weight: 600;
										margin-bottom: 1rem;
										opacity: 0.85;
									}

									.inner-event {
										display: flex;
										align-items: baseline;
										column-gap: 1.2rem;
										@include max-mq(1200) {
											column-gap: 1rem;
										}

										.event-select {
											margin-bottom: 0;
											display: flex;
											align-items: baseline;
											column-gap: 1rem;

											&.custom-date-picker {
												label {
													white-space: nowrap;
												}

												.date-input-group {
													position: relative;
													min-width: 200px;

													.ng-dirty {
														&.error {
															.ng2-flatpickr-input {
																text-indent: -300px;
																border-color: $red;
															}
														}
													}

													&.disableDate {
														pointer-events: none !important;

														.ng2-flatpickr-input-container {
															.form-control {
																background-color: $input-disabled-bg;
															}
														}
													}

													.ng2-flatpickr-input-container {
														.ng2-flatpickr-input {
															padding-right: 30px;
														}
													}

													.icon-calendar {
														position: absolute;
														top: 4px;
														border: none !important;
														padding: 5px;
														font-size: 1.4rem;
														right: 5px;
														color: $title-color;
													}
												}

												.input-group {
													.form-control {
														border-top-right-radius: 0.357rem;
														border-bottom-right-radius: 0.357rem;
													}

													.input-group-append {
														position: absolute;
														right: 0;
														z-index: 99;

														.btn {
															color: $title-color;
															font-size: 1.2rem;
															border-color: transparent !important;
															padding: 0.72rem 1rem;
															background-color: transparent;
														}
													}
												}
											}

											@include max-mq(1200) {
												column-gap: 0.6rem;
											}

											label {
												margin-bottom: 0;
												white-space: nowrap;
												@include max-mq(1023) {
													margin-bottom: 0.5rem;
												}
											}

											.form-group {
												margin-bottom: 0;
											}
										}

										.icon-btn-group {
											margin-left: auto;

											.icon-btn {
												font-size: 1.45rem;
												padding: 0.5rem;
												width: 36px;
												height: 36px;
												@include max-mq(567) {
													font-size: 1.25rem;
												}

												&.btnDelete {
													color: $danger;

													&:first-of-type {
													}
												}

												&.btnReset {
												}
											}
										}
									}
								}

								.row {
									margin-right: 1rem;

									.col-12 {
										border-bottom: 2px dashed $border-color;
									}
								}

								.add-level {
									margin-top: 2rem;
									display: flex;
									align-items: center;
									column-gap: 1rem;
									width: fit-content;
									cursor: pointer;

									.btn {
										padding: 0.5rem;
										font-size: 18px;
										width: 35px;
										height: 35px;
										display: flex;
										align-items: center;
										justify-content: center;
									}

									span {
										color: $primary;
										font-size: 1.25rem;
										font-weight: 500;
									}
								}

								.event-head {
									font-size: 1.12rem;
									margin-bottom: 0.8rem;
									font-weight: 500;
									color: $title-color;
								}

								.event-select {
									margin-bottom: 2rem;

									.form-group {
									}
								}
							}
						}
					}
				}
			}
		}

		.profile-inner-bottom {
			padding: 1rem;

			.add-another-btn {
				padding: 0.8rem 1rem;
				display: flex;
				align-items: center;
				column-gap: 1rem;
				background-color: rgba($primary, 0.1) !important;
				border-radius: 6px;
				@include max-mq(767) {
					padding: 0.8rem 1rem;
				}

				.btn {
					font-size: 18px;
					padding: 0.5rem;
					width: 35px;
					height: 35px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				span {
					font-size: 1.2rem;
					font-weight: 600;
					color: $primary;
				}
			}
		}
	}

	// End of SLA Inner Pages //
	.sla-main-sec {
		// Form Wizard
		.horizontal-wizard {
			display: grid;

			.bs-stepper {
				background-color: transparent;
				box-shadow: none;
				display: none !important;

				&.linear {
					&.active {
						display: flex !important;
						flex-direction: column;
					}
				}

				.bs-stepper-header {
					padding: 0 !important;

					.card {
						width: 100%;
						border-radius: 0px !important;

						.card-body {
							display: flex;
							align-items: center;
							@include max-mq(796) {
								// column-gap: 1rem;
								> * {
									margin-right: 1rem;

									&:last-of-type {
										margin-right: 0rem;
									}
								}
							}
							@include max-mq(600) {
								flex-direction: column;
								flex-direction: column;
								align-items: baseline;
							}

							.step {
								.step-trigger {
									.bs-stepper-box {
										font-size: 1.3rem;
									}
								}

								&.completed-step {
									.step-trigger {
										opacity: 0.85;

										.bs-stepper-box {
											background-color: rgba($color: $primary, $alpha: 0.12);
											color: $primary;
											font-size: 1.2rem;
											line-height: normal;
										}

										.bs-stepper-label {
											.bs-stepper-title {
												color: $primary;
											}

											.bs-stepper-subtitle {
												color: $primary;
											}
										}
									}
								}
							}
						}
					}
				}

				.bs-stepper-content {
					padding: 0 !important;

					.content {
						.divider {
							border-bottom: 2px dashed $border-color;
						}

						.traget-profile-sec {
							// margin-bottom: 5rem;
							display: flex;
							flex-direction: column;

							.add-another-btn {
								padding: 0.8rem;
								display: flex;
								align-items: center;
								// column-gap: 1rem;
								background-color: rgba($primary, 0.1);
								border-radius: 6px;

								> * {
									margin-right: 1rem;

									&:last-of-type {
										margin-right: 0rem;
									}
								}

								span {
									font-size: 1.2rem;
									font-weight: 500;
									color: $primary;
								}
							}

							.custom-component {
								.selct-feature {
									.select-sec {
										.form-group {
											// margin-bottom: 0;
										}
									}
								}

								.btn {
									&.btnDelete {
										padding: 0 !important;
										border: 0 !important;
										background-color: transparent !important;
										color: $title-color !important;
									}
								}
							}

							.target-profile {
								border-bottom: 2px dashed $border-color;

								&:nth-child(2) {
									// margin-top: -2rem;
								}

								&:first-of-type {
									padding-bottom: 0rem !important;
									border-bottom: none !important;
									margin-bottom: 0 !important;
									order: 1;
								}

								&:last-of-type {
									border-bottom: none !important;
								}

								.detail-wrapper {
									width: 100%;

									.detail-sec {
										&.noShadow {
											box-shadow: none !important;
										}

										.list-sec {
											.hours-list {
												.list-table {
													.table {
														tbody {
															border-top: 0 !important;
														}
													}
												}
											}
										}
									}
								}
							}
						}

						.detail-wrapper {
							width: fit-content;
							@include max-mq(767) {
								margin-bottom: 1.5rem;
							}

							.detail-sec {
								// border: 1px solid;
								// border-radius: 6px;
								padding: 1.5rem;
								box-shadow: $box-shadow;
								border-color: $border-color;
								position: relative;
								background-color: $white;
								border-radius: 6px;
								@include max-mq(1200) {
									padding: 0.8rem;
								}
								@include max-mq(767) {
									padding: 1.2rem;
								}

								&:hover {
									.remove-btn {
										display: flex;
									}
								}

								// .remove-btn {
								//   width: 32px;
								//   height: 32px;
								//   border-radius: 100%;
								//   background-color: $red;
								//   display: flex;
								//   align-items: center;
								//   justify-content: center;
								//   font-size: 20px;
								//   color: $white;
								//   position: absolute;
								//   top: -12px;
								//   right: -12px;
								//   display: none;
								// }
								.header-sec {
									.main-head {
										h3 {
											font-size: 18px;
											font-weight: 600;
											margin-bottom: 0.5rem;
										}
									}

									.sub-head {
										margin-bottom: 1.5rem;
										text-transform: uppercase;
										font-weight: 500;
									}
								}

								.list-sec {
									display: flex;
									// column-gap: 4rem;
									> * {
										margin-right: 4rem;

										&:last-of-type {
											margin-right: 0rem;
										}
									}

									@include max-mq(767) {
										flex-direction: column;
										// row-gap: 2rem;
										> * {
											margin-bottom: 2rem;

											&:last-of-type {
												margin-bottom: 0rem;
											}
										}
									}

									.hours-list {
										width: 100%;

										.list-head {
											color: $title-color;
											font-weight: 600;
											margin-bottom: 0.5rem;
											display: flex;
											justify-content: space-between;
											align-items: center;
											@include max-mq(1200) {
												font-size: 0.8rem;
												line-height: normal;
											}

											.list-head-toggle {
												.custom-switch {
													line-height: 1.2rem;

													.custom-control-label {
														padding-left: 2.5rem;

														&::before {
															top: 0px;
															left: 0;
															width: 2.5rem;
															height: 1.2rem;
														}

														&::after {
															width: 0.9rem;
															height: 0.9rem;
															top: 2px;
															left: 0px;
														}
													}
												}
											}
										}

										.list-table {
											.table {
												thead {
													tr {
														th {
															background-color: transparent !important;
															border: none !important;
															padding: 0.55rem 0rem 0.25rem;
															padding-right: 0.8rem;
															font-size: 11px;
															font-weight: 500;
															line-height: 12px;
															text-transform: inherit;
															margin-bottom: -5px;

															&.time-td {
																width: 30px;
																padding: 0.55rem 0rem 0.25rem !important;
															}
														}
													}
												}

												tbody {
													tr {
														td {
															border: none !important;
															padding: 0.55rem 0rem;
															padding-right: 0.8rem;
															font-size: 12px;
															font-weight: 500;
															text-transform: inherit;

															&.time-td {
																width: 30px;
																padding: 0.55rem 0rem 0.55rem !important;

																&:nth-child(2) {
																	.form-group {
																		.form-control {
																			border-top-right-radius: 0;
																			border-bottom-right-radius: 0;
																		}
																	}
																}

																&:nth-child(3) {
																	.form-group {
																		.form-control {
																			border-radius: 0;
																		}
																	}
																}

																&:last-of-type {
																	.form-group {
																		.form-control {
																			border-top-left-radius: 0;
																			border-bottom-left-radius: 0;
																		}
																	}
																}

																.form-group {
																	margin-bottom: 0;

																	.form-control {
																		padding: 0.188rem 0.2rem;
																		text-align: center;

																		&:focus {
																			&::placeholder {
																				opacity: 0;
																			}
																		}
																	}
																}
															}

															.badge {
																font-size: 100%;
																padding: 0.4rem 0.6rem;
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}

						.card {
							.card-body {
								.custom-select-feature {
									.custom-dropdown {
										@include max-mq(1270) {
											flex-direction: column;
											// row-gap: 1rem;
											> * {
												margin-bottom: 1rem;

												&:last-of-type {
													margin-bottom: 0rem;
												}
											}
										}

										.form-group {
											@include max-mq(1270) {
												flex-direction: column;
												align-items: flex-start;
												// row-gap: 1rem;
												> * {
													margin-bottom: 1rem;

													&:last-of-type {
														margin-bottom: 0rem;
													}
												}
											}

											.ng-select {
												@include max-mq(1200) {
													width: 100%;
												}
											}
										}
									}

									.bottom-sec {
										.drop-btn {
											position: relative;
											margin: 1rem 0rem;

											&::before {
												position: absolute;
												content: "";
												width: 100%;
												height: 1px;
												// background-color: $gray-400;
												top: 16px;
												border-bottom: 1px dashed $border-color;
											}

											.form-group {
												.ng-select {
													max-width: 80px;
													margin: 0px auto;

													.ng-select-container {
														border-radius: 35px;
														min-height: 32px !important;
														height: 32px;
														padding-left: 5px;

														.ng-clear-wrapper {
															display: none;
														}
													}
												}
											}
										}
									}
								}

								.inner-sec {
									border-bottom: 2px dashed $border-color;
									margin-top: 2rem;
									padding-bottom: 1rem;

									&:first-of-type {
										margin-top: 0;
									}

									&:last-of-type {
										border-bottom: 0;
									}

									.select-frequency {
										display: grid;
										grid-template-columns: repeat(3, 1fr);
										@include max-mq(767) {
											grid-template-columns: repeat(3, auto);
										}
										@include max-mq(600) {
											grid-template-columns: 100%;
										}

										.select-item {
											border-left: 2px dashed $border-color;
											padding: 0px 3rem;
											@include max-mq(1200) {
												padding: 0px 1rem;
											}
											@include max-mq(600) {
												padding: 0px 0rem;
												border-left: 0 !important;
												border-bottom: 2px dashed $border-color;
												margin-bottom: 1.5rem;
												padding-bottom: 1.5rem;
											}

											&:last-of-type {
												@include max-mq(600) {
													border-bottom: 0 !important;
													margin-bottom: 0rem;
													padding-bottom: 0rem;
												}
											}

											&:first-of-type {
												padding-left: 0 !important;
												border-left: none;
											}

											.custom-selection {
												margin-left: 1.8rem;
												margin-top: 0.8rem;
											}
										}
									}

									.content-header {
										margin-bottom: 1.5rem;

										h5 {
											font-size: 1.2rem;
											font-weight: 600;
											color: $title-color;
										}
									}

									.content-subhead {
										h5 {
											font-size: 1rem;
											margin-top: -0.5rem;
											margin-bottom: 1.2rem !important;
											font-weight: 600;
										}
									}

									.form-group {
										&.custom-date-picker {
											.input-group {
												.form-control {
													border-top-right-radius: 0.357rem;
													border-bottom-right-radius: 0.357rem;
												}

												.input-group-append {
													position: absolute;
													right: 0;
													z-index: 99;

													.btn {
														color: $title-color;
														font-size: 1.2rem;
														border-color: transparent !important;
														padding: 0.72rem 1rem;
														background-color: transparent;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// End of Form Wizard
	}
}

// End of SLA Main Page
// Custom Components
.custom-component {
	.data-label {
		font-weight: 600;
		font-size: 1.2rem;
		color: $dark-black;
	}

	&.custom-select-feature {
		.custom-dropdown {
			border-color: transparent !important;

			.form-group {
				.ng-select {
					.ng-select-container {
						border: 1px solid #ccc !important;
						background-color: transparent !important;
					}
				}
			}
		}
	}

	.card {
		margin-bottom: 0 !important;

		.card-body {
			padding: 0 !important;

			.selct-feature {
				grid-template-columns: 100% !important;
			}
		}
	}
}

// End of Custom Components
// End of Custom CSS //
// SLA Inner Pages //
.profile-inner-card {
	.card-body {
		.profile-main {
			.main-inner {
				.main-repeat-sec {
					.main-repeat-inner {
						.main-event {
							.sub-event {
								.inner-event {
									.event-select {
										&.custom-date-picker {
											.date-input-group {
												.ng-dirty {
													&.error {
														.ng2-flatpickr-input {
															text-indent: -300px;
															border-color: $red;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// End of SLA Inner Pages //
// Breadcurmb
.breadcrumb {
	.breadcrumb-list {
		padding-right: 0.8rem;
		margin-right: 0.8rem;
		position: relative;

		&::after {
			content: " ";
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: 50%;
			color: #6e6b7b;
			margin-right: 0.6rem;
			background-size: 16px;
			width: 20px !important;
			height: 20px;
			position: absolute;
			left: 16px;
			top: -1px;
		}

		&:last-of-type {
			&::after {
				display: none;
			}
		}

		a {
		}
	}
}

// End of Breadcurmb
// Add Metric
.add-metric {
	.metric-inner-sec {
		.condition-card {
			.condition-inner {
				.condition-main {
					.condition-row-inner {
						.condition-col {
							&.condition-input {
								&.multi-input {
									.form-group {
										.ng-select {
											max-width: inherit;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.drop-btn {
			.form-group {
				.ng-select {
					.ng-select-container {
						border-radius: 35px !important;
						min-height: 32px !important;
						height: 32px !important;
						padding-left: 5px !important;

						.ng-value-container {
							padding-left: 5px;
						}

						.ng-clear-wrapper {
							display: none !important;
						}
					}
				}
			}
		}
	}
}

// End of Add Metric
// #9aaabb
// [ngClass]="{
//   disableDate: item.isDisable === true
// }"
.title-color {
	color: $title-color;
}

.subtitle-color {
	color: $body-color;
}

.card-shadow:hover {
	box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.08) !important;

	.title-blue {
		color: $primary;
	}
}

// Widget Configuration
.configure-widget-modal {
}

.view-widget {
	min-height: 540px;
	display: grid;
	@include max-height(650) {
		min-height: 440px;
	}
	app-widget-render {
		height: 100%;

		.custom-scrollbar {
			.ng-scroll-content {
				height: 100% !important;
			}
		}

		> div {
			height: 100%;

			.display-grid-height_100 {
				.display-grid-height_100 {
					.display-grid-height_100 {
						display: block;

						> div {
							// height: 100% !important;

							> canvas {
								height: 100% !important;
							}
						}
					}
				}
			}
		}
	}
}

.widget-main {
	app-widget-render {
		.custom-scrollbar {
			.ng-scroll-content {
				height: 100% !important;
			}
		}
		.display-grid-height_100 {
			display: flex;
			flex-direction: column;
			.display-grid-height_100 {
				display: flex;
				flex-direction: column;
				.table-widget {
					simple-table {
						.table-responsive {
							height: 100% !important;
							// overflow: hidden !important;
							@extend .browser-scrollbar;
							table.table {
								height: inherit !important;
								overflow: auto !important;
								display: inline-table !important;
								thead {
									position: sticky;
									top: -1px;
									tr {
										white-space: nowrap;
										height: 34px;
									}
								}
							}
							// .ng-scrollbar{
							// 	.ng-scrollbar-wrapper{
							// 		.ng-scroll-viewport-wrapper{
							// 			.ng-scroll-content{
							// 				height: 100% !important;
							// 				table.table{
							// 					margin-bottom: 0px;
							// 					display: grid;
							// 					grid-template-rows: 34px auto;
							// 					height: 100%;
							// 					thead{
							// 						tr{
							// 							th{
							// 								white-space: nowrap;
							// 							}
							// 						}
							// 					}
							// 				}
							// 			}
							// 		}
							// 		// >.scrollbar-control{
							// 		// 	display: block;
							// 		// }
							// 	}
							// }
						}
					}
					.drillDown-table {
						&.table-responsive {
							height: 100% !important;
							@extend .browser-scrollbar;
							table.table {
								height: inherit !important;
								overflow: auto !important;
								display: inline-table !important;
								thead {
									position: sticky;
									top: -1px;
									tr {
										white-space: nowrap;
										height: 34px;
									}
								}
							}
						}
					}
				}
				simple-table {
					.table-responsive {
						height: 100% !important;
						.ng-scrollbar {
							.ng-scrollbar-wrapper {
								.ng-scroll-viewport {
									.ng-scroll-content {
										height: auto !important;
									}
								}
							}
						}
					}
				}
				.table {
					&.data-table-main-block-sec {
						position: relative;
						thead {
							position: sticky;
							top: -1px;
							tr {
								th {
									white-space: nowrap;
								}
							}
						}
					}
				}
				.display-grid-height_100 {
					display: block;

					> div {
						// height: 100% !important;

						> canvas {
							height: 100% !important;
						}
					}
				}
			}
		}
	}
}

.widget-configuration {
	.widget-configuration-inner {
		.widget-body {
			.horizontal-wizard {
				.bs-stepper {
					.bs-stepper-content {
						.widget-form {
							.content {
								form {
									.content-detail {
										&.configuration-detail {
											.configuration-nav {
												.tab-content {
													.config {
														&.chart-option {
															.form-timescale {
																.ng-select {
																	.ng-select-container {
																		.ng-value-container {
																			.ng-input {
																				padding-left: 35px !important;
																				padding-right: 35px !important;
																				top: 8px !important;
																			}

																			.ng-value {
																				.ng-value-label {
																					padding-left: 28px !important;
																				}
																			}
																		}
																	}
																}
															}
														}

														&.image-selector {
															.img-selector-modal {
																.tab-content {
																	.image-uploder {
																		padding: 1.5rem;

																		.upload-file-sec {
																			justify-content: center;
																			width: 100%;
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// End of Widget Configuration
// Chart Setting
gridster-item {
	app-widget-render {
		display: flex;
		flex-direction: column;
		height: 100% !important;

		> div {
			display: contents;
			max-height: max-content;
		}

		.demo-chart {
			width: 100% !important;
			height: 100% !important;

			div {
				width: 100% !important;
				height: 100% !important;

				&:nth-child(2) {
					opacity: 1 !important;
					width: auto !important;
					height: auto !important;
				}

				svg {
					width: 100% !important;
					height: 100% !important;

					g {
						* {
							width: 100% !important;
							height: 100% !important;
						}
					}
				}

				// canvas{
				// 	width: 100% !important;
				// 	height: 100% !important;
				// }
			}
		}
	}
}

// End of Chart Setting

.sekeleton-card {
	background-color: transparent;
}

.grid-header {
	background-color: #f3f2f7;
}

// Zoom Widget Modal //
.view-widget {
	.demo-chart {
		min-height: calc(100vh - 200px);
	}
}

// End of Zoom widget Modal //

// Presantation Mode //
.presentation-mode {
	&.fluent-layout {
		.vertical-layout {
			&.menu-collapsed {
				.content {
					&.app-content {
						margin-left: 0 !important;
						max-height: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	.vertical-layout {
		.header-navbar {
			display: none !important;
		}

		.main-menu {
			display: none !important;
		}

		.app-content {
			margin-left: 0 !important;
			padding: 0 !important;
			max-height: 100%;
			height: 100%;

			.inner-main-content {
				padding: 0px !important;
				height: auto;

				.full-body-height {
					height: 100vh;
				}
			}

			.header-navbar-shadow {
				opacity: 0 !important;
				z-index: 0;
			}
		}
	}
}

//  End of Presantion Mode //
.slider-table,
.server-hardware {
	.table {
		tbody {
			tr {
				td {
					nouislider.ng2-nouislider {
						.noUi-target {
							.noUi-origin {
								.noUi-handle {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

// Open Layers map //
.olMap {
	width: 100%;
	height: 100%;

	.ol-viewport {
		display: none;

		&:last-of-type {
			display: block !important;
		}

		.ol-layers {
			.ol-layer {
				canvas {
					width: 100% !important;
					height: 100% !important;
					transform: none !important;
					transform-origin: center !important;
				}
			}
		}

		.ol-attribution,
		.ol-rotate,
		.ol-zoom-extent {
			display: none;
		}

		.ol-zoom {
			.ol-zoom-out {
				position: relative;
				height: 30px;
				width: 30px;
				border-radius: 50%;
				margin: 8px 5px;
				&::after {
					content: "\f010";
					font-family: "Font Awesome 6 Pro";
					font-size: 1em;
					color: $primary;
					width: 34px;
					height: 34px;
					border-radius: 100%;
					border: 1px solid $primary;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: $body-bg;
					position: absolute;
					left: -3px;
					top: -3px;
				}
			}
			.ol-zoom-in {
				position: relative;
				height: 30px;
				width: 30px;
				border-radius: 50%;
				margin: 8px 5px;
				&::after {
					content: "\f00e";
					font-family: "Font Awesome 6 Pro";
					font-size: 1em;
					color: $primary;
					width: 34px;
					height: 34px;
					border-radius: 100%;
					border: 1px solid $primary;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: $body-bg;
					position: absolute;
					left: -3px;
					top: -3px;
				}
			}
		}
	}
}

.ol_map_zoom_button {
	float: right;
	font: 12px Calibri, Arial, Helvetica, sans-serif !important;
	border: 1px solid;
}

.map-tooltip {
	position: relative;
	padding: $size-8-px;
	background: rgba($color: $dark-black, $alpha: 0.8);
	color: $white;
	opacity: 1;
	white-space: nowrap;
	border-radius: $size-4-px;

	.tooltipData {
		h6.white-color {
			color: $white !important;
		}

		ul {
			list-style: none;
			padding-left: 0px;
			margin-bottom: 0px;

			li {
				font-size: $size-12-px !important;
			}
		}
	}
}

// End of Open Layers map //

//   Vis Network Tree chart  //
.vis-network {
	margin: auto !important;
}

//   End of Vis Network Tree chart  //

// Assigee Dashboard Section //
.assignee-dashboard {
	.assignee-dashboard-inner {
		.assignee-head {
			h2 {
			}

			.btn-icon {
			}
		}

		.assignee-body {
			.accordion {
				.accordion-item {
					// min-height: calc((100vh - 164px)/2);
					// max-height: calc((100vh - 164px)/2);
					box-shadow: none !important;
					// border-top: 1px solid $border-color;

					&:first-of-type {
						border-top: none;
					}

					.collapse {
						max-height: inherit;
						overflow-y: auto;

						&.show {
							min-height: calc((100vh - 242px) / 2);
							max-height: calc((100vh - 242px) / 2);
							@include max-mq(1400) {
								min-height: calc((100vh - 238px) / 2);
								max-height: calc((100vh - 238px) / 2);
							}
							@include max-mq(1200) {
								min-height: calc((100vh - 244px) / 2);
								max-height: calc((100vh - 244px) / 2);
							}
						}
					}
				}
			}
		}
	}
}

// End of Assigee Dashboard Section //

// Tree DropDown //
.tree-dropdown {
	// min-height: calc(100vh - 7.5rem);

	&.single-select {
		&.multi-tree-dropdown {
			.dropdown-container {
				.treeview-container {
					max-height: 240px !important;
					height: 220px !important;
					@extend .browser-scrollbar;
					.treeview-item {
						.text-nowrap {
							.form-check-label {
								cursor: pointer;
							}

							span.mr-25 {
								& ~ .form-check-label {
									cursor: pointer;
									pointer-events: inherit;
								}
							}
						}
					}
				}
			}
		}

		.dropdown {
			.btn {
				&.dropdown-toggle {
					font-size: $size-12-px !important;
					max-height: 36px;

					span:not(.placeholder-txt) {
						background-color: transparent;
						padding: 0;
						color: $body-color;
						margin: 0 !important;
						font-size: $size-12-px !important;
						white-space: break-spaces;
						overflow-wrap: anywhere;
					}
				}
			}
		}

		.dropdown-container {
			.treeview-container {
				max-height: 240px !important;
				@extend .browser-scrollbar;

				.treeview-item {
					.row-item {
						span {
							.feather {
								width: $size-14-px;
								height: $size-14-px;
								color: $body-color;
							}
						}

						.form-check-label {
							padding: 0.5rem 0px;
							font-weight: 500;
							font-size: $size-12-px !important;
							color: $body-color;
						}
					}

					.text-nowrap {
						.form-check-label {
							cursor: pointer;
							padding-left: 1.5rem;
						}

						span.mr-25 {
							& ~ .form-check-label {
								cursor: not-allowed;
								padding-left: 0rem;
								pointer-events: none;
							}
						}
					}
				}
			}
		}
	}

	.dropdown {
		.btn {
			&.dropdown-toggle {
				display: flex;
				// display: grid;
				align-items: center;
				justify-content: flex-start;
				font-size: $size-14-px;
				border: 1px solid $input-border-color !important;
				padding: 0.438rem 1rem !important;
				font-family: $font-family-sans-serif;
				min-height: 36px;
				font-weight: 300;
				&.no-tree-data{
					color: $input-placeholder-color !important;
				}
				&:focus {
					border-color: $primary;
					box-shadow: 0 0 0 4px rgba($color: $primary, $alpha: 0.12);
				}

				column-gap: 0.5rem;
				row-gap: 0.5rem;
				// > * {
				// 	margin-right: 0.5rem;
				// 	margin-bottom: 0.5rem;
				// }

				flex-wrap: wrap;

				span:not(.placeholder-txt) {
					background-color: $primary;
					padding: 3px 8px;
					border-radius: 4px;
					color: $white;

					svg {
						margin-left: 0.65rem;
						width: 0.825em;
					}
				}

				&::after {
					margin-top: 0;
					background-position: right center;
					left: auto;
					top: auto;
				}
			}
		}

		.dropdown-menu {
			width: 100%;

			.dropdown-container {
				.treeview-container {
					max-height: 200px !important;
					height: 160px !important;
					@extend .browser-scrollbar;
					.treeview-item {
						.form-inline {
							padding-left: 1.8rem;
							position: relative;

							span {
								&.mr-1 {
									margin-right: 0.5rem !important;
									position: absolute;
									left: 0;
								}
							}
						}

						.treeview-item {
							margin-left: 1rem;

							.form-inline {
								// margin-left: -0.5rem;
							}

							.treeview-item {
								margin-left: 1.5rem;
							}
						}

						.feather {
							width: $size-14-px;
							height: $size-14-px;
							color: $body-color;
						}

						.form-check-label {
							font-size: $size-14-px;
							color: $body-color;
						}

						.form-inline {
							margin-bottom: 1rem;

							.feather {
								width: $size-14-px;
								height: $size-14-px;
								color: $body-color;
							}

							.form-check-label {
								font-size: $size-14-px;
								color: $body-color;
							}

							.custom-control {
								&.custom-checkbox {
									padding-left: 2rem;

									.custom-control-label {
										font-weight: 500;
									}

									.custom-control-input {
										&:checked {
											& ~ .custom-control-label {
												color: $primary;
											}
										}

										&:indeterminate {
											& ~ .custom-control-label {
												&::after {
													background-size: 60%;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// End of Tree DropDown //

// Layout pages //
// Table View //
.table-view {
	position: relative;
	overflow: auto;
	flex: 1%;

	dns-server-grid {
		&.bootstrap {
			&.core-bootstrap {
				height: 100% !important;
				overflow: auto;
				display: flex;
				flex-direction: column;
				&.d-grid {
					grid-template-rows: auto 1fr;
				}
				.card {
					height: 100%;
					margin-bottom: 0;
					border: none !important;
					overflow: auto;
					border-radius: 0px !important;
				}
			}
		}
	}

	.ngx-datatable {
		height: 100%;
		max-height: 100%;

		.visible {
			display: flex;
			flex-direction: column;
			height: 100%;
			max-height: 100%;

			.datatable-header {
				flex: initial;
				width: inherit;

				.datatable-header-inner {
					.datatable-header-cell {
						padding: 0.6rem 1rem;
						font-weight: 600;
					}
				}
			}

			.datatable-body {
				overflow: auto;
				flex: 1%;
				width: inherit;
				// background-color: #f0f1f3;
				datatable-selection {
					width: 100% !important;

					.datatable-scroll {
						width: 100% !important;

						.datatable-row-wrapper {
							.datatable-body-row {
								border: none !important;
							}

							&:nth-child(odd) {
								.datatable-body-row {
									.datatable-body-cell {
										background-color: $white;
									}
								}
							}

							&:nth-child(even) {
								.datatable-body-row {
									.datatable-body-cell {
										background-color: $body-bg;
									}
								}
							}

							.datatable-body-row {
								.datatable-body-cell {
									border-top: 1px solid $border-color !important;
								}
							}

							&:last-of-type {
								.datatable-body-row {
									.datatable-body-cell {
										border-bottom: 1px solid $border-color;
									}
								}
							}
						}
					}
				}
			}

			.datatable-footer {
				width: inherit;
				border-radius: 0px;
				overflow: hidden;
				flex: initial;
				border-top-left-radius: 0px;
				border-top-right-radius: 0px;

				.datatable-footer-inner {
					height: 40px !important;

					> div {
						display: flex !important;
						align-items: center;
						padding-top: 0 !important;
						border-top: 0 !important;
						span {
							// color: $title-color !important;
							font-size: $size-12-px !important;
						}
						label {
							margin-bottom: 0 !important;
						}
					}

					.datatable-pager {
						margin: 2px 1rem;
						@include max-mq(600) {
							display: flex;
							justify-content: flex-end;
							margin-right: 1rem !important;
						}

						.pager {
							height: 30px;

							li {
								margin: 1px 0 !important;

								&:not(.disabled) {
									&.active {
										a {
											width: 28px;
											height: 28px;
											padding: 3px;
										}
									}
								}

								a {
									height: 20px;
									min-width: 24px;
								}
							}
						}
					}

					.page-count {
						display: none;
					}
				}
			}
		}
	}

	.show-items {
		position: absolute;
		bottom: 0.5rem;
		z-index: 9;
		left: 1rem;

		.item-select {
			margin-bottom: 0;

			.form-control {
				font-size: 0.857rem !important;
				font-weight: 400 !important;
				line-height: 1.5 !important;
				// color: #6e6b7b !important;
				height: 20px;
				padding: 0rem 0.65rem;

				&:not([multiple="multiple"]) {
					background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'><polyline points='6 9 12 15 18 9'></polyline></svg>");
					background-size: 16px;
					padding-right: 1.5rem;
					background-position: right 6px center;
				}
			}
		}
	}
}

// End of Table View //
// Card View //
.main-card-view {
	height: 100%;

	.card-view {
		display: grid;
		height: 100%;

		.inner-card-view {
			grid-template-columns: repeat(auto-fill, minmax(324px, 1fr));
			display: grid;
			column-gap: 1rem;
			row-gap: 1rem;
			padding: 1rem 1rem;
			@include max-mq(600) {
				grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
			}

			.single-card {
				background-color: $white;
				border-radius: 6px;
				box-shadow: $box-shadow;
				height: 100% !important;
				padding: $size-14-px !important;

				&.p-0 {
					padding: 0px !important;
				}

				.media-body {
					> div {
						small {
							line-height: 23px !important;
						}
					}
				}

				.upper-section {
					border-bottom: 1px solid;
					border-color: $border-color;
					padding: 16px 16px 14px;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;

					.info-sec {
						display: flex;

						.icon-sec {
							width: 32px;
							height: 32px;
							border-radius: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							background: $text-muted;
							font-weight: 600;
							font-size: 0.875rem;
							line-height: 1.285rem;
							text-transform: uppercase;
							color: $white;
							margin-right: 0.6rem;
						}
					}

					.info-detail {
						.name {
							color: $primary;
							line-height: 18px;
						}

						.nick-name {
							color: $text-muted;
						}
					}
				}

				.lower-section {
					padding: 12px 16px;
					display: flex;

					> * {
						margin-right: 16px;
					}

					color: $body-color;

					.email {
						margin-right: 0 !important;
					}

					a {
						color: $body-color;
					}
				}
			}
		}
	}
}

// End of Card View //

// Card Pagination //
.card-pagination {
	background-color: $white;
	padding: 2px 1rem 2px 1.5rem;
	// border-radius: 6px;
	box-shadow: $box-shadow;

	@include max-mq(600) {
		padding: 2px 4px;
	}

	.pagination-inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		@include max-mq(600) {
			flex-direction: column;
			// row-gap: 1rem;
			> * {
				margin-bottom: 1rem;

				&:last-of-type {
					margin-bottom: 0rem;
				}
			}
		}

		.datatable-footer {
			width: 100%;
			label {
				// color: $title-color !important;
				font-size: $size-12-px;
			}
			@include max-mq(767) {
				> div {
					flex-wrap: wrap;

					> div {
						&:nth-child(2) {
							order: 3;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							margin: 4px 0px;

							.datatable-pager {
								display: flex;
								justify-content: center;
								margin-right: 0px !important;
							}
						}
					}
				}
			}
		}

		.show-entries {
			display: flex;
			align-items: center;

			.form-group {
				display: flex;

				label {
					margin-right: 0.25rem;
					margin-bottom: 0;
				}

				.form-control {
					font-size: 0.857rem !important;
					font-weight: 400 !important;
					line-height: 1.5 !important;
					height: 20px;
					padding: 0rem 0.65rem;

					&:not([multiple="multiple"]) {
						background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'><polyline points='6 9 12 15 18 9'></polyline></svg>");
						background-size: 16px;
						padding-right: 1.5rem;
						background-position: right 6px center;
					}
				}
			}

			.custom-selection {
				display: flex;
				align-items: center;
				justify-content: center;

				.form-label {
					margin-right: 8px;
					margin-bottom: 0;
				}

				.ng-select {
					min-width: 56px;

					&.ng-select-focused {
						.ng-select-container {
							height: 20px;
							min-height: 20px !important;

							.ng-value {
								@extend .sm-txt;
							}
						}
					}

					.ng-select-container {
						height: 20px;
						min-height: 20px !important;
						border-radius: 6px;
						border: 1px solid $border-color;

						.ng-clear-wrapper {
							display: none;
						}

						.ng-arrow-wrapper {
							width: 18px;

							.ng-arrow {
								padding-right: 1rem;
							}
						}

						.ng-value-container {
							padding-left: 7px;

							.ng-value {
								@extend .sm-txt;
							}

							.ng-input {
								top: auto;
							}
						}
					}
				}
			}

			.form-group {
				margin-bottom: 0;
				@include max-mq(767) {
					max-width: 80px;
				}
			}
		}

		.pagination {
			display: flex;
			align-items: center;
			background-color: $table-head-bg;
			padding: 0px 5px;
			border-radius: 28px;

			> * {
				margin-left: 4px;
			}

			.go-end {
				position: relative;
				font-size: 18px;
				line-height: 27px;
				display: flex;
				align-items: center;
				justify-content: center;

				&::before {
					font-family: data-table !important;
					font-style: normal !important;
					font-weight: 400 !important;
					font-feature-settings: normal !important;
					font-variant: normal !important;
					text-transform: none !important;
					speak: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				}

				&.go-first {
					&::before {
						content: "\72";
					}
				}

				&.go-last {
					&::before {
						content: "\71";
					}
				}
			}

			.prv,
			.nxt {
				width: 28px;
				height: 28px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				font-size: $size-12-px;
			}

			.number {
				padding: 5px 0px;
				display: flex;
				border-radius: 52px;
				margin-left: 0 !important;

				span {
					width: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 4px;
				}

				.selected {
					position: relative;
					color: $white;
					z-index: 0;
					width: 28px;
					font-weight: 600;

					&::before {
						position: absolute;
						content: "";
						width: 28px;
						height: 28px;
						background-color: rgba($color: $primary, $alpha: 1);
						z-index: -1;
						border-radius: 100%;
					}
				}
			}
		}
	}
}

// End of Card Pagination //
// Main collapsible //
.main-content {
	height: 100%;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	border-radius: 6px;
	box-shadow: $box-shadow;
	position: relative;

	&.activeSidebarPanel {
		.main-sec {
			@include max-mq(1199) {
				z-index: 0 !important;
			}

			// &.normalGridSection {
			// 	max-width: calc(100% - 274px);
			// }

			.action-bar {
				@include max-mq(1200) {
					.inner-section {
						.left-section {
							.subheader {
								position: absolute;
								left: 274px;
								z-index: 999;
								background-color: $card-bg;
								top: 0px;
								border-radius: 0px !important;

								.toggale-submenu {
									.submenu-toggle {
										border-radius: 0%;
										border-left: 0;
										background-color: $primary;
										color: $card-bg;
										border-top-right-radius: $size-4-px;
										border-bottom-right-radius: $size-4-px;
									}
								}
							}
						}
					}
				}
			}
		}

		.left-sec {
			display: block;
			@include max-mq(1199) {
				left: 0px;
				z-index: 9 !important;
			}

			.side-pannel {
				border-top-left-radius: 0;
			}
		}

		.main-sec {
			.action-section {
				border-bottom-left-radius: 0px;
			}

			.action-bar {
				border-top-left-radius: 0px;
			}
		}
	}

	> .left-sec {
		height: 100%;
		display: none;
		width: 274px;
		flex-shrink: 0;
		box-shadow: $box-shadow;

		@include max-mq(1199) {
			position: absolute;
			left: -274px;
			top: 0;
			z-index: 9999;
		}

		.side-pannel {
			background-color: $white;
			height: 100%;
			border: none !important;
			display: flex;
			position: relative;
			left: 0;

			.sidebar {
				padding: $size-16-px 0px;

				.sidebar-nav {
					list-style: none;
					padding-left: 0;
					margin-bottom: 0;

					&.nav-tabs {
						width: 100%;
						display: flex;
						flex-direction: column;

						.sidebar-item {
							&.nav-item {
								padding: 0;

								.nav-link {
									padding: $size-8-px $size-16-px;
									justify-content: flex-start;
									@extend .reg-txt;

									&:hover {
										background-color: $body-bg;
										color: $title-color !important;

										p,
										span {
											color: $title-color !important;
										}
									}

									&.active {
										background-color: rgba($color: $primary, $alpha: 0.12);
										position: relative;
										color: $primary !important;

										p {
											color: $primary !important;
										}

										&::after {
											display: none;
										}

										&::before {
											position: absolute;
											content: "";
											width: 2px;
											height: 100%;
											background-color: $primary;
											border-radius: 1.5px;
											left: 0;
											top: 0;
										}
									}
								}
							}
						}
					}

					.sidebar-item {
						@extend .reg-txt;
						padding: $size-8-px $size-16-px;
						cursor: pointer;

						&:hover {
							background-color: $body-bg;
							color: $title-color !important;

							p,
							span {
								color: $title-color !important;
							}
						}

						&.active {
							background-color: rgba($color: $primary, $alpha: 0.12);
							position: relative;
							color: $primary !important;

							p {
								color: $primary !important;
							}

							&::before {
								position: absolute;
								content: "";
								width: 2px;
								height: 100%;
								background-color: $primary;
								border-radius: 1.5px;
								left: 0;
								top: 0;
							}
						}
					}
				}
			}
		}
	}

	.main-sec {
		height: 100%;
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: hidden;
		// Action Bar //
		.action-bar {
			&.expanded-search {
				position: relative;
				// &::before{
				// 	position: absolute;
				// 	content: '';
				// 	width: 100%;
				// 	height: 100%;
				// 	background-color: rgba($color: $card-bg, $alpha: 0.12);
				// 	z-index: 9;
				// 	backdrop-filter: blur(1px);
				// }
				.action-search {
					// position: relative;
					z-index: 9;
					min-height: 36px;
					app-search {
						position: absolute;
						// top: -18px;
					}
				}
			}
			//   border-radius: 6px;
			box-shadow: $box-shadow;
			//   border-bottom-left-radius: 0;
			//   border-bottom-right-radius: 0;
			flex: initial;
			// overflow: hidden;
			.inner-section {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0.714rem 1rem;
				background-color: $white;
				@include max-mq(1023) {
					flex-direction: column;
					// row-gap: 1rem;
					> * {
						margin-bottom: 1rem;

						&:last-of-type {
							margin-bottom: 0rem;
							width: 100%;
						}
					}
				}

				.left-section {
					display: flex;
					align-items: center;
					@include max-mq(1023) {
						justify-content: flex-start;
						width: 100%;
					}
					@include max-mq(767) {
						width: 100%;
						// flex-wrap: wrap;
						> * {
							// margin-bottom: 1rem;
							// margin-right: 0rem !important;
							width: 100%;

							&:last-of-type {
								// margin-bottom: 0rem;
							}
						}
					}
					@include max-mq(567) {
						display: grid !important;
						gap: 0.5rem;
						grid-template-columns: 1fr;
					}

					> * {
						margin-right: $size-12-px;
					}

					.main-search {
						@include max-mq(767) {
							padding: 0;
						}
					}

					.subheader {
						height: 28px !important;
						width: 28px !important;
						margin-right: 1rem !important;

						&.mobile-sidebar-toggle {
							display: none;
							@include max-mq(1199) {
								display: flex;
							}
						}

						.toggale-submenu {
							.submenu-toggle {
								width: 28px !important;
								height: 28px !important;
								border: 1px solid $border-color;
								border-radius: 100%;
								display: flex;
								.pl-25 {
									padding-left: 0px !important;
								}
							}
						}
					}

					.form-timescale {
						position: relative;
						min-width: 240px;
						margin-bottom: 0;
						@include max-mq(1300) {
							min-width: 220px;
						}

						&::before {
							content: "";
							position: absolute;
							width: 38px;
							height: 100%;
							left: 0;
							top: 0;
							z-index: 9;
							mask-image: url(../../assets/images/svg/calender.svg);
							-webkit-mask-image: url(../../assets/images/svg/calender.svg);
							background-color: $primary;
							-webkit-mask-size: 50%;
							-webkit-mask-position: center;
							-webkit-mask-repeat: no-repeat;
						}
					}

					.action-search {
						// @include max-mq(567) {
						//   min-width: 220px;
						// }
						// .form-group {
						//   position: relative;
						//   margin-bottom: 0rem;
						//   &::before {
						//     position: absolute;
						//     content: '';
						//     width: 3rem;
						//     height: 100%;
						//     background: url(../../assets/images/icons/search.svg);
						//     background-repeat: no-repeat;
						//     background-position: center;
						//     top: 0px;
						//     left: 0;
						//     background-size: 45%;
						//   }
						//   .form-control {
						//     padding-left: 3rem !important;
						//   }
						// }
					}
				}

				.right-section {
					display: flex;
					align-items: center;
					@include max-mq(1023) {
						justify-content: flex-start;
						width: 100%;
					}

					> * {
						margin-left: $size-14-px;

						&:nth-child(1) {
							margin-left: 0;
						}

						@include max-mq(767) {
							margin-left: 0.5rem;
						}
					}
				}
			}
		}

		// End of Action Bar //
		.action-bar {
			flex: initial;

			.left-sec {
				display: block !important;
			}
		}

		.action-section {
			flex: 1%;
			display: flex;
			flex-direction: column;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
			box-shadow: $box-shadow;
			position: relative;
		}
	}
}

// End of Main collapsible //

// End of Layout pages //

// Default SideOut stepping Components //
.default-stepping {
	&.slideout-content {
		&.sm-stepping {
			.bs-stepper {
				.bs-stepper-header {
					padding: $size-12-px $size-14-px !important;

					@include max-mq(991) {
						display: flex !important;
						flex-direction: row !important;
						flex-wrap: wrap;
						padding: 8px 12px !important;
					}

					.line {
						border-top: 0px;
						bottom: auto;
					}

					.step {
						@include max-mq(991) {
							margin-right: 1rem;
							padding: 0 !important;
						}

						.step-trigger {
							@include max-mq(991) {
								padding: 0 !important;
								font-size: $size-12-px !important;
							}

							.bs-stepper-box {
								width: 24px !important;
								height: 24px !important;
								font-weight: 600 !important;
								align-items: center !important;
							}

							.bs-stepper-label {
								.bs-stepper-subtitle {
									display: none;
								}
							}
						}
					}
				}
			}
		}

		.bs-stepper {
			.bs-stepper-header {
				.step {
					&.active {
						.step-trigger {
							.bs-stepper-label {
								.bs-stepper-subtitle {
									color: $body-color !important;
								}
							}
						}
					}

					.step-trigger {
						.bs-stepper-label {
							.bs-stepper-subtitle {
								color: $text-muted !important;
							}
						}
					}
				}

				@include max-mq(991) {
					display: flex !important;
					flex-direction: row !important;
					flex-wrap: wrap;
					padding: 8px 12px !important;
				}

				.step {
					@include max-mq(991) {
						margin-right: 1rem;
						padding: 0 !important;
					}

					.step-trigger {
						@include max-mq(991) {
							padding: 0 !important;
							font-size: $size-12-px !important;
						}
					}
				}

				.line {
					border-top: 0px;
					bottom: auto;
				}
			}
		}

		.modal-dialog {
			.modal-content {
				height: 100%;
				padding-bottom: 0;
				display: flex;
				flex-direction: column;

				.modal-header {
					background-color: $card-bg;
					padding: 10px 1rem;
					flex: initial;
					border-bottom: 1px solid $border-color;

					.btn-icon {
						font-size: $size-20-px;
						height: auto !important;
						width: auto !important;
						background-color: transparent;
						color: $body-color;

						&:hover {
							color: $title-color;
						}
					}
				}

				.modal-body {
					padding: 0;
					flex: 1%;

					.stepping-form {
						height: 100%;
					}

					.bs-stepper {
						height: 100%;
						display: flex;
						flex-direction: column;

						.bs-stepper-header {
							padding: $size-14-px $size-20-px;
							flex: initial;

							.step {
								.step-trigger {
									align-items: center !important;

									.bs-stepper-label {
										margin: 0rem 0 0 1rem !important;
									}
								}
							}
						}

						.bs-stepper-content {
							flex: 1%;
							padding: 0;

							.bs-stepper-inner {
								height: 100%;

								form {
									height: 100%;
								}

								.content {
									height: 100%;

									.stepper-inner {
										display: flex;
										flex-direction: column;
										height: 100%;

										.stepper-body {
											flex: 1%;

											.stepper-main {
												padding: 1.5rem;
											}
										}

										.stepper-btn {
											flex: initial;
											border-top: 1px solid $border-color;
											padding: $size-12-px $size-14-px;
											display: flex;
											justify-content: space-between;

											.left-sec {
												display: flex;
											}

											.right-sec {
												display: flex;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// End of Default SideOut stepping Components //

// Default Sideout Components //
.default-slideout {
	&.slideout-content {
		.modal-dialog {
			.modal-content {
				height: 100%;
				padding-bottom: 0;
				display: flex;
				flex-direction: column;

				.modal-header {
					background-color: $card-bg;
					padding: 10px 1rem;
					flex: initial;
					border-bottom: 1px solid $border-color;

					.btn-icon {
						font-size: $size-20-px;
						height: auto !important;
						width: auto !important;
						background-color: transparent;
						color: $body-color;

						&:hover {
							color: $title-color;
						}
					}
				}

				.modal-body {
					padding: 0;
					flex: 1%;
					display: flex;
					flex-direction: column;

					.modal-content-header {
						flex: initial;
					}

					.modal-content-body {
						flex: 1%;
					}

					.modal-content-footer {
						flex: initial;
					}
				}
			}
		}
	}
}

// Enb of default Sideout Components//

// Full Calender //
.fc {
	.fc-header-toolbar {
		margin-top: 1rem;

		.fc-toolbar-title {
			@extend .head5;
		}
	}

	.fc-view-harness {
		.fc-view {
			.fc-scrollgrid {
				thead {
				}

				tbody {
					.fc-scrollgrid-section {
						.fc-scrollgrid-sync-table {
							border-bottom-style: unset !important;
						}
					}
					.fc-daygrid-body {
						.fc-scrollgrid-sync-table {
							tbody {
								tr {
									.fc-daygrid-day {
										.fc-daygrid-day-events {
											.fc-daygrid-event-harness {
												&:first-of-type {
													z-index: 99;
												}
												&:nth-child(2) {
													z-index: 98;
												}
												&:nth-child(3) {
													z-index: 97;
												}
												&:nth-child(4) {
													z-index: 96;
												}
												&:nth-child(5) {
													z-index: 95;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// End of Full Calender//

// Customer Card Radio //
.custom-card-radio {
	padding-left: 0 !important;

	.custom-control-input {
		left: auto;
		right: $size-8-px;
		top: $size-8-px;

		&:not(:disabled):active {
			& ~ .custom-control-label {
				border: 1px solid $primary;
			}
		}

		&:checked ~ .custom-control-label {
			border: 1px solid $primary;
		}
	}

	.custom-control-label {
		padding: $size-16-px $size-16-px $size-14-px !important;
		border-radius: $size-8-px;
		border: 1px solid $border-color;
		width: 100%;
		cursor: pointer;
		display: flex;
		align-items: center;

		.icon-section {
			font-size: $size-18-px;
			margin-right: 1rem;
		}

		&::before {
			left: auto;
			right: $size-8-px;
			top: $size-8-px;
			background-color: $body-bg;
		}

		&::after {
			left: auto;
			right: $size-8-px;
			top: $size-8-px;
		}

		.card-title {
			@extend .head6;
			font-weight: 500 !important;
			margin-bottom: 5px;
		}

		.card-txt {
			@extend .sm-txt;
		}
	}
}

// Customer Card Radio //
// Customer Card Checkbox //
.custom-card-checkbox {
	padding-left: 0 !important;

	.custom-control-input {
		right: auto;
		left: $size-16-px;
		top: $size-18-px;

		&:not(:disabled):active {
			& ~ .custom-control-label {
				border: 1px solid $primary;
			}
		}

		&:checked ~ .custom-control-label {
			border: 1px solid $primary;
		}
	}

	.custom-control-label {
		padding: $size-16-px $size-16-px $size-14-px !important;
		border-radius: $size-8-px;
		border: 1px solid $border-color;
		width: 100%;
		cursor: pointer;
		display: flex;
		align-items: center;
		padding-left: 40px !important;

		.icon-section {
			font-size: $size-18-px;
			margin-right: 1rem;
		}

		&::before {
			right: auto;
			left: $size-16-px;
			top: $size-18-px;
			background-color: $body-bg;
		}

		&::after {
			right: auto;
			left: $size-16-px;
			top: $size-18-px;
		}

		.card-title {
			@extend .head6;
			font-weight: 500 !important;
			margin-bottom: 5px;
		}

		.card-txt {
			@extend .sm-txt;
		}
	}
}

// Customer Card Checkbox //
.macros-modal {
	min-width: 320px;
	border-radius: 8px;
	box-shadow: $box-shadow;
	position: relative;
	z-index: 999;
	// top: calc(36px + 7px);
	background-color: $white;

	.macros-head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $size-16-px;
		border-bottom: 1px solid $border-color;

		h6 {
			font-size: $size-12-px !important;
			margin-bottom: 0;
			font-weight: 500;
		}

		i {
			font-size: $size-16-px;
		}
	}

	.macros-list {
		background-color: $white;
		min-height: 240px;
		display: grid;
		padding: 1rem;

		.list-title {
			@extend .sm-txt;
			font-weight: 500 !important;
			color: $title-color !important;
			margin-bottom: $size-8-px;
		}

		.group-list {
			list-style: none;
			padding-left: 0;

			li {
				padding: $size-8-px $size-8-px $size-8-px 2rem;
			}
		}
	}

	.macros-bottom {
		position: relative;
		padding: 10px 12px;
		border-top: 1px solid $border-color;
		background-color: $body-bg;

		.btn {
			position: absolute;
		}

		.form-group {
			margin-bottom: 0;

			.form-control {
				padding-left: 38px;
			}
		}
	}
}

// KB timeline //
.kb-event-history {
	.timeline {
		.timeline-item {
			&:not(:last-child) {
				padding-bottom: $size-14-px;
			}

			padding-left: 2rem;
		}
	}
}

// End of KB timeline //

// Top Sticky section //
.top-sticky-sec {
	padding: 1rem;
	position: absolute;
	top: 100%;
	background-color: $white;
	width: 100%;
	z-index: 1;
	border-bottom: 1px solid $border-color;

	&.show {
		-webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
		animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	}

	&.hide {
		-webkit-animation: scale-out-ver-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
		animation: scale-out-ver-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
		display: none;
	}
}

// End of Top Sticky section //

// Common Seach section //
.input-group {
	&.search-form-group {
		.input-group-prepend {
			.input-group-text {
				color: $text-muted;
				font-size: $size-14-px;
				padding: $size-4-px $size-14-px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				&::after {
					content: "";
					position: absolute;
					right: 1px;
					left: auto;
					height: calc(100% - 8px);
					top: 4px;
					background-color: $border-color;
					width: 1px;
				}
			}
		}

		.form-control {
			&.sm-form-control {
				& ~ .input-group-prepend {
					.input-group-text {
						font-size: $size-12-px;
					}
				}
			}

			border-left: none !important;
		}
	}
}

// End of Common Seach section //
.auth-page {
	//   height: 100vh;
	//   max-height: 100vh;
	.auth-wrapper {
		display: grid;

		.ng-scroll-content {
			height: 100%;
		}

		.brand-logo {
			position: absolute !important;
			@include max-mq(625) {
				position: absolute !important;
				top: 2rem !important;
				left: 2rem !important;
				margin: 0 !important;
				z-index: 1 !important;
				padding-left: 0 !important;
			}
		}

		.auth-section {
			display: grid;
			grid-template-columns: 65% 35%;
			@include max-mq(1450) {
				grid-template-columns: 60% 40%;
			}
			@include max-mq(1300) {
				grid-template-columns: 55% 45%;
			}
			@include max-mq(1200) {
				grid-template-columns: 50% 50%;
			}
			@include max-mq(1023) {
				grid-template-columns: 100%;
			}

			.image-sec {
				display: flex;
				@include max-mq(1023) {
					display: none;
				}
			}

			.auth-bg {
				display: flex;
				width: 100%;
				justify-content: center;
				align-items: center;
				@include max-mq(1023) {
					padding-top: 7rem !important;
				}

				.auth-form {
					width: 100%;
					// .ng-scroll-content{
					//   display: flex;
					//   justify-content: center;
					//   height: 100%;
					//   flex-direction: column;
					// }
				}
			}
		}
	}
}

form {
	quill-editor {
		&.error {
			.ql-container {
				border: 1px solid $danger !important;
				color: initial;
			}
		}
	}
}

.skeleton-data {
	// padding: 1rem 0rem;
	width: 100%;
}

dns-pagination {
	.datatable-footer {
		padding: 0 !important;

		.datatable-pager {
			margin: 0;
			display: flex;
			@include max-mq(600) {
				display: flex;
				justify-content: flex-end;
				margin-right: 1rem !important;
			}

			.pager {
				height: 30px;
				background-color: $pagination-bg;
				border-radius: 28px;
				display: flex !important;
				align-items: center;
				justify-content: center;

				.datatable-icon-left,
				.datatable-icon-skip,
				.datatable-icon-prev,
				.datatable-icon-right {
					font-size: 18px;
					padding: 0 3px;
					line-height: 12px;
				}

				li {
					margin: 1px 0 !important;

					&:hover {
						a {
							color: $primary;
						}
					}

					a {
						height: 20px;
						min-width: 24px;
						color: #636363;
						height: 20px;
						min-width: 24px;
						line-height: 20px;
						display: flex !important;
						align-items: center;
						justify-content: center;
						margin: 0 3px;
						font-size: 13px;

						&:hover {
							color: $primary;
						}
					}

					&.disabled {
						&:hover {
							a {
								color: #a0a3bd !important;
							}
						}

						a {
							color: #a0a3bd !important;
						}
					}

					&:not(.disabled) {
						&.active {
							a {
								width: 28px;
								height: 28px;
								padding: 3px;
								background-color: $primary !important;
								color: $white !important;
								border-radius: 50%;
								display: flex;
								align-items: center;
								justify-content: center;
								font-weight: bold;
								font-size: 13px;
							}
						}
					}
				}
			}
		}
	}
}

.card,
.single-card {
	&:hover {
		.profile-name {
			color: $primary !important;
		}
	}

	.profile-name {
		margin-bottom: 0;
	}

	.profile-info {
		display: flex;
		flex-direction: column;
	}
}

.ng-dropdown-panel {
	.ng-dropdown-panel-items {
		.ng-option {
			.cell-line-height {
				p {
					color: $title-color;
					display: block;
					width: 280px;
					white-space: nowrap;
					overflow: hidden !important;
					text-overflow: ellipsis;
				}
				span {
					display: block;
					width: 280px;
					white-space: nowrap;
					overflow: hidden !important;
					text-overflow: ellipsis;
				}
			}

			&.ng-option-selected {
				&.ng-option-marked {
					.cell-line-height {
						p {
							color: $primary !important;
						}
						span {
							color: $primary !important;
						}
					}
				}
				.cell-line-height {
					p {
						color: $primary !important;
					}
					span {
						color: $primary !important;
					}
				}
			}
			&.ng-option-marked {
				.cell-line-height {
					p {
						color: $title-color !important;
					}
					span {
						color: $title-color !important;
					}
					small {
						color: $title-color !important;
					}
				}
			}
		}
	}
}

ngx-intl-tel-input {
	// &.ng-invalid, &.is-invalid, &.error{

	// }
	&:focus {
		.form-control {
			border-color: $primary !important;
			outline: none !important;
		}
	}

	&:focus-visible {
		outline: none !important;
	}

	.form-control {
		&:focus {
			border-color: $primary !important;
			outline: none !important;
		}

		&:focus-visible {
			outline: none !important;
		}
	}

	&.is-invalid {
		> .iti {
			.form-control {
				background-image: none !important;
				padding-right: 1rem !important;
				border-color: $danger !important;

				&:focus {
					border-color: $danger !important;
					box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;
				}
			}
		}
	}

	&.error {
		> .iti {
			.form-control {
				background-image: none !important;
				padding-right: 1rem !important;
				border-color: $danger !important;

				&:focus {
					border-color: $danger !important;
					box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;
				}
			}
		}
	}

	> .iti {
		> .iti__flag-container {
			position: unset !important;

			.iti__selected-flag {
				position: absolute;
			}

			> .country-dropdown {
				&.show {
					width: 100% !important;
					.search-container {
						> input {
							&:focus-visible {
								outline: 1px solid $border-color !important;
							}
						}
					}
					ul {
						&.iti__country-list {
							@extend .browser-scrollbar;
							li {
								&.iti__country {
									&:hover {
										// background-color: rgba($color: $primary, $alpha: 0.12) !important;
										background-color: $body-bg !important;
										.iti__country-name {
											color: $primary !important;
										}
										.iti__dial-code {
											color: $primary !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.flat-picker {
	.flatpickr-wrapper {
		width: 100%;

		.ng2-flatpickr-input {
			background-color: transparent !important;
			height: 28px;
			font-size: $size-12-px !important;
		}
	}
}

.flatpickr-calendar {
	&.open {
		z-index: 2003 !important;
	}
}

.no-filter {
	display: flex;
	flex-direction: column;

	.icon-sec {
		display: flex;
		align-items: center;
		flex-direction: column;

		.dark-icon {
			display: none;
		}

		.light-icon {
			display: block;
		}
	}
}

.count-animation {
	// -webkit-animation: heartbeat 1.2s ease-in-out 10 alternate-reverse backwards;
	// animation: heartbeat 1.2s ease-in-out 10 alternate-reverse backwards;
	-webkit-animation: jello-horizontal 0.9s 3 both;
	animation: jello-horizontal 0.9s 3 both;
}

.help {
	.help-tab-pane {
		.accordion {
			.accordion {
				.accordion-item {
					.accordion-header {
						.btn-link {
							display: flex;
							justify-content: flex-start;
						}
					}
				}
			}
		}
	}
}

// Incident smart grid for sticky column //
.incident-smart-grid-table {
	&.custom-material-table {
		thead {
			z-index: 2030 !important;

			.mat-header-row {
				.mat-header-cell {
					&:nth-child(2) {
						&.mat-table-sticky {
							left: 340px !important;
						}

						&.table-column-200 ~ .mat-header-cell {
							&.mat-table-sticky {
								left: 540px !important;
							}
						}

						&.table-column-350 ~ .mat-header-cell {
							&.mat-table-sticky {
								left: 690px !important;
							}
						}
					}
				}
			}
		}

		tbody {
			.mat-row {
				.mat-cell {
					&.mat-table-sticky {
						z-index: 2024 !important;

						&:hover {
							z-index: 2028 !important;
						}

						&:nth-child(2) {
							left: 340px !important;

							&.table-column-200 ~ .mat-cell {
								left: 540px !important;
							}

							&.table-column-350 ~ .mat-cell {
								left: 690px !important;
							}
						}
					}
				}
			}
		}
	}
}

// End of Incident smart grid for sticky column //
.table {
	.custom-checkbox {
		min-height: 1rem;
		height: 1rem;

		.custom-control-input {
			width: 1rem;
			height: 1rem;
			margin-bottom: 0;
			padding-bottom: 0px;
		}

		.custom-control-label {
			&::before,
			&::after {
				width: 14px;
				height: 14px;
			}
		}
	}

	thead {
		tr {
			th {
				line-height: 16px !important;
				font-size: $size-10-px !important;
				text-transform: uppercase;
				color: $title-color !important;
				padding-top: 0.5rem !important;
				padding-bottom: 0.5rem !important;
				font-weight: 600;

				.custom-checkbox {
					.custom-control-label {
						line-height: 16px !important;
						font-size: $size-10-px !important;
						text-transform: uppercase;
						color: $title-color !important;
					}
				}
			}
		}
	}
}

// default Modal //
.modal {
	.modal-header {
		background-color: $card-bg;
	}
}

// End of default Modal //
.timeline-event {
	.table {
		tbody {
			&:first-of-type {
				tr {
					th,
					td {
						border-top: 0 !important;
					}
				}
			}

			&:last-of-type {
				tr {
					th {
						border: 1px solid $border-color !important;
						border-top: 0px !important;
						border-right: 0 !important;

						&:last-of-type {
							border-bottom: 1px solid $border-color !important;
						}
					}

					td {
						border: 1px solid $border-color !important;
						border-top: 0px !important;

						&:last-of-type {
							border-bottom: 1px solid $border-color !important;
						}
					}
				}
			}

			tr {
				th {
					background-color: $gray-50;
					color: $title-color;
					border: 1px solid $border-color !important;
					border-top: 0px !important;
					border-right: 0 !important;
					border-top: 0 !important;
					word-break: break-word !important;
				}

				td {
					border: 1px solid $border-color !important;
					border-top: 0px !important;
					// &:last-of-type{
					//   border-bottom: 1px solid $border-color !important;
					// }
				}
			}
		}
	}
}

.badge[class*="badge-"] a {
	color: inherit !important;
}

form {
	.error:not(input),
	.error:not(.ng-select) {
		.ql-toolbar {
			span.ql-formats {
				color: $title-color !important;
			}
		}

		.ql-toolbar,
		.ql-container {
			span,
			small,
			p {
				color: inherit !important;
			}
		}

		span,
		small,
		p {
			color: $danger !important;
		}

		.ng-dropdown-panel {
			.ng-dropdown-panel-items {
				.ng-option {
					.ng-option-label {
						color: inherit !important;
					}
				}
			}
		}
	}

	.mail-to-address {
		.form-group {
			margin-bottom: 0;
			.ng-select {
				min-width: auto;
				cursor: pointer;
				&.ng-select-focused {
					min-width: 208px;

					&:hover {
						.ng-select-container {
							border: 1px solid $input-border-color !important;
							transition: all 0.5s ease;
							.ng-value-container {
								padding-left: 10px;
								padding-right: 5px;
								transition: all 0.5s ease;
							}
							.ng-clear-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
							.ng-arrow-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
						}
					}

					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
				&.ng-select-opened {
					min-width: 208px;
					&:hover {
						.ng-select-container {
							border: 1px solid $input-border-color !important;
							transition: all 0.5s ease;
							.ng-value-container {
								padding-left: 10px;
								padding-right: 5px;
								transition: all 0.5s ease;
							}

							.ng-clear-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}

							.ng-arrow-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
						}
					}

					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}

						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
				.ng-select-container {
					border: 1px solid transparent !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 0px;
						padding-right: 0px;
						transition: all 0.5s ease;
						.ng-value {
							background-color: rgb(186 191 199 / 13%) !important;
							border-radius: 13px !important;
							color: $black;

							.ng-value-icon {
								color: $black;
								opacity: 0;
								&:hover {
									opacity: 1;
								}
							}
						}
					}
					.ng-clear-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					&:hover {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						// min-width: 208px;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}

						.ng-clear-wrapper {
							opacity: 0;
							visibility: hidden;
							width: 0px;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 0;
							visibility: hidden;
							width: 0px;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}

					&:focus {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						min-width: 208px;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}

						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
				&:hover {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 0;
							visibility: hidden;
							width: 0px;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
						.ng-arrow-wrapper {
							opacity: 0;
							visibility: hidden;
							width: 0px;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
				&:focus {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
			}
		}
	}
	.mail-cc {
		.form-group {
			margin-bottom: 0;
			.ng-select {
				min-width: auto;
				cursor: pointer;
				&.ng-select-focused {
					min-width: 208px;

					&:hover {
						.ng-select-container {
							border: 1px solid $input-border-color !important;
							transition: all 0.5s ease;
							.ng-value-container {
								padding-left: 10px;
								padding-right: 5px;
								transition: all 0.5s ease;
							}
							.ng-clear-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
							.ng-arrow-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
						}
					}

					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
				&.ng-select-opened {
					min-width: 208px;
					&:hover {
						.ng-select-container {
							border: 1px solid $input-border-color !important;
							transition: all 0.5s ease;
							.ng-value-container {
								padding-left: 10px;
								padding-right: 5px;
								transition: all 0.5s ease;
							}

							.ng-clear-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}

							.ng-arrow-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
						}
					}

					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}

						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
				.ng-select-container {
					border: 1px solid transparent !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 0px;
						padding-right: 0px;
						transition: all 0.5s ease;
						.ng-value {
							background-color: rgb(186 191 199 / 13%) !important;
							border-radius: 13px !important;
							color: $black;

							.ng-value-icon {
								color: $black;
								opacity: 0;
								&:hover {
									opacity: 1;
								}
							}
						}
					}
					.ng-clear-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					&:hover {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						// min-width: 208px;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}

						.ng-clear-wrapper {
							opacity: 0;
							visibility: hidden;
							width: 0px;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 0;
							visibility: hidden;
							width: 0px;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}

					&:focus {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						min-width: 208px;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}

						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
				&:hover {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 0;
							visibility: hidden;
							width: 0px;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
						.ng-arrow-wrapper {
							opacity: 0;
							visibility: hidden;
							width: 0px;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
				&:focus {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
			}
		}
	}
	.mail-from-address {
		.ng-select {
			min-width: auto;
			cursor: pointer;
			&.ng-select-focused {
				min-width: 208px;

				&:hover {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}

				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&.ng-select-opened {
				min-width: 208px;
				&:hover {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}

						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}

				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			.ng-select-container {
				border: 1px solid transparent !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 0px;
					padding-right: 0px;
					transition: all 0.5s ease;
					.subject-value {
						background-color: rgb(186 191 199 / 13%) !important;
						border-radius: 13px !important;
						color: $black;
						padding: 5px;
					}
				}
				.ng-clear-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				&:hover {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					// min-width: 208px;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}

				&:focus {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					min-width: 208px;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&:hover {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&:focus {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
		}
	}
	.error {
		&.ng-select {
			.ng-select-container {
				.ng-value-container {
					.ng-value {
						span {
							color: inherit !important;
						}
					}
				}
			}

			.ng-dropdown-panel {
				.ng-dropdown-panel-items {
					.ng-option {
						p {
							color: inherit !important;
						}

						span {
							color: inherit !important;
						}

						.ng-option-label {
							color: inherit !important;
						}
					}
				}
			}
		}
	}
}

.input-group {
	.form-control {
		&.error {
			border-color: $danger !important;
		}
	}

	.input-group-append {
		.btn {
			&.btn-outline-secondary {
				border: 1px solid $input-border-color !important;
			}
		}
	}
}

.blog-wrapper {
	.blog-main {
		.blog-left {
			.blog-detail-wrapper {
				.blog-detail-info {
					.accordion {
						.accordion-item {
							.accordion-header {
								.btn-link {
									span.card-text {
										width: 100%;
									}
								}
							}
						}
					}
				}
				.blog-basic-info {
					.card-body {
						.card-text {
							img {
								width: 100% !important;
								margin-bottom: 0.5rem !important;
								height: auto !important;
							}
						}
						div:not(.media) {
							img {
								width: 100% !important;
								margin-bottom: 0.5rem !important;
								height: auto !important;
							}
						}
					}
				}
			}
		}
	}
}

// Assignee Dashboard Modal //
.assignee-modal {
	.accordion {
		flex: 1%;
		display: flex;
		flex-direction: column;

		.accordion-item {
			height: 50%;
			display: flex;
			flex-direction: column;
			box-shadow: none !important;
			border-top: 1px solid $border-color;
			border-radius: 0px;
			margin-bottom: 0px;
			padding-bottom: 0px;
			&:first-of-type {
				border-top: none !important;
			}

			.accordion-header {
				flex: initial;
			}

			.collapse {
				flex: 1%;

				.accordion-body {
					height: 100%;

					.assignee-card {
						height: 100%;
					}

					.user-sec,
					.team-sec {
						height: 100%;
						display: flex;
						flex-direction: column;

						.icon-search {
							flex: initial;
						}

						.user-list,
						.team-list {
							flex: 1%;
						}
					}
				}
			}
		}
	}
}

// Assignee Dashboard Modal //
// History Table/list sec //
.timeline {
	.timeline-item {
		.timeline-event {
			span {
				// white-space: break-spaces;
				white-space: normal;
				word-break: break-word;
			}

			.default-img {
				max-width: 100%;
				height: auto;
				margin-bottom: $size-8-px;
			}

			.badge {
				img {
					max-width: 100%;
					height: auto;
					margin-bottom: $size-8-px;
				}
			}
		}
	}
}

.history-table {
	.default-img {
		max-width: 100%;
		height: auto;
		margin-bottom: $size-8-px;
	}

	.gtr {
		.gtd {
			.badge {
				img {
					max-width: 100%;
					height: auto;
					margin-bottom: $size-8-px;
				}
			}
		}
	}
}

// End of History Table/list sec //
// Schedule Dashboard modal //
.schedule-main-sec {
	.assign-sec {
		.assign {
			.form-group {
				.ng-select {
					.ng-select-container {
						border-top-left-radius: 0px !important;
						border-bottom-left-radius: 0px !important;
					}
				}
			}
		}
	}
}

// End of Schedule Dashboard modal //
.custom-material-table {
	overflow-x: unset !important;
	display: flex !important;
	flex-direction: column;

	thead {
		flex: initial;
		background-color: $table-head-bg !important;
		position: sticky;
		top: 0;
		z-index: 10;
		min-width: 100%;
	}

	tbody {
		flex: 1%;
		min-width: 100%;

		.mat-row {
			min-width: 100%;
		}
	}

	tfoot {
		flex: initial;
		min-width: 100%;
	}

	.mat-header-row {
		background-color: inherit !important;
		min-height: 30px !important;
		margin-bottom: 0;
		border-radius: 0px;
		border-left: 0px !important;

		.mat-header-cell {
			&.dotted_line {
				position: relative;

				&::after {
					position: absolute;
					content: "";
					width: 1px;
					height: 226% !important;
					border-right: 1px dashed $title-color;
					right: 1px;
					top: 0;
				}
			}
		}
	}

	.mat-row {
		// border-top: 0!important;
		margin-top: 0;
		border-radius: 0px !important;
		min-height: auto;
		max-height: max-content !important;
		border-left: 0px !important;
		position: relative;
		overflow: visible;
		border-top: 1px solid $border-color !important;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;

		.mat-cell {
			// border-top: 1px solid $border-color !important;
			&.mat-column-Severity {
				&.dotted_line {
					border-right: none !important;
				}

				.state-color {
					position: absolute;
					height: 100%;
					width: 6px !important;
					border-right: 0px !important;
					top: 0;
					left: -1px;
					$state-colors: ("Critical", "Major", "Minor", "Normal", "Informational");
					@each $state-color, $i in $state-colors {
						&.state-#{$state-color} {
							@if $state-color == "Critical" {
								background-color: $danger;
							} @else if $state-color == "Major" {
								background-color: $warning;
							} @else if $state-color == "Minor" {
								background-color: $info;
							} @else if $state-color == "Normal" {
								background-color: $success;
							} @else if $state-color == "Informational" {
								background-color: $success;
							} @else {
								background-color: $primary;
							}
						}
					}
				}
			}

			.tree-dropdown {
				.dropdown {
					.dropdown-toggle {
						background-color: transparent !important;
						border-color: transparent !important;
						font-size: $size-12-px !important;
						color: transparent !important;

						span.block:not(.placeholder-txt) {
							color: $body-color !important;
						}

						.block {
							line-height: 1.5;
						}

						&::after {
							opacity: 0;
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23313349' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
							color: $body-color !important;
						}

						&:hover {
							border-color: $input-border-color !important;

							&::after {
								opacity: 1;
							}
						}
					}

					.dropdown-menu {
						.dropdown-container {
							.treeview-container {
								.treeview-item {
									margin-left: $size-10-px;

									.form-check-label {
										font-size: $size-12-px !important;
									}
								}
							}
						}
					}
				}
			}

			.selct-feature {
				&.group-list-feature {
					.form-group {
						.ng-select {
							.ng-select-container {
								.ng-value-container {
									.ng-value {
										.group-lists {
											margin-bottom: 0px;

											.avatar-group {
												display: flex;
												flex-direction: row-reverse;
												align-items: center;
												justify-content: center;
												padding-left: 8px;

												.avatar {
													width: $size-16-px;
													height: $size-16-px;
													background-color: transparent;

													&:last-of-type {
														margin-right: 0.571rem;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.select-sec {
					.form-group {
						margin-bottom: 0px;
						cursor: pointer;

						.form-label {
							display: none;
						}

						&:hover {
							.ng-select {
								&.ng-select-opened {
									.ng-select-container {
										border-color: $input-border-color !important;
										background-color: transparent !important;

										.ng-arrow-wrapper {
											opacity: 0 !important;
										}
									}
								}

								.ng-select-container {
									border-color: $input-border-color !important;
									background-color: transparent !important;
									cursor: pointer;

									.ng-value-container {
										.ng-input {
											> input {
												cursor: pointer;
											}
										}
									}

									.ng-clear-wrapper {
										opacity: 1 !important;
									}

									.ng-arrow-wrapper {
										opacity: 1 !important;
									}
								}
							}
						}

						.ng-select {
							cursor: pointer;

							&.ng-select-opened {
								.ng-select-container {
									border-color: $input-border-color !important;
									background-color: transparent !important;

									.ng-arrow-wrapper {
										opacity: 0 !important;
									}
								}
							}

							.ng-select-container {
								border-color: transparent !important;
								background-color: transparent !important;

								.ng-value-container {
								}

								.ng-clear-wrapper {
									opacity: 0;
								}

								.ng-arrow-wrapper {
									opacity: 0 !important;
								}
							}
						}
					}
				}
			}
		}

		&:nth-child(odd) {
			background-color: $white;

			&.highlight {
				.mat-cell {
					.on_select {
						// background-color: $body-bg;
					}
				}
			}

			.mat-cell {
				.on_select {
					// background-color: $white;
				}
			}
		}

		&:nth-child(even) {
			background-color: $body-bg;

			&.highlight {
				.mat-cell {
					.on_select {
						// background-color: $white;
					}
				}
			}

			.mat-cell {
				.on_select {
					// background-color: $body-bg;
				}
			}
		}

		&:last-of-type {
			border-bottom: 1px solid $border-color;
		}
	}

	.mat-tabl-first-cell {
		.ng-star-inserted {
			margin-left: -1rem;
		}
	}

	.mat-cell {
		padding: 0.5rem 1rem;
		min-height: 104px !important;
		max-height: 104px !important;
		font-size: 12px;
		border: 0px !important;
		max-height: fit-content !important;
		overflow: visible;

		&.summary_card {
			border-right: 1px solid $border-color !important;
			// &.dotted_line {
			// 	border-right: 2px dashed $info !important;
			// }
		}

		// &.dotted_line {
		// 	border-right: 2px dashed $info !important;
		// }

		&.mat-table-sticky {
			overflow: visible;

			&.dotted_line {
				position: relative;

				&::after {
					position: absolute;
					content: "";
					width: 1px;
					height: 100% !important;
					border-right: 1px dashed $title-color;
					right: 0px;
				}
			}

			&:hover {
				z-index: 9 !important;
			}

			&.mat-table-sticky-border-elem-right {
				// background-color: rgba($color: $card-bg, $alpha: 1);
				// box-shadow: $box-shadow;
				align-items: center;
				background: linear-gradient(270deg, $card-bg 0%, rgba($color: $card-bg, $alpha: 0) 100%);
			}

			.sticky-col {
				.dropdown-toggle {
					.dropdown-menu {
						// transform: none !important;
						right: 20px !important;
						top: 60px !important;
						left: auto !important;
					}
				}
			}
		}
	}

	.mat-header-cell {
		// .ng-star-inserted {
		//   display: none;
		// }
		letter-spacing: 1px;
		padding: 0rem 1rem !important;
		position: relative;

		&.summary_card_title {
			min-height: 30px;
		}

		.mat_header_checkbox {
			// margin-top: 0px;
			margin-left: 2px;

			.custom-checkbox {
				margin-right: 5px;
			}
		}

		.mat-checkbox {
			margin-right: $size-12-px;

			.mat-checkbox-layout {
				margin-bottom: 0px;
			}
		}

		span {
			line-height: $size-16-px !important;
			font-size: $size-10-px !important;
			text-transform: uppercase;
			color: $title-color;
			font-weight: 600;
			letter-spacing: 1px;
		}

		.pointer {
			line-height: $size-14-px !important;
			position: absolute;
			right: 10px;
			margin-left: auto !important;

			.sort_up {
				margin-top: -2px !important;
			}

			.sort_down {
				margin-top: 8px !important;
			}

			&.summary_sort_up {
				margin-top: -12px;
			}

			.summary_sort_down {
				margin-top: 10px;
			}
		}
	}
}

.custom-datatable-footer-container {
	.datatable-footer {
		.datatable-footer-inner {
			.datatable-pager {
				margin: 0;
				display: flex;
				@include max-mq(600) {
					display: flex;
					justify-content: flex-end;
					margin-right: 1rem !important;
				}

				.pager {
					height: 30px;
					background-color: $pagination-bg;
					border-radius: 28px;
					display: flex !important;
					align-items: center;
					justify-content: center;
					padding: 0;
					margin: 0;
					list-style: none;

					.datatable-icon-left,
					.datatable-icon-skip,
					.datatable-icon-prev,
					.datatable-icon-right {
						font-size: 18px;
						padding: 0 3px;
						line-height: 12px;
					}

					li {
						margin: 1px 0 !important;

						&:hover {
							a {
								color: $primary;
							}
						}

						a {
							height: 20px;
							min-width: 24px;
							color: #636363;
							height: 20px;
							min-width: 24px;
							line-height: 20px;
							display: flex !important;
							align-items: center;
							justify-content: center;
							margin: 0 3px;
							font-size: 13px;
							padding: 0;

							&:hover {
								color: $primary;
							}
						}

						&.disabled {
							&:hover {
								a {
									color: #a0a3bd !important;
								}
							}

							a {
								color: #a0a3bd !important;
							}
						}

						&:not(.disabled) {
							&.active {
								a {
									width: 28px;
									height: 28px;
									padding: 3px;
									background-color: $primary !important;
									color: $white !important;
									border-radius: 50%;
									display: flex;
									align-items: center;
									justify-content: center;
									font-weight: bold;
									font-size: 13px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.mat-checkbox {
	&.mat-checkbox-checked {
		.mat-checkbox-background {
			background-color: $primary !important;
		}
	}

	.mat-checkbox-inner-container {
		opacity: 1 !important;
	}
}

.filters-option {
	.collapse-icon {
		.filters-collapse {
			.accordion {
				.accordion-item {
					.accordion-header {
						.btn-link {
							text-align: left;

							&:focus {
								box-shadow: none;
							}

							> span {
								color: $title-color;
								font-size: $size-12-px;
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: space-between;
								padding-right: 1rem;
							}
						}
					}

					.collapse {
						padding-top: 0px;
					}
				}
			}
		}
	}
}

.small-date-range {
	.selct-feature {
		.select-sec {
			.form-control {
				min-height: 28px;
				height: auto !important;

				.ng-select-container {
					min-height: 28px !important;
					height: auto !important;
					font-size: $size-12-px !important;

					.ng-value-container {
						.ng-value {
							line-height: normal !important;
							font-size: $size-10-px !important;
							.badge {
								display: flex;
								align-items: center;
							}
							.ng-value-icon {
								line-height: 13px !important;
							}

							.ng-value-label {
								padding: 1px 5px !important;
							}
						}
					}
				}

				&.ng-select-focused {
					min-height: 28px !important;

					.ng-select-container {
						min-height: 28px !important;
					}
				}
			}
		}
	}
}

.btn-group {
	.btn {
		&[class*="btn-"]:not([class*="btn-outline-"]):not([class*="btn-flat-"]):not([class*="btn-gradient-"]):not([class*="btn-relief-"]) {
			&.dropdown-toggle {
				border-left-color: transparent !important;
				border-right-color: transparent !important;
			}
		}
	}
}

// End of default Modal //
.timeline-event {
	.table {
		tbody {
			&:last-of-type {
				tr {
					th {
						border: 1px solid $border-color !important;
						border-bottom: 0px !important;
						border-right: 0 !important;

						&:last-of-type {
							border-bottom: 1px solid $border-color !important;
						}
					}

					td {
						border: 1px solid $border-color !important;
						border-bottom: 0px;

						&:last-of-type {
							border-bottom: 1px solid $border-color !important;
						}
					}
				}
			}

			tr {
				th {
					background-color: $body-bg;
					color: $title-color;
					border: 1px solid $border-color !important;
					border-bottom: 0px !important;
					border-right: 0 !important;
				}

				td {
					border: 1px solid $border-color !important;
					border-bottom: 0px !important;
					// &:last-of-type{
					//   border-bottom: 1px solid $border-color !important;
					// }
				}
			}
		}
	}
}

// custom input group for input groups with custom inputs
// for input-append
.custom-input-group {
	flex-wrap: nowrap;

	.ng2-flatpickr-input-container,
	ng2-flatpickr {
		flex: 1;
	}

	.form-control,
	.ng2-flatpickr-input {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		padding-right: 0;
	}

	.ng-select.ng-select-focused {
		box-shadow: none !important;
	}
}

// for input prepend
.custom-input-group2 {
	flex-wrap: nowrap;

	.ng2-flatpickr-input-container,
	ng2-flatpickr {
		flex: 1;
	}

	.form-control,
	.ng2-flatpickr-input,
	.ng-select .ng-select-container {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		padding-left: 0;
	}

	.ng-select .ng-select-container {
		border-left: 0 !important;
	}

	.ng-select .ng-select-container .ng-value-container {
		padding-left: 0 !important;
	}

	.ng-select.ng-select-focused {
		box-shadow: none !important;
	}
}

// custom pagination style for mat table
.custom-datatable-footer-container {
	.datatable-footer .datatable-pager .pager {
		height: 30px;

		li:not(.disabled).active a,
		li:not(.disabled).active:hover a {
			width: 28px;
			height: 28px;
		}

		li {
			margin: 0 !important;
		}

		a {
			height: 20px;
			line-height: 20px;
		}

		.datatable-icon-left,
		.datatable-icon-prev,
		.datatable-icon-right,
		.datatable-icon-skip {
			font-size: 18px;
			line-height: 27px;
			padding: 0 3px;
		}
	}
}

// end custom pagination style for mat table

// input prepend fixes for custom date range select
.custom-range-select-group {
	.input-group-prepend {
		position: relative;
	}

	.ng-dropdown-panel {
		width: calc(100% + 40px);
		left: -40px;
	}

	.ng-dropdown-panel.ng-select-bottom {
		top: calc(100% + 2px);
	}
}

// end custom date range select

// custom progressbar
.custom-progress-wrapper {
	.progress {
		height: 16px;
	}

	.progress-bar {
		position: relative;
	}
}

// end : custom progressbar
// start : custom data list
.custom-data-list {
	background-color: $white;

	.table {
		border-radius: $size-4-px;
		margin: 0px;

		th {
			font-weight: $font-weight-bold;
		}

		th,
		td {
			font-size: $size-14-px;
			line-height: $size-18-px;
			color: $body-color;
			border-color: $border-color;
			word-wrap: break-word;
			padding: $size-8-px $size-12-px;
		}

		th {
			width: 30%;
		}

		td {
			width: 70%;
		}

		tbody {
			tr:first-child {
				th:first-child {
					border-top-left-radius: $size-4-px;
				}

				td:first-child {
					border-top-right-radius: $size-4-px;
				}
			}

			tr:last-child {
				th:first-child {
					border-bottom-left-radius: $size-4-px;
				}

				td:first-child {
					border-bottom-right-radius: $size-4-px;
				}
			}
		}
	}
}

// end : custom data list

// custom tree view component design
.sidebar-tree-view-menu {
	.tree-node {
		.node-wrapper {
			padding: 0.5rem 1rem !important;
		}

		.node-content-wrapper-active,
		.node-content-wrapper-focused,
		.node-content-wrapper:hover {
			box-shadow: none;
			background: transparent;
		}

		.tree-children {
			// padding: 0;
		}

		.node-content-wrapper-focused {
			background: transparent;
			box-shadow: none;
		}

		.tree-node-active:not(.tree-node-expanded) {
			color: $primary;
			background: rgba(26, 90, 217, 0.08);
		}

		.tree-node-leaf {
			&:not(.tree-node-active):hover {
				background-color: $body-bg;
			}
		}

		.toggle-children {
			background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.44063 7.91406C1.24844 8.02812 1.00938 8.02812 0.814065 7.91718C0.620315 7.80625 0.500002 7.59843 0.500002 7.375L0.500002 0.624999C0.500002 0.400937 0.620315 0.193906 0.814065 0.0826088C1.00938 -0.0287349 1.24844 -0.0274381 1.44063 0.0859838L7.19141 3.46094C7.3825 3.57344 7.5 3.77812 7.5 4C7.5 4.22187 7.3825 4.42656 7.19141 4.53906L1.44063 7.91406Z' fill='%23313349'/%3E%3C/svg%3E%0A");
		}

		&.has-children-active > tree-node-wrapper {
			.toggle-children {
				background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.44063 7.91406C1.24844 8.02812 1.00938 8.02812 0.814065 7.91718C0.620315 7.80625 0.500002 7.59843 0.500002 7.375L0.500002 0.624999C0.500002 0.400937 0.620315 0.193906 0.814065 0.0826088C1.00938 -0.0287349 1.24844 -0.0274381 1.44063 0.0859838L7.19141 3.46094C7.3825 3.57344 7.5 3.77812 7.5 4C7.5 4.22187 7.3825 4.42656 7.19141 4.53906L1.44063 7.91406Z' fill='%231A5AD9'/%3E%3C/svg%3E%0A");
			}

			.node-wrapper {
				color: $primary;
			}
		}

		.tree-node {
			.node-wrapper {
				padding: 0.5rem 1rem 0.5rem 2rem !important;
			}

			.tree-node {
				.node-wrapper {
					padding: 0.5rem 1rem 0.5rem 3rem !important;
				}
			}
		}
	}
}

// end custom tree view component design

// modal with higher z index

.modal-backdrop-higher {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #22292f;
	opacity: 0.5;
	background: rgba(34, 41, 47, 0.5);
	z-index: 2004 !important;
}
.modal-backdrop-highest {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #22292f;
	opacity: 0.5;
	background: rgba(34, 41, 47, 0.5);
	z-index: 2005 !important;
}

.modal.modal-higher {
	z-index: 2005 !important;
	&.modal-highest {
		z-index: 2006 !important;
	}
}
// Configure workflow //
.canvas-card {
	.custom-scrollbar {
		.ng-scroll-content {
			height: 100%;
		}
	}

	.configure-workflow {
		.blockelem {
			border: 1px solid $border-color;
			background-color: $card-bg;
			height: 120px !important;
			min-height: 120px !important;
			max-height: 120px !important;
			border-radius: $size-8-px;
			padding-top: 0px;
			overflow: hidden;

			.blockyleft {
				.block-icon {
					color: $primary;
					font-size: $size-14-px;
					margin-right: $size-10-px;
				}
			}

			.state-color {
				position: absolute;
				height: 100%;
				width: 4px;
				top: 0;
				left: -1px;
				$state-colors: ("primary", "info", "warning", "success", "secondary");
				@each $state-color, $i in $state-colors {
					&.state-#{$state-color} {
						@if $i == 1 {
							background-color: $primary;
						} @else if $i == 2 {
							background-color: $info;
						} @else if $i == 3 {
							background-color: $warning;
						} @else if $i == 4 {
							background-color: $success;
						} @else if $i == 5 {
							background-color: $secondary;
						} @else {
							background-color: $primary;
						}
					}
				}
			}

			.blockyleft {
				padding: $size-12-px $size-16-px;

				.img {
					background-color: transparent;
					font-size: $size-14-px;
					color: $primary !important;
					margin-right: $size-8-px;
				}
			}

			.blockyinfo {
				margin-top: $size-14-px;
				margin-bottom: $size-14-px;
				text-indent: 0px;
				padding-left: $size-14-px;
			}

			.blockyright {
				margin-right: 20px;
				margin-top: 10px;
				width: auto;
				height: auto;
			}

			.blockplaceholder,
			.blockplaceholder-cond {
				opacity: 0;
				display: none;
			}

			&.cond-block {
				width: 40px !important;
				height: 40px !important;
				min-height: 40px !important;
				max-height: 40px !important;
				border-radius: 100% !important;
				display: flex;
				justify-content: center;
				align-items: center;

				.blockyinfo {
					padding-left: 0px;
					margin-top: 0;
					margin-bottom: 0;
					color: $white !important;
				}
			}
		}

		.arrowblock {
			svg {
				path {
					stroke: $primary !important;
				}
			}
		}
	}
}

// End of Configure workflow //
// Stepping form //

.bs-stepper {
	&.sm-stepping {
		.bs-stepper-header {
			padding: $size-12-px $size-14-px !important;

			.line {
				border-top: 0px;
				bottom: auto;
			}

			.step {
				.step-trigger {
					align-items: center !important;

					.bs-stepper-box {
						width: 24px !important;
						height: 24px !important;
						font-weight: 600 !important;
						align-items: center !important;
					}

					.bs-stepper-label {
						margin-top: 0 !important;

						.bs-stepper-subtitle {
							display: none;
						}
					}
				}
			}
		}
	}
}

// End of Stepping form //
// Tag Select //
.tag-select {
	display: flex;
	border-radius: 6px;

	> span {
		font-size: $size-12-px;
		display: flex;
		align-items: center;
		padding: 0px 12px;
		height: inherit;
		border-radius: 6px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		border-right: none !important;
	}

	.ng-select {
		min-width: 200px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;

		.ng-select-container {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}
	}
}

// Add Field Constrain
.main-constraint-sec {
	.roll-sec {
		.constrain-list {
			.constrain-row {
				.single-constrain {
					.state-status {
						app-status-picker {
							.selct-feature {
								.select-sec {
									.form-group {
										margin-bottom: 0 !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// End of Add Field Constrain
// Service Edit //
.service-classification {
	app-tree-crud-view {
		app-tree-crud {
			.tree-view {
				.edit-input-sec {
					.form-group {
						@include max-mq(1500) {
							min-width: 220px;
						}
						@include max-mq(1300) {
							min-width: 200px;
						}
					}
				}
			}
		}
	}
}

//style2 scss
@import "./style2.scss";
.config-sidebar {
	.nav-tabs {
		flex: initial;
	}

	.tab-content {
		flex: 1%;

		.tab-pane {
			height: 100%;
		}
	}
}

// End of Service Edit //

// Custom Modal //

.tab-content {
	flex: 1%;

	.tab-pane {
		height: 100%;
		padding-bottom: 0;
		display: flex;
		flex-direction: column;

		.modal-header {
			background-color: $card-bg;
			padding: 10px 1rem;
			flex: initial;
			border-bottom: 1px solid $border-color;

			.btn-icon {
				font-size: $size-20-px;
				height: auto !important;
				width: auto !important;
				background-color: transparent;
				color: $body-color;

				&:hover {
					color: $title-color;
				}
			}
		}

		.modal-body {
			background-color: $body-bg;
		}
	}
}

.modal {
	&.custom-modal {
		.modal-content {
			height: 100%;
			padding-bottom: 0;
			display: flex;
			flex-direction: column;

			.modal-header {
				background-color: $card-bg;
				padding: 10px 1rem;
				flex: initial;
				border-bottom: 1px solid $border-color;

				.btn-icon {
					font-size: $size-20-px;
					height: auto !important;
					width: auto !important;
					background-color: transparent;
					color: $body-color;

					&:hover {
						color: $title-color;
					}
				}
			}

			.modal-body {
				background-color: $body-bg;

				&.card-bg {
					background-color: $card-bg;
				}
			}

			.modal-footer {
				padding: $size-14-px !important;
			}
		}
	}
}

// End of Custom Modal //
// Comman NG-select //
.ng-select {
	.ng-dropdown-panel {
		// background-color: transparent !important;
		> span:not(.ng-option-label) {
		}
		.ng-dropdown-panel-items {
			&.scroll-host {
				@extend .browser-scrollbar;
			}

			.ng-option {
				&.ng-option-marked {
					// background-color: $body-bg !important;

					&.ng-option-selected {
						// background-color: $primary !important;
					}
				}
			}
		}
	}
	&.ng-select-filtered {
		.ng-dropdown-panel {
			// background-color: transparent !important;
			.ng-dropdown-panel-items {
				.ng-option {
					> span:not(.ng-option-label) {
						display: flex !important;
						align-items: center;
						gap: 8px;
						span.ng-tag-label {
							padding-right: 0px !important;
						}
					}
				}
			}
		}
	}
}
ng-dropdown-panel {
	&.ng-dropdown-panel {
		&.form-control {
			height: auto !important;
			padding: 0px;
			&.sm-form-control {
				height: auto !important;
			}
			&.lg-form-control {
				height: auto !important;
			}
		}
		// background-color: transparent !important;
		// @extend .browser-scrollbar;
		// max-height: calc(36px*4) !important;
		height: auto !important;
		display: grid;
		z-index: 2003 !important;

		.ng-dropdown-panel-items {
			&.scroll-host {
				@extend .browser-scrollbar;
				max-height: calc(36px * 4) !important;
			}
			.ng-option {
				text-overflow: inherit;
				&.ng-option-selected {
					// background-color: $primary !important;
					// color: $white !important;
					background-color: rgba($color: $primary, $alpha: 0.2) !important;
					color: $primary !important;
					&.ng-option-marked {
						// background-color: $primary !important;
						// color: $white !important;
						background-color: rgba($color: $primary, $alpha: 0.2) !important;
						color: $primary !important;
					}

					.ng-option-label {
						font-weight: inherit !important;
					}
				}

				&.ng-option-marked {
					background-color: rgba($color: $primary, $alpha: 0.12) !important;
					color: $title-color !important;
					small {
						color: $title-color !important;
					}
				}

				&.ng-option-disabled {
					color: $text-muted !important;
				}
			}
		}
	}
}

// End of Ng-select //

// Input Tree Dropdown //
.input-tree-dropdown {
	.disable-treeview {
		.tree-dropdown {
			&.single-select {
				.dropdown {
					.dropdown-toggle {
						&:focus {
							box-shadow: none !important;
						}

						&:hover {
							background-color: $input-disabled-bg !important;
							cursor: not-allowed;

							&:focus {
								box-shadow: none !important;
							}
						}

						&.disabled {
							background-color: $input-disabled-bg !important;
						}
					}

					.dropdown-menu {
						display: none;
					}
				}
			}
		}
	}

	.ng-select {
		&.ng-select-single {
			.ng-select-container {
				.ng-value-container {
					> div,
					span {
						background-color: transparent;
						padding: 0;
						margin: 0 !important;
						font-size: $size-12-px !important;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						width: 250px;
					}
				}
			}
		}
	}
	.tree-dropdown {
		&.single-select {
			.dropdown {
				.dropdown-toggle {
					border-color: $input-border-color !important;
					background-color: transparent !important;
					word-break: break-word;
					white-space: break-spaces;
					font-weight: 400;
					line-height: $size-14-px;
					color: $title-color;
					padding-left: $size-10-px !important;
					max-height: initial;
					min-height: 36px;

					&::after {
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23313349' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
						right: $size-12-px;
					}

					span {
						// word-break: break-word;
						// white-space: break-spaces;
						// font-weight: 400;
						// line-height: $size-18-px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						// width: 95%;
						padding-bottom: 0px !important;
						width: 250px;
					}
				}
			}
		}
	}
}

// Input Tree Dropdown //

// Custom Quil Editer //
.custom-quil-editer {
	quill-editor {
		.ql-container {
			.ql-editor {
				max-height: 320px;
				@extend .browser-scrollbar;
			}
		}
	}
}
.email-quill-editor {
	quill-editor {
		.ql-toolbar {
			border-left: none;
			border-right: none;
			border-bottom: 1px solid $input-border-color !important;
			border-radius: 0;
			background: $body-bg;
		}
		.ql-container {
			border-left: none;
			border-right: none;
		}
	}
}

// End og Custom Quil Editer //
// Date Picker //
.modal-dialog {
	&.data-picker-modal {
		max-width: 786px !important;
		width: 786px !important;
	}
}

.date-range-inner {
	.date-range-container {
		display: grid;
		grid-template-columns: 160px auto;

		.list-group-section {
			.list-group {
				.list-group-item {
					border-radius: 0px !important;
					padding: $size-8-px $size-20-px !important;

					&.active {
						background-color: rgba($color: $primary, $alpha: 0.12);
						color: $primary;
					}
				}
			}
		}

		.date-range-section {
			display: flex;
			flex-direction: column;

			.q-datepicker {
				ngb-datepicker {
					.ngb-dp-header {
						width: 100%;
						padding-top: 0px !important;

						ngb-datepicker-navigation {
							.ngb-dp-arrow {
								.btn-link {
									span {
										width: $size-8-px;
										height: $size-8-px;
									}
								}
							}

							.ngb-dp-month-name {
								color: $title-color !important;
								font-size: $size-14-px !important;
								font-weight: 500;
							}
						}
					}

					.ngb-dp-months {
						.ngb-dp-month {
							ngb-datepicker-month {
								.ngb-dp-week {
									&.ngb-dp-weekdays {
										.ngb-dp-weekday {
											color: $title-color !important;
											width: 36px !important;
											height: 32px !important;
											margin-right: 0px !important;
										}
									}

									.ngb-dp-day {
										margin-right: 0px !important;
										width: 36px !important;
										height: 32px !important;

										&.hidden {
											display: block !important;
										}

										.custom-day {
											width: inherit !important;
											height: inherit !important;

											&.range:not(.faded) {
												// border-radius: 100%
												background-color: $primary !important;
											}

											&.range {
												&.faded {
													background-color: rgba($color: $primary, $alpha: 0.5) !important;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// End of Date Picker //

//matrix view tooltip design //
.matrix-info-tooltip-main {
	border-radius: 12px;
	min-width: 342px;
	max-width: 342px;

	.popover-body {
		padding: 14px;
		border: none;
		max-height: none;
	}
}

//end matrix view tooltip design

// Quill Editer Card //
.quill-card {
	box-shadow: none !important;
	background-color: $body-bg !important;

	quill-editor {
		background-color: $card-bg !important;

		.ql-container {
			&.ql-snow {
				border-radius: 0px !important;
				border: none !important;
			}
		}
	}
}

// Quill Editer Card //
.incident-title {
	.tooltip {
		left: 36px !important;
	}
}

.incident-title-truncate {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

//style2 scss
@import "./style2.scss";
//  Workflow Canvas //
// .workflow-canvas{
// 	.blockelem{
// 		border: 1px solid $border-color !important;
// 		border-radius: $size-8-px;
// 		.blockydiv{
// 			background-color: $border-color !important;
// 		}
// 		&.cond-block{
// 			width: 54px;
// 			height: 54px;
// 			.blockyinfo{
// 				color: $white;
// 			}
// 			&.bg-success{
// 				background-color: $success;
// 			}
// 			&.bg-danger{
// 				background-color: $danger;
// 			}
// 		}
// 		.blockyleft{
// 			padding: $size-12-px;
// 		}
// 	}
// 	.arrowblock{
// 		svg {
// 			stroke: $primary !important;
// 			// fill: $primary !important;
// 			path{
// 				stroke: $primary !important;
// 				&:last-of-type{
// 					fill: $primary !important;
// 				}
// 			}
// 		}
// 	}
// }
//  End of Workflow Canvas //

// Admin-Portal//
.main-menu {
	&.admin-menu {
		.admin-menu {
			.menus {
				height: 100%;
				display: flex;
				flex-direction: column;

				.navbar-header {
					&.admin-navbar-header {
						flex: initial;
						padding: 0.45rem 1rem 0.45rem 1.5rem !important;

						.navbar-nav {
							.nav-item {
								.navbar-brand {
									.brand-logo {
										img {
											max-width: 159px !important;
										}
									}
								}
							}
						}
					}
				}

				.main-menu-content {
					flex: 1%;

					.navigation {
						> li {
							&.active {
								a {
									background-color: rgba($color: $primary, $alpha: 0.08) !important;
									color: $primary !important;
									background: none;
									font-weight: 600;
								}
							}

							> a {
								margin: 4px $size-20-px !important;

								> div {
									> div {
										display: block;
									}
								}
							}
						}
					}
				}

				.footer-menu-content {
					flex: initial;
					margin: 2px $size-20-px;
				}
			}
		}
	}
}

// End of Admin-Portal//
// Popover //
.popover {
	.popover-body {
		@extend .browser-scrollbar;
	}
}

// End of Popover //

.email-quill-card {
	quill-editor {
		.ql-container {
			.ql-editor {
				max-height: 320px;
				@extend .browser-scrollbar;
			}
		}
	}

	app-macros-modal {
		top: 36px;
	}
}

// Vis network //
.vis-network-section {
	.custom-scrollbar {
		.ng-scroll-content {
			height: calc(100% - 6px);
		}
	}

	#visNetwork {
		&:focus-visible {
			outline: none !important;
		}
		&:focus {
			outline: none !important;
		}
		&:focus-within {
			outline: none !important;
		}
		.vis-network {
			&:focus-visible {
				outline: none !important;
			}
			&:focus {
				outline: none !important;
			}
			&:focus-within {
				outline: none !important;
			}
			canvas {
				&:focus-visible {
					outline: none !important;
				}
				&:focus {
					outline: none !important;
				}
				&:focus-within {
					outline: none !important;
				}
			}
			.vis-navigation {
				.vis-button {
					background-color: $card-bg;
					background-image: none !important;
					font-family: "Font Awesome 6 Pro" !important;

					&:hover {
						box-shadow: none !important;
					}

					&::after {
						font-size: 1em;
						color: $primary;
						width: 34px;
						height: 34px;
						border-radius: 100%;
						border: 1px solid $primary;
						display: flex;
						justify-content: center;
						align-items: center;
						position: absolute;
					}

					&.vis-up {
						bottom: 60px;
						left: 80px;
						right: auto;
						top: auto;

						&::after {
							content: "\f062";
							font-family: "Font Awesome 6 Pro";
						}
					}

					&.vis-down {
						left: 80px;
						bottom: 10px;
						right: auto;
						top: auto;

						&::after {
							content: "\f063";
							font-family: "Font Awesome 6 Pro";
						}
					}

					&.vis-left {
						left: 25px;
						bottom: 10px;
						right: auto;
						top: auto;

						&::after {
							content: "\f060";
							font-family: "Font Awesome 6 Pro";
						}
					}

					&.vis-right {
						left: 135px;
						bottom: 10px;
						right: auto;
						top: auto;

						&::after {
							content: "\f061";
							font-family: "Font Awesome 6 Pro";
						}
					}

					&.vis-zoomIn {
						right: 25px;
						bottom: 10px;
						left: auto;
						top: auto;

						&::after {
							content: "\f00e";
							font-family: "Font Awesome 6 Pro";
						}
					}

					&.vis-zoomOut {
						right: 80px;
						bottom: 10px;
						left: auto;
						top: auto;

						&::after {
							content: "\f010";
							font-family: "Font Awesome 6 Pro";
						}
					}

					&.vis-zoomExtends {
						right: 25px;
						bottom: 60px;
						left: auto;
						top: auto;

						&::after {
							content: "\e0a5";
							font-family: "Font Awesome 6 Pro";
						}
					}
				}
			}
		}

		.vis-tooltip {
			background-color: $card-bg;
			border-radius: $size-12-px;
			padding: $size-20-px $size-18-px;
			border: 1px solid $border-color;
			position: absolute;
			font-weight: 500;
			font-family: $font-family-sans-serif !important;
		}
	}
}

// Admin-Portal//
.main-menu {
	&.admin-menu {
		.admin-menu {
			app-admin-menu {
				height: 100%;
				display: flex;
				flex-direction: column;

				.navbar-header {
					&.admin-navbar-header {
						flex: initial;
						padding: 0.45rem 1rem 0.45rem 1.5rem !important;

						.navbar-nav {
							.nav-item {
								.navbar-brand {
									.brand-logo {
										img {
											max-width: 159px !important;
										}
									}
								}
							}
						}
					}
				}

				.main-menu-content {
					flex: 1%;

					.navigation {
						> li {
							&.active {
								a {
									background-color: rgba($color: $primary, $alpha: 0.08) !important;
									color: $primary !important;
									background: none;
									font-weight: 600;
								}
							}

							> a {
								margin: 2px $size-20-px !important;

								> div {
									> div {
										display: block;
									}
								}
							}
						}
					}
				}

				.footer-menu-content {
					flex: initial;
					margin: 2px $size-20-px;
				}
			}
		}
	}
}

// End of Admin-Portal//
.qrcode-modal {
	.modal-content {
		overflow: hidden;

		.modal-body {
			padding-top: $size-20-px;

			.close-modal {
				position: absolute;
				top: 4px;
				right: 16px;
				cursor: pointer;
				font-size: $size-18-px;
			}
		}
	}
}

/* The whole thing */
.comman-breadcrumb {
	margin-top: -1rem;
}

.comman-simple-table {
	min-height: 300px;
	display: block;
	position: relative;
}

.right-click-node {
	display: none;
	background-color: $white;
	border-radius: 0.857rem;
	padding: 1.428rem 1.285rem;
	border: 1px solid $border-color;
	position: absolute;
	font-weight: 500;

	&.show {
		display: block;
	}

	.context-menu {
		.context-menu-list {
			padding-left: 0 !important;
			margin-bottom: 0;
			list-style-type: none;

			.context-menu-item {
				margin-bottom: $size-8-px;

				&:last-of-type {
					margin-bottom: 0;
				}

				a.context-menu__link {
					display: grid;
					grid-template-columns: $size-14-px 1fr;
					align-items: center;
					gap: $size-14-px;
				}
			}
		}
	}

	.detail-info {
		ul {
			margin-bottom: 0;
			padding-left: 0;
			list-style: none;

			li {
				display: grid;
				grid-template-columns: 140px 1fr;
				margin-bottom: $size-8-px;
			}
		}

		&.show {
			display: block;
		}
	}
}

.cc-mail-list {
	&.popover {
		border: 1px solid $border-color;
		max-width: 400px;

		> .arrow {
			&::before {
				border-bottom-color: $border-color !important;
				border-width: 0 0.55rem 0.5rem;
			}

			&::after {
				border-width: 0 0.55rem 0.55rem !important;
				border-bottom-color: $card-bg !important;
			}
		}

		.popover-body {
			border: none !important;

			.mail-list {
				.email-list {
					.comman-list {
						display: grid;
						grid-template-columns: 72px 1fr;
						gap: $size-12-px;

						> span {
							display: flex;
							align-items: baseline;
							justify-content: space-between;
						}

						&:first-of-type {
							.mail-id {
								ul {
									padding-left: 0px;
								}
							}
						}

						&:last-of-type {
							.mail-id {
								ul {
									margin-bottom: 0px;
								}
							}
						}

						.mail-id {
							ul {
								padding-left: $size-14-px;
								margin-left: 0px;
							}
						}
					}
				}
			}
		}
	}
}

.summary-profile-popover {
	.popover {
		min-width: 200px;
	}
}

// .custom-color-list{
// 	.bg-white{
// 		background-color: $card-bg !important;
// 	}
// }
.inactive-sec {
	height: 100vh;
	width: 100%;

	&::before {
		position: absolute;
		content: "";
		background-image: url(../../assets/images/inactive/inactive-bg-img-1.svg);
		background-size: 100%;
		width: 100vw;
		height: 100vh;
	}

	.inactive {
		height: 100%;
		position: relative;

		.inactive-inner {
			height: 100%;
			display: flex;
			flex-direction: column;

			.inactive-info {
				flex: 1%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-top: 3rem;

				h2 {
					font-weight: 600 !important;
					font-size: 35px !important;
					line-height: 50px !important;
					color: $dark-black !important;
					text-align: center;
					@include max-height(900) {
						font-size: 28px !important;
						line-height: 42px !important;
					}
					@include max-height(800) {
						font-size: 24px !important;
						line-height: 28px !important;
					}
				}

				p {
					font-weight: 500 !important;
					font-size: 24px !important;
					line-height: 30px !important;
					color: $body-color !important;
					text-align: center;
					@include max-height(900) {
						font-size: 18px !important;
					}
					@include max-height(800) {
						font-size: 16px !important;
						line-height: 22px !important;
					}
					@include max-mq(767) {
						font-size: 14px !important;
						line-height: 20px !important;
					}
				}

				.administratorsList {
					ul {
						list-style: none;

						li {
							font-weight: 700;
							font-size: 24px !important;
							line-height: 30px !important;
							color: $body-color !important;
							text-align: center;
							@include max-height(900) {
								font-size: 18px !important;
							}
							@include max-height(800) {
								font-size: 16px !important;
								line-height: 22px !important;
							}
							@include max-mq(767) {
								font-size: 16px !important;
								line-height: 20px !important;
							}
						}
					}
				}

				.btn {
					font-weight: 500 !important;
					font-size: 26px !important;
					line-height: 44px !important;
					text-align: center !important;
					letter-spacing: 0.4px !important;
					height: 48px !important;
				}
			}

			.bottom-img-section {
				background-image: url(../../assets/images/inactive/inactive-bg-img-4.svg);
				width: 1140px;
				height: 464px;
				background-repeat: no-repeat;
				// background-size: 956px;
				background-position: top center;
				position: relative;
				overflow: hidden;
				flex: initial;
				background-size: 100%;
				margin: 0px auto;
				@include max-height(800) {
					width: 980px;
					height: 398px;
				}
				@include max-height(770) {
					width: 854px;
					height: 346px;
				}
				@include max-mq(1023) {
					width: 605px !important;
					height: 246px !important;
				}
				@include max-mq(767) {
					display: none !important;
				}

				img {
					position: absolute;
					top: 79px;
					left: 48.55%;
					transform: translate(-50%);
					width: 381px;
					@include max-height(800) {
						top: 68px;
						width: 326px;
					}
					@include max-height(770) {
						top: 61px;
						width: 283px;
					}
					@include max-mq(1023) {
						top: 40px !important;
						width: 205px !important;
					}
					@include max-mq(767) {
						display: none !important;
					}
				}
			}
		}
	}
}

.popover {
	&.selected-node-popover {
		border-radius: $size-12-px !important;

		.popover-body {
			border: 1px solid $border-color !important;
			border-radius: $size-12-px !important;
		}
	}
}

// New Registration page //
.new-auth-page {
	background-image: url("../../assets/images/auth-bottom-bg.svg");
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: bottom;
	display: grid;
	max-height: 100vh;
	overflow: auto !important;
	position: relative;
	min-height: 100vh;

	.ng-scroll-content {
		height: 100%;
		display: grid;
		@include max-mq(1023) {
			justify-content: center;
		}
		@include max-mq(767) {
			grid-template-columns: 1fr;
		}

		.auth-wrapper {
			// @extend .browser-scrollbar;
			display: flex;
			justify-content: flex-end;
			padding: 2rem 0px;
			height: fit-content !important;
			max-height: fit-content;
			min-height: fit-content;
			margin: auto 0px;
			// align-items: center !important;
			@include max-mq(1023) {
				display: grid;
				flex-direction: column;
				padding-bottom: 2rem;
				grid-template-rows: auto 1fr;
			}
			@include max-mq(767) {
				grid-template-columns: 1fr;
			}

			.auth-content-section {
				position: absolute;
				top: 22%;
				left: 140px;
				// margin-top: 8%;
				// margin-left: 140px;
				.logo-sec {
					@include max-mq(1023) {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}

				@include max-mq(1260) {
					left: 2rem;
					// margin-left: 2rem;
				}
				@include max-mq(1023) {
					position: unset;
					top: auto;
					left: auto;
					right: auto;
					width: calc(100% - 4rem);
					margin: 0px auto;
					margin-top: 4rem;
					text-align: center;
					margin-bottom: 2rem;
					flex: initial;
				}
				@include max-mq(767) {
					width: calc(100% - 2rem);
					margin-top: 2rem;
					margin-bottom: 1rem;
				}

				.infraon-logo {
					margin-bottom: $size-14-px;
					display: flex;
				}

				h1 {
					font-size: 40px !important;
					line-height: 56px !important;
					font-weight: 600 !important;
					margin-bottom: $size-16-px !important;
					@include max-mq(767) {
						font-size: 22px !important;
						line-height: 28px !important;
					}
				}

				h5 {
					font-weight: 600 !important;
				}
			}

			.registration-form-sec {
				width: 461px;
				// position: absolute;
				// top: 50%;
				// transform: translate(0%, -50%);
				// right: 160px;
				margin: auto 160px auto 0px;
				border-radius: 20px;
				overflow: hidden;
				// max-height: calc(100vh - 4rem);
				// overflow: auto !important;
				// @extend .browser-scrollbar;
				@include max-mq(1260) {
					// right: 2rem;
					margin: auto 2rem auto 0px;
				}
				@include max-mq(1023) {
					position: unset;
					top: auto;
					left: auto;
					right: auto;
					width: calc(100% - 4rem);
					margin: 0px auto;
					transform: translate(0%, 0%);
					flex: 1%;
					max-height: 100%;
					margin-bottom: 2rem;
					box-shadow: $box-shadow;
				}
				@include max-mq(767) {
					width: calc(100% - 2rem);
					margin-bottom: 0rem;
				}

				.auth-form {
					padding: 40px;
					@include max-mq(767) {
						padding: $size-16-px;
					}
				}
			}
		}
	}
}

.incident-grid-data {
	.main-content {
		.main-sec {
			.action-bar {
				.inner-section {
					.left-section {
						.action-search {
							app-search {
								.main-search {
									.inner-search {
										.search-sec {
											@include max-mq(1279) {
												min-width: 240px !important;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// End of New Registration page //
// Open Layers map //
.olMap {
	width: 100%;
	height: 100%;

	.ol-viewport {
		display: none;

		&:last-of-type {
			display: block !important;
		}

		.ol-layers {
			.ol-layer {
				canvas {
					width: 100% !important;
					height: 100% !important;
					transform: none !important;
					transform-origin: center !important;
				}
			}
		}

		.ol-attribution {
			display: none;
		}
	}
}

.map-tooltip {
	position: relative;
	padding: $size-8-px;
	background: rgba($color: $dark-black, $alpha: 0.8);
	color: $white;
	opacity: 1;
	white-space: nowrap;
	border-radius: $size-4-px;

	.tooltipData {
		.name-info {
			display: table-cell;
			padding: 4px 0;
			width: 100px;
		}

		.value-info {
			display: table-cell;
			font-weight: bold;
			padding: 4px 0;
		}

		display: table-row;
		font-size: 10px;
	}
}

// End of Open Layers map //
.ball {
	width: 25px;
	height: 25px;
	border-radius: 100%;
	margin-right: 0.75rem;
}

.congratulationModal {
	&.modal {
		.modal-dialog {
			max-width: 706px;

			.modal-content {
				overflow: hidden;

				.modal-body {
					.congratulation-modal {
						height: 312px;
						display: grid;

						.main-congratulation {
							height: 100% !important;
							position: unset !important;
							left: auto;
							top: auto;
							width: 100% !important;

							&::before {
								width: 190px !important;
								height: 98px !important;
							}

							&::after {
								width: 190px !important;
								height: 98px !important;
							}

							.inner-congratulation {
								.congratulation-containt {
									p {
										margin-bottom: 0px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.manage-subscription {
	.manage-input-row {
		.ng-select {
			background-color: $card-bg !important;

			.ng-select-container {
				background-color: $card-bg !important;
			}
		}
	}
}

ngb-rating {
	.visually-hidden {
		display: none;
	}
}
.sla-text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@import "./style2.scss";
.sla-section {
	.sla-left-sec {
		.sla-left-inner {
			padding: 0 !important;

			.left-inner {
				.menu-link {
					padding: 10px 10px;

					&.active {
						background-color: rgba($color: $primary, $alpha: 0.12);
						position: relative;
						color: $primary !important;

						&::before {
							height: 100% !important;
							top: 0 !important;
							left: 0 !important;
						}

						.icon-sec {
							color: $primary !important;
						}

						.info-sec {
							div {
								color: $primary !important;
							}
						}
					}
				}
			}
		}
	}
}

.add-incident {
	.left-sec {
		flex: initial;
		width: 365px;
		box-shadow: $box-shadow;
		position: relative;

		.comment {
			&.overlay {
				.media {
					.media-body {
						.req-info {
							display: flex;
							flex-direction: column;

							span {
								width: 200px;
							}
						}
					}
				}
			}
		}
	}

	.middle-sec {
		flex: 1%;
		box-shadow: $box-shadow;
		position: relative;

		.input-group {
			.form-control {
				&::placeholder {
					text-align: left;
					white-space: inherit;
				}
			}
		}
	}

	.right-sec {
		flex: initial;
		width: 332px;
		box-shadow: $box-shadow;
		display: flex;
		position: relative;

		.inner-sec {
			flex: 1%;
			// padding: $size-14-px;
			.flex-1 {
				position: relative;
				// padding: $size-14-px;
			}
		}

		.side-sec {
			padding: $size-4-px;
			flex: initial;
			border-left: 1px solid $border-color;
		}
	}
}

ngb-datepicker-navigation {
	.form-select {
		flex: 1 1 auto;
		-webkit-appearance: none;
		-moz-appearance: none;
		display: inline-block;
		width: 100%;
		height: auto;
		padding: 8px;
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
		color: $title-color;
		vertical-align: middle;
		background: $card-bg
			url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23d8d6de' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
			right 1rem center/10px 10px no-repeat;
		border: 1px solid #d8d6de;
		border-radius: 0.357rem;
		appearance: none;
		transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
			background 0s, border-color 0s;
	}
}

td {
	span.ql-editor {
		p {
			margin: 0px 1rem !important;
		}
	}
}

@import "./style2.scss";

// pane-view css start, white panel block
.dns-panel-main-body-sec {
	// display: flex;
	// flex-direction: column;
	// align-items: flex-start;
	// padding: 0px;
	// gap: 8px;
	// width: 99%;
	// margin: 7px auto;
	// height: 176px;
	// background: $white;
	// border-radius: 7px;
	// border-left: 6px solid #28c76f;
	.dns-panel-header-sec {
		// display: flex;
		// height: auto;
		// border: 1px solid $border-color;
		.dns-panel-contact-sec {
			// width: 168px;
			// border-right: 1px solid $border-color;
			.dns-panel-contact-name {
				// display: flex;
				// align-items: center;
				// font-weight: 600;
				// padding: 14px 7px 7px 7px;
				// .f-color {
				// 	color: $primary;
				// }
				.panel-global-icon {
					// display: flex;
					// padding: 3px;
					// gap: 10px;
					// width: 20px;
					// height: 20px;
					// background: rgba(26, 90, 217, 0.08);
					// border-radius: 4px;
				}
			}
			.dns-panel-icon-list {
				// display: flex;
				// align-items: center;
				// padding: 6px 7px;
				// span {
				// 	display: flex;
				// 	flex-direction: row;
				// 	justify-content: center;
				// 	align-items: center;
				// 	width: 20px;
				// 	height: 20px;
				// 	border: 1px solid $border-color;
				// 	border-radius: 4px;
				// 	margin-right: 0.5rem;
				// 	cursor: pointer;
				// 	i {
				// 		font-size: 10px;
				// 	}
				// }
			}
		}
		.panel-desc-main-block {
			// padding: 1rem;
			// width: calc(100% - 168px);
			.panel-title {
				display: flex;
				justify-content: space-between;
				padding: 12px 8px 8px 14px;
				// margin-bottom: 0.5rem;
				// font-family: 'Montserrat';
				// font-style: normal;
				// font-size: 1em;
				// line-height: 21px;
				// color: #23232F;
				span {
					p {
						margin: 0;
						// padding: 12px 0px 8px 14px;
						font-family: $font-family-sans-serif;
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
					}
				}
			}
			.panel-title-desc {
				font-weight: 600;
				width: 86%;
				word-wrap: break-word;
			}
			.panel-title-date {
				font-size: 12px;
			}
			.panel-desc {
				font-family: $font-family-sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 21px;
				color: #4b4b4b;
				padding-left: 1rem;
				word-wrap: break-word;
				p {
					margin: 0;
					padding: 0px 8px 12px 14px;
				}
				padding-left: 1rem;
			}
		}
	}
	.dns-panel-main-body-block {
		// display: flex;
		// justify-content: space-between;
		// border-bottom: 1px solid $border-color;
		// display: grid;
		// // grid-template-columns: repeat(9, 1fr);
		// grid-template-columns: 240px 240px 18% 10% 10% 10% 10% auto auto;
		// border-bottom: 1px solid $border-color;
		// border: 1px solid $border-color;
		// border-top: none;
		// height: 65px;
		.dns-panel-body-sec {
			// width: 20%;
			// border-right: 1px solid $border-color;
			// position: relative;
			// height: 67px;
			// &.m-left span:nth-child(1) {
			// 	margin-left: 3px;
			// }
			// .panel-data-block {
			// 	padding: 0.5rem;
			// 	.panel-data-title {
			// 		font-family: $font-family-sans-serif;
			// 		font-style: normal;
			// 		font-weight: 400;
			// 		font-size: 12px;
			// 		display: flex;
			// 		align-items: center;
			// 		color: #4b4b4b;
			// 		margin-bottom: 4px;
			// 		// cursor: pointer;
			// 		&.r-span span:nth-child(1) {
			// 			background: #a72e94;
			// 			border-radius: 40px;
			// 			color: $white;
			// 			width: 20px;
			// 			height: 20px;
			// 			font-family: $font-family-sans-serif;
			// 			font-style: normal;
			// 			font-weight: 600;
			// 			font-size: 9px;
			// 			/* line-height: 8px; */
			// 			display: flex;
			// 			padding: 0.5rem;
			// 			align-items: center;
			// 			justify-content: center;
			// 			margin-right: 0.5rem;
			// 		}
			// 		.panel-status {
			// 			display: flex;
			// 			align-items: center;
			// 			& span:nth-child(1) {
			// 				display: flex;
			// 				border: 34px;
			// 				width: 10px;
			// 				height: 10px;
			// 				// background: #EA5455;
			// 				border-radius: 3px;
			// 				margin-right: 0.5rem;
			// 			}
			// 			& span:nth-child(3) {
			// 				margin-left: 0.5rem;
			// 				font-weight: 500;
			// 				font-size: 11px;
			// 				align-items: center;
			// 				// color: #00CFE8;
			// 				padding: 1px 4px;
			// 				width: 59px;
			// 				height: 20px;
			// 				background: rgba(0, 207, 232, 0.12);
			// 				border-radius: 4px;
			// 			}
			// 			.p-status {
			// 				display: flex;
			// 				align-items: center;
			// 				padding: 1px 4px;
			// 				gap: 4px;
			// 				// width: 66px;
			// 				// height: 19px;
			// 				// background: rgba(234, 84, 85, 0.12);
			// 				border-radius: 4px;
			// 				// color: #EA5455;
			// 				font-size: 11px;
			// 			}
			// 		}
			// 	}
			// }
			// .p-icon {
			// 	display: flex;
			// 	flex-direction: row;
			// 	justify-content: center;
			// 	align-items: center;
			// 	width: 24px;
			// 	height: 24px;
			// 	border: 1px solid $border-color;
			// 	border-radius: 4px;
			// 	margin-right: 3px;
			// 	cursor: pointer;
			// 	span {
			// 		i {
			// 			font-size: 12px;
			// 		}
			// 	}
			// }
			// .dns-panel-drop-down-list {
			// 	background: $white;
			// 	position: absolute;
			// 	width: 100%;
			// 	border: 1px solid $border-color;
			// 	z-index: 100;
			// 	height: 136px;
			// 	overflow-y: scroll;
			// 	.list-item-block {
			// 		display: flex;
			// 		align-items: center;
			// 		justify-content: left;
			// 		padding: 12px 7px;
			// 		border-bottom: 1px solid $border-color;
			// 		cursor: pointer;
			// 		& span:nth-child(1) {
			// 			background: #a72e94;
			// 			border-radius: 40px;
			// 			color: $white;
			// 			width: 20px;
			// 			height: 20px;
			// 			font-family: $font-family-sans-serif;
			// 			font-style: normal;
			// 			font-weight: 600;
			// 			font-size: 9px;
			// 			/* line-height: 8px; */
			// 			display: flex;
			// 			padding: 0.5rem;
			// 			align-items: center;
			// 			justify-content: center;
			// 			margin-right: 0.5rem;
			// 			margin-bottom: 0;
			// 		}
			// 		span {
			// 			font-family: $font-family-sans-serif;
			// 			font-style: normal;
			// 			font-weight: 400;
			// 			font-size: 12px;
			// 			/* line-height: 18px; */
			// 			display: flex;
			// 			align-items: center;
			// 			color: #4b4b4b;
			// 			/* margin-bottom: 0.5rem; */
			// 			cursor: pointer;
			// 		}
			// 	}
			// }
			// .custom-drop-down-block {
			// 	.list-item-block {
			// 		display: flex;
			// 		align-items: center;
			// 		justify-content: left;
			// 		// padding: 12px 7px;
			// 		// border-bottom: 1px solid $border-color;
			// 		cursor: pointer;
			// 		& span:nth-child(1) {
			// 			background: #a72e94;
			// 			border-radius: 40px;
			// 			color: $white;
			// 			width: 20px;
			// 			height: 20px;
			// 			font-family: $font-family-sans-serif;
			// 			font-style: normal;
			// 			font-weight: 600;
			// 			font-size: 9px;
			// 			/* line-height: 8px; */
			// 			display: flex;
			// 			padding: 0.5rem;
			// 			align-items: center;
			// 			justify-content: center;
			// 			margin-right: 0.5rem;
			// 			margin-bottom: 0;
			// 		}
			// 		span {
			// 			font-family: $font-family-sans-serif;
			// 			font-style: normal;
			// 			font-weight: 400;
			// 			font-size: 12px;
			// 			/* line-height: 18px; */
			// 			display: flex;
			// 			align-items: center;
			// 			color: #4b4b4b;
			// 			/* margin-bottom: 0.5rem; */
			// 			cursor: pointer;
			// 		}
			// 	}
			// 	.custom-option-dropdown {
			// 		display: flex;
			// 		align-items: center;
			// 		justify-content: left;
			// 		// padding: 12px 7px;
			// 		// border-bottom: 1px solid $border-color;
			// 		cursor: pointer;
			// 		& span:nth-child(1) {
			// 			background: #a72e94;
			// 			border-radius: 40px;
			// 			color: $white;
			// 			width: 20px;
			// 			height: 20px;
			// 			font-family: $font-family-sans-serif;
			// 			font-style: normal;
			// 			font-weight: 600;
			// 			font-size: 9px;
			// 			/* line-height: 8px; */
			// 			display: flex;
			// 			padding: 0.5rem;
			// 			align-items: center;
			// 			justify-content: center;
			// 			margin-right: 0.5rem;
			// 			margin-bottom: 0;
			// 		}
			// 		span {
			// 			font-family: $font-family-sans-serif;
			// 			font-style: normal;
			// 			font-weight: 400;
			// 			font-size: 12px;
			// 			/* line-height: 18px; */
			// 			display: flex;
			// 			align-items: center;
			// 			color: #4b4b4b;
			// 			/* margin-bottom: 0.5rem; */
			// 			cursor: pointer;
			// 		}
			// 	}
			// }
		}
	}
}
// .panel-edit-select-box{
// 	.form-control{
// 		border: none !important;
// 		padding: 0;
// 	}
// }

.active-hover {
	border-radius: 4px;
	padding: 0px;
}

.active-hover:hover {
	outline: #b3b3b3;
	outline-style: solid;
	outline-width: thin;
}

// .dns-panel-view-scroll {
// 	height: 100%;
// 	// overflow-y: scroll;
// }

// .dns-panel-view-scroll::-webkit-scrollbar {
// 	width: 0px;
// }

// /* Track */
// .dns-panel-view-scroll::-webkit-scrollbar-track {
// 	background: $white;
// }

// /* Handle */
// .dns-panel-view-scroll::-webkit-scrollbar-thumb {
// 	background: $white;
// }

// /* Handle on hover */
// .dns-panel-view-scroll::-webkit-scrollbar-thumb:hover {
// 	background: $white;
// }

.dns-panel-drop-down-list {
	height: 100%;
	overflow-y: scroll;
}

.dns-panel-drop-down-list::-webkit-scrollbar {
	width: 0px;
}

/* Track */
.dns-panel-drop-down-list::-webkit-scrollbar-track {
	background: $white;
}

/* Handle */
.dns-panel-drop-down-list::-webkit-scrollbar-thumb {
	background: $white;
}

/* Handle on hover */
.dns-panel-drop-down-list::-webkit-scrollbar-thumb:hover {
	background: $white;
}

.w-auto {
	width: auto !important;
	padding: 0.5rem !important;
	color: #23232f !important;
	i {
		color: #0d51d9 !important;
	}
	span {
		font-size: 0.9rem;
		// margin-left: 0.5rem;
		color: #22292f;
	}
}

.expand-filter-block {
	width: 36px;
	height: 36px;
	border: 1px solid $border-color;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	cursor: pointer;
}

.a-item {
	padding: 3px;
	border-radius: 4px;
}

.w-complete,
.w-action {
	align-items: center;
	display: flex;
	justify-content: center;
}

// pane-view css end, white panel block

.edit-comment {
	p {
		color: #4b4b4b;
		font-family: $font-family-sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 12px !important;
	}
}

.panel-edit-form-sec {
	.panel-quick-edit-icon {
		width: 20px;
		height: 20px;
		margin-left: 6px;
		text-align: center;
		background: rgba(115, 103, 240, 0.12);
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		i {
			font-size: 10px;
			color: $primary;
		}
	}
	.panel-edit-form-block {
		margin-bottom: 14px;
		h6 {
			font-family: $font-family-sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 14px !important;
			margin-bottom: 0px;
		}
	}
	.panel-edit-select-box {
		margin-bottom: 14px;
		h6 {
			font-size: 12px !important;
		}
		.input-group {
			input {
				border-radius: 4px;
			}
			.custom-calender {
				position: absolute;
				right: 3%;
				top: 32%;
				z-index: 10000;
				font-size: 14px;
			}
		}
	}
	.d-flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.panel-edit-form-sec-main-block {
	.sticky-footer {
		position: sticky;
		bottom: 0;
		z-index: 1000000;
		width: 100%;
		background: $white;
	}
}

.activeWidth {
	width: calc(100% - 240px) !important;
}

.maxContentActive {
	width: max-content !important;
}

.p-14 {
	padding: 0.875rem;
}

.m-btm-14 {
	margin-bottom: 0.875rem;
}

.m-top-14 {
	margin-top: 0.875rem;
}

.add-new-desc-block {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.width-50 {
		width: 49% !important;
		h6 {
			font-weight: 400 !important;
		}
	}
}

.add-submit-sec {
	position: sticky;
	bottom: 0px;
	width: 100%;
	z-index: 100000;
	margin-bottom: 0 !important;
}

.editor-item-flex {
	display: flex;
	align-items: center;
	padding: 0.275rem !important;
}

.suggeested-main-block {
	margin: 14px;
	border: 1px solid $border-color;
	border-radius: 6px;
	padding: 14px;
	.suggested-header-block {
		div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 12px;
			span:nth-child(1) {
				color: $primary;
				font-family: $font-family-sans-serif;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				text-transform: uppercase;
			}
			span {
				i {
					margin-left: 10px;
				}
			}
		}
	}
	.suggested-desc {
		font-family: $font-family-sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		margin-bottom: 7px;
		color: #6e6b7b;
		&.c-code {
			color: #4b4b4b;
		}
	}
	.suggested-name {
		display: flex;
		align-items: center;
		div:nth-child(1) {
			margin-right: 10px;
			border-right: 1px solid $border-color;
			padding-right: 10px;
		}
		.suggested-f-size {
			font-family: $font-family-sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
		}
	}
	.suggested-created {
		font-family: $font-family-sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		margin-top: 12px;
	}
}

.view-all-base-knowlegde {
	padding: 8px 20px;
	border: 1px solid $border-color;
	border-radius: 4px;
	color: $primary;
	width: 76%;
	margin: 0 auto;
	text-align: center;
	font-family: $font-family-sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	cursor: pointer;
}

.bt-flex {
	align-items: center;
	display: flex !important;
	justify-content: center;
}

// view edit feature

.p-all {
	padding: 10px !important;
}

.view-edit-id-block {
	.avatar-content {
		width: 22px;
		height: 22px;
	}
}

.custom-text {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1px 4px;
	border-radius: 4px;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 8px;
}

.custom-squre {
	width: 10px;
	height: 10px;
	border-radius: 3px;
	margin-right: 8px;
	padding: 5px;
}

.category-attachment {
	padding: $size-12-px;
	background-color: $body-bg;
	border: 1px solid $border-color;
	border-radius: 6px;
	margin-bottom: $size-8-px;
	.category-attachment-list {
		.left-info-sec {
			.file_position {
				width: 220px !important;
				display: flex;
				line-height: normal !important;
				> span {
					line-height: normal !important;
				}
				.file-name {
					max-width: 178px !important;
					white-space: nowrap;
					overflow: hidden;
					display: inline-block;
					text-overflow: ellipsis;
				}
				.file-version {
					position: relative;
					padding-left: $size-14-px;
					font-weight: 500;
					&::before {
						content: "";
						position: absolute;
						width: 4px;
						height: 4px;
						left: 2px;
						top: 4.5px;
						border-radius: 100%;
						background-color: $body-color;
					}
				}
			}
		}
		&:last-of-type {
			margin-bottom: 0 !important;
			padding-bottom: 0 !important;
			border-bottom: 0px !important;
		}
		.avatar {
			border-radius: 4px;
		}
	}
}

.pm-0 {
	padding: 0px !important;
	margin: 0 !important;
	margin-bottom: 10px !important;
}

.custom-calender {
	position: absolute;
	right: 3%;
	top: 32%;
	z-index: 10000;
	font-size: 14px;
}

.f-12 {
	font-family: $font-family-sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
}

.f-14 {
	font-family: $font-family-sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
}

.wbp {
	width: 100%;
	border-bottom: 1px solid $border-color;
	padding: 10px;
}

.edit-release-deploy-btn {
	display: flow-root;
	padding: 10px;
	div {
		float: right;
	}
}

.bmw {
	border: 1px solid $border-color !important;
	margin: 10px;
	width: auto;
	border-radius: 4px;
}

.tab-main-block {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	li {
		padding: 10px;
		cursor: pointer;
		font-weight: 600;
		span {
			padding: 10px;
			color: #a0a3bd;
			cursor: pointer;
		}
	}
	.active {
		color: $primary !important;
		border-bottom: 2px solid $primary !important;
	}
}

.task-main-block {
	.task-filter {
		border-bottom: 1px solid $border-color;
		.inner-section {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;
			background-color: $white;
		}
	}
	.task-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		border-bottom: 1px solid $border-color;
		&:hover {
			background: #e9e9e954;
		}
		cursor: pointer;
		.task-left-side {
			display: flex;
			align-items: center;
			.f-color {
				color: #084cd4;
				margin-right: 10px;
			}
		}
		.task-right-side {
			display: flex;
			align-items: center;
			div {
				margin-right: 10px;
			}
			.task-assignee {
				width: 30px;
				height: 30px;
				background: #ccc;
				border-radius: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
			}
		}
	}
}

.pbr {
	padding: 4px;
	border-radius: 4px;
	font-weight: 500;
	font-size: 12px;
	padding: 7px !important;
}

.p-all-edit {
	padding: 9px !important;
}

.pos {
	position: relative;
}

.filter-pos {
	display: flex;
	width: 76%;
	align-items: center;
	justify-content: end;
}

.search-item {
	position: absolute;
	top: 10px;
	right: 190px;
}

.filter-po {
	.search-item {
		.bordered-layout {
			.add-incident {
				.left-sec {
					border-right: none !important;
				}
			}
		}
	}
}

.feature-view-title {
	font-weight: 500;
	font-size: 14px;
	//color: #313349;
	background: rgba(108, 111, 143, 0.14);
	padding: 1.17647px 4.70588px;
	border-radius: 4.70588px;
}

.view-task-title-section {
	span {
		background: #f7f8fb;
		border: 1px solid #d8d8e3;
		font-weight: 400;
		font-size: 12px;
	}
}

.feature-cancel {
	button {
		color: $primary;
	}
}

// Dark panel-view start

.dark-layout {
	.dns-panel-main-body-sec {
		background: #20293b !important;
		.dns-panel-header-sec {
			border: 1px solid #323a4d !important;
			.dns-panel-contact-sec {
				border-right: 1px solid #323a4d !important;
				.dns-panel-icon-list {
					span {
						border-color: #404656 !important;
					}
				}
			}
		}
	}
	.dns-panel-main-body-sec {
		.dns-panel-main-body-block {
			border-bottom: 1px solid #323a4d !important;
			.dns-panel-body-sec {
				// border-right: 1px solid #323a4d !important;
				.panel-data-block {
					.panel-data-title {
						color: #eef0f5 !important;
					}
				}
				.p-icon {
					border-color: #404656 !important;
				}
				.dns-panel-drop-down-list {
					background: #20293b !important;
					border: 1px solid #323a4d !important;
					.list-item-block {
						border-bottom: 1px solid #323a4d !important;
						cursor: pointer;
						& span:nth-child(1) {
						}
						span {
							color: $white !important;
						}
					}
				}
			}
		}
	}
	.expand-filter-block {
		border: 1px solid $border-color !important;
	}
	.suggeested-main-block {
		.suggested-desc {
			color: $white !important;
			&.c-code {
				color: $white !important;
			}
		}
	}
	.task-main-block {
		.task-filter {
			.inner-section {
				background-color: transparent !important;
			}
		}
	}
	.feature-cancel {
		button {
			color: $white !important;
		}
	}

	.dns-panel-main-body-sec {
		.dns-panel-header-sec {
			.panel-desc-main-block {
				.panel-desc {
					color: $white !important;
				}
			}
		}
	}
}

// Dark panel-view end

.feature-cancel {
	button {
		color: $primary;
	}
}

.setting-wraper {
	display: grid;
	grid-template-columns: 49% 50%;
	grid-column-gap: 10px;
	align-items: baseline;
	margin-top: 4rem;
	.setting-block {
		background: $white;
		// padding: 0.5rem;
		border: 1px solid $border-color;
		border-radius: 4px;
		margin-bottom: 10px;
		.setting-label {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.5rem;
			font-weight: 500;
			label {
				font-size: 14px;
				margin-bottom: 0px;
			}
		}
		.setting-body-block {
			padding: 0.5rem;
			// margin: 0.5rem 0px 0px;
			border-top: 1px solid $border-color;
			&.auto-flex {
				display: flex;
				align-items: center;
				ng-select {
					width: 100px;
				}
			}
			label {
				font-size: 13px;
			}
		}
	}
}
.setting-save-btn {
	position: absolute;
	width: 100%;
	background: $white;
	left: 0;
	padding: 12px 13px;
	top: 0;
	z-index: 1000;
}
.title-custom-style {
	// margin: 0 1rem;
	// font-size: 14px;
}

.w-action-border {
	justify-content: space-evenly;
	border-right: none;
}

.br-left {
	border-left: 1px solid $border-color;
}
// Dark panel-view start

.dark-layout {
	.dns-panel-main-body-sec {
		background: #20293b !important;
		.dns-panel-header-sec {
			border: 1px solid #323a4d !important;
			.dns-panel-contact-sec {
				border-right: 1px solid #323a4d !important;
				.dns-panel-icon-list {
					span {
						border-color: #404656 !important;
					}
				}
			}
		}
	}
	.dns-panel-main-body-sec {
		.dns-panel-main-body-block {
			border: 1px solid #323a4d;
			.dns-panel-body-sec {
				// border-right: 1px solid #323a4d !important;
				.panel-data-block {
					.panel-data-title {
						color: #eef0f5 !important;
					}
				}
				.p-icon {
					border-color: #404656 !important;
				}
				.dns-panel-drop-down-list {
					background: #20293b !important;
					border: 1px solid #323a4d !important;
					.list-item-block {
						border-bottom: 1px solid #323a4d !important;
						cursor: pointer;
						& span:nth-child(1) {
						}
						span {
							color: $white !important;
						}
					}
				}
			}
		}
	}
	.expand-filter-block {
		border: 1px solid $border-color !important;
	}
	.suggeested-main-block {
		.suggested-desc {
			color: $white !important;
			&.c-code {
				color: $white !important;
			}
		}
	}
	.task-main-block {
		.task-filter {
			.inner-section {
				background-color: transparent !important;
			}
		}
	}
	.feature-cancel {
		button {
			color: $white !important;
		}
	}
	.setting-wraper {
		.setting-block {
			background: transparent !important;
			border: 1px solid #323a4d !important;
			.setting-body-block {
				border-top: 1px solid #323a4d !important;
			}
		}
	}
	.setting-save-btn {
		background: #323a4d !important;
		// border-bottom: 1px solid #323a4d !important;
	}
	.w-action-border {
		border-right: none !important;
	}
	.br-left {
		border-left: 1px solid #323a4d !important;
	}
}

// Dark panel-view end
.details-list-box {
	padding: 0;
	margin-bottom: 0.5rem;
	list-style-type: none;
	li {
		margin-bottom: 0.5rem;
		display: flex;
		align-items: center;
		font-size: $size-12-px;
		line-height: 1.5rem;
		&:nth-last-child(1) {
			margin-bottom: 0;
		}
		span {
			&:nth-child(1) {
				min-width: 125px;
			}
			&:nth-child(2) {
				margin: 0 1.5rem;
			}
			&:nth-child(3) {
				width: 65%;
				word-wrap: break-word;
			}
		}
	}
}

// New General Settings Start

.general-seting-main-parent-block {
	padding: 1rem;
	background: $white;
	border-radius: 6px;
	margin-bottom: 1rem;
	margin-top: 5rem;
}
.general-seting-main-block {
	label {
		font-size: 14px;
		margin-bottom: 0;
		font-weight: 500;
		margin-bottom: 1rem;
	}
	.general-seting-sub-block {
		display: grid;
		grid-template-columns: 33% 34% 33%;
		align-items: center;
		label {
			font-size: 13px;
			margin-bottom: 0.5rem;
		}
		.m-right {
			margin-right: 3rem;
		}
		.new-account-disable-block {
			display: flex;
			align-items: center;
			label {
				margin: 0 5px;
			}
			.new-auto-flex {
				width: 100px;
			}
		}
	}
}

.prefix-module-sections-main-wrapper-block {
	.table-responsive {
		.table {
			thead {
				.w-module {
					width: 476px;
				}
				.w-activation {
					width: 232px;
				}
				.w-prefix {
					width: 265px;
				}
				.w-preview {
					width: 346px;
				}
			}
			tbody {
				border: 1px solid $border-color;
				td {
					padding: 24px 24px;
				}
			}
		}
	}
	.prefix-module-sections-main-block {
		font-size: 14px;
		font-weight: 400;
		.prefix-module-sections-sub-block {
			&.auto-disable-block {
				display: flex;
				align-items: center;
				font-size: 12px;
				margin: 2rem 0px 0rem 1.5rem;
			}
		}
	}

	.activation-status-block {
		font-family: $font-family-sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		color: #28c76f;
		background: rgba(40, 199, 111, 0.12);
		padding: 0.25rem 1rem;
		border-radius: 4px;
		&.font-cr {
			color: #000000;
		}
	}

	.upgrade-status-block {
		background: rgba(26, 90, 217, 0.08);
		color: $primary;
		font-size: 12px;
		font-family: $font-family-sans-serif;
		font-style: normal;
		padding: 0.25rem 1rem;
		border-radius: 4px;
	}

	.ugrade-btn {
		padding: 4px;
		padding: 6px 12px;
		font-size: 12px;
		font-weight: 400;
		.fa-square-up {
			margin: 0;
		}
	}

	.validation-msg {
		margin-bottom: 0;
		background: transparent !important;
		font-weight: 300;
		position: absolute;
		margin-top: 5px;
	}
}

// preview section start

.preview-main-block-section {
	// width: 248px;
	height: auto;
	border: 1px solid $border-color;
	border-radius: 4px;
	border-left: 5px solid #b5b5b7;
	.preview-header-block-section {
		display: flex;
		align-items: center;
		padding: 7px;
		input {
			margin-right: 6px;
		}
		.preview-header-name {
			color: $primary;
			font-weight: 600;
			margin-right: 0.5rem;
		}
		.preview-icon-block {
			width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: rgba(115, 103, 240, 0.12);
			border-radius: 4px;
			margin-right: 10px;
			font-size: 10px;
			&.br-c-bg {
				border: 1px solid $border-color;
				background: none;
			}
		}
	}
	.preview-body-block {
		width: 91%;
		height: 16px;
		border: none;
		margin: 0px auto;
		border-radius: 2px;
		background: rgba(108, 111, 143, 0.14);
		&.assets-preview {
			height: 36px;
			margin-top: 10px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.preview-footer-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 10px;
		padding: 0 13px 10px;
		.preview-footer-loader-block {
			width: 75%;
			border: none;
			height: 16px;
			background: rgba(108, 111, 143, 0.14);
			border-radius: 2px;
		}
		.preview-footer-icon-block {
			width: 25%;
			text-align: end;
		}
	}
	.preview-of-request {
		border-right: 1px solid $border-color;
		width: 44%;
		.preview-of-request-footer {
			border-top: 1px solid $border-color;
			padding-top: 10px;
		}
	}
	.preview-of-request-right {
		width: 66%;
		.preview-of-request-top {
			padding-bottom: 10px;
			border-bottom: 1px solid $border-color;
		}
	}
}

// preview section end

.dark-layout {
	.general-seting-main-parent-block {
		background: transparent;
	}

	.preview-main-block-section {
		border: 1px solid #323a4d !important;
		border-left: 5px solid #323a4d !important;
	}
	.preview-main-block-section {
		.preview-of-request {
			border-right: 1px solid #323a4d !important;
			.preview-of-request-footer {
				border-right: 1px solid #323a4d !important;
			}
		}
	}
	.preview-main-block-section {
		.preview-of-request-right {
			.preview-of-request-top {
				border-bottom: 1px solid #323a4d !important;
			}
		}
	}
	.br-c-bg {
		border: 1px solid #323a4d !important;
	}
	.prefix-module-sections-main-wrapper-block {
		.table-responsive {
			.table {
				tbody {
					border: 1px solid #323a4d !important;
				}
			}
		}
	}
	.preview-of-request-footer {
		border-top: 1px solid #323a4d !important;
	}
	.font-cr {
		color: $white !important;
	}
	.validation-msg {
		margin-bottom: 0;
		background: transparent !important;
		font-weight: 300;
		position: absolute;
		margin-top: 5px;
	}
}
// New General Settings End

.btn,
.badge {
	&.btn-outline-secondary {
		border: 1px solid $border-color !important;
	}
}

.text-fade-in {
	animation: fadeInAnimation ease 2s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// Update scrollbar for Accordion //
.accordion {
	.accordion-item {
		.collapse {
			&.show {
				ng-scrollbar {
					&.custom-scrollbar {
						.ng-scrollbar-wrapper {
							.ng-scroll-viewport {
								.ng-scroll-content {
									height: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}
// End of Update scrollbar for Accordon //

.recaptcha {
	div {
		div {
			div {
				width: 100% !important;
			}
		}
	}
}
.spinner-slow-animation {
	animation: 1.25s linear infinite spinner-border !important;
}

.spinner-slower-animation {
	animation: 1.75s linear infinite spinner-border !important;
}

.spinner-fast-animation {
	animation: 0.4s linear infinite spinner-border !important;
}

.spinner-faster-animation {
	animation: 0.2s linear infinite spinner-border !important;
}

// Ticket Communication //

.communication-main-sec {
	.custom-scrollbar {
		.ng-scrollbar-wrapper {
			.ng-scroll-viewport-wrapper {
				.ng-scroll-content {
					max-width: 100%;
				}
			}
		}
	}
}
.textenhancementpopover {
	min-width: 586px !important;
	min-height: 339px !important;
	border-radius: 5px;
	z-index: 10000;
	.popover-body {
		border: 1px solid transparent;
		padding: 0 !important;
		margin: 0 !important;
		max-height: fit-content !important;
	}
}
.communication-badges {
	.badge {
		cursor: pointer;
	}
}
.communication-preview {
	img {
		width: 100%;
	}
}

// End Of Ticket Communication //

// Skeleton Pannel View //
.skeleton-view-sec {
	.custom-scrollbar {
		.ng-scrollbar-wrapper {
			.ng-scroll-viewport-wrapper {
				.ng-scroll-content {
					min-height: 100%;
					display: grid;
				}
			}
		}
	}
	.sekeleton-card {
		.ngx-skeleton-loader {
			span {
				margin-bottom: 0px !important;
			}
		}
	}
}
// End Of Skeleton Pannel View //
// End Of Ticket Communication //

// Assignee Ng-select dropdown update design changes//

.assign-user-dropdown {
	&.ng-dropdown-panel {
		.ng-dropdown-panel-items {
			.ng-option {
				&.ng-option-selected {
					&.ng-option-marked {
						.media {
							.media-body {
								h5 {
									color: $primary !important;
								}
								span {
									color: $primary !important;
								}
								p {
									color: $primary !important;
								}
							}
						}
						> div {
							.cell-line-height {
								p {
									color: $primary !important;
								}
								span {
									color: $primary !important;
								}
							}
						}
						> span {
							.cell-line-height {
								p {
									color: $primary !important;
								}
								span {
									color: $primary !important;
								}
							}
						}
					}
					.media {
						.media-body {
							h5 {
								color: $primary !important;
							}
							span {
								color: $primary !important;
							}
							p {
								color: $primary !important;
							}
						}
					}
					> div {
						.cell-line-height {
							p {
								color: $primary !important;
							}
							span {
								color: $primary !important;
							}
						}
					}
					> span {
						.cell-line-height {
							p {
								color: $primary !important;
							}
							span {
								color: $primary !important;
							}
						}
					}
				}
				&.ng-option-marked {
					.media-body {
						h5 {
							color: $title-color !important;
						}
						span {
							color: $title-color !important;
						}
						p {
							color: $title-color !important;
						}
						small {
							color: $title-color !important;
						}
					}
					> div {
						.cell-line-height {
							p {
								color: $title-color !important;
							}
							span {
								color: $title-color !important;
							}
							small {
								color: $title-color !important;
							}
						}
					}
					> span {
						.cell-line-height {
							p {
								color: $title-color !important;
							}
							span {
								color: $title-color !important;
							}
							small {
								color: $title-color !important;
							}
						}
					}
				}
				> div:not(.cursor-pointer) {
					width: 100% !important;
					display: grid !important;
					grid-template-columns: 28px 1fr;
					gap: $size-12-px;
					align-items: center;
					> div:not(.cell-line-height) {
						.avatar {
							width: 28px !important;
							height: 28px !important;
							display: flex;
							margin: 0px !important;
							.avatar-content {
								width: 28px !important;
								height: 28px !important;
								display: flex;
								align-items: center;
								justify-content: center;
								img {
									width: 28px !important;
									height: 28px !important;
								}
							}
						}
					}
					.avatar {
						width: 28px !important;
						height: 28px !important;
						display: flex;
						margin: 0px !important;
						.avatar-content {
							width: 28px !important;
							height: 28px !important;
							display: flex;
							align-items: center;
							justify-content: center;
							img {
								width: 28px !important;
								height: 28px !important;
							}
						}
					}
					.cell-line-height {
						max-width: 224px;
						width: 100% !important;
						p {
							max-width: 224px;
							width: 100% !important;
							white-space: nowrap;
							overflow: hidden !important;
							text-overflow: ellipsis;
							font-size: $size-14-px;
							line-height: normal !important;
							font-weight: 500 !important;
							color: $title-color;
						}
						span {
							max-width: 224px;
							width: 100% !important;
							line-height: normal !important;
						}
					}
				}
				> span {
					width: 100% !important;
					display: grid !important;
					grid-template-columns: 28px 1fr;
					gap: $size-12-px;
					align-items: center;
					> div:not(.cell-line-height) {
						.avatar {
							width: 28px !important;
							height: 28px !important;
							display: flex;
							margin: 0px !important;
							.avatar-content {
								width: 28px !important;
								height: 28px !important;
								display: flex;
								align-items: center;
								justify-content: center;
								img {
									width: 28px !important;
									height: 28px !important;
								}
							}
						}
					}
					.avatar {
						width: 28px !important;
						height: 28px !important;
						display: flex;
						margin: 0px !important;
						.avatar-content {
							width: 28px !important;
							height: 28px !important;
							display: flex;
							align-items: center;
							justify-content: center;
							img {
								width: 28px !important;
								height: 28px !important;
							}
						}
					}
					.cell-line-height {
						max-width: 224px;
						width: 100% !important;
						p {
							max-width: 224px;
							width: 100% !important;
							white-space: nowrap;
							overflow: hidden !important;
							text-overflow: ellipsis;
							font-size: $size-14-px !important;
							line-height: normal !important;
							font-weight: 500 !important;
							color: $title-color;
						}
						span {
							max-width: 224px;
							width: 100% !important;
							line-height: normal !important;
						}
					}
				}
				.media {
					width: 100% !important;
					display: grid !important;
					grid-template-columns: 28px 1fr;
					gap: $size-12-px;
					align-items: center;
					.avatar {
						width: 28px !important;
						height: 28px !important;
						display: flex;
						margin: 0px !important;
						.avatar-content {
							width: 28px !important;
							height: 28px !important;
							display: flex;
							align-items: center;
							justify-content: center;
							img {
								width: 28px !important;
								height: 28px !important;
							}
						}
					}
					.media-body {
						max-width: 224px;
						width: 100% !important;
						h5 {
							max-width: 224px;
							width: 100% !important;
							white-space: nowrap;
							overflow: hidden !important;
							text-overflow: ellipsis;
							font-size: $size-14-px !important;
							line-height: normal !important;
							font-weight: 500 !important;
							color: $title-color;
						}
						p {
							max-width: 224px;
							width: 100% !important;
							white-space: nowrap;
							overflow: hidden !important;
							text-overflow: ellipsis;
							font-size: $size-14-px !important;
							line-height: normal !important;
							font-weight: 500 !important;
							color: $title-color !important;
						}
						span {
							max-width: 224px;
							width: 100% !important;
							line-height: normal !important;
						}
					}
				}
			}
		}
	}
}
.assign-user-dropdown {
	&.ng-select:not(.ng-select-filtered) {
		.ng-dropdown-panel {
			.ng-dropdown-panel-items {
				.ng-option {
					&.ng-option-selected {
						&.ng-option-marked {
							.media {
								.media-body {
									h5 {
										color: $primary !important;
									}
									span {
										color: $primary !important;
									}
									p {
										color: $primary !important;
									}
								}
							}
							> div {
								.cell-line-height {
									p {
										color: $primary !important;
									}
									span {
										color: $primary !important;
									}
								}
							}
							> span {
								.cell-line-height {
									p {
										color: $primary !important;
									}
									span {
										color: $primary !important;
									}
								}
							}
						}
						.media {
							.media-body {
								h5 {
									color: $primary !important;
								}
								span {
									color: $primary !important;
								}
								p {
									color: $primary !important;
								}
							}
						}
						> div {
							.cell-line-height {
								p {
									color: $primary !important;
								}
								span {
									color: $primary !important;
								}
							}
						}
						> span {
							.cell-line-height {
								p {
									color: $primary !important;
								}
								span {
									color: $primary !important;
								}
							}
						}
					}
					&.ng-option-marked {
						.media-body {
							h5 {
								color: $title-color !important;
							}
							span {
								color: $title-color !important;
							}
							p {
								color: $title-color !important;
							}
							small {
								color: $title-color !important;
							}
						}
						> div {
							.cell-line-height {
								p {
									color: $title-color !important;
								}
								span {
									color: $title-color !important;
								}
								small {
									color: $title-color !important;
								}
							}
						}
						> span {
							.cell-line-height {
								p {
									color: $title-color !important;
								}
								span {
									color: $title-color !important;
								}
								small {
									color: $title-color !important;
								}
							}
						}
					}
					> div:not(.cursor-pointer) {
						width: 100% !important;
						display: grid !important;
						grid-template-columns: 28px 1fr;
						gap: $size-12-px;
						align-items: center;
						> div:not(.cell-line-height) {
							.avatar {
								width: 28px !important;
								height: 28px !important;
								display: flex;
								margin: 0px !important;
								.avatar-content {
									width: 28px !important;
									height: 28px !important;
									display: flex;
									align-items: center;
									justify-content: center;
									img {
										width: 28px !important;
										height: 28px !important;
									}
								}
							}
						}
						.avatar {
							width: 28px !important;
							height: 28px !important;
							display: flex;
							margin: 0px !important;
							.avatar-content {
								width: 28px !important;
								height: 28px !important;
								display: flex;
								align-items: center;
								justify-content: center;
								img {
									width: 28px !important;
									height: 28px !important;
								}
							}
						}
						.cell-line-height {
							max-width: 224px;
							width: 100% !important;
							p {
								max-width: 224px;
								width: 100% !important;
								white-space: nowrap;
								overflow: hidden !important;
								text-overflow: ellipsis;
								font-size: $size-14-px;
								line-height: normal !important;
								font-weight: 500 !important;
								color: $title-color;
							}
							span {
								max-width: 224px;
								width: 100% !important;
								line-height: normal !important;
							}
						}
					}
					> span {
						width: 100% !important;
						display: grid !important;
						grid-template-columns: 28px 1fr;
						gap: $size-12-px;
						align-items: center;
						> div:not(.cell-line-height) {
							.avatar {
								width: 28px !important;
								height: 28px !important;
								display: flex;
								margin: 0px !important;
								.avatar-content {
									width: 28px !important;
									height: 28px !important;
									display: flex;
									align-items: center;
									justify-content: center;
									img {
										width: 28px !important;
										height: 28px !important;
									}
								}
							}
						}
						.avatar {
							width: 28px !important;
							height: 28px !important;
							display: flex;
							margin: 0px !important;
							.avatar-content {
								width: 28px !important;
								height: 28px !important;
								display: flex;
								align-items: center;
								justify-content: center;
								img {
									width: 28px !important;
									height: 28px !important;
								}
							}
						}
						.cell-line-height {
							max-width: 224px;
							width: 100% !important;
							p {
								max-width: 224px;
								width: 100% !important;
								white-space: nowrap;
								overflow: hidden !important;
								text-overflow: ellipsis;
								font-size: $size-14-px !important;
								line-height: normal !important;
								font-weight: 500 !important;
								color: $title-color;
							}
							span {
								max-width: 224px;
								width: 100% !important;
								line-height: normal !important;
							}
						}
					}
					.media {
						width: 100% !important;
						display: grid !important;
						grid-template-columns: 28px 1fr;
						gap: $size-12-px;
						align-items: center;
						.avatar {
							width: 28px !important;
							height: 28px !important;
							display: flex;
							margin: 0px !important;
							.avatar-content {
								width: 28px !important;
								height: 28px !important;
								display: flex;
								align-items: center;
								justify-content: center;
								img {
									width: 28px !important;
									height: 28px !important;
								}
							}
						}
						.media-body {
							max-width: 224px;
							width: 100% !important;
							h5 {
								max-width: 224px;
								width: 100% !important;
								white-space: nowrap;
								overflow: hidden !important;
								text-overflow: ellipsis;
								font-size: $size-14-px !important;
								line-height: normal !important;
								font-weight: 500 !important;
								color: $title-color;
							}
							p {
								max-width: 224px;
								width: 100% !important;
								white-space: nowrap;
								overflow: hidden !important;
								text-overflow: ellipsis;
								font-size: $size-14-px !important;
								line-height: normal !important;
								font-weight: 500 !important;
								color: $title-color !important;
							}
							span {
								max-width: 224px;
								width: 100% !important;
								line-height: normal !important;
							}
						}
					}
				}
			}
		}
	}
}

#txtReqAssignee {
	@extend .assign-user-dropdown;
}
#selRepaceAssignee {
	@extend .assign-user-dropdown;
}
#txtInciAssignee {
	@extend .assign-user-dropdown;
}
#selAssignee {
	@extend .assign-user-dropdown;
}
#txtChnAssignee {
	@extend .assign-user-dropdown;
}
#txtProbAssignee {
	@extend .assign-user-dropdown;
}
#txtRelAssignee {
	@extend .assign-user-dropdown;
}
#selOwner {
	@extend .assign-user-dropdown;
}
#selSubmitter {
	@extend .assign-user-dropdown;
}
#selRequester {
	@extend .assign-user-dropdown;
}
#selUser {
	@extend .assign-user-dropdown;
}
#selPrimeUser {
	@extend .assign-user-dropdown;
}
#selUsers {
	@extend .assign-user-dropdown;
}
#selUsrFilter {
	@extend .assign-user-dropdown;
}
#reporting_manager {
	@extend .assign-user-dropdown;
}
#selRepaceAssignee {
	@extend .assign-user-dropdown;
}
#selMultiAssignee {
	@extend .assign-user-dropdown;
}
#selServiceOwner {
	@extend .assign-user-dropdown;
}
#mselBzOwner {
	@extend .assign-user-dropdown;
}
#mselITOwner {
	@extend .assign-user-dropdown;
}
#mselRequesters {
	@extend .assign-user-dropdown;
}
#mselOwner {
	@extend .assign-user-dropdown;
}
#selApprovalStaff {
	@extend .assign-user-dropdown;
}
#selUsrLevel {
	@extend .assign-user-dropdown;
}
#selUsrRepMgr {
	@extend .assign-user-dropdown;
}
#selectFollowers {
	@extend .assign-user-dropdown;
}
#txtChnSelAssignGroup {
	@extend .assign-user-dropdown;
}
#selManager {
	@extend .assign-user-dropdown;
}
#txtInciClosedBy {
	@extend .assign-user-dropdown;
}
#to_address {
	@extend .assign-user-dropdown;
}
#cc_address {
	@extend .assign-user-dropdown;
}
#bcc_address {
	@extend .assign-user-dropdown;
}
#txtInciSelAssignGroup {
	@extend .assign-user-dropdown;
}
#txtReqClosedBy {
	@extend .assign-user-dropdown;
}
#txtChnClosedBy {
	@extend .assign-user-dropdown;
}
#txtReqSidebarAssignGroup {
	@extend .assign-user-dropdown;
}
#txtReqAssignGroup {
	@extend .assign-user-dropdown;
}
#txtChnAssignGroup {
	@extend .assign-user-dropdown;
}
#txtInciAssignGroup {
	@extend .assign-user-dropdown;
}
#txtChnSidebarAssignGroup {
	@extend .assign-user-dropdown;
}
#txtInciSidebarAssignGroup {
	@extend .assign-user-dropdown;
}
#parent {
	@extend .assign-user-dropdown;
}
#txtProbSidebarAssignGroup {
	@extend .assign-user-dropdown;
}
#txtProbAssignGroup {
	@extend .assign-user-dropdown;
}
#txtProbSelAssignGroup {
	@extend .assign-user-dropdown;
}
#txtProbClosedBy {
	@extend .assign-user-dropdown;
}
#txtRelSidebarAssignGroup {
	@extend .assign-user-dropdown;
}
#txtRelAssignGroup {
	@extend .assign-user-dropdown;
}
#txtReqSelAssignGroup {
	@extend .assign-user-dropdown;
}
// End of Assignee Ng-select dropdown update design changes//

// For communiication mail img //
.mail-content {
	p {
		img {
			max-width: 100% !important;
			max-height: 100% !important;
		}
	}
	pre {
		white-space: pre-wrap !important;
		&.ql-syntax {
			white-space: pre-wrap !important;
		}
	}
}
// End of For communiication mail img //

// DNS Pannel View //
.dns-panel-view-scroll {
	height: 100%;
	display: flex;
	padding: $size-14-px 6px;
	gap: $size-8-px;
	flex-direction: column;
	.custom-scrollbar {
		.ng-scrollbar-wrapper {
			.ng-scroll-viewport-wrapper {
				.ng-scroll-viewport {
					.ng-scroll-content {
						min-height: 100%;
						display: grid;
					}
				}
			}
		}
	}
	.dns-panel-main-body-sec {
		&.common-pannel-view {
			display: grid;
			border-radius: 6px !important;
			&.state-color {
				border-left: 6px solid transparent !important;
				&.state-1 {
					border-left: 6px solid $danger !important;
				}
				&.state-2 {
					border-left: 6px solid $warning !important;
				}
				&.state-3 {
					border-left: 6px solid $info !important;
				}
				&.state-4 {
					border-left: 6px solid $success !important;
				}
				&.state-5 {
					border-left: 6px solid $dark !important;
				}
				&.state-6 {
					border-left: 6px solid $secondary !important;
				}
				&.state-7 {
					border-left: 6px solid $light !important;
				}
				// $state-colors: ("danger", "warning", "info", "success", "dark", "secondary","light");
				// @each $state-color, $i in $state-colors {
				// 	&.state-#{$i} {
				// 		@if $i == 1 {
				// 			border-left: 6px solid $danger !important;
				// 		} @else if $i == 2 {
				// 			border-left: 6px solid $warning !important;
				// 		} @else if $i == 3 {
				// 			border-left: 6px solid $info !important;
				// 		} @else if $i == 4 {
				// 			border-left: 6px solid $success !important;
				// 		} @else if $i == 5 {
				// 			border-left: 6px solid $dark !important;
				// 		} @else if $i == 6 {
				// 			border-left: 6px solid $secondary !important;
				// 		} @else{
				// 			border-left: 6px solid $light !important;
				// 		}
				// 	}
				// }
			}
			.dns-panel-header-sec {
				display: grid;
				grid-template-columns: 168px 1fr;
				.dns-panel-contact-sec {
					display: grid;
					gap: $size-12-px;
					padding: $size-14-px $size-8-px;
					.dns-panel-contact-name {
					}
					.dns-panel-icon-list {
						display: grid;
						grid-template-columns: repeat(5, 20px);
						grid-template-rows: 20px;
						gap: $size-8-px;
					}
				}
			}
			.dns-panel-main-body-block {
				display: grid;
				grid-template-columns: 168px 168px 310px 138px 138px 134px 120px minmax(auto, 240px) minmax(auto, 240px);
				.dns-panel-body-sec {
					.panel-data-block {
						padding: $size-8-px;
						&.action-btn-list {
							display: grid;
							grid-template-columns: repeat(5, 28px);
							grid-template-rows: 28px;
							gap: $size-8-px;
							height: 100%;
							align-content: center;
						}
						.panel-data-title {
							margin-bottom: $size-8-px;
						}
						.form-group {
							margin-bottom: 0px !important;
						}
						.ng-select {
							&.assign-group-dropdown {
								.ng-select-container {
									.ng-value-container {
										.ng-value {
											.media {
												display: grid;
												grid-template-columns: 16px 1fr;
												gap: 4px;
												align-items: center;
												.avatar {
													width: $size-16-px;
													height: $size-16-px;
													border-radius: 100%;
													.avatar-content {
														width: $size-16-px;
														height: $size-16-px;
														border-radius: 100%;
														font-size: 7px;
														img {
															width: 100%;
															height: 100%;
														}
													}
												}
											}
										}
									}
								}
							}
							.ng-select-container {
								border: 1px solid transparent !important;
								.ng-value-container {
									.ng-value {
										> span {
											color: $title-color;
										}
										.list-item-block {
											display: grid;
											grid-template-columns: 16px 1fr;
											gap: 4px;
											align-items: center;
											.avatar {
												width: $size-16-px;
												height: $size-16-px;
												border-radius: 100%;
												.avatar-content {
													width: $size-16-px;
													height: $size-16-px;
													border-radius: 100%;
													font-size: 7px;
													img {
														width: 100%;
														height: 100%;
													}
												}
											}
										}
									}
								}
								.ng-arrow-wrapper {
									opacity: 0;
								}
							}
							&:hover {
								.ng-select-container {
									border: 1px solid $input-border-color !important;
									.ng-arrow-wrapper {
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.sm-select-feature {
	.selct-feature {
		.select-sec {
			.form-group {
				.ng-select {
					min-height: 28px;
					height: auto !important;
					.ng-select-container {
						min-height: 28px !important;
						height: auto !important;
						font-size: $size-12-px !important;
						.ng-value-container {
							.ng-placeholder {
								top: 4px !important;
							}
							.ng-value {
								line-height: normal !important;
								font-size: $size-12-px !important;
								.badge {
									display: flex;
									align-items: center;
									gap: 2px;
								}
								.ng-value-icon {
									line-height: 13px !important;
								}

								.ng-value-label {
									padding: 1px 5px !important;
								}
							}
						}
					}
				}
			}
		}
	}
}
// End of DNS Pannel View //

// Table Head sticky for report //
app-report-widget {
	simple-table {
		.table-responsive {
			position: relative;
			overflow: auto;
			max-height: 360px !important;
			@extend .browser-scrollbar;
			table.table {
				thead {
					position: sticky;
					top: 0px;
					left: 0px;
					tr {
						th {
							border-top: 0px !important;
						}
					}
				}
			}
		}
	}
}
// End Of Table Head sticky for report //
// Dashboard Toggale btn //
.default-layout {
	&.modal-open {
		.main-menu {
			z-index: 9;
			.header-navbar-shadow {
				display: none;
			}
		}
	}
	.dashboard-toggale-submenu {
		position: absolute;
		z-index: 1000;
		.submenu-toggle {
			&.btn {
				&:focus {
					box-shadow: none;
				}
			}
		}
	}
	&.spaced-out-layout {
		.dashboard-toggale-submenu {
			top: 64px;
			left: 0px;
		}
	}
	&.fixed-layout {
		.dashboard-toggale-submenu {
			top: 46px;
		}
	}
}
// End of Dashboard Toggale btn //

// Dashboards Widget Sidebar //
.dashboard-widget-sidebar {
	&.modal {
		.default-slideout {
			.modalsd {
				.modal-dialog {
					.modal-content {
						// display: grid;
						// gap: 0;
						// grid-template-rows: 45px calc(100% - 45px);
						.modal-header {
						}
						.modal-body {
							height: 100%;
							max-width: 100%;
							max-height: calc(100% - 45px);
							.dashboard-view-sidebar:not(.inci-grid):not(.change-grid):not(.release-grid):not(.resource-grid):not(.req-process-grid) {
								height: 100%;
								max-width: 100%;
								> * {
									max-width: 100%;
									height: 100%;
									display: grid;
									max-height: calc(100vh - 45px);
									.action-bar {
										display: none;
									}
								}
							}
							.dashboard-view-sidebar {
								height: 100%;
								max-width: 100%;
							}
						}
					}
				}
			}
		}
	}
}
// End of Dashboards Widget Sidebar //

// Dashboard Sidebar //
.dashboard-view {
	.main-content {
		.dashbord-sidebar {
			.dashbord-toggle-menu {
				.side-pannel {
					.sidebar {
						.sidebar-body {
							.sidebar-nav {
								.custom-scrollbar {
									&.ng-scrollbar {
										.ng-scrollbar-wrapper {
											.ng-scroll-viewport-wrapper {
												.ng-scroll-viewport {
													.ng-scroll-content {
														max-width: 100%;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
// End of Dashboard sidebar //

// Demo video modal //
.demo-video {
	padding: 6px 10px;
	// border-radius: 14px;
	// overflow: hidden;
	a {
		&.nav-link {
			padding: 0 !important;
		}
	}
	&.show-animation {
		position: relative;
		color: $white !important;
		&::before {
			position: absolute;
			left: 0;
			top: 0;
			content: "";
			-webkit-animation: heartbeat 1.5s ease-in-out 5 both;
			animation: heartbeat 1.5s ease-in-out 5 both;
			background-color: $primary;
			width: 100%;
			height: 100%;
			border-radius: 14px;
			z-index: -1;
		}
	}
}
.demo-video-modal {
	&.modal {
		.modal-dialog {
			max-width: 72%;
			margin-left: 14%;
			margin-right: 14%;
			.modal-content {
				overflow: hidden;
				.modal-body {
					padding: 0;
					.close {
						position: absolute;
						width: 48px;
						height: 48px;
						border-radius: 100%;
						background-color: rgba(35, 35, 47, 0.25);
						right: 24px;
						top: 24px;
						font-size: 26px;
						color: $white;
						z-index: 99;
						text-shadow: none;
						opacity: 1;
					}
					video {
						width: 100%;
						height: 100%;
						display: block;
					}
				}
			}
		}
	}
}
// End of Demo video modal //

// Getting Started Step //
.getting-started-item {
	// margin-right: $size-4-px;
	.getting-started-sec {
		display: flex;
		gap: 24px;
		padding: 5px 8px;
		border-radius: 8px;
		background: rgba($color: $primary, $alpha: 0.08);
		align-items: center;
		.remian-day-sec {
			display: flex;
			align-items: center;
			gap: 4px;
			.day-left {
				width: 24px;
				height: 24px;
				border-radius: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				span {
					font-size: $size-12-px;
					line-height: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.buy-product {
				a {
					text-decoration: underline;
				}
			}
		}
		.getting-stared-step {
			display: flex;
			gap: 8px;
			align-items: center;
			font-size: $size-10-px;
			.step-info {
				display: flex;
				gap: $size-8-px;
			}
			.steps {
				display: flex;
				gap: 3px;
				align-items: center;
				.total-step {
					display: flex;
					gap: 3px;
					align-items: center;
					.step {
						width: 24px;
						height: 6px;
						border-radius: 24px;
						background-color: $table-head-bg;
						font-size: 0px;
					}
				}
				.completed-step {
					display: flex;
					gap: 3px;
					align-items: center;
					position: absolute;
					.done {
						width: 24px;
						height: 6px;
						border-radius: 24px;
						font-size: 0px;
						background-color: $success;
					}
				}
			}
		}
	}
}

// End of Getting Started Step //

// Getting Started Modal //
body {
	&.getting-started-modal {
		&.modal-open {
			&.has-notification {
				.vertical-layout {
					.notification-bar {
						z-index: 9;
					}
				}
			}
			.main-menu {
				z-index: 10;
			}
		}
	}
}
.getting-steps-info {
	.steps-info {
		.single-step {
			.step-info {
				.step-completed {
					padding: 2px 8px;
					background-color: rgba($color: $success, $alpha: 0.12);
					width: fit-content;
					border-radius: 4px;
				}
			}
		}
	}
}
// End of Getting Started Modal //
.modal-max-height {
	max-height: calc(100vh - 12rem);
	overflow: auto;
	@extend .browser-scrollbar;
}

// Create Channel For workspace //
.create-channel-modal {
	@extend .browser-scrollbar;
	.create-channel {
		&.modal-dialog-scrollable {
			&.modal-dialog-centered {
				display: table;
			}
		}
		.modal-content {
			height: inherit;
			app-create-channel {
				min-height: 100%;
				form {
					height: 100%;
					display: flex;
					flex-direction: column;
				}
			}
		}
	}
}
// End of Create Channel For workspace //
.incident-view-sidebar {
	.modal-dialog {
		.modal-content {
			.modal-header {
				background-color: $body-bg !important;
			}
		}
	}
}
.incident-view-detail-grid {
	.basic-details {
		.input-tree-dropdown {
			max-width: 360px;
			app-single-tree-dropdown {
				app-ngx-dropdown-treeview-select {
					.tree-dropdown {
						ngx-dropdown-treeview {
							.dropdown {
								.dropdown-toggle {
									border-color: transparent !important;
									&::after {
										opacity: 0;
									}
									&:hover {
										border-color: $input-border-color !important;
										&::after {
											opacity: 1;
										}
									}
									&:focus {
										border-color: $input-border-color !important;
										&::after {
											opacity: 1;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.ng-select {
			&:focus {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
				}
			}
			&.ng-select-opened {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
				}
			}
			&.ng-select-single {
				max-width: 309px;
				.ng-select-container {
					border: 1px solid transparent !important;
					.ng-clear-wrapper {
						display: none;
					}
					.ng-arrow-wrapper {
						display: none;
					}
					&:hover {
						border: 1px solid $input-border-color !important;
					}

					.ng-placeholder {
						font-size: $size-12-px;
					}
					.ng-value {
						.ng-value-label {
							font-size: $size-12-px;
						}
					}
				}
			}
		}
		input.form-control,
		textarea.form-control {
			border: 1px solid transparent !important;
			resize: none;
			font-size: $size-12-px;
			&:hover {
				border: 1px solid $input-border-color !important;
			}
			&::-webkit-input-placeholder {
				/* Edge */
				font-size: $size-12-px;
			}

			&:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				font-size: $size-12-px;
			}

			&::placeholder {
				font-size: $size-12-px;
			}
		}
	}
}

quill-editor {
	.ql-toolbar {
	}
	.ql-container {
		.ql-editor {
			max-height: 200px;
			@extend .browser-scrollbar;
		}
	}
}

.compose-form {
	.mail-header {
		background: $body-bg;
	}
	.select2-primary {
		border: none !important;
		// display: grid !important;
		// grid-template-columns: 1fr 0fr !important;
		.mail-subject {
			input {
				// border:1px solid #dedeeb ;
				border: 1px solid transparent !important;
				line-height: 21px !important;
				font-size: 14px;
				outline: none;
				box-shadow: none;
				background: $body-bg;
				&:focus {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
				}
				&:hover {
					border: 1px solid $input-border-color !important;
					padding-left: 1rem;
					padding-left: 1rem !important;
					transition: all 0.5s ease;
				}
			}
			// input:focus{
			//     outline: 1px solid #dedeeb;
			//     box-shadow: none;
			// }
		}
		.compose-footer-wrapper {
			border: 1px solid $input-border-color !important;
		}
		.mail-to-address {
			border: 1px solid transparent;
			border-radius: 5px;
			// &:hover{
			//     border:1px solid $input-border-color;
			// }
			// &:focus{
			//     border:1px solid $input-border-color;
			// }
		}
	}
}

// Common Pannel Card //
.pannel-view-card {
	border-radius: 6px;
	&:hover {
		.top-view {
			.hover-sec {
				display: block !important;
			}
		}
	}
	&.task-card {
		// overflow: hidden;
		border-left: 8px solid !important;
		border-color: transparent;
		&.priority-1 {
			border-left-color: $danger !important;
		}
		&.priority-2 {
			border-left-color: $warning !important;
		}
		&.priority-3 {
			border-left-color: $info !important;
		}
		&.priority-4 {
			border-left-color: $success !important;
		}
		.bottom-view {
			padding: $size-4-px $size-14-px 10px;
			display: grid;
			gap: 6px;
			.task-bottom-view {
				display: flex;
				gap: 12px;
			}
		}
	}
	.top-view {
		display: flex;
		gap: $size-14-px;
		padding: $size-12-px $size-14-px;
		position: relative;
		.hover-sec {
			display: none !important;
			position: absolute;
			box-shadow: 0px 0px 6px 1px rgba(115, 103, 240, 0.32);
			border-radius: 6px;
			left: auto;
			right: 16px;
			padding: 8px 12px;
			display: flex;
			gap: 12px;
			top: -22px;
			.action-list {
				font-size: 14px;
				height: 16px;
				line-height: normal;
			}
		}
		.dispaly-id {
			align-self: baseline;
			margin-left: -$size-14-px;
			padding: 6px 14px;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			color: $white;
			display: flex;
			gap: 5px;
			line-height: 18px;
			align-items: center;
			font-weight: 500;
			.cusotm-badge {
				border-radius: 4px;
				background-color: rgba($color: $white, $alpha: 0.2);
				color: $white;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 16px;
				width: 16px;
				i {
					font-size: 10px;
					height: auto;
					// line-height: 14px;
				}
			}
		}
		.basic-info {
			width: 100%;
			.card-summary {
			}
			.creation-time {
				white-space: nowrap;
			}
			.card-discription {
				margin-top: 4px;
				.common-action-dropdown {
					&.dropdown {
						&.show {
							.dropdown-menu {
								min-width: auto;
								padding: 0px;
								display: flex;
								overflow: hidden;
								.dropdown-item {
									padding: 6px 8px;
									font-size: 12px;
								}
							}
						}
					}
				}
			}
		}
	}
	.bottom-view {
		padding: $size-4-px $size-14-px 10px;
		display: grid;
		gap: 6px;
		.option-action-section {
			display: flex;
			gap: 22px;
			@include max-mq(1450) {
				gap: 16px;
			}
		}
		.reply-sec {
			margin-left: 10px;
			.has-reply {
				padding-left: 24px;
				position: relative;
				&::before {
					position: absolute;
					content: "";
					width: 27px;
					height: 14px;
					left: 0px;
					top: -2px;
					border-bottom-left-radius: 4px;
					background-color: transparent;
					border-left: 1px solid $text-muted;
					border-bottom: 1px solid $text-muted;
				}
			}
		}
		.assignee-dropdown {
			.ng-select {
				.ng-select-container {
					.ng-value {
						.cell-line-height {
							.line-height-1 {
								line-height: 10px !important;
							}
						}
					}
				}
			}
		}
		.priority-dropdown {
			.info-block {
				.value-block {
					app-select-badge {
						.selct-feature {
							.select-sec {
								.ng-select {
									min-width: auto !important;
								}
							}
						}
					}
				}
			}
		}
		.info-block {
			display: flex;
			flex-direction: column;
			gap: 4px;
			height: 100%;
			justify-content: flex-start;
			.label-block {
			}
			.value-block {
				min-height: 36px;
				display: flex;
				align-items: center;
				justify-content: baseline;
				&.noPermission {
					min-height: auto;
				}
				.sla-info-container {
					.sla-info-item {
						.incident-sla {
							.cp-timer {
								margin-top: 0px;
								align-self: center;
								display: flex;
								flex-direction: column;
								margin-left: 4px !important;
								.sla-status {
									font-size: 10px !important;
								}
								.sla-timer {
									font-size: 10px !important;
								}
							}
						}
					}
				}
				app-status-picker {
					.selct-feature {
						.select-sec {
							.form-group {
								margin-bottom: 0;
							}
						}
					}
				}
				.status-list-picker {
					.ng-dropdown-panel {
						// width: auto !important;
						min-width: auto !important;
					}
				}
				.process-date-picker {
				}
				ng2-flatpickr {
					.ng2-flatpickr-input-container {
						.ng2-flatpickr-input {
							padding-left: 0;
							padding-right: 0;
							border: 1px solid transparent !important;
							transition: all 0.5s ease;
						}
					}
					&:hover {
						.ng2-flatpickr-input-container {
							.ng2-flatpickr-input {
								padding-left: 1rem;
								padding-right: 1rem;
								border: 1px solid $input-border-color !important;
								transition: all 0.5s ease;
							}
						}
					}
				}
				.ng-select {
					min-width: auto;
					cursor: pointer;
					&:hover {
						&.ng-select-disabled {
							.ng-select-container {
								border: 0px solid $input-border-color !important;
							}
						}
					}
					&.ng-select-disabled {
						.ng-select-container {
							border: 0px solid $input-border-color !important;
							.ng-value-container {
								padding-left: 10px !important;
								padding-right: 5px !important;
							}
						}
					}
					&.ng-select-focused {
						min-width: 208px;
						&:hover {
							.ng-select-container {
								border: 1px solid $input-border-color !important;
								transition: all 0.5s ease;
								.ng-value-container {
									padding-left: 10px;
									padding-right: 5px;
									transition: all 0.5s ease;
								}
								.ng-clear-wrapper {
									opacity: 1;
									visibility: visible;
									width: auto;
									-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
									transition: opacity 500ms, visibility 500ms, width 500ms;
								}
								.ng-arrow-wrapper {
									opacity: 1;
									visibility: visible;
									width: auto;
									-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
									transition: opacity 500ms, visibility 500ms, width 500ms;
								}
							}
						}
						.ng-select-container {
							border: 1px solid $input-border-color !important;
							transition: all 0.5s ease;
							.ng-value-container {
								padding-left: 10px;
								padding-right: 5px;
								transition: all 0.5s ease;
							}
							.ng-clear-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
							.ng-arrow-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
						}
					}
					&.ng-select-opened {
						min-width: 208px;
						&:hover {
							.ng-select-container {
								border: 1px solid $input-border-color !important;
								transition: all 0.5s ease;
								.ng-value-container {
									padding-left: 10px;
									padding-right: 5px;
									transition: all 0.5s ease;
								}
								.ng-clear-wrapper {
									opacity: 1;
									visibility: visible;
									width: auto;
									-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
									transition: opacity 500ms, visibility 500ms, width 500ms;
								}
								.ng-arrow-wrapper {
									opacity: 1;
									visibility: visible;
									width: auto;
									-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
									transition: opacity 500ms, visibility 500ms, width 500ms;
								}
							}
						}
						.ng-select-container {
							border: 1px solid $input-border-color !important;
							transition: all 0.5s ease;
							.ng-value-container {
								padding-left: 10px;
								padding-right: 5px;
								transition: all 0.5s ease;
							}
							.ng-clear-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
							.ng-arrow-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
						}
					}
					.ng-select-container {
						border: 1px solid transparent !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 0px;
							padding-right: 0px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 0;
							visibility: hidden;
							width: 0px;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
						.ng-arrow-wrapper {
							opacity: 0;
							visibility: hidden;
							width: 0px;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
						&:hover {
							border: 1px solid $input-border-color !important;
							transition: all 0.5s ease;
							// min-width: 208px;
							.ng-value-container {
								padding-left: 10px;
								padding-right: 5px;
								transition: all 0.5s ease;
							}
							.ng-clear-wrapper {
								opacity: 0;
								visibility: hidden;
								width: 0px;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
							.ng-arrow-wrapper {
								opacity: 0;
								visibility: hidden;
								width: 0px;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
						}
						&:focus {
							border: 1px solid $input-border-color !important;
							transition: all 0.5s ease;
							min-width: 208px;
							.ng-value-container {
								padding-left: 10px;
								padding-right: 5px;
								transition: all 0.5s ease;
							}
							.ng-clear-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
							.ng-arrow-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
						}
					}
					&:hover {
						.ng-select-container {
							border: 1px solid $input-border-color !important;
							transition: all 0.5s ease;
							.ng-value-container {
								padding-left: 10px;
								padding-right: 5px;
								transition: all 0.5s ease;
							}
							.ng-clear-wrapper {
								opacity: 0;
								visibility: hidden;
								width: 0px;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
							.ng-arrow-wrapper {
								opacity: 0;
								visibility: hidden;
								width: 0px;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
						}
					}
					&:focus {
						.ng-select-container {
							border: 1px solid $input-border-color !important;
							transition: all 0.5s ease;
							.ng-value-container {
								padding-left: 10px;
								padding-right: 5px;
								transition: all 0.5s ease;
							}
							.ng-clear-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
							.ng-arrow-wrapper {
								opacity: 1;
								visibility: visible;
								width: auto;
								-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
								transition: opacity 500ms, visibility 500ms, width 500ms;
							}
						}
					}
				}
			}
			.status-info {
				gap: 8px;
				display: flex;
				align-items: center;
				.status-color {
					width: 10px;
					height: 10px;
					border-radius: 3px;
				}
			}
			.custom-badge {
				padding: 1px 4px !important;
				border-radius: 4px !important;
				display: flex;
				align-items: center;
				gap: 4px;
			}
		}
		.avatar {
			&.avatar-xs {
				width: 20px;
				height: 20px;
				.avatar-content {
					width: 20px;
					height: 20px;
					line-height: 20px !important;
					img {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
		.icon-badge {
			background-color: rgba($color: $primary, $alpha: 0.12);
			color: $primary;
			font-size: 12px;
			width: 20px;
			height: 20px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.sla-dropdown {
			.sla-info-container {
				.sla-info-item {
					.incident-edit {
					}
				}
			}
		}
		.task-tag-list {
			display: flex;
			align-items: center;
			gap: 8px;
			flex-wrap: wrap;
			.single-tag {
				border-radius: $size-4-px;
				padding: 0px 12px;
			}
		}
		.slash {
			width: 1px;
			height: 20px;
			align-self: center;
			background-color: $border-color;
		}
		.bottom-btn-sec {
			.btn {
				&.btn-sm {
					@include max-mq(1450) {
						font-size: $size-10-px;
						padding: 4px 12px;
						img,
						svg,
						.fa-light,
						i {
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
}
// End of Common Pannel Card //

.compose-form {
	.mail-header {
		background: $body-bg;
	}
	.select2-primary {
		border: none !important;
		// display: grid !important;
		// grid-template-columns: 1fr 0fr !important;
		.mail-subject {
			input {
				// border:1px solid #dedeeb ;
				border: 1px solid transparent !important;
				line-height: 21px !important;
				font-size: 14px;
				outline: none;
				box-shadow: none;
				background: $body-bg;
				transition: all 0.5s ease;
				&:focus {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
				}
				&:hover {
					border: 1px solid $input-border-color !important;
					padding-left: 1rem;
					padding-left: 1rem !important;
					transition: all 0.5s ease;
				}
			}
			// input:focus{
			//     outline: 1px solid #dedeeb;
			//     box-shadow: none;
			// }
		}
		.compose-footer-wrapper {
			border: 1px solid $input-border-color !important;
		}
		.mail-to-address {
			border: 1px solid transparent;
			border-radius: 5px;
			// &:hover{
			//     border:1px solid $input-border-color;
			// }
			// &:focus{
			//     border:1px solid $input-border-color;
			// }
		}
	}
}
.mail-to-address {
	.form-group {
		margin-bottom: 0;
		.ng-select {
			min-width: auto;
			cursor: pointer;
			&.ng-select-focused {
				min-width: 208px;

				&:hover {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}

				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&.ng-select-opened {
				min-width: 208px;
				&:hover {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}

						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}

				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			.ng-select-container {
				border: 1px solid transparent !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 0px;
					padding-right: 0px;
					transition: all 0.5s ease;
					.ng-value {
						background-color: rgb(186 191 199 / 13%) !important;
						border-radius: 13px !important;
						color: $black;

						.ng-value-icon {
							color: $black;
							opacity: 0;
							&:hover {
								opacity: 1;
							}
						}
					}
				}
				.ng-clear-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				&:hover {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					// min-width: 208px;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}

				&:focus {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					min-width: 208px;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&:hover {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&:focus {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
		}
	}
}
.mail-cc {
	.form-group {
		margin-bottom: 0;
		.ng-select {
			min-width: auto;
			cursor: pointer;
			&.ng-select-focused {
				min-width: 208px;

				&:hover {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}

				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&.ng-select-opened {
				min-width: 208px;
				&:hover {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}

						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}

						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}

				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			.ng-select-container {
				border: 1px solid transparent !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 0px;
					padding-right: 0px;
					transition: all 0.5s ease;
					.ng-value {
						background-color: rgb(186 191 199 / 13%) !important;
						border-radius: 13px !important;
						color: $black;

						.ng-value-icon {
							color: $black;
							opacity: 0;
							&:hover {
								opacity: 1;
							}
						}
					}
				}
				.ng-clear-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				&:hover {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					// min-width: 208px;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}

				&:focus {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					min-width: 208px;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&:hover {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&:focus {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
		}
	}
}
.mail-from-address {
	.ng-select {
		min-width: auto;
		cursor: pointer;
		&.ng-select-focused {
			min-width: 208px;

			&:hover {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}

			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		&.ng-select-opened {
			min-width: 208px;
			&:hover {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}

			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}

				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		.ng-select-container {
			border: 1px solid transparent !important;
			transition: all 0.5s ease;
			.ng-value-container {
				padding-left: 0px;
				padding-right: 0px;
				transition: all 0.5s ease;
				.subject-value {
					background-color: rgb(186 191 199 / 13%) !important;
					border-radius: 13px !important;
					color: $black;
					padding: 5px;
				}
			}
			.ng-clear-wrapper {
				opacity: 0;
				visibility: hidden;
				width: 0px;
				-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
				transition: opacity 500ms, visibility 500ms, width 500ms;
			}
			.ng-arrow-wrapper {
				opacity: 0;
				visibility: hidden;
				width: 0px;
				-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
				transition: opacity 500ms, visibility 500ms, width 500ms;
			}

			&:hover {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				// min-width: 208px;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}

				.ng-clear-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				.ng-arrow-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}

			&:focus {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				min-width: 208px;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}

				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		&:hover {
			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		&:focus {
			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
	}
}
.tone-description {
	quill-editor {
		.ql-container {
			height: 175px;
			border: none;
		}
	}
}
.email-quill-editor {
	quill-editor {
		.ql-toolbar {
			border-left: none;
			border-right: none;
			border-bottom: 1px solid $input-border-color !important;
			border-radius: 0;
			background: $body-bg;
		}
		.ql-container {
			border-left: none;
			border-right: none;
		}
	}
}
.seenby-dropdown {
	.seen-user-list {
		position: absolute;
		z-index: 99;
		left: -100%;
		top: 81%;
		width: 100%;
		border-radius: 6px;
		height: 180px;
		display: none;
	}

	&:hover {
		.seen-user-list {
			display: block;
		}
	}
}

.marketplace_modal {
	&.modal-dialog {
		max-width: 60rem;
	}
}

// Market place//
.marketplace-carousal {
	.carousel {
		.carousel-indicators {
			gap: 8px;
			margin: 1rem 0px;
			bottom: -10px;
			button {
				width: 20px;
				height: 6px;
				box-shadow: none !important;
				outline: none !important;
				border: 1px solid transparent;
				border-radius: 4px;
				background: rgba($color: $primary, $alpha: 0.12);
				&.active {
					background-color: $primary;
				}
			}
		}
		.carousel-inner {
			display: flex;
			align-content: center;
			justify-content: center;
			padding: 1rem 0px;
			min-height: 544px;
			.carousel-item {
				width: auto;
				margin: 0px auto;
				border-radius: 20px;
				box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
				max-height: 480px;
				min-height: 480px;
				height: 480px;
				.picsum-img-wrapper {
					border-radius: 20px;
					overflow: hidden;
				}
				.visually-hidden {
					display: none;
				}
			}
		}
	}
}

.vis-timeline {
	border: none !important;
}
.vis-panel.vis-left {
	width: 300px;
	background-color: $white;
}
.vis-item-overflow {
	background: $gray-200;
	border: none;
	border-radius: 8px;
}
.vis-item {
	background-color: $white !important;
	border: none !important;
	border-width: 0px !important;
}
// .vis-time-axis.vis-foreground{
//     background: #E5E6F0;
// }
.vis-major:nth-child(even) {
	width: 100% !important;
	border: 1px solid $border-color !important;
	text-align: center;
	background: $border-color;
}
.vis-panel.vis-center {
	background-color: $white !important;
}
.vis-minor {
	background-color: $white !important;
	border-left: 1px solid $border-color;
}
.vis-inner {
	width: 100% !important;

	display: flex !important;
	justify-content: space-between !important;
}
.no-margin {
	.form-group {
		margin-bottom: 0px !important;
	}
}
// Sort component start
.sortpopover {
	.popover-body {
		padding: 0;
	}
}
// Sort component end

.slider-presentation-mode {
	&.modal {
		.modal-dialog {
			max-width: 100%;
			min-width: 100%;
			margin: 0px !important;
			min-height: 100%;
			max-height: 100%;
			display: grid;
			&::before {
				display: none;
			}
			.modal-content {
				display: flex;
				flex-direction: column;
				height: 100%;
				.modal-header {
					flex: initial;
				}
				.modal-body {
					flex: 1%;
					ngb-carousel {
						&.carousel {
							height: 100%;
							.carousel-indicators {
								width: 100%;
								background: transparent !important;
								display: flex;
								gap: 1rem;
								justify-content: center;
								align-items: center;
								left: 0;
								margin-left: 0;
								button {
									width: 28px;
									height: 6px;
									border: 1px solid rgba($color: $dark-black, $alpha: 0.8);
									background-color: transparent;
									box-shadow: none;
									border-radius: 4px;
									position: relative;
									z-index: 10;
									&.active {
										border: 1px solid $primary;
										background-color: $primary;
									}
								}
							}
							.carousel-inner {
								height: 100%;
								.carousel-item {
									height: 100%;
									.visually-hidden {
										display: none;
									}
									app-dashboard-view {
										height: 100%;
										.full-body-height {
											height: 100% !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.panel-card-aging-timer {
	.aging-timer {
		background-color: transparent !important;
	}
}
// Stock and consumable
.inventory-period {
	.ng-select {
		&.has-value {
			.ng-select-container {
				// background-color: #e4e4e4
			}
		}

		.ng-select-container {
			background-color: #f5f5f5;
			border-color: transparent !important;
			.ng-value-container {
				color: $primary !important;
			}
			.ng-clear-wrapper {
				display: none;
			}
		}

		&.ng-select-focused {
			&:not(.ng-select-opened) {
				.ng-select-container {
					box-shadow: none !important;
				}
			}
		}

		&.ng-select-opened {
			.ng-select-container {
				background-color: #f5f5f5;
				border-color: transparent !important;
			}
		}
	}
}
.consumable-basic-details-body {
	.input {
		// border:1px solid #dedeeb ;
		border: 1px solid transparent !important;
		line-height: 21px !important;
		font-size: 14px;
		outline: none;
		box-shadow: none;
		transition: all 0.5s ease;
		padding-left: 0;
		color: $text-muted;
		&:focus {
			border: 1px solid $input-border-color !important;
			transition: all 0.5s ease;
			box-shadow: none !important;
		}
		&:hover {
			border: 1px solid $input-border-color !important;
			padding-left: 1rem;
			padding-left: 1rem !important;
			transition: all 0.5s ease;
		}
	}
	.ng-select {
		min-width: auto;
		cursor: pointer;
		&.ng-select-focused {
			min-width: 208px;
			&:hover {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		&.ng-select-opened {
			min-width: 208px;
			&:hover {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		.ng-select-container {
			border: 1px solid transparent !important;
			transition: all 0.5s ease;
			.ng-value-container {
				padding-left: 0px;
				padding-right: 0px;
				transition: all 0.5s ease;
				.ng-value {
					color: $text-muted;
				}
			}
			.ng-clear-wrapper {
				opacity: 0;
				visibility: hidden;
				width: 0px;
				-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
				transition: opacity 500ms, visibility 500ms, width 500ms;
			}
			.ng-arrow-wrapper {
				opacity: 0;
				visibility: hidden;
				width: 0px;
				-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
				transition: opacity 500ms, visibility 500ms, width 500ms;
			}
			&:hover {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				// min-width: 208px;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
			&:focus {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				min-width: 208px;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		&:hover {
			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		&:focus {
			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
	}
}
// shift management
.vis-inner {
	.shift-container {
		.shift-header {
			display: flex;
			padding: 5px;
			.shift-title {
				color: blue !important;
				font-family: "Montserrat";
				font-weight: 600;
				font-size: 14px;
				line-height: 21px;
			}
			.shift-count {
				position: absolute;
				right: 1rem;
			}
		}
		.shift-body {
			font-family: "Montserrat";
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			margin-left: 5px;
			color: $gray-400;
		}
		.shift-footer {
			display: flex;
			.shiftTime {
				font-family: "Montserrat";
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 22px;
			}
			.shiftAction {
				position: absolute;
				left: 17rem;
			}
		}
	}
}
.add-user-popover {
	align-self: center;
	&.popover {
		max-width: 420px;
		min-width: 420px;
		&.add-assignee-popover {
			max-width: auto;
			min-width: auto;
		}
		.popover-body {
			padding: 12px 0px;
			max-height: 250px !important;
			.search-info {
				.add-user-label {
					padding: 0px 12px 12px;
					margin-bottom: 12px;
				}
				.input-group {
					margin: 0px 12px;
					width: calc(100% - 24px);
				}
				.single-user-card {
					.media {
						display: flex;
						align-items: center;
						width: 100%;
						.media-body {
							display: flex;
							justify-content: space-between;
							width: 100%;
							align-items: center;
						}
					}
				}
			}
		}
	}
}
// Video List //
.video-list-sec {
	.product-video {
		.product-video-list {
			&.show-all {
				.custom-scrollbar {
					.ng-scrollbar-wrapper {
						position: unset !important;
						.ng-scroll-viewport-wrapper {
							position: unset !important;
							.ng-scroll-viewport {
								position: unset !important;
								display: inline-table;
								.video-list {
									display: flex;
									width: 100%;
									flex-wrap: wrap;
								}
							}
						}
					}
				}
			}
		}
	}
}
.custom-field-popover {
	z-index: 10000;
	.popover-body {
		background: #000000;
		padding: 1rem !important;
	}
}

// IMACD
.basic-details-row {
	.ng-select {
		cursor: pointer;
		&.ng-select-focused {
			min-width: 208px;

			&:hover {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}

			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		&.ng-select-opened {
			min-width: 208px;
			&:hover {
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}

					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}

					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}

			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}

				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		.ng-select-container {
			border: 1px solid transparent !important;
			transition: all 0.5s ease;
			.ng-value-container {
				padding-left: 0px;
				padding-right: 0px;
				transition: all 0.5s ease;
				.ng-value {
					color: $black;
					.ng-value-icon {
						color: $black;
						opacity: 0;
						&:hover {
							opacity: 1;
						}
					}
				}
			}
			.ng-clear-wrapper {
				opacity: 0;
				visibility: hidden;
				width: 0px;
				-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
				transition: opacity 500ms, visibility 500ms, width 500ms;
			}
			.ng-arrow-wrapper {
				opacity: 0;
				visibility: hidden;
				width: 0px;
				-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
				transition: opacity 500ms, visibility 500ms, width 500ms;
			}

			&:hover {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				// min-width: 208px;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}

				.ng-clear-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				.ng-arrow-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}

			&:focus {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				min-width: 208px;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}

				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		&:hover {
			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
		&:focus {
			.ng-select-container {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
				.ng-value-container {
					padding-left: 10px;
					padding-right: 5px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}

				.ng-arrow-wrapper {
					opacity: 1;
					visibility: visible;
					width: auto;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
			}
		}
	}
	.date {
		input {
			// border:1px solid #dedeeb ;
			border: 1px solid transparent !important;
			line-height: 21px !important;
			font-size: 14px;
			outline: none;
			box-shadow: none;
			background: $body-bg;
			padding-left: 0;
			&:focus {
				border: 1px solid $input-border-color !important;
				transition: all 0.5s ease;
			}
			&:hover {
				border: 1px solid $input-border-color !important;
				padding-left: 1rem;
				padding-left: 1rem !important;
				transition: all 0.5s ease;
			}
		}
	}
}
.add-asset-container {
	.asset-details-container {
		.asset-details {
			.comment {
				input {
					border: transparent !important;
					padding-left: 0px;
					transition: all 0.6s ease;
					&:focus {
						box-shadow: none !important;
					}
					&:hover {
						border: 1px solid $input-border-color !important;
						padding-left: 14px;
						transition: all 0.6s ease;
					}
				}
			}
		}
	}
}

.po-side-card {
	&.stage-representation {
		.progress-time {
			position: relative;
			top: 10px;
			.custom-feature {
				.custom-main {
					.custom-row {
						grid-template-columns: repeat(auto-fit, minmax(33px, 1fr));
						.dropdown {
							p.lable {
								font-size: 12px !important;
								display: none;
								white-space: nowrap;
								left: initial;
								top: 20px;
								right: 50%;
								transform: translate(50%, 0%);
							}
							hr {
								position: absolute;
								left: 50%;
								top: -2px;
							}
							.dot {
								margin: 0 auto;
								&.stage-current {
									+ p.lable {
										display: block;
									}
								}
								&.remove-lable {
									+ p.lable {
										display: none;
									}
								}
								&:hover {
									+ p.lable {
										display: block;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.modal-open {
	.sortpopover {
		z-index: 2005;
	}
}

ng2-flatpickr {
	&.error {
		.ng2-flatpickr-input-container {
			.ng2-flatpickr-input {
				&.form-control {
					border-color: $danger !important;
				}
			}
		}
	}
}
// Bot Configuration

.cart-action-container {
	.chart-action-box {
		.new-thread-input {
			.form-control {
				border: 1px solid transparent !important;
				background-color: $body-bg;
				&:focus {
					box-shadow: none !important;
				}
			}
		}
	}
}

.contract-dashboard {
	app-contract-detail-sidebar {
		.full-width-sideout {
			&.modal-dialog {
				width: calc(100% - 63px) !important;
			}
		}
	}
}

// Project Tree Root //
.project-tree-root {
	.tree-wrapper {
		tree-viewport {
			.angular-tree-component {
				tree-node-collection {
					tree-node {
						.tree-node {
							&.tree-node-level-1 {
								tree-node-wrapper {
									.node-wrapper {
										tree-node-expander {
											.toggle-children-wrapper {
											}
										}
										.node-content-wrapper {
											width: 100%;
											tree-node-content {
												display: flex;
												align-items: center;
												gap: 6px;
												.icon-img {
													width: 22px;
													height: 22px;
													min-width: 22px;
													background-color: #e3e3e3;
													border-radius: 4px;
													display: flex;
													align-items: center;
													justify-content: center;
												}
												.add-option {
													position: absolute;
													right: 18px;
													padding: 4px;
												}
											}
										}
									}
								}
								tree-node-children {
									.tree-children {
										overflow: visible;
										tree-node {
											.tree-node {
												tree-node-wrapper {
													&:hover {
														.node-wrapper {
															tree-node-content {
																.edit-section {
																	display: flex;
																}
															}
														}
													}
													.node-wrapper {
														tree-node-content {
															.edit-section {
																display: none;
																gap: 0.5rem;
																position: absolute;
																right: 0;
																justify-content: center;
																align-items: center;
																margin-right: 0.5rem;
																border-radius: 2px;
																padding: 2px 4px;
																box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
																span {
																	padding: 0px 2px;
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.tree-children {
		padding-left: 0;
	}
}
.task-title-popcontent {
	&.popover {
		max-width: 40% !important;
	}
}
.task-popover {
	&.popover {
		min-width: 280px;
		max-width: 360px;
		.popover-body {
			border-radius: $popover-border-radius;
			border-top-color: $popover-border-color;
			padding: 0px;
		}
	}
}
.editcard-popover {
	&.popover {
		&.modal-popover {
			z-index: 2003;
		}
		.popover-body {
			border-radius: $popover-border-radius;
			border-top-color: $popover-border-color;
			padding: 10px;
		}
	}
}
.task-assignee-popover {
	&.editcard-popover {
		&.popover {
			.popover-body {
				padding: 0px;
			}
		}
	}
}
.custom-checklist-checkbox {
	&.custom-checkbox {
		&.single-task-checklist {
			.custom-control-label {
				&.custom-checklist-label {
					font-size: 14px;
				}
			}
		}
		cursor: pointer;
		.custom-control-input {
			&.custom-checklist-input {
				&:checked ~ .custom-control-label {
					&:not(.placeholder-input) {
						text-decoration: line-through;
					}
					&::after {
						background-image: none;
					}
				}
			}
		}
		.custom-control-label {
			&.custom-checklist-label {
				line-height: 19px;
				font-size: 12px;
				font-weight: 500;
				&::before {
					border-radius: 100%;
					width: 16px;
					height: 16px;
				}
				&::after {
					width: 16px;
					height: 16px;
				}
			}
		}
	}
}
.task-custom-datePicker {
	display: flex;
	width: inherit;
	min-width: inherit;
	&.borad-custom-datePicker {
		max-width: 84px;
	}
	.due-datePicker {
		&.custom-datePicker {
			display: flex;
			min-width: inherit;
			width: inherit;
			max-width: inherit;
			.ng2-flatpickr-input-container {
				display: flex;
				min-width: inherit;
				width: inherit;
				max-width: inherit;
				.ng2-flatpickr-input {
					display: flex;
					width: inherit;
					min-width: inherit;
					max-width: inherit;
					border: none !important;
					color: $title-color;
					&:focus-visible {
						border: none !important;
						outline: none !important;
					}
				}
			}
		}
	}
}
.task-img-popover {
	&.task-popover {
		&.popover {
			z-index: 2003;
			.popover-body {
				min-height: 228px;
				padding: 1rem;
				display: grid;
			}
		}
	}
}
.task-due_on {
	.column-icon {
		opacity: 0;
		display: none !important;
	}
	.task-custom-datePicker {
	}
	&.hide-date-input {
		position: relative;
		.column-icon {
			opacity: 1;
			display: flex !important;
		}
		.task-custom-datePicker {
			position: absolute;
			top: 50%;
			left: 0%;
			transform: translate(0%, -50%);
			width: 26px;
			max-width: 26px;
			min-width: 26px;
			border-radius: 100% !important;
			opacity: 0;
			&.due_on-value {
				.custom-datePicker {
					&.due-datePicker {
						.ng2-flatpickr-input-container {
							.flatpickr-input {
								min-width: 26px;
								width: 26px;
								height: 26px;
								max-width: 26px;
								max-height: 26px;
							}
						}
					}
				}
			}
		}
	}
}
// End of Project Tree Root //
// Add New Project Modal //
.add-new-project-modal {
	&.modal {
		.add-new-project {
			&.modal-dialog {
				.modal-content {
					height: 100%;
					overflow: hidden;
					app-add-project-modal {
						height: 100%;
					}
				}
			}
		}
	}
}
// End of Add New Project //
.add-custom-columns-popcontent {
	&.popover {
		.popover-body {
			max-height: 374px;
			min-height: 374px;
			display: grid;
		}
	}
}

// Custom Field Dropdown //
.custom-field-modal {
	&.properties-modal {
		.modal-dialog {
			height: calc(100% - 50px) !important;
			display: grid;
			.modal-content {
				height: 100%;
			}
		}
	}
}
// End Of custom Field Dropdown //
// Task Inline Edit basic info //
.task-basic-information {
	.custom-datePicker {
		width: 100% !important;
		display: grid;
		.ng2-flatpickr-input-container {
			width: 100%;
			.flatpickr-input {
				width: 100%;
				border: none;
				&:focus-visible {
					outline: none !important;
				}
			}
		}
	}
}
// End of Task Inline Edit basic info //

.fixed-layout {
	.ssp-full-height {
		&.self-service-height {
			.full-body-height {
				height: 100% !important;
			}
		}
	}
}
// Feedback
.feedback-period {
	.ng-select {
		&.has-value {
			.ng-select-container {
				// background-color: #e4e4e4
			}
		}
		.ng-select-container {
			background-color: #f5f5f5;
			border-color: transparent !important;
			.ng-value-container {
				color: $primary !important;
			}
			.ng-clear-wrapper {
				display: none;
			}
		}
		&.ng-select-focused {
			&:not(.ng-select-opened) {
				.ng-select-container {
					box-shadow: none !important;
				}
			}
		}
		&.ng-select-opened {
			.ng-select-container {
				background-color: #f5f5f5;
				border-color: transparent !important;
			}
		}
	}
}
.feedbackRaitingpopover {
	min-width: 482px !important;
	min-height: 481px !important;
	border-radius: 5px;
	z-index: 999;
	position: relative;
	.popover-body {
		border: 1px solid transparent;
		padding: 0 !important;
		margin: 0 !important;
		max-height: fit-content !important;
	}
}
.link-asset-model {
	.modal-dialog {
		.modal-content {
			.modal-body {
				.search-section {
					.search {
						.action-search {
							app-search {
								.main-search {
									.inner-search {
										.search-sec {
											min-width: 200px !important;
											.search-modal {
												min-width: 562px !important;
												.inner-modal {
													.advanced-filter {
														.inner-modal-padding {
															.filter-inner {
																.filter-form-group {
																	.filter-form {
																		.advance-filter-main {
																			.filter-condition {
																				.single-condition {
																					grid-template-columns: 1fr 1fr 1fr 36px !important;
																					gap: 12px;
																					.form-group {
																						&.field-input {
																							grid-column: 1/3;
																						}
																						&.conditio-input {
																							grid-column: 3/4;
																						}
																						&.value-input {
																							// grid-column: -1/1;
																							grid-column: 1/4;
																							.form-control {
																								width: 100% !important;
																							}
																						}
																					}
																					.btn-icon-group {
																						grid-column: 4/5;
																						grid-row: 1/2;
																					}
																				}
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.disable-widget-click {
	gridster {
		.gridster-item-container:not(.chart-widget) {
			&::before {
				position: absolute;
				left: 0;
				top: 0;
				background-color: transparent;
				width: 100%;
				height: 100%;
				content: "";
				z-index: 9;
			}
		}
	}
}
.widget-modal-view{
	position: relative;
	&::before {
		position: absolute;
		left: 0;
		top: 0;
		background-color: transparent;
		width: 100%;
		height: 100%;
		content: "";
		z-index: 9;
	}
}
