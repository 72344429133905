//  ================================================================================================
//  File Name: variables-dark.scss
//  Description: Custom dark theme variables
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
//  ================================================================================================

//  ================================================================================================
//  WARNING: PLEASE DO NOT CHANGE THIS VARIABLE FILE.
//  THIS FILE WILL GET OVERWRITTEN WITH EACH VUEXY HTML TEMPLATE RELEASE.
//  TIP:
//  We suggest you to use this (assets/scss/variables/_variables-components.scss) file for overriding components variables.
//  ================================================================================================

// New comman Color For Dark Theme //
// $theme-dark-headings-color: #D0D2D6 !default;  // For Title Color (h1-h6 tag)
// $theme-dark-body-bg: #161d31 !default;  //For body Background Color
// $theme-dark-body-color: #B4B7BD !default;  // For body text
// $theme-dark-text-muted-color: #676d7d !default;  // For Muted Text color
// $theme-dark-border-color: #3B4253 !default; // For all section or div border color
// $theme-dark-input-border-color: #404656 !default; // For form input border color
// $theme-dark-table-header-bg: #343D55 !default; // For Table header Background Color
// $theme-dark-card-bg: #283046 !default; // Card Background Color
$theme-dark-headings-color: #eef0f5 !default; // For Title Color (h1-h6 tag)
$theme-dark-body-bg: #131a2e !default; //For body Background Color
$theme-dark-body-color: #cfd6e5 !default; // For body text
$theme-dark-text-muted-color: #69738d !default; // For Muted Text color
$theme-dark-border-color: #323a4d !default; // For all section or div border color
$theme-dark-input-border-color: #404656 !default; // For form input border color
$theme-dark-table-header-bg: #343d55 !default; // For Table header Background Color
$theme-dark-card-bg: #20293b !default; // Card Background Color
// End Of New comman Color For Dark Theme //

//Body
$theme-dark-custom-control-border-color: #44405e !default;

//Typography
$theme-dark-label-color: #d0d2d6 !default;

//Card
$theme-dark-box-shadow: 0 4px 24px 0 rgba($black, 0.24);

//Input
$theme-dark-input-bg: #283046 !default;
$theme-dark-input-placeholder-color: #606570 !default;
$theme-dark-input-disabled-bg: #24233a !default;
$theme-dark-input-disabled-border-color: #444b60 !default;

// Switch
$theme-dark-switch-bg: #545a6a;
$theme-dark-switch-bg-disabled: #1b2337;

//Table
$theme-dark-table-bg: #283046 !default;
$theme-dark-table-row-bg: #283046 !default;
$theme-dark-table-hover-bg: #242b3d !default;
$theme-dark-table-striped-bg: #242b3d !default;

$theme-dark-modal-header-bg: #161d31 !default;
$theme-dark-pagination-bg: #242b3d !default;
$theme-dark-chart-bg: #384056 !default;
$theme-dark-widget-bg: #384056 !default;
