// ================================================================================================
// 	File Name: fixed-layout.scss
// 	Description: SCSS file for Fixed layout.
// 	----------------------------------------------------------------------------------------------

@import '../bootstrap-extended/include'; // Bootstrap includes
@import '../components/include'; // Components includes

.fixed-layout {
  // Angular Custom Scrollbar //
  .ng-scrollbar {
    &.custom-scrollbar {
      &.section-scrollbar {
        .ng-scrollbar-wrapper {
          .ng-scroll-viewport-wrapper {
            .ng-scroll-content {
              padding-right: 0px;
            }
          }
        }
      }
      .ng-scrollbar-wrapper {
        .ng-scroll-viewport-wrapper {
          .ng-scroll-content {
            padding-right: 12px;
          }
        }
      }
    }
  }
  // End of Angular Custom Scrollbar //

  .vertical-layout {
    .header-navbar {
      border-radius: 0 !important;
      width: calc(100vw - (100vw - 100%) - 64px) !important;
      border-left: 0px !important;
      margin: 0;
      border-top: 0px !important;
      border-right: 0px !important;
      &.admin-navbar{
        width: calc(100vw - (100vw - 100%) - 260px) !important;
      }
      @include max-mq(1200) {
        width: calc(100vw - (100vw - 100%)) !important;
      }
    }
    .app-content {

      padding: #{$navbar-height} 0 0 !important;
      .inner-main-content {
        padding: 0rem 0rem !important;
      }
    }
  }

  .widget-configuration {
    margin-left: -63px !important;
  }
  .action-section {
    border-radius: 0 !important;
  }

  // Table View //
  .table-view {
    position: relative;
    .ngx-datatable {
      .visible {
        .datatable-body {
        }
        .datatable-footer {
          border-radius: 0px !important;
        }
      }
    }
  }
  // End of Table View //
  .action-bar {
    border-radius: 0 !important;
  }
  .card-pagination {
    border-radius: 0 !important;
  }
  // Card View //
  // .card-view {
  //   padding: 1rem;
  // }
  // End of Card View //
  .full-body-height {
    height: calc(100vh - #{$navbar-height});
  }
  .main-content {
    border-radius: 0;
    border-top: none;
    border-left: none;
    &.border-radius-6{
      border-radius: 6px !important;
    }
    .left-sec {
      border-left: 0px !important;
      .side-pannel {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }
  .dark-layout{
    .main-content{
      border-radius: 0;
      border-top: none !important;
      border-left: none !important;
    }
    .left-sec {
      border-left: 0px !important;
    }
  }

  .app-content{
    .inner-main-content{
      .header-navbar-shadow{
        // height: 60px !important;
        // padding-top: 0.5rem !important;
        display: none !important;
      }
    }
  }

  // temp CSS for KB //
  app-kb-list, app-kb-detail{
    .full-body-height{
      padding: $size-14-px;
    }
  }
  // End of temp CSS for KB //
}
