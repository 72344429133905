// Font weight mixins
// ------------------
@mixin font-weight($weight) {
  $weights: (
    thin: 100,
    extra-light: 200,
    ultra-light: 200,
    light: 300,
    normal: 400,
    book: 400,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    demi-bold: 600,
    bold: 700,
    extra-bold: 800,
    ultra-bold: 900,
    heavy: 900,
    black: 900,
    ultra: 900,
    ultra-black: 900,
    extra-ultra: 900
  );

  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }

  font-weight: $output;
}
// Responsive Mixin //
@mixin max-mq($max-screen-size) {
  @media (max-width: ($max-screen-size +'px')) {
    @content;
  }
}
@mixin min-mq($min-screen-size) {
  @media (min-width: ($min-screen-size +'px')) {
    @content;
  }
}
// End of Responsive Mixin //
