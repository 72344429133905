// stylesheet for Telecom_UI changes
@import "./base/bootstrap-extended/variables";
@import "./base/components/variables-dark";

.fw-700 {
	font-weight: 700;
}
// asset add new item sidebar
.custom-stepper-sidebar-content {
	.btn-flat-primary {
		color: $body-color;

		&.active {
			color: $primary;
			background-color: rgba($color: $primary, $alpha: 0.08) !important;
		}
	}
}

.dark-layout {
	.sidebar-menu-list {
		.list-group-item {
			&:hover:not(.active) {
				background-color: $theme-dark-body-bg;
			}
		}

		.list-group {
			.sub-item {
				&:hover:not(.active) {
					background-color: $theme-dark-body-bg !important;
				}
			}
		}
	}

	//Inventory tree tree view design
	.email-app-menu .collapse-icon [data-toggle="collapse"] {
		&:after {
			background-image: url(str-replace(
				str-replace($app-icon-play-dark, "currentColor", $body-color),
				"#",
				"%23"
			)) !important;
		}

		&.active,
		&:focus {
			&:after {
				background-image: url(str-replace(
					str-replace($app-icon-play-primary, "currentColor", $body-color),
					"#",
					"%23"
				)) !important;
			}
		}
	}

	//end Inventory tree tree view design

	// asset add new item sidebar
	.custom-stepper-sidebar-content {
		.btn-flat-primary {
			color: $theme-dark-body-color;

			&.active {
				color: $primary;
			}
		}
	}

	//custom badges
	.cust-badge {
		border-color: $theme-dark-border-color !important;
		span {
			color: $theme-dark-headings-color !important;
		}
	}
	//tab content
	.tab-content {
		border-color: $theme-dark-border-color !important;
	}
	//user media
	.user-sec .user-list .single-user .user-info {
		.user-name,
		.tag-text {
			color: $theme-dark-headings-color !important;
		}
		.user-mail {
			color: $theme-dark-body-color !important;
		}
	}
	.team-sec .team-list .single-team .team-info {
		.team-name {
			color: $theme-dark-headings-color !important;
		}
	}
	.input-group {
		&.custom-input-group,
		&.custom-input-group2 {
			.input-group-text {
				background-color: transparent;
			}
		}
	}

	.sidebar-tree-view-menu {
		.tree-node {
			.node-wrapper {
				&:hover {
					background-color: $theme-dark-body-bg;
				}
			}
			.tree-node-leaf:not(.tree-node-active):hover {
				background-color: $theme-dark-body-bg;
			}

			.toggle-children {
				background-image: url(str-replace(
					str-replace($app-icon-play-dark, "currentColor", $theme-dark-text-muted-color),
					"#",
					"%23"
				)) !important;
			}
			&.has-children-active > tree-node-wrapper {
				.toggle-children {
					background-image: url(str-replace(
						str-replace($app-icon-play-primary, "currentColor", $theme-dark-text-muted-color),
						"#",
						"%23"
					)) !important;
				}
			}
		}
	}
	.table-view
		.bootstrap.core-bootstrap[hidefooter="true"]
		.ngx-datatable
		.visible
		.datatable-body
		datatable-selection
		.datatable-scroll
		.datatable-row-wrapper:nth-child(2n)
		.datatable-body-row
		.datatable-body-cell {
		background-color: $theme-dark-card-bg !important;
	}

	// custom tag
	.custom-tags-container {
		.custom-tag,
		.custom-tag-rounded {
			border-color: $theme-dark-input-border-color;
			color: $theme-dark-headings-color;
		}
	}
}

.sidebar-tree-view-menu {
	.tree-node {
		.node-drop-slot:empty {
			height: 0;
		}
		.node-wrapper {
			padding: 0.5rem 1rem 0.5rem 1.75rem !important;
			position: relative;
		}

		.toggle-children-placeholder {
			display: none;
		}

		.toggle-children-wrapper {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			padding: 0.5rem 1rem;
		}

		.node-wrapper:hover {
			background-color: $body-bg;
		}

		.tree-node.tree-node-level-2:not(.tree-node-leaf) {
			.node-content-wrapper {
				padding: 0.5rem 1rem 0.5rem 2.85rem !important;
			}

			.toggle-children-wrapper {
				padding: 0.5rem 1rem 0.5rem 1.75rem;
			}
		}
		.tree-node.tree-node-level-3:not(.tree-node-leaf) {
			.toggle-children-wrapper {
				padding: 0.5rem 1rem 0.5rem 4rem;
			}
		}

		.tree-node .tree-node.tree-node-leaf .node-wrapper .node-content-wrapper {
			padding: 0.5rem 1rem 0.5rem 3rem !important;
		}

		.tree-node .tree-node .tree-node .tree-node.tree-node-leaf .node-wrapper .node-content-wrapper {
			padding: 0.5rem 1rem 0.5rem 2.85rem !important;
		}
		.tree-node .tree-node .tree-node.tree-node-leaf .node-wrapper .node-content-wrapper {
			padding: 0.5rem 1rem 0.5rem 3.5rem !important;
		}

		.tree-node.tree-node-level-4:not(.tree-node-leaf) {
			.toggle-children-wrapper {
				padding: 0.5rem 1rem 0.5rem 5rem;
			}
		}

		.tree-node .tree-node .node-wrapper {
			padding: 0 !important;

			.node-content-wrapper {
				width: 100%;
				padding: 0.5rem 1rem 0.5rem 4.5rem !important;
			}
		}
		.tree-node .tree-node .node-wrapper .node-content-wrapper {
			width: 100%;
			padding: 0.5rem 1rem 0.5rem 5.2rem !important;
		}

		.tree-node .tree-node .tree-node .node-wrapper .node-content-wrapper {
			width: 100%;
			padding: 0.5rem 1rem 0.5rem 6.2rem !important;
		}
		.tree-node .node-wrapper {
			padding: 0 !important;

			.node-content-wrapper {
				width: 100%;
				padding: 0.5rem 1rem 0.5rem 2.75rem !important;
			}
		}
		&.has-children-active > tree-node-wrapper .node-wrapper {
			position: relative;
			background-color: rgba($primary, 12%);
			&:before {
				position: absolute;
				content: "";
				width: 2px;
				height: 100%;
				background-color: $primary;
				border-radius: 1.5px;
				left: 0;
				top: 0;
			}
		}
		.tree-node-active:not(.tree-node-expanded) {
			background-color: rgba($primary, 12%);
			position: relative;
			.node-wrapper {
				background: transparent;
			}
			&:before {
				position: absolute;
				content: "";
				width: 2px;
				height: 100%;
				background-color: $primary;
				border-radius: 1.5px;
				left: 0;
				top: 0;
				z-index: 2;
			}
		}
	}
}

.badge-icon-square {
	height: 16px;
	width: 16px;
	font-size: 0.714rem;
	border-radius: 4px;
	display: inline-flex !important;
	align-items: center;
	justify-content: center;
	color: $primary;
	background-color: rgba($primary, 12%);
	font-weight: 400 !important;
}
.badge-round {
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	border-radius: 100%;
}

.custom-input-group2 {
	.form-control,
	.ng2-flatpickr-input,
	.ng-select .ng-select-container {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		padding-left: 0;
		border-left: none;
	}
}

.table-grid-top-bar,
.table-grid-top-bar.selected-data {
	margin: 0 !important;
	border-radius: 0 !important;
}

// last row border hidden if table doesn't have footer
.table-view
	.bootstrap.core-bootstrap[hidefooter="true"]
	.ngx-datatable
	.visible
	.datatable-body
	datatable-selection
	.datatable-scroll
	.datatable-row-wrapper:last-of-type
	.datatable-body-row
	.datatable-body-cell {
	border-bottom: none !important;
	margin-bottom: -5px;
}

.table-view
	.bootstrap.core-bootstrap[hidefooter="true"]
	.ngx-datatable
	.visible
	.datatable-body
	datatable-selection
	.datatable-scroll
	.datatable-row-wrapper:nth-child(2n)
	.datatable-body-row
	.datatable-body-cell {
	background-color: $white;
}

.matrix-info-tooltip-high-index {
	z-index: 2005;
}

.popover {
	z-index: 2001;
}

.matrix-info-tooltip-main {
	&.popover.bs-popover-auto[x-placement^="bottom"] .arrow:after,
	&.popover.bs-popover-bottom .arrow:after {
		border-bottom-color: #fff;
	}
}
.pos-popover {
	&.popover.bs-popover-auto[x-placement^="bottom"] .arrow:after,
	&.popover.bs-popover-bottom .arrow:after {
		border-bottom-color: #fff;
	}
}

// custom tags
.custom-tags-container {
	display: flex;
	flex-wrap: wrap;
	margin-left: -4px;
	margin-right: -4px;
	.custom-tag {
		margin: 4px;
		margin-right: 12px;
		margin-left: 12px;
		border: 1px solid $border-color;
		background-color: transparent;
		color: $title-color;
	}
	.custom-tag-rounded {
		margin: 4px;
		border: 1px solid $border-color;
		background-color: transparent;
		color: $title-color;
		border-radius: 20px;
	}
}

.main-content.custom-inner-main-content {
	border: none;
}

.main-content.custom-inner-main-content {
	border: none;
}

.custom-split-btn-group.btn-group
	.btn[class*="btn-"]:not([class*="btn-outline-"]):not([class*="btn-flat-"]):not([class*="btn-gradient-"]):not([class*="btn-relief-"]).dropdown-toggle {
	border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
	padding: 10px 1rem;
}

.collapse-icon .ng-input {
	&[aria-expanded="false"]:after,
	&[aria-expanded="true"]:after {
		display: none;
	}
}

// remove overflow from accordion cards
.accordion > .card {
	overflow: unset !important;
}

.modal-body-inner-footer {
	.modal-footer {
		margin-left: -1.4rem;
		margin-right: -1.4rem;
	}
}

.pricing-plan-modal {
	max-width: 1320px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 1rem;
	.modal-content {
		background: $body-bg;
	}
	.modal-header {
		background: transparent;
	}
}

.admin-user-page-header .ng-select.form-control {
	background-color: $white !important;
}
//incident print
@media print {
	.app-content.content {
		margin-left: 0px !important;
		padding-top: 0px !important;
	}
	.incident-print-sidebar,
	.header-navbar-shadow,
	.main-menu,
	.header-navbar.navbar,
	.header-navbar-shadow,
	.incident-print-page .action-bar {
		display: none !important;
	}

	html body .full-body-height,
	html body .content,
	.vertical-layout .app-content.content {
		height: auto !important;
		min-height: auto !important;
	}
}
.app-touchspin {
	.touchspin-wrapper {
		align-items: center;
		.btn-touchspin {
			top: 0px !important;
			svg {
				margin-right: 0px;
			}
		}
		.form-control {
			border: 1px solid $border-color !important;
		}
	}
}
.self-service-height {
	height: calc(100vh - 4rem - 3.35rem);
}
.self-service-main-container {
	width: 100%;
	// padding-right: 1rem;
	// padding-left: 1rem;
	padding-top: 1.75rem;
	padding-bottom: 1.75rem;
	margin-right: auto;
	margin-left: auto;
	@include min-mq(576) {
		max-width: 576px;
	}
	@include min-mq(768) {
		max-width: 768px;
	}
	@include min-mq(992) {
		max-width: 992px;
	}
	@include min-mq(1200) {
		max-width: 1200px;
	}
	@include min-mq(1440) {
		max-width: 1440px;
	}
	@include max-mq(416) {
		padding-bottom: 2.75rem;
	}
	@include max-mq(1200){
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

.submit-ticket-sidebar-modal .modal-slide-in .modal-dialog.sidebar-md {
	@include max-mq(876) {
		width: 100%;
		max-width: 30rem;
	}
}

.card.collapse-margin.custom-collapse-card {
	border: none !important;
	box-shadow: none !important;
}

.ticket-quill-container .ql-container {
	min-height: 250px;
}

// dark layout style start

.dark-layout {
	.text-card {
		.customwidthdiv{
			border-right:1px solid $theme-dark-border-color !important;
		}
	}
	.sidebar-menu-list {
		.list-group-messages {
			.list-group-item {
				&.all-categories {
					&.list-group-item-action {
						&:active {
							color: $theme-dark-headings-color !important;
						}
					}
				}
				&:hover:not(.active) {
					background-color: $theme-dark-body-bg;
				}
			}
		}

		.list-group {
			.sub-item {
				&:hover:not(.active) {
					background-color: $theme-dark-body-bg !important;
				}
			}
		}
	}

	//Inventory tree tree view design
	.email-app-menu .collapse-icon [data-toggle="collapse"] {
		&:after {
			background-image: url(str-replace(
				str-replace($chevron-down, "currentColor", $theme-dark-body-color),
				"#",
				"%23"
			)) !important;
		}

		&.active,
		&:focus {
			&:after {
				background-image: url(str-replace(
					str-replace($app-icon-play-primary, "currentColor", $body-color),
					"#",
					"%23"
				)) !important;
			}
		}
	}

	//end Inventory tree tree view design

	// asset add new item sidebar
	.custom-stepper-sidebar-content {
		.btn-flat-primary {
			color: $theme-dark-body-color;

			&.active {
				color: $primary;
			}
		}
	}

	//custom badges
	.cust-badge {
		border-color: $theme-dark-border-color !important;
		span {
			color: $theme-dark-headings-color !important;
		}
	}
	//tab content
	.tab-content {
		border-color: $theme-dark-border-color !important;
	}
	//user media
	.user-sec .user-list .single-user .user-info {
		.user-name,
		.tag-text {
			color: $theme-dark-headings-color !important;
		}
		.user-mail {
			color: $theme-dark-body-color !important;
		}
	}
	.team-sec .team-list .single-team .team-info {
		.team-name {
			color: $theme-dark-headings-color !important;
		}
	}

	.input-group {
		&.custom-input-group,
		&.custom-input-group2 {
			.input-group-text {
				background-color: transparent;
			}
		}
	}

	.sidebar-tree-view-menu {
		.tree-node {
			.node-wrapper {
				&:hover {
					background-color: $theme-dark-body-bg;
				}
			}
			.tree-node-leaf:not(.tree-node-active):hover {
				background-color: $theme-dark-body-bg;
			}

			.toggle-children {
				background-image: url(str-replace(
					str-replace($app-icon-play-dark, "currentColor", $theme-dark-text-muted-color),
					"#",
					"%23"
				)) !important;
			}
			&.has-children-active > tree-node-wrapper {
				.toggle-children {
					background-image: url(str-replace(
						str-replace($app-icon-play-primary, "currentColor", $theme-dark-text-muted-color),
						"#",
						"%23"
					)) !important;
				}
			}
		}
	}
	// .table-view
	// 	.bootstrap.core-bootstrap[hidefooter="true"]
	// 	.ngx-datatable
	// 	.visible
	// 	.datatable-body
	// 	datatable-selection
	// 	.datatable-scroll
	// 	.datatable-row-wrapper:nth-child(2n)
	// 	.datatable-body-row
	// 	.datatable-body-cell {
	// 	background-color: $theme-dark-card-bg !important;
	// }

	// // custom tag
	// .custom-tags-container {
	// 	.custom-tag,
	// 	.custom-tag-rounded {
	// 		border-color: $theme-dark-input-border-color;
	// 		color: $theme-dark-headings-color;
	// 	}
	// }

	// &.bordered-layout .main-content .left-sec .side-pannel .sidebar .collapse-default .card {
	// 	border-bottom: 1px solid $theme-dark-border-color !important;
	// }
	// .collapse-icon [aria-expanded="false"]:after,
	// .collapse-icon [aria-expanded="true"]:after {
	// 	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaBAMAAABbZFH9AAAAG1BMVEUAAACRkZGRkZGSkpKRkZGSkpKSkpKRkZGRkZHLso+9AAAACHRSTlMA+1JoWo0vLFQDmmkAAABlSURBVBjTY6ALSACTbBAOazOYsggAUxEdBkCSuaMVxGGX6BABUo4djQUgrmJHhwFQqkMIrJJJoqOZwaKjUQHIhkg6g6QggEWiQ7Cj0QHIgkpCpaA6wbrgkiAphKSgArJTXRhoBgB9GRPswyvBqAAAAABJRU5ErkJggg==");
	// }
}

.performance-chart-wrapper {
	.performance-overlay {
		background-color: rgba($color: #000000, $alpha: 0.7) !important;
		.btn {
			border: 1px solid $primary !important;
		}
	}
}

.asignee-user-form-group {
	.ng-select:not(.ng-select-opened):not(:hover) .ng-select-container.ng-has-value {
		border-color: transparent !important;
		.ng-arrow-wrapper,
		.ng-clear-wrapper {
			display: none !important;
			opacity: 0 !important;
		}
	}
	.ng-select .ng-select-container.ng-has-value {
		.ng-value .avatar-group {
			flex-direction: row-reverse;
			align-items: center;
			.avatar {
				margin-right: 0.5rem !important;
			}
		}
	}
}
.dark-layout {
	.incident-header {
		.inciden-list {
			ul {
				li {
					&::after {
						background: $theme-dark-body-bg !important;
					}
				}
			}
		}
	}
	.work-space {
		.right-pannel {
			.bottom-input {
				border-top-color: $theme-dark-border-color !important;
				// input{
				// 	background-color: $theme-dark-card-bg !important;
				// 	border-color: $theme-dark-border-color !important;
				// }
			}
		}
		.text-card {
			.discovery-card {
				background-color: $theme-dark-body-bg !important;
			}
			.small-card {
				background-color: $theme-dark-body-bg !important;
			}
		}
		.incident-card {
			.item {
				background-color: $theme-dark-body-bg !important;
				border-left-color: $theme-dark-body-bg !important;
			}
		}
		.incident-card,
		.discovery-card,
		.small-card,
		.dropdown {
			border-color: $theme-dark-border-color !important;
		}
		.border-line {
			&::after {
				border-color: $theme-dark-border-color !important;
			}
		}
	}
}
.workspace-apx-chart {
	apx-chart > div {
		display: flex;
		justify-content: center;
	}
}
.workspace-apx-chart {
	.apexcharts-canvas {
		.apexcharts-text {
			fill: #ff9f43 !important;
			font-weight: 500 !important;
		}
	}
}
.dark-layout {
	.media-body{
		.commented-detail{
			.commented-by{
			  a{
				color:$theme-dark-headings-color !important;
			}
		  }
		}
	}
	.vertical-layout{
		.header-navbar{
			&.self-service-navbar {
				.self-service-nav {
					.navbar-collapse{
						.navbar-nav{
							.nav-item{
								.nav-link{
									color:$theme-dark-headings-color !important;
								}
							}
						}
					}
				}
			}
		}
	}

	// ng-select{
	// 	.right-arrow{
	// 		&.custom-text{
	// 			background-color: $theme-dark-body-bg !important;
	// 		}
	// 	}
	// }
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .cell-line-height p {
		color:$theme-dark-headings-color !important;
	}
	.q-marker{
		background-color: $theme-dark-border-color !important;
		color:$theme-dark-headings-color !important;
	}
	.dropdown-menu{
		&.country-dropdown{
			.search-container{
				&:focus-visible{
					background-color:  $theme-dark-body-bg !important;
				}
			}
			&.show{
				background-color: $theme-dark-border-color !important;
			}
		}
		#country-search-box{
			border-bottom:1px solid $theme-dark-border-color !important;
		}
	}
	.iti__country-list {
		background-color:  $theme-dark-body-bg !important;
		.iti__country-name{
			color:$theme-dark-headings-color !important;
		}
		.iti__divider{
			border-bottom: 1px solid $theme-dark-border-color !important;
		}
	}
	#country-search-box{
		background-color:  $theme-dark-body-bg !important;
	}
	.vertical-layout {
		p {
			span{
				color:$theme-dark-headings-color !important;
			}
		}
	}
	.popover {
		box-shadow: 0px 0px 2px 2px $theme-dark-card-bg;
		.popover-header {
			color: $theme-dark-body-color !important;
		}
		// .popover-body {
		// 	padding: 0 !important;
		// }
	}
}
.input-tree-dropdown {
	.tree-dropdown {
		&.single-select {
			.dropdown {
				.dropdown-toggle {
					border-color: #dedeeb !important;
					background-color: transparent !important;
					word-break: break-word;
					white-space: break-spaces;
					font-weight: 400;
					line-height: 1rem;
					color: #23232f;
					padding-left: 0.714rem !important;
					max-height: none;
					min-height: 36px;
				}
			}
		}
	}
}
.summary-card,
.question-card {
	.accordion {
		.btn {
			justify-content: left !important;
		}
		.card {
			.card-header {
				button {
					width: auto !important;
					&:focus {
						border: none !important;
						outline: none !important;
						box-shadow: none !important;
					}
					.number {
						width: 2rem;
						height: 2rem;
						border-radius: 100%;
						background-color: $primary;
						margin-right: 1rem;
						color: $white !important;
						line-height: 2rem;
						text-align: center !important;
						font-weight: 600 !important;
					}
				}
			}
		}
	}
}
.selection-barchart {
	.apexcharts-canvas {
		margin: 0 auto;
	}
}
.question-card {
	.accordion > .card {
		margin-bottom: 20px !important;
		border: 1px solid $border-color !important;
		border-bottom: 1px solid $border-color !important;
		border-bottom-right-radius: 6px !important;
		border-bottom-left-radius: 6px !important;
		border-top-left-radius: 6px !important;
		border-top-right-radius: 6px !important;
	}
}
.escalation-data {
	.badge {
		&.priority {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}
}

.text-decoration-underline {
	text-decoration: underline;
}

.text-decoration-underline {
	text-decoration: underline;
	&:hover {
		text-decoration: underline;
	}
}

.accordion-item {
	position: relative;
    display: block;
    width: 100%;
    height: auto;
 //   background-color: #FFF;
    padding: 12px 12px 4px;
    margin: 0 auto 15px auto;
	//border: 1px solid $border-color !important;
    border-radius: 5px;
    cursor: pointer;
}
.accordion-button {
    // font-weight: 500;
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    font-size: 15px;
    letter-spacing: .01em;
    cursor: pointer !important;
    background: transparent;
    border: none;
    text-align: left;
}
.accordion-body {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 12px 0px 20px;
    cursor: pointer;
}

.accordion-button{

		&::after{
			position: absolute;
			top: 14px !important;
			transition: all 0.3s ease-in-out;
			// font-size: 18px;
		}
	}

@media (min-width: 576px){
	.form-inline{
		.form-select, .input-group{
			width: auto;
		}
	} 
}
.wrap-text-around{
	white-space: normal !important;
    word-break: break-all;
}

@media (max-width: 576px){
	.main-card-view {
		.card-view {
			.inner-card-view {
				grid-template-columns: 100% !important;
			}
		}
	}
}
.row-highlight{
	.datatable-body-cell{
		background-color:#0d51d91f !important ;
	}
} 
.dndDropArea{
	.dragPointer{
		h2, h5{
			word-break: break-all !important;
		}
	}
}

.ql-tooltip.ql-editing { 
	left: 10px !important;
}

textarea{
	height: 36px;
    display: block;
    padding: 0.438rem 1rem;
    border: 1px solid rgb(222, 222, 235);
    border-radius: 0.357rem;
    width: 100%;
    min-height: 36px !important;
}
.quill-tree-view{
	.dropdown{
			.dropdown-toggle{
					min-width: 220px;
					height: 26px;
					font-weight: 500;
					font-family: $font-family-sans-serif;
					margin-bottom: 2px;
					padding-left: 10px;
			}
			.dropdown-menu{  
				font-family: $font-family-sans-serif;          
			    .dropdown-container {
			      font-family: inherit;
						.treeview-container{
							max-height: 125px !important;     
							.form-check-label{
								padding-left: 0 !important;
							}                                 
						}
			    }
			}
	}    
}
body:not(.dark-layout){
	.tree-dropdown{
		.dropdown {
			.btn.dropdown-toggle:after{				
				background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'><polyline points='6 9 12 15 18 9'></polyline></svg>");
			}
		}
	} 
}
.custom-date-picker{
	.ngb-dp-month{
		width: 100%;
		.ngb-dp-week{
			justify-content: space-around;
		}
	}
}
.ng-select.team-select{
  .ng-option{
    .hover_icon{
      display: none;
    }
    &:hover{
      .hover_icon{
        display: block !important;
      }
    }
		&.ng-option-disabled{
			color: #000000de !important;
		}
  }
  .ng-value{
		.hover_icon{
      display: none;
    }
	}
  
}
.level_badge{
  border: 1px solid $primary;
	border-radius: 4px;
	color: $primary;
	font-size: $size-12-px;
	padding: 0 $size-8-px;
}
.ql-tooltip.ql-editing {
  left: 10px !important;
} 
.email-scroll-area{
	 .mail-message{
		 >a{
			 display: block;
			 margin-bottom: 10px;
			 &+ table {
				a{
					 color: $white;
					 display: inline-block; 
					 padding: 2px 5px;
					}
				}
			}
			p{
				img{
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}
	.file-upload-modal{
		.img-crop-modal{
			.img-cropp-sec{
	 			.image-cropper {
					width: auto !important;
				}
			}
		}
	}
	.dark-layout{
		.round-chart{
		text{
			 fill: $white;
			}
		}
	}
	.dark-layout{
		.flatpickr-monthDropdown-months {
			 background: $theme-dark-body-bg !important;
		 }
	}

	.main-content .main-sec .action-bar{
		.btn-group{
			.btn-icon:nth-last-of-type(1) {
				border-top-right-radius: $size-4-px !important;
				border-bottom-right-radius: $size-4-px !important;
			}
		}
	}
	.card-achieved {
		background: linear-gradient(118deg,#7367f0,rgba(115,103,240,.7));
		color: #fff;
	}
	#ngb-live{
		display: none !important;
	}
	.set-color:hover {
		color: blue;
	}

	.dark-layout {
		.ng-select-container {
			background-color: $theme-dark-card-bg !important;
		}
	}
	.requester-modal{
		.modal-dialog{
			display: flex;
			flex-direction: column;
			.modal-content{
				flex: 1%;
				display: grid;
				.slideout-content{
					display: grid;
				}
			}
		}
	}