// ================================================================================================
// 	File Name: dark-layout.scss
// 	Description: SCSS file for dark layout.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

@import "../bootstrap-extended/include";
// Bootstrap includes
@import "../components/include"; // Components includes

.dark-layout {
	.bg-muted {
		background-color: $theme-dark-text-muted-color;
	}

	.bg-body {
		background-color: $theme-dark-body-bg;
	}

	.card-bg {
		background-color: $theme-dark-card-bg !important;

		&.form-control {
			background-color: $theme-dark-card-bg !important;
		}
	}

	.cls-5 {
		fill: $theme-dark-card-bg !important;
	}

	.cls-8 {
		fill: $theme-dark-label-color !important;
	}

	.cls-6 {
		stroke: $theme-dark-border-color !important;
	}

	.bg-card {
		background-color: $theme-dark-card-bg !important;

		&.form-control {
			background-color: $theme-dark-card-bg !important;
		}
	}

	.contrast-txt {
		color: $white !important;
	}

	.title-color {
		color: $theme-dark-headings-color !important;
	}

	.body-color {
		color: $theme-dark-body-color !important;
	}

	.text-muted-color {
		color: $theme-dark-text-muted-color !important;
	}

	.bg-body {
		background-color: $theme-dark-body-bg;
	}

	@at-root body#{&} {
		color: $theme-dark-body-color;
		background-color: $theme-dark-body-bg;
	}
	body {
		color: $theme-dark-body-color;
		background-color: $theme-dark-body-bg;
	}

	// universal elements
	//heading tags
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $theme-dark-headings-color;
	}

	.head1,
	.head2,
	.head3,
	.head4,
	.head5,
	.head6 {
		color: $theme-dark-headings-color !important;
	}

	.reg-txt,
	.sm-txt {
		color: $theme-dark-body-color !important;
	}

	.vertical-layout {
		.small,
		small {
			&.text-danger {
				color: $danger !important;
			}
		}
	}

	// border color

	.border {
		border: 1px solid $theme-dark-border-color !important;

		&.border-0 {
			border: 0 !important;
		}
	}

	.border-top {
		border-top: 1px solid $theme-dark-border-color !important;

		&.border-0 {
			border: 0 !important;
		}
	}

	.border-right {
		border-right: 1px solid $theme-dark-border-color !important;

		&.border-0 {
			border: 0 !important;
		}
	}

	.border-bottom {
		border-bottom: 1px solid $theme-dark-border-color !important;

		&.border-0 {
			border: 0 !important;
		}
	}

	.border-left {
		border-left: 1px solid $theme-dark-border-color !important;

		&.border-0 {
			border: 0 !important;
		}
	}

	// other tags
	a {
		&:hover {
			color: $primary;
		}
	}

	label {
		color: $theme-dark-label-color !important;
	}

	.btn span {
		color: inherit;
	}

	hr {
		border-color: $theme-dark-border-color;
	}

	pre {
		background-color: $theme-dark-body-bg !important;
		border: 0;

		code {
			background-color: inherit;
			text-shadow: none;

			// .operator,
			.url {
				background-color: $theme-dark-body-bg;
			}
		}
	}

	code {
		background-color: $theme-dark-body-bg;
	}

	kbd {
		background-color: $theme-dark-body-bg;
	}

	// for dark text
	.text-dark {
		color: $theme-dark-headings-color !important;
	}

	.text-muted {
		color: $theme-dark-text-muted-color !important;
	}

	.text-body {
		color: $theme-dark-body-color !important;
	}

	.text-body-heading {
		color: $theme-dark-headings-color;
	}

	.section-label {
		color: $theme-dark-text-muted-color;
	}

	// shadow
	.shadow {
		box-shadow: $theme-dark-box-shadow !important;
	}

	// blockquote footer
	.blockquote-footer {
		color: $theme-dark-text-muted-color;
	}

	.ng-select {
		background-color: $theme-dark-input-bg !important;
		&.ng-select-filtered {
			.ng-select-container {
				.ng-value-container {
					.ng-input {
						input {
							color: $theme-dark-body-color !important;
						}
					}
				}
			}
		}
		&.ng-select-multiple {
			.ng-select-container {
				.ng-value-container {
					.ng-input {
						input {
							color: $theme-dark-body-color !important;
						}
					}
				}
			}
		}
		.ng-select-container {
			border: 1px solid $theme-dark-input-border-color !important;
			background-color: $theme-dark-input-bg !important;
		}

		.ng-dropdown-panel {
			background-color: $theme-dark-card-bg !important;

			.ng-dropdown-panel-items {
				.ng-option-disabled {
					background-color: $theme-dark-card-bg !important;
				}
			}
		}

		&.form-control {
			&.ng-select-disabled {
				.ng-select-container {
					background-color: $theme-dark-card-bg !important;
				}
			}
		}
	}
	ng-dropdown-panel {
		&.ng-dropdown-panel {
			background-color: $theme-dark-card-bg !important;
			.ng-dropdown-panel-items {
				.ng-option {
					background-color: $theme-dark-card-bg !important;
					color: $theme-dark-body-color !important;
					&.ng-option-selected {
						background-color: rgba($color: $primary, $alpha: 0.2) !important;
						color: $primary !important;
						&.ng-option-marked {
							background-color: rgba($color: $primary, $alpha: 0.2) !important;
							color: $primary !important;
							span {
								color: $primary !important;
							}
						}
						span {
							color: $primary !important;
						}
					}
					&.ng-option-marked {
						background-color: rgba($color: $primary, $alpha: 0.12) !important;
						color: $theme-dark-headings-color !important;
						span {
							color: $theme-dark-headings-color !important;
						}
						small {
							color: $theme-dark-headings-color !important;
						}
					}
					span {
						color: $theme-dark-body-color !important;
					}
					&.ng-option-marked {
						background-color: rgba($color: $primary, $alpha: 0.12) !important;
						color: $theme-dark-headings-color !important;
						small {
							color: $theme-dark-headings-color !important;
						}
					}
					&.ng-option-disabled {
						background-color: $theme-dark-card-bg !important;
					}
				}
			}
		}
	}
	// End of Form Input Components //
	// main navbar and header navbar shadow(generic navbar)
	.header-navbar-shadow {
		background: linear-gradient(
			180deg,
			rgba(22, 29, 49, 0.9) 44%,
			rgba(22, 29, 49, 0.43) 73%,
			rgba(22, 29, 49, 0)
		) !important;
		z-index: 11;
	}

	// main header navbar dark style

	.horizontal-layout {
		.header-navbar {
			background-color: $theme-dark-card-bg !important;
		}

		&.navbar-sticky,
		&.navbar-static {
			.header-navbar {
				background-color: $theme-dark-body-bg !important;
			}
		}
	}

	.navbar-sticky {
		.header-navbar {
			&.navbar-shadow {
				box-shadow: 0 4px 24px 0 rgba($black, 0.75);
			}
		}
	}
	.vertical-layout{
		.header-navbar {
			// &[class*=bg-]{
	
			// }
			&.bg-transparent{
				background-color: $theme-dark-card-bg !important;
			}
			&.bg-card {
				background-color: $white !important;
				.navbar-container {
					.nav {
						.nav-item {
							.nav-link {
								color: $title-color !important;
								background-color: transparent;
	
								i,
								svg {
									color: $title-color !important;
								}
	
								&.bookmark-star {
									i,
									svg {
										color: $title-color !important;
									}
								}
	
								h5.page-title {
									color: $title-color !important;
								}
	
								.bookmark-btn {
									color: $primary !important;
								}
	
								.user-nav {
									.title-color {
										color: $title-color !important;
									}
								}
							}
							a{
								color: $title-color !important;
							}
							.search-input-icon {
								color: $title-color !important;
	
								&:hover {
									color: $primary !important;
								}
							}
						}
	
						li i.ficon,
						li svg.ficon {
							color: $title-color !important;
						}
					}
				}
			}
			&.bg-white {
				background-color: $white !important;
				.navbar-container {
					.nav {
						.nav-item {
							.nav-link {
								color: $title-color !important;
								background-color: transparent;
	
								i,
								svg {
									color: $title-color !important;
								}
	
								&.bookmark-star {
									i,
									svg {
										color: $title-color !important;
									}
								}
	
								h5.page-title {
									color: $title-color !important;
								}
	
								.bookmark-btn {
									color: $primary !important;
								}
	
								.user-nav {
									.title-color {
										color: $title-color !important;
									}
								}
							}
							a{
								color: $title-color !important;
							}
							.search-input-icon {
								color: $title-color !important;
	
								&:hover {
									color: $primary !important;
								}
							}
						}
	
						li i.ficon,
						li svg.ficon {
							color: $title-color !important;
						}
					}
				}
			}
			&:not([class*="bg-"]):not([class*="card-bg"]) {
				// background-color: $theme-dark-card-bg !important;
				.navbar-container {
					.nav {
						.nav-item {
							.nav-link {
								color: $title-color !important;
								background-color: transparent;
	
								i,
								svg {
									color: $title-color !important;
								}
	
								&.bookmark-star {
									i,
									svg {
										color: $title-color !important;
									}
								}
	
								h5.page-title {
									color: $title-color !important;
								}
	
								.bookmark-btn {
									color: $primary !important;
								}
	
								.user-nav {
									.title-color {
										color: $title-color !important;
									}
								}
							}
	
							.search-input-icon {
								color: $title-color !important;
	
								&:hover {
									color: $primary !important;
								}
							}
						}
	
						li i.ficon,
						li svg.ficon {
							color: $title-color !important;
						}
					}
				}
			}
	
			.navbar-container {
				.nav {
					.nav-item {
						.nav-link {
							color: $theme-dark-headings-color;
							background-color: transparent;
	
							i,
							svg {
								color: $theme-dark-headings-color;
							}
	
							&.bookmark-star {
								i,
								svg {
									color: $white !important;
								}
							}
						}
	
						&.nav-search {
							.search-input {
								&.open {
									background-color: $theme-dark-card-bg;
	
									.input {
										border-color: $theme-dark-border-color;
									}
	
									.input,
									.input::placeholder,
									.search-input-close {
										color: $theme-dark-body-color;
									}
								}
							}
						}
	
						.bookmark-input {
							background-color: $theme-dark-card-bg;
	
							.form-control {
								background-color: $theme-dark-card-bg;
							}
						}
	
						.search-list {
							background-color: $theme-dark-card-bg;
	
							li {
								a {
									color: $theme-dark-body-color;
								}
							}
	
							.auto-suggestion.current_item {
								background-color: $theme-dark-body-bg;
							}
						}
					}
	
					li i.ficon,
					li svg.ficon {
						color: $theme-dark-body-color;
					}
	
					.dropdown-cart,
					.dropdown-notification {
						.dropdown-menu.dropdown-menu-media {
							overflow: hidden;
	
							.media {
								border-color: $theme-dark-border-color;
	
								.media-meta {
									color: $theme-dark-body-color;
								}
	
								&:hover {
									background-color: $theme-dark-body-bg;
								}
							}
	
							.dropdown-menu-header {
								border-bottom: 1px solid $theme-dark-border-color;
							}
	
							.dropdown-menu-footer {
								background-color: $theme-dark-card-bg;
								border-top: 1px solid $theme-dark-border-color;
	
								.dropdown-item {
									border-color: $theme-dark-border-color;
	
									&:hover {
										background-color: $theme-dark-card-bg;
									}
								}
							}
						}
					}
	
					.dropdown-cart {
						&.empty-cart {
							&:before {
								background-color: $theme-dark-body-bg;
							}
						}
	
						li {
							color: $white;
						}
	
						.cart-item {
							color: $theme-dark-body-color;
						}
	
						.dropdown-header {
							span {
								color: $white;
							}
						}
	
						.media {
							img {
								background-color: $theme-dark-body-bg;
							}
						}
	
						.media-heading .cart-item-by {
							color: $theme-dark-text-muted-color;
						}
					}
	
					.dropdown-notification {
						.media-body .media-heading {
							color: $theme-dark-headings-color;
						}
	
						.notification-text {
							color: $theme-dark-text-muted-color;
						}
					}
				}
			}
	
			&.navbar-static-top {
				background-color: transparent !important;
			}
	
			&[class*="bg-"] {
				.navbar-nav {
					.nav-item {
						.nav-link {
							background-color: inherit;
						}
					}
				}
			}
		}
	}

	// Angular Custom Scrollbar //
	.ng-scrollbar {
		&.custom-scrollbar {
			--scrollbar-thumb-color: rgba(255, 255, 255, 0.35);
			--scrollbar-thumb-hover-color: rgba(255, 255, 255, 0.5);
			--scrollbar-track-color: rgba(255, 255, 255, 0.15);
		}
	}

	// End of Angular Custom Scrollbar //
	// Browser Scroll Bar //
	.browser-scrollbar {
		scrollbar-color: rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.15) !important;

		&:hover {
			&::-webkit-scrollbar-thumb {
				background-color: rgba(255, 255, 255, 0.4) !important;
			}
		}

		&::-webkit-scrollbar-track {
			background-color: rgba(255, 255, 255, 0.15) !important;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(255, 255, 255, 0.25) !important;
		}
	}

	// End of Browser Scroll Bar//
	//Text color for Blank page with transparent card
	.blank-page {
		.card.bg-transparent {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $theme-dark-card-bg;
			}

			p {
				color: $theme-dark-body-bg;
			}
		}
	}

	// main menu
	.main-menu {
		background-color: $theme-dark-card-bg;

		.shadow-bottom {
			background: linear-gradient(
				180deg,
				$theme-dark-card-bg 44%,
				rgba($theme-dark-card-bg, 0.51) 73%,
				rgba($theme-dark-card-bg, 0)
			);
		}

		&:not(.expanded) {
			.navigation {
				.sidebar-group-active {
					> a {
						background-color: $theme-dark-body-bg;
					}
				}
			}
		}

		&.menu-dark {
			.navigation > li.open:not(.menu-item-closing) > a {
				background-color: $theme-dark-body-bg;
			}

			.navigation > li.sidebar-group-active > a {
				background-color: $theme-dark-body-bg;
			}

			.navigation {
				& > li {
					&.active {
						background-color: transparent;
					}
				}
			}
		}

		.collapse-toggle-icon {
			color: $primary !important;
		}
	}

	// Perfect Scrollbar
	.ps__thumb-x,
	.ps__thumb-y {
		background-color: $theme-dark-body-color;
	}

	.ps .ps__rail-x.ps--clicking,
	.ps .ps__rail-x:focus,
	.ps .ps__rail-x:hover,
	.ps .ps__rail-y.ps--clicking,
	.ps .ps__rail-y:focus,
	.ps .ps__rail-y:hover {
		background-color: $theme-dark-border-color;
	}

	.ps__rail-y.ps--clicking .ps__thumb-y,
	.ps__rail-y:focus > .ps__thumb-y,
	.ps__rail-y:hover > .ps__thumb-y {
		background-color: $theme-dark-body-color;
	}

	.footer-menu-content {
		.navigation {
			background-color: $theme-dark-card-bg !important;
			color: $theme-dark-headings-color !important;

			li {
				a {
					color: $theme-dark-headings-color !important;
				}

				span {
					color: $theme-dark-headings-color !important;
				}
			}

			.product-togale {
				.header-menu-item {
					.btn {
						svg {
							stroke: $theme-dark-headings-color !important;
						}

						span {
							color: $theme-dark-headings-color !important;
						}
					}
				}
			}
		}
	}

	// main menu content
	.main-menu-content {
		.navigation-main {
			background-color: $theme-dark-card-bg !important;

			.navigation-header {
				color: $theme-dark-text-muted-color;
			}

			li {
				a {
					color: $theme-dark-headings-color !important;

					svg {
						fill: $theme-dark-headings-color !important;

						path {
							fill: $theme-dark-headings-color !important;
						}
					}
				}

				ul {
					.open,
					.sidebar-group-active {
						> a {
							background-color: $theme-dark-body-bg;
						}
					}
				}
			}

			.nav-item {
				i,
				svg {
					color: $theme-dark-headings-color;
				}

				&.open {
					> a {
						background-color: $theme-dark-body-bg !important;
					}
				}

				a:after {
					color: $theme-dark-headings-color;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
				}

				.menu-content {
					background-color: $theme-dark-card-bg;

					.active {
						.menu-item {
							color: $white;
						}
					}
				}
			}

			.active {
				.menu-title,
				i,
				svg {
					color: $white;
				}
			}

			.sidebar-group-active {
				.menu-content {
					background-color: $theme-dark-card-bg;

					.active {
						z-index: 1;

						a {
							background-color: transparent;
						}
					}
				}
			}
		}
	}

	// menu collapsed active item color
	// .menu-collapsed {
	// 	.main-menu:not(.expanded) {
	// 		.navigation-main {
	// 			li.active {
	// 				> a {
	// 					//background: $theme-dark-body-bg !important;
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	/********* COMPONENTS *********/
	// alerts
	.alert {
		.alert-heading,
		p {
			color: inherit;
		}

		.close {
			text-shadow: none;
		}

		&.alert-dark {
			.alert-heading,
			.alert-body,
			p {
				color: $theme-dark-headings-color;
			}

			&.alert-dismissible .close {
				color: $theme-dark-headings-color !important;
			}
		}
	}

	// breadcrumbs

	// Header breadcrumb
	.content-header-left {
		.breadcrumbs-top {
			.content-header-title {
				color: $theme-dark-headings-color;
				border-color: $theme-dark-border-color;
			}
		}
	}

	// Breadcrumb dark style
	.breadcrumb {
		.breadcrumb-item {
			color: $theme-dark-body-color;

			+ .breadcrumb-item::before {
				color: $theme-dark-body-color;
			}
		}

		&:not([class*="breadcrumb-"]) {
			.breadcrumb-item + .breadcrumb-item {
				&:before {
					background-image: url(str-replace(
						str-replace($chevron-right, "currentColor", $theme-dark-body-color),
						"#",
						"%23"
					));
				}
			}
		}
	}

	// Breadcrumb with Chevron
	.breadcrumb-chevron {
		&.breadcrumb {
			.breadcrumb-item + .breadcrumb-item:before {
				background-image: url(str-replace(
					str-replace($chevron-right, "currentColor", $theme-dark-body-color),
					"#",
					"%23"
				));
			}
		}
	}

	// collapse

	[class*="collapse-"] .card:not(:last-of-type) {
		border-color: $theme-dark-border-color;
	}

	// for shadow and bordered
	.collapse-border {
		.card {
			border-color: $theme-dark-border-color;
		}
	}

	// Collapse shadow
	.collapse-shadow {
		box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24);
	}

	// margin collapse
	.collapse-margin {
		.card {
			box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24) !important;
		}
	}

	// if there is an anchor tag inside
	.collapse-icon {
		.card-header {
			position: relative;

			a {
				color: $theme-dark-body-color;
			}
		}

		[data-toggle="collapse"] {
			&:after {
				background-image: url(str-replace(
					str-replace($chevron-down, "currentColor", $theme-dark-body-color),
					"#",
					"%23"
				)) !important;
			}

			&:before {
				background-image: url(str-replace(
					str-replace($chevron-up, "currentColor", $theme-dark-body-color),
					"#",
					"%23"
				));
			}
		}
	}

	.asset-sidebar {
		.email-app-menu {
			.collapse-icon {
				.card-header {
					position: relative;
				}

				[data-toggle="collapse"] {
					&.active {
						&:after {
							background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.44063 7.91406C1.24844 8.02812 1.00938 8.02812 0.814065 7.91718C0.620315 7.80625 0.500002 7.59843 0.500002 7.375L0.500002 0.624999C0.500002 0.400937 0.620315 0.193906 0.814065 0.0826088C1.00938 -0.0287349 1.24844 -0.0274381 1.44063 0.0859838L7.19141 3.46094C7.3825 3.57344 7.5 3.77812 7.5 4C7.5 4.22187 7.3825 4.42656 7.19141 4.53906L1.44063 7.91406Z' fill='%230d51d9'/%3E%3C/svg%3E%0A") !important;
						}
					}

					&:after {
						position: absolute;
						top: 58%;
						left: 1rem;
						margin-top: -8px;
						// background-image: url(str-replace(
						//   str-replace($chevron-down, 'currentColor', $body-color),
						//   '#',
						//   '%23'
						// ));
						transform: rotate(90deg);
						background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.44063 7.91406C1.24844 8.02812 1.00938 8.02812 0.814065 7.91718C0.620315 7.80625 0.500002 7.59843 0.500002 7.375L0.500002 0.624999C0.500002 0.400937 0.620315 0.193906 0.814065 0.0826088C1.00938 -0.0287349 1.24844 -0.0274381 1.44063 0.0859838L7.19141 3.46094C7.3825 3.57344 7.5 3.77812 7.5 4C7.5 4.22187 7.3825 4.42656 7.19141 4.53906L1.44063 7.91406Z' fill='%230d51d9'/%3E%3C/svg%3E%0A") !important;
						background-repeat: no-repeat;
						height: 8px;
						width: 9px;
						background-size: contain;
						background-repeat: no-repeat;
						background-position: 50%;
						content: "";
						transition: all 300ms linear 0s;
					}

					&:disabled {
						&:before {
							// background-image: url(str-replace(
							//   str-replace($chevron-right, 'currentColor', $body-color),
							//   '#',
							//   '%23'
							// ));
							background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.44063 7.91406C1.24844 8.02812 1.00938 8.02812 0.814065 7.91718C0.620315 7.80625 0.500002 7.59843 0.500002 7.375L0.500002 0.624999C0.500002 0.400937 0.620315 0.193906 0.814065 0.0826088C1.00938 -0.0287349 1.24844 -0.0274381 1.44063 0.0859838L7.19141 3.46094C7.3825 3.57344 7.5 3.77812 7.5 4C7.5 4.22187 7.3825 4.42656 7.19141 4.53906L1.44063 7.91406Z' fill='%23eef0f5'/%3E%3C/svg%3E%0A") !important;
						}
					}
				}

				[aria-expanded="false"] {
					&:after {
						transform: rotate(0deg);
						background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.44063 7.91406C1.24844 8.02812 1.00938 8.02812 0.814065 7.91718C0.620315 7.80625 0.500002 7.59843 0.500002 7.375L0.500002 0.624999C0.500002 0.400937 0.620315 0.193906 0.814065 0.0826088C1.00938 -0.0287349 1.24844 -0.0274381 1.44063 0.0859838L7.19141 3.46094C7.3825 3.57344 7.5 3.77812 7.5 4C7.5 4.22187 7.3825 4.42656 7.19141 4.53906L1.44063 7.91406Z' fill='%23eef0f5'/%3E%3C/svg%3E%0A") !important;
					}
				}
			}
		}
	}

	// buttons
	.btn {
		&.btn-white {
			background-color: $theme-dark-card-bg !important;
		}

		&:not([class*="btn-"]) {
			color: $theme-dark-body-color;
		}

		&.btn-dark,
		&.btn-outline-dark,
		&.btn-flat-dark {
			color: $theme-dark-headings-color;
			.dropdown-toggle {
				&::after {
					background-image: url(str-replace(
						str-replace($chevron-right, "currentColor", $theme-dark-headings-color),
						"#",
						"%23"
					));
				}
			}
			&.dropdown-toggle {
				&::after {
					background-image: url(str-replace(str-replace($chevron-down, "currentColor", $white), "#", "%23"));
				}
			}
		}

		&.btn-dark {
			background-color: $dark !important;
		}

		&.btn-outline-dark {
			border-color: $dark;
			color: $theme-dark-headings-color;

			&:hover:not(.disabled):not(:disabled) {
				color: $theme-dark-headings-color;
			}
		}

		&.btn-outline-tertiary {
			border-color: $theme-dark-input-border-color !important;
			color: $primary !important;
			background-color: $theme-dark-card-bg;

			&:hover:not(.disabled):not(:disabled) {
				color: $primary !important;
			}
		}

		&.btn-flat-dark {
			&:active,
			&:focus {
				background: $dark;
			}
		}
	}

	.btn-group-toggle {
		label[class*="btn-outline-"] {
			color: $primary !important;
		}
	}

	// dropdown
	.dropdown-menu {
		background-color: $theme-dark-card-bg;
		box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.24);

		.dropdown-header {
			color: $theme-dark-headings-color;
		}

		.dropdown-item {
			color: $theme-dark-body-color;

			&:hover,
			&:focus {
				background: $dropdown-link-hover-bg;
				color: $dropdown-link-hover-color;
			}

			&:active,
			&.active {
				color: $white !important;
				background-color: $primary !important;
			}

			&.disabled,
			&:disabled {
				color: $theme-dark-text-muted-color;
			}
		}
	}

	.dropdown-divider {
		border-color: $theme-dark-border-color;
	}

	// modal
	.modal {
		.modal-header,
		.modal-header[class*="bg-"] {
			// why removed !important? Not working if added bg-COLOR class with modal-header
			// background-color: $theme-dark-card-bg !important;
			background-color: $theme-dark-body-bg;
		}

		// close button
		.modal-header .close {
			text-shadow: none;
			background-color: $theme-dark-card-bg !important;
			color: $theme-dark-body-color;
			box-shadow: 0 3px 8px 0 rgba(11, 10, 25, 0.49) !important;
		}

		.modal-content,
		.modal-body,
		.modal-footer {
			background-color: $theme-dark-card-bg;
			border-color: $theme-dark-border-color;
		}
	}

	//pagination
	.pagination {
		&:not([class*="pagination-"]) {
			.page-item {
				&.active {
					background-color: $theme-dark-pagination-bg;

					.page-link {
						background-color: $primary;

						&:hover {
							color: $white;
						}
					}
				}

				.page-link {
					background-color: $theme-dark-pagination-bg;
					color: $theme-dark-body-color;

					&:hover {
						color: $primary;
					}
				}

				&.prev-item,
				&.next-item {
					.page-link:hover {
						color: $white;
					}
				}
			}
		}

		&[class*="pagination-"] {
			.page-item:not(.active) {
				.page-link {
					background-color: $theme-dark-pagination-bg;
				}

				&:not(:hover) {
					.page-link {
						color: $white;
					}
				}
			}

			.page-item {
				&:not(.active) {
					.page-link:hover {
						background-color: $theme-dark-pagination-bg;
					}
				}

				&.active {
					background-color: $theme-dark-pagination-bg;
				}
			}
		}

		.page-item {
			&.prev-item,
			&.previous,
			&.prev {
				.page-link:before {
					background-image: url(str-replace(
						str-replace($chevron-left, "currentColor", $theme-dark-body-color),
						"#",
						"%23"
					));
				}

				&.disabled {
					.page-link {
						&:before {
							background-image: url(str-replace(
								str-replace($chevron-left, "currentColor", $theme-dark-text-muted-color),
								"#",
								"%23"
							));
						}
					}
				}
			}

			&.next-item,
			&.next {
				.page-link::after {
					background-image: url(str-replace(
						str-replace($chevron-right, "currentColor", $theme-dark-body-color),
						"#",
						"%23"
					));
				}

				&.disabled {
					.page-link {
						&:after {
							background-image: url(str-replace(
								str-replace($chevron-right, "currentColor", $theme-dark-text-muted-color),
								"#",
								"%23"
							));
						}
					}
				}
			}

			&.disabled {
				.page-link {
					color: $theme-dark-text-muted-color;
				}
			}
		}
	}

	// nav tabs
	.nav-tabs,
	.nav-pills,
	.nav-tabs.nav-justified {
		.nav-item {
			.nav-link {
				color: $theme-dark-headings-color;

				&.active {
					background-color: $theme-dark-card-bg;
					color: $primary;
				}

				&.disabled {
					color: $theme-dark-text-muted-color;
				}
			}
		}
	}

	// bordered nav
	.nav.wrap-border {
		border-color: $theme-dark-border-color;
	}

	// nav pills
	.nav-pills {
		.nav-item {
			.nav-link {
				&.active {
					color: $white;
					background-color: $primary;
				}
			}
		}
	}

	// media
	.media-bordered {
		.media:not(:first-child) {
			border-color: $theme-dark-border-color;
		}
	}

	// popover
	.popover {
		background-color: $theme-dark-card-bg !important;
		border: 1px solid $theme-dark-border-color !important;

		&[x-placement="top"] {
			.arrow {
				&:before {
					border-top-color: $theme-dark-border-color;
				}

				&:after {
					border-top-color: $theme-dark-card-bg;
				}
			}
		}

		&[x-placement="left"] {
			.arrow {
				&:before {
					border-left-color: $theme-dark-border-color;
				}

				&:after {
					border-left-color: $theme-dark-card-bg;
				}
			}
		}

		&[x-placement="right"] {
			.arrow {
				&:before {
					border-right-color: $theme-dark-border-color;
				}

				&:after {
					border-right-color: $theme-dark-card-bg;
				}
			}
		}

		.popover-header {
			color: $white;
		}

		.popover-body {
			background-color: $theme-dark-card-bg !important;
			color: $theme-dark-body-color;
			border-color: $theme-dark-border-color !important;
		}
	}

	// BS toast
	.toast {
		background-color: rgba($theme-dark-card-bg, 0.85);
		box-shadow: $theme-dark-box-shadow;
		color: $theme-dark-body-color;

		.toast-header {
			background-color: $theme-dark-card-bg;
			color: $theme-dark-body-color;

			.close {
				background-color: transparent !important;
				box-shadow: none !important;
				text-shadow: none;
			}
		}
	}

	// list group

	.list-group {
		&.incident-option-list {
			.list-group-item {
				&.list-group-item-action:not(.active):not(:active) {
					background-color: $theme-dark-body-bg;
					&:hover {
						background-color: rgba($color: #6c6f8f, $alpha: 0.14);
						color: $theme-dark-headings-color !important;
					}
				}
			}
		}
		&.consumable-option-list {
			.list-group-item {
				&.list-group-item-action:not(.active):not(:active) {
					background-color: $theme-dark-body-bg;
					&:hover {
						background-color: rgba($color: #6c6f8f, $alpha: 0.14);
						color: $theme-dark-headings-color !important;
					}
				}
			}
		}
		.list-group-item {
			color: $theme-dark-body-color !important;

			&:not([class*="list-group-item-"]),
			&.list-group-item-action:not(.active):not(:active) {
				background-color: $theme-dark-card-bg;
				border-color: $theme-dark-border-color;

				&:not(.disabled) {
					color: $theme-dark-body-color !important;
				}
			}

			&.list-group-item-action {
				&:not(.active):not(:active) {
					&:hover,
					&:focus {
						background-color: $theme-dark-body-bg;
					}
				}

				&.active,
				&:active {
					background-color: $primary;
					color: $white !important;
				}
			}

			&.active {
				p,
				small {
					color: $white;
				}
			}

			&.disabled {
				color: $theme-dark-text-muted-color;
			}
		}

		&.list-group-circle .list-group-item:after {
			background-image: url(str-replace(
				str-replace($circle, "currentColor", $theme-dark-body-color),
				"#",
				"%23"
			));
		}
	}

	// spinners
	.spinner-border {
		border-color: currentColor !important;
		border-right-color: transparent !important;
	}

	// avatar
	.avatar {
		//background-color: $theme-dark-body-bg;

		[class*="avatar-status-"] {
			border-color: $theme-dark-card-bg;
		}
	}

	.avatar-group {
		.avatar {
			img,
			.avatar-content {
				box-shadow: 0 0 0 2px $theme-dark-card-bg, inset 0 0 0 1px rgba($theme-dark-card-bg, 0.07);
			}
		}
	}

	// divider
	.divider {
		.divider-text {
			color: $theme-dark-body-color;

			&::before,
			&::after {
				border-color: $theme-dark-border-color;
			}
		}

		&.divider-dark {
			.divider-text {
				&::before,
				&::after {
					border-color: $theme-dark-border-color !important;
				}
			}
		}
	}

	// Timeline
	.timeline {
		.timeline-item {
			border-color: $theme-dark-border-color;

			.timeline-point:not(.timeline-point-indicator) {
				background-color: $theme-dark-card-bg;
			}

			.timeline-event {
				.timeline-event-time {
					color: $theme-dark-text-muted-color;
				}
			}

			&:last-of-type:after {
				background: linear-gradient($theme-dark-border-color, transparent);
			}
		}
	}

	// card
	.card {
		background-color: $theme-dark-card-bg;
		box-shadow: $theme-dark-box-shadow;

		.card-footer {
			border-color: $theme-dark-border-color;
		}

		&.overlay-img-card {
			.card-img-overlay {
				span,
				p,
				.card-body {
					color: $white;
				}
			}
		}
	}

	//--------------- Advance Cards ---------------
	//---------------------------------------------

	// Developer Meetup Card
	.card-developer-meetup {
		.meetup-header .meetup-day {
			border-right-color: $theme-dark-input-border-color;
		}
	}

	// Profile Card
	.card-profile {
		.profile-image-wrapper .profile-image {
			background-color: $theme-dark-body-bg;
		}
	}

	// Business Card
	.business-card {
		.business-items .business-item {
			border-color: $theme-dark-border-color;
		}
	}

	// App Design Card
	.card-app-design {
		.design-planning-wrapper .design-planning {
			background-color: $theme-dark-body-bg;
		}
	}

	//--------------- Statistics Card ---------------
	//------------------------------------------------

	.card-tiny-line-stats {
		.apexcharts-series-markers {
			.apexcharts-marker {
				stroke: $info;
			}

			&:last-child {
				.apexcharts-marker {
					fill: $theme-dark-card-bg;
				}
			}
		}
	}

	//-------------- Analytics Cards --------------
	//---------------------------------------------

	// Revenue Report Card
	.card-revenue-budget {
		.revenue-report-wrapper {
			border-right-color: $theme-dark-border-color;
		}

		.budget-wrapper {
			.apexcharts-series:not(:first-child) {
				path {
					stroke: $theme-dark-body-color;
				}
			}
		}
	}

	// Earnings Card
	.earnings-card {
		.apexcharts-canvas .apexcharts-pie {
			.apexcharts-datalabel-label {
				fill: $theme-dark-body-color !important;
			}

			.apexcharts-datalabel-value {
				fill: $theme-dark-headings-color;
			}
		}
	}

	// Company Table Card
	.card-company-table {
		.avatar {
			background-color: $theme-dark-body-bg;
		}
	}

	/********** FORMS **********/
	.content {
		.header-bg {
			border-color: $theme-dark-border-color;
			background-color: $theme-dark-card-bg;
		}

		.row {
			.uploadfile {
				border-color: $theme-dark-border-color;
				background-color: $theme-dark-card-bg;
			}
		}
	}

	// input
	input.form-control,
	.custom-file-label,
	textarea.form-control {
		background-color: $theme-dark-input-bg !important;
		color: $theme-dark-body-color !important;
		border-color: $theme-dark-input-border-color !important;

		&.error {
			&:focus {
				border-color: $danger !important;

				~ .custom-control-label::before,
				~ .custom-file-label {
					border-color: $danger !important;
				}

				~ .custom-control-label {
					color: $danger;
				}
			}

			border-color: $danger !important;

			~ .custom-control-label::before,
			~ .custom-file-label {
				border-color: $danger !important;
			}

			~ .custom-control-label {
				color: $danger;
			}

			&:not(:focus) {
				border-color: $danger !important;

				~ .custom-control-label::before,
				~ .custom-file-label {
					border-color: $danger !important;
				}

				~ .custom-control-label {
					color: $danger;
				}
			}
		}

		&:not(:focus) {
			border-color: $theme-dark-input-border-color !important;
		}

		&::placeholder {
			color: $theme-dark-input-placeholder-color !important;
		}

		&:disabled,
		&[readonly="readonly"] {
			opacity: 0.5;
		}
	}

	// text area counter
	.char-textarea {
		&.active {
			color: $theme-dark-body-color !important;
		}

		&.max-limit {
			color: $danger !important;
		}
	}

	// file uploader default
	.custom-file-label {
		&:after {
			background-color: $theme-dark-body-bg;
			border-left: 1px solid $theme-dark-border-color;
			color: $theme-dark-body-color;
		}
	}

	// custom control like checkbox, radio and switch
	.custom-control-input {
		&:not(:checked):not(:indeterminate) {
			~ .custom-control-label:before {
				background-color: $theme-dark-card-bg;
				border-color: $theme-dark-input-border-color;
			}
		}

		&:disabled {
			~ .custom-control-label:before {
				background-color: $theme-dark-input-disabled-border-color;
				border-color: $theme-dark-input-disabled-border-color;
			}
		}
	}

	.custom-switch {
		.custom-control-input:not(:checked) {
			~ .custom-control-label:before {
				background-color: $theme-dark-switch-bg;
			}
		}

		.custom-control-input:disabled {
			~ .custom-control-label:before {
				background-color: $theme-dark-switch-bg-disabled;
			}
		}
	}

	// select
	select.form-control,
	.custom-select {
		background-color: $theme-dark-input-bg;
		color: $theme-dark-body-color;
		border-color: $theme-dark-border-color;

		option:checked {
			background-color: $theme-dark-card-bg;
		}

		&:disabled {
			opacity: 0.5;
		}

		&:focus {
			border-color: $primary;
		}
	}

	select.form-control {
		&:not([multiple="multiple"]) {
			background-image: url(str-replace(
				str-replace($chevron-down, "currentColor", $theme-dark-body-color),
				"#",
				"%23"
			));
		}
	}

	// Added for override
	.was-validated {
		.form-control:invalid,
		.form-control.is-invalid,
		select:not(:focus):invalid,
		select:not(:focus).is-valid {
			border-color: $danger !important;
		}

		.form-control:valid,
		.form-control.is-valid,
		select:not(:focus):invalid,
		select:not(:focus).is-valid {
			border-color: $success !important;
		}
	}

	// form wizard
	.wizard {
		.steps {
			ul {
				.disabled,
				.done {
					a {
						color: $theme-dark-body-color !important;

						&:hover {
							color: $theme-dark-body-color;
						}

						.step {
							background-color: $theme-dark-body-bg !important;
							color: $white !important;
						}
					}
				}
			}
		}
	}

	// input groups
	.input-group {
		&.search-form-group {
			.input-group-prepend {
				.input-group-text {
					&::after {
						background-color: $theme-dark-border-color !important;
					}
				}
			}
		}

		.input-group-text {
			background-color: $theme-dark-input-bg !important;
			border-color: $theme-dark-input-border-color !important;
			color: $theme-dark-body-color;
		}

		&:focus-within {
			.form-control,
			.input-group-text {
				border-color: $primary;
				box-shadow: none;
			}
		}
	}

	// floating label
	.form-label-group {
		& > input:not(:focus):not(:placeholder-shown) ~ label,
		& > textarea:not(:focus):not(:placeholder-shown) ~ label {
			color: $theme-dark-body-color !important;
		}
	}

	.counter-value {
		color: white;
	}

	/********** TABLE **********/

	// table
	.table {
		// background-color: $theme-dark-table-bg;

		td,
		th {
			color: $theme-dark-body-color;
			border-color: $theme-dark-border-color !important;
		}

		.thead,
		tbody {
			tr:not([class*="table-"]) {
				th,
				td {
					border-color: $theme-dark-border-color;
					color: $theme-dark-body-color;

					code {
						background-color: $theme-dark-table-row-bg;
					}
				}
			}
		}

		thead,
		tfoot {
			tr {
				th {
					border-color: $theme-dark-border-color;
					background-color: $theme-dark-table-header-bg;
					color: $theme-dark-headings-color;
				}
			}

			&.thead-dark th {
				background-color: $white;
				color: $dark !important;
			}
		}

		// if table not light/dark and thead not have dark class
		&:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
		&:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
			background-color: $theme-dark-table-header-bg;
		}

		// table-dark style
		&.table-dark {
			background-color: $white;

			&.table-striped tbody tr:nth-of-type(odd) {
				background-color: rgba($dark, 0.05);

				td {
					background-color: inherit;
				}
			}

			td,
			th {
				border-color: #f8f9fa !important;
				color: $dark !important;
				background-color: $white;

				.text-white {
					color: $dark !important;
				}
			}
		}

		tbody {
			tr {
				// for contextual classes
				&[class*="table-"] {
					td,
					th {
						background-color: unset;
					}
				}

				&.table-dark {
					td,
					th {
						color: $white;
					}
				}

				&.table-active {
					td,
					th {
						color: $theme-dark-body-color;
					}
				}
			}
		}

		// bordered table
		&.table-bordered {
			border-color: $theme-dark-border-color;
		}

		// table hover row color
		&.table-hover {
			tbody {
				tr {
					&:hover {
						background-color: $theme-dark-table-hover-bg;
					}

					th,
					td {
						background-color: unset;
					}
				}
			}
		}

		// hover animation
		&.table-hover-animation tbody tr {
			background-color: $theme-dark-table-bg;

			&:hover {
				box-shadow: $theme-dark-box-shadow;
			}
		}

		// table striped
		&.table-striped {
			tbody {
				tr {
					&:nth-of-type(odd) {
						background-color: $theme-dark-table-striped-bg;
					}
				}
			}
		}
	}

	// datatable
	.dataTables_wrapper {
		.dt-buttons {
			.buttons-copy,
			.buttons-excel,
			.buttons-pdf,
			.buttons-print,
			.btn-secondary,
			.dt-button-collection [class*="buttons-"] {
				background-color: $theme-dark-body-bg !important;

				&:active {
					background-color: $primary !important;
					color: $white;
				}
			}
		}

		.dt-button-collection {
			> div[role="menu"] {
				box-shadow: $theme-dark-box-shadow;
			}
		}

		.table.dataTable {
			tr {
				&.group {
					td {
						background-color: $theme-dark-table-striped-bg;
						color: $theme-dark-headings-color;
					}
				}
			}

			// Sorting Icons Change
			thead {
				.sorting,
				.sorting_asc,
				.sorting_desc {
					&:before {
						background-image: url(str-replace(
							str-replace($chevron-up, "currentColor", $theme-dark-body-color),
							"#",
							"%23"
						));
					}

					&:after {
						background-image: url(str-replace(
							str-replace($chevron-down, "currentColor", $theme-dark-body-color),
							"#",
							"%23"
						));
					}
				}

				.sorting {
					&:before,
					&:after {
						opacity: 0.5;
					}
				}

				.sorting_asc {
					&:before {
						opacity: 1;
					}

					&:after {
						opacity: 0.5;
					}
				}

				.sorting_desc {
					&:after {
						opacity: 1;
					}

					&:before {
						opacity: 0.5;
					}
				}
			}
		}
	}

	// agGrid Page Btn
	.ag-grid-btns {
		.filter-btn {
			background-color: transparent !important;
		}
	}

	// agGrid Main
	.aggrid {
		.ag-input-wrapper input {
			color: $theme-dark-body-color;
		}
	}

	.aggrid,
	.ag-header {
		background-color: $theme-dark-card-bg;
		color: $theme-dark-body-color;
		border-color: $theme-dark-border-color;

		.ag-row-hover,
		.ag-row-selected {
			background-color: $theme-dark-table-hover-bg !important;
		}

		.ag-icon {
			color: $theme-dark-input-border-color;
		}

		.ag-header-cell.ag-column-hover {
			background: transparent !important;
		}

		.ag-header-cell,
		.ag-cell,
		.ag-row,
		.ag-pinned-left-header,
		.ag-pinned-left-cols-container,
		.ag-horizontal-left-spacer,
		.ag-paging-panel,
		.ag-floating-filter-input {
			border-color: $theme-dark-border-color !important;
			color: $theme-dark-body-color;
		}

		.ag-cell.ag-cell-inline-editing {
			border-color: $theme-dark-border-color;
			background: $theme-dark-body-bg;
			height: auto;
			padding-top: 0;
		}

		.ag-paging-panel {
			.ag-paging-button {
				background-color: $theme-dark-body-bg;
			}

			.ag-disabled {
				background: $theme-dark-card-bg;
			}

			span[ref="lbTotal"] {
				background-color: $theme-dark-pagination-bg;
				color: $theme-dark-body-color !important;
			}
		}

		::-webkit-scrollbar-track {
			background: $theme-dark-body-bg;
		}

		::-webkit-scrollbar-thumb {
			background: $primary;
		}
	}

	/********** PAGES **********/

	// coming soon
	.getting-started {
		.clockCard {
			p {
				color: $theme-dark-body-color !important;
			}
		}
	}

	// profile page
	#user-profile {
		.profile-header {
			.navbar {
				background-color: $theme-dark-card-bg;

				.navbar-toggler {
					color: $theme-dark-body-color;
					border-color: $theme-dark-border-color;
				}
			}
		}
	}

	// Search page
	.search-bar {
		.form-control {
			background-color: $theme-dark-card-bg;
		}
	}

	// Blog Edit Page
	.blog-edit-wrapper {
		.border {
			border-color: $theme-dark-border-color !important;
		}
	}

	// Knowledge Base
	.thcolor {
		background-color: $theme-dark-table-header-bg;
	}

	// list group circle for kb
	.list-group-circle {
		.list-group-item:not([class*="list-group-item-"]),
		.list-group-item-action {
			&:hover,
			&:focus,
			&:active {
				background-color: transparent !important;
			}
		}
	}

	/********** APPLICATIONS **********/

	// App common sidebar and content dark style
	.content-area-wrapper {
		border-color: $theme-dark-border-color !important;

		.sidebar {
			.sidebar-content {
				background-color: $theme-dark-card-bg !important;
			}
		}

		.app-fixed-search {
			background-color: $theme-dark-card-bg !important;
			border-color: $theme-dark-border-color !important;
		}

		.content-right {
			border-color: $theme-dark-border-color !important;
		}
	}

	// email application
	.email-application {
		.content-area-wrapper {
			// Email list
			.email-app-list {
				.app-action {
					border-color: $theme-dark-border-color;
					background-color: $theme-dark-card-bg;

					.action-right {
						.list-inline-item {
							.dropdown-toggle {
								color: $theme-dark-body-color;
							}
						}
					}
				}

				.header-bg {
					border-color: $theme-dark-border-color;
					background-color: $theme-dark-card-bg;
				}

				.email-user-list {
					.media {
						border-color: $theme-dark-border-color;
						background-color: $theme-dark-card-bg;

						&:hover {
							box-shadow: 0 3px 10px 0 $theme-dark-card-bg;
						}

						&.selected-row-bg {
							background-color: rgba($primary, 0.06);
							border-color: $theme-dark-border-color;
						}
					}

					.media {
						.user-details,
						.mail-message {
							p,
							.mail-date {
								color: $theme-dark-text-muted-color;
							}
						}
					}

					.mail-read {
						background-color: $theme-dark-table-hover-bg;
					}
				}
			}

			// app email details
			.email-app-details {
				border-color: $theme-dark-border-color;

				.email-scroll-area {
					background-color: $theme-dark-body-bg;
				}

				.email-detail-header {
					background-color: $theme-dark-card-bg;
					border-color: $theme-dark-border-color;

					.email-header-right {
						.list-inline-item {
							.dropdown-toggle,
							.action-icon {
								color: $theme-dark-body-color;
							}
						}
					}
				}

				.email-info-dropup {
					.dropdown-toggle::after {
						background-image: url(str-replace(
							str-replace($chevron-down, "currentColor", $theme-dark-text-muted-color),
							"#",
							"%23"
						));
					}
				}

				.mail-message-wrapper,
				.email-detail-head {
					border-color: $theme-dark-border-color !important;
				}
			}

			// Compose mail
			#compose-mail {
				.compose-mail-form-field,
				.ql-toolbar,
				.ql-container {
					border-color: $theme-dark-border-color;
				}

				label {
					color: $theme-dark-body-color;
				}

				.modal-body {
					border-bottom-left-radius: $modal-content-border-radius;
					border-bottom-right-radius: $modal-content-border-radius;
				}
			}
		}
	}

	// chat application
	.chat-application {
		.content-area-wrapper {
			border-color: $theme-dark-border-color;
		}

		// left sidebar
		.sidebar {
			.chat-profile-sidebar {
				background-color: $theme-dark-card-bg;
				border-color: $theme-dark-border-color;
			}

			.sidebar-content {
				border-color: $theme-dark-border-color;

				.chat-fixed-search {
					border-color: $theme-dark-border-color;
				}

				.chat-user-list-wrapper {
					.chat-users-list {
						li {
							&:not(.active):hover {
								background: $theme-dark-body-bg;
							}

							.chat-info .card-text,
							.chat-time {
								color: $theme-dark-text-muted-color;
							}

							&.active {
								.chat-info .card-text,
								.chat-time {
									color: $white;
								}
							}

							img {
								border-color: $theme-dark-border-color;
							}
						}
					}
				}
			}
		}

		.avatar-border {
			img {
				border-color: $theme-dark-border-color;
			}
		}

		// Right side content area
		.content-right {
			.chat-app-window {
				.start-chat-area {
					background-color: $theme-dark-card-bg;

					.start-chat-icon,
					.start-chat-text {
						background: $theme-dark-card-bg;
						color: $theme-dark-body-color;
					}
				}

				.start-chat-area,
				.user-chats {
					background-image: url($chat-bg-dark);
					background-color: #1e232f;
				}

				.user-chats {
					.divider .divider-text {
						background: $theme-dark-card-bg;

						&:before,
						&:after {
							border-color: $theme-dark-border-color;
						}
					}

					.avatar img {
						border-color: $theme-dark-border-color;
					}
				}

				// Active conversation style
				.active-chat {
					.chat-left {
						.chat-content {
							background-color: $theme-dark-card-bg;

							p {
								color: $theme-dark-body-color;
							}
						}
					}

					.chat-content {
						p {
							color: $white;
						}
					}

					// Chat form
					.chat-header .dropdown-toggle {
						color: $theme-dark-body-color;
					}

					.chat-header,
					.chat-app-form {
						background-color: $theme-dark-card-bg;
						border-color: $theme-dark-border-color;
					}
				}
			}

			.user-profile-sidebar {
				background-color: $theme-dark-card-bg;
				border-color: $theme-dark-border-color;
			}
		}
	}

	// Chat widget dark style
	.chat-widget {
		.user-chats {
			background-color: $theme-dark-widget-bg !important;
		}

		.card-header,
		.chat-app-form {
			background-color: $theme-dark-card-bg;
		}

		.chat-app-window .user-chats .chat-left .chat-content {
			background-color: $theme-dark-card-bg !important;
			color: $theme-dark-body-color !important;
		}

		.chat-app-window .user-chats .avatar img {
			border-color: $theme-dark-body-bg;
		}
	}

	// Kanban application
	.kanban-application {
		.kanban-wrapper {
			.kanban-container {
				.kanban-board {
					.kanban-board-header {
						.kanban-title-board {
							color: $white;

							&:hover,
							&:focus {
								background-color: $theme-dark-card-bg;
							}
						}
					}

					.kanban-item {
						background-color: $theme-dark-card-bg;

						.item-dropdown i.dropdown-toggle,
						.item-dropdown svg.dropdown-toggle {
							stroke: $white;
						}

						.kanban-title-button {
							color: $theme-dark-body-color;
						}

						i,
						svg {
							stroke: $theme-dark-body-color;
						}
					}
				}
			}
		}

		.update-item-sidebar {
			.nav-tabs .nav-item .nav-link,
			.tab-content .tab-pane {
				background-color: transparent;
			}
		}
	}

	.kanban-item.gu-mirror {
		background-color: $theme-dark-card-bg;
	}

	// Todo application
	.todo-application {
		.content-area-wrapper {
			.sidebar {
				.todo-form {
					.todo-item-action {
						color: $theme-dark-body-color;
					}
				}
			}

			.content-right {
				.todo-task-list-wrapper {
					background-color: $theme-dark-card-bg !important;
					border-color: $theme-dark-border-color;
				}

				.todo-task-list {
					.todo-item {
						&:not(:first-child) {
							border-color: $theme-dark-border-color;
						}

						&.completed {
							.todo-title {
								color: $theme-dark-text-muted-color;
							}
						}

						&:hover {
							box-shadow: $theme-dark-box-shadow;
						}

						.todo-item-action {
							.todo-item-favorite:not(.text-warning) {
								i,
								svg {
									color: $theme-dark-body-color;
								}
							}
						}
					}
				}
			}
		}

		.todo-item-action {
			.close {
				background-color: transparent !important;
			}
		}
	}

	.todo-item.gu-mirror {
		background-color: $theme-dark-card-bg;
		border-color: $theme-dark-border-color;
		box-shadow: $theme-dark-box-shadow;

		&.completed {
			.todo-title {
				color: $theme-dark-text-muted-color;
			}
		}
	}

	// E-commerce application
	.ecommerce-application {
		.ecommerce-card {
			.btn-light {
				background-color: $theme-dark-body-bg !important;
				color: $white;
				border-color: transparent;
			}
		}

		// main content
		.content-right,
		.content-body {
			// header items
			.ecommerce-header-items {
				.result-toggler {
					.search-results {
						color: $theme-dark-headings-color;
					}

					.shop-sidebar-toggler {
						i,
						svg {
							color: $theme-dark-headings-color;
						}
					}
				}
			}

			// list and grid view
			.grid-view,
			.list-view {
				.ecommerce-card {
					.card-body {
						border-color: $theme-dark-border-color;
					}

					.item-options {
						.wishlist {
							span {
								color: $headings-color;
							}
						}

						.cart {
							span {
								color: $white;
							}
						}
					}
				}
			}
		}

		.grid-view {
			.item-name a,
			.item-price {
				color: $theme-dark-body-color;
			}
		}

		// wishlist-items
		.wishlist-items {
			.ecommerce-card {
				.move-cart {
					.move-to-cart {
						color: $white;
					}
				}
			}
		}

		// checkout
		.product-checkout {
			.checkout-options {
				.coupons {
					input {
						color: $theme-dark-body-color;

						&::placeholder {
							color: $theme-dark-body-color;
						}
					}
				}
			}
		}

		// Ecommerce details
		.app-ecommerce-details {
			.item-features,
			.swiper-responsive-breakpoints.swiper-container .swiper-slide {
				background-color: $theme-dark-body-bg;
			}
		}
	}

	// file manager app
	.file-manager-application {
		.sidebar-file-manager {
			background-color: $theme-dark-card-bg !important;

			.jstree {
				.jstree-container-ul {
					.jstree-anchor {
						color: $theme-dark-body-color;
					}
				}
			}

			.storage-status {
				i,
				svg {
					color: $theme-dark-body-color !important;
				}
			}

			.file-manager-title {
				color: $theme-dark-label-color;
			}

			&.show {
				border: 1px solid $theme-dark-border-color !important;
			}
		}

		.content-area-wrapper {
			border: 1px solid $theme-dark-border-color !important;
		}

		// File Manager App
		.file-manager-main-content {
			border: 1px solid $theme-dark-border-color !important;

			.file-manager-app-searchbar {
				background-color: transparent !important;
				border-bottom: 1px solid $theme-dark-border-color !important;

				.file-manager-toggler {
					color: $theme-dark-body-color !important;
				}
			}

			.file-manager-content-header,
			.file-manager-content-body {
				background-color: $theme-dark-card-bg !important;
			}

			.file-manager-content-header {
				border-color: $theme-dark-border-color !important;
			}

			.file-manager-content-body {
				.drives {
					.card {
						background-color: $theme-dark-table-bg;
					}
				}

				.view-container {
					.file-manager-item {
						.file-logo-wrapper {
							background-color: $theme-dark-body-bg !important;
						}

						&:not(.selected) {
							border-color: $theme-dark-border-color !important;
						}
					}

					&:not(.list-view) {
						.file-manager-item {
							.file-size,
							.file-accessed {
								color: $theme-dark-text-muted-color !important;
							}
						}
					}
				}
			}
		}

		#app-file-manager-info-sidebar {
			.nav-tabs .nav-link,
			.tab-content .tab-pane {
				background-color: transparent;
			}

			.nav-tabs {
				.nav-item:not(.active) {
					.nav-link {
						color: $theme-dark-body-color;
					}
				}
			}
		}
	}

	// calendar
	.app-calendar {
		.app-calendar-sidebar {
			background-color: $theme-dark-card-bg;
			border-color: $theme-dark-border-color;

			.sidebar-content-title {
				color: $theme-dark-label-color;
			}

			.select-all,
			.input-filter {
				~ label {
					color: $theme-dark-label-color;
				}
			}
		}

		.fc {
			.fc-day-today {
				background: $theme-dark-body-bg !important;
				background-color: $theme-dark-body-bg !important;
			}

			.fc-timegrid {
				.fc-scrollgrid-section {
					.fc-col-header-cell,
					.fc-timegrid-axis,
					.fc-daygrid-day {
						background-color: $theme-dark-card-bg;
						border-color: $theme-dark-border-color;
					}

					.fc-day-today {
						background-color: rgba($color: $gray-100, $alpha: 0.12) !important;

						&.fc-col-header-cell {
							background-color: $theme-dark-card-bg !important;
						}
					}
				}
			}

			.fc-popover {
				background: $theme-dark-card-bg;
				border-color: $theme-dark-border-color;
			}

			.fc-popover-header {
				.fc-popover-title,
				.fc-popover-close {
					color: $theme-dark-body-color;
				}
			}

			tbody td,
			thead th {
				border-color: $theme-dark-border-color;
			}

			.fc-scrollgrid {
				border-color: $theme-dark-border-color;
			}

			.fc-list,
			th {
				border-color: $theme-dark-border-color;

				.fc-list-day-cushion {
					background: $theme-dark-body-bg;
				}

				.fc-list-event:hover td {
					background-color: $theme-dark-body-bg;
				}

				.fc-list-event-time {
					color: $theme-dark-body-color;
				}

				.fc-list-event {
					td {
						border-color: $theme-dark-border-color;
					}
					.fc-list-event-title {
						color: #cfd6e5;
					}
				}
			}
		}

		.fc-timegrid-event-harness-inset .fc-timegrid-event,
		.fc-timegrid-event.fc-event-mirror,
		.fc-timegrid-more-link {
			box-shadow: 0 0 0 1px $theme-dark-border-color;
		}
	}

	// Invoice Application
	.invoice-list-wrapper {
		.dataTables_wrapper {
			.invoice-list-dataTable-header {
				background-color: $theme-dark-card-bg;
				border-color: $theme-dark-border-color;
			}

			.invoice-data-table {
				background-color: $theme-dark-card-bg;

				tbody .selected-row-bg {
					background-color: $theme-dark-border-color;
				}

				td {
					background-color: inherit;
				}
			}
		}
	}

	.invoice-edit,
	.invoice-add {
		.invoice-preview-card {
			.invoice-product-details {
				background-color: $theme-dark-body-bg;

				.product-details-border {
					background-color: $theme-dark-card-bg;
					border-color: $theme-dark-border-color;
				}
			}
		}
	}

	.invoice-print {
		.form-control {
			background-color: $theme-dark-card-bg;
		}
	}

	// Users Application
	.app-user-view {
		.plan-card {
			border-color: $primary !important;
		}
	}

	.app-user-edit {
		.form-control.error {
			border-color: $danger !important;
		}
	}

	/********** CUSTOMIZER **********/

	.customizer {
		background-color: $theme-dark-card-bg;

		.customizer-close {
			i,
			svg {
				color: $theme-dark-body-color;
			}
		}
	}

	.footer-fixed {
		.footer {
			background-color: $theme-dark-card-bg;
		}
	}

	/********** HORIZONTAL LAYOUT **********/
	.horizontal-layout {
		&.navbar-sticky .horizontal-menu-wrapper .navbar-horizontal.header-navbar.fixed-top {
			box-shadow: 0 4px 24px 0 rgba($black, 0.75) !important;
		}

		.horizontal-menu-wrapper {
			background: linear-gradient(
				to bottom,
				rgba(37, 43, 71, 0.76) 44%,
				rgba(56, 53, 53, 0.46) 73%,
				rgba($white, 0) 100%
			) !important;

			.header-navbar {
				background: $theme-dark-card-bg !important;

				&.navbar-shadow {
					box-shadow: 0 4px 24px 0 rgba($black, 0.75);
				}

				&.navbar-horizontal {
					ul#main-menu-navigation > li:hover:not(.active) > a {
						background: $theme-dark-body-bg;
					}

					.active {
						.nav-link {
							&.dropdown-toggle::after {
								background-image: url(str-replace(
									str-replace($chevron-down, "currentColor", $white),
									"#",
									"%23"
								));
							}
						}
					}

					&.navbar-dark {
						background: $theme-dark-card-bg !important;
					}

					.nav-link {
						&.dropdown-toggle::after {
							background-image: url(str-replace(
								str-replace($chevron-down, "currentColor", $theme-dark-body-color),
								"#",
								"%23"
							));
						}
					}
				}
			}
		}

		.main-menu-content {
			.navbar-nav {
				.dropdown-submenu {
					&.show {
						background-color: $theme-dark-card-bg !important;
						color: $theme-dark-body-color;
					}
				}

				.dropdown-menu {
					a {
						&:hover {
							color: $theme-dark-headings-color !important;
						}
					}

					.disabled a {
						color: $theme-dark-text-muted-color;
					}

					.dropdown-item {
						color: $theme-dark-headings-color;
					}

					.dropdown-toggle {
						&::after {
							background-image: url(str-replace(
								str-replace($chevron-right, "currentColor", $theme-dark-body-color),
								"#",
								"%23"
							));
						}

						&:hover,
						&:active {
							&::after {
								background-image: url(str-replace(
									str-replace($chevron-right, "currentColor", $white),
									"#",
									"%23"
								));
							}
						}
					}
				}

				> li.active {
					> a {
						> i,
						> svg {
							color: $white !important;
						}
					}

					.dropdown-menu {
						li.active > a {
							background: $theme-dark-body-bg !important;
							color: $theme-dark-body-color;

							&:hover {
								color: $primary !important;
							}
						}
					}

					.dropdown-menu {
						li.open.active {
							> a {
								color: $theme-dark-body-color !important;
							}
						}
					}
				}
			}
		}
	}

	// .product-togale{
	//   .header-menu-item{
	//     .btn{
	//       stroke: $theme-dark-body-color !important;
	//     }
	//     .menu-title{
	//       stroke: $theme-dark-body-color !important;
	//     }
	//   }
	// }
	.custom-table {
		.mat-table {
			background-color: $theme-dark-card-bg !important;

			.mat-header-cell {
				border-color: $theme-dark-border-color !important;
				background-color: $theme-dark-table-header-bg !important;
				color: $theme-dark-headings-color !important;
			}

			.mat-cell,
			.mat-footer-cell {
				color: $theme-dark-body-color !important;
				border-color: $theme-dark-border-color !important;
			}
		}
	}

	// mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
	//   border-color: $theme-dark-border-color !important;
	// }
	.mat-menu-panel {
		background-color: $theme-dark-card-bg !important;

		.mat-menu-content {
			background-color: $theme-dark-card-bg !important;

			.mat-menu-item {
				color: $theme-dark-body-color !important;
			}
		}
	}

	.subheader {
		background-color: $theme-dark-card-bg !important;
	}

	.custom-dropdown {
		.form-group {
			.ng-select {
				&.has-value {
					.ng-select-container {
						background-color: $theme-dark-card-bg !important;
					}
				}

				.ng-select-container {
					background-color: $theme-dark-card-bg !important;
					// background-color: darken($color: $theme-dark-card-bg , $amount: 4) !important;
					color: $theme-dark-body-color !important;
					// border-color: transparent;
				}

				&.ng-select-opened {
					.ng-select-container {
						background-color: $theme-dark-card-bg !important;
						// background-color: darken($color: $theme-dark-card-bg , $amount: 4) !important;
						color: $theme-dark-body-color !important;
						// border-color: transparent;
					}
				}

				.ng-dropdown-panel {
					background-color: $primary !important;
					border-color: $primary !important;

					.ng-dropdown-panel-items {
						.ng-option {
							background-color: $theme-dark-card-bg !important;
							color: $theme-dark-body-color !important;

							&.ng-option-selected {
								background-color: rgba($color: $primary, $alpha: 0.2) !important;
							}

							&.ng-option-marked {
								background-color: rgba($color: $primary, $alpha: 0.12) !important;
								color: $theme-dark-headings-color !important;
								small {
									color: $theme-dark-headings-color !important;
								}
							}
						}
					}
				}
			}

			.form-control {
				background-color: darken($color: $theme-dark-card-bg, $amount: 4) !important;
				color: $theme-dark-body-color !important;
				border-color: transparent;

				&.has-value {
					background-color: $theme-dark-body-bg !important;
				}
			}

			label {
			}
		}
	}

	.custom-feature {
		&.incident-stage {
			.custom-main {
				.custom-row {
					.dropdown {
						hr {
							border-top: 1px solid $theme-dark-border-color;
						}
					}
				}
			}
		}
		.custom-main {
			.custom-row {
				.dropdown {
					hr {
						border-color: $theme-dark-body-bg;
					}

					.dot {
						background-color: $theme-dark-body-bg;
						border-color: $theme-dark-body-bg;

						&::after {
						}

						i {
						}
					}

					.multi-level {
						.custom-down {
							border-color: #fff;
						}

						> .dropdown-item {
						}

						.dropdown-divider {
						}

						.dropdown-submenu {
							&:hover {
								.dropdown-menu {
								}
							}

							> .dropdown-item {
								&::after {
								}
							}

							.dropdown-menu {
							}
						}
					}
				}
			}
		}
	}

	// Search Section //
	.main-search {
		.inner-search {
			.left-sec {
				&.search-sec {
					.search-input {
						.search-main-input {
							.input-group-text {
								background-color: $theme-dark-input-bg !important;
								border-color: $theme-dark-input-border-color !important;
							}

							.filter-btn {
								&::before {
									background-color: $theme-dark-border-color !important;
								}
							}
						}

						.search-modal {
							background-color: $theme-dark-card-bg !important;
							box-shadow: 0 2px 20px 0 rgba(255, 255, 255, 0.08) !important;

							.inner-modal {
								.filter-main {
									.filter-inner {
										.save-filter-list {
											.save-filter {
												background-color: $theme-dark-body-bg !important;

												> span {
													background-color: $theme-dark-card-bg !important;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// End of Search
	.gtr {
		border-bottom: 1px solid $theme-dark-border-color !important;
	}

	// sidebar for Toggle menu
	&.hasSubmenu {
		.side-pannel {
			&.activeSidebar {
				background-color: $theme-dark-card-bg !important;
			}
		}
	}

	// End of Sidebar for Toggle menu
	// Asset Life Cycle
	.asset-lifeCycle-main {
		.life-cycle-line {
			.lifeCycle-data-list {
				.data-list {
					.data {
						.detail-data {
							background-color: $theme-dark-body-bg !important;
							box-shadow: $theme-dark-box-shadow !important;
						}
					}
				}
			}
		}
	}

	// End of Asset Life Cycle

	//  Select Feature
	.selct-feature {
		.select-sec {
			.form-group {
				//   its commented due to dark mode select are not proper in servicedesk modules
				// .ng-select {
				// 	background-color: $theme-dark-input-bg !important;
				// 	&.has-value {
				// 		.ng-select-container {
				// 			// background-color: $theme-dark-card-bg !important;
				// 			background-color: $theme-dark-input-bg !important;
				// 		}
				// 	}

				// 	.ng-select-container {
				// 		background-color: $theme-dark-input-bg !important;
				// 		// background-color: $theme-dark-card-bg !important;
				// 		// background-color: darken($color: $theme-dark-card-bg , $amount: 4) !important;
				// 		color: $theme-dark-body-color !important;
				// 		// border-color: transparent;
				// 	}

				// 	&.ng-select-opened {
				// 		.ng-select-container {
				// 			background-color: $theme-dark-input-bg !important;
				// 			// background-color: $theme-dark-card-bg !important;
				// 			// background-color: darken($color: $theme-dark-card-bg , $amount: 4) !important;
				// 			color: $theme-dark-body-color !important;
				// 			// border-color: transparent;
				// 		}
				// 	}

				// 	.ng-dropdown-panel {
				// 		// background-color: $primary !important;
				// 		// border-color: $primary !important;
				// 		.ng-dropdown-panel-items {
				// 			.ng-option {
				// 				background-color: $theme-dark-card-bg !important;
				// 				color: $theme-dark-body-color !important;

				// 				&.ng-option-selected {
				// 					background-color: $theme-dark-body-bg !important;
				// 				}

				// 				&.ng-option-marked {
				// 					background-color: $theme-dark-body-bg !important;
				// 				}
				// 			}
				// 		}
				// 	}
				// }

				.form-control {
					background-color: darken($color: $theme-dark-card-bg, $amount: 4) !important;
					color: $theme-dark-body-color !important;
					border-color: transparent;

					&.has-value {
						background-color: $theme-dark-body-bg !important;
					}
				}

				label {
				}
			}

			.detail-wrapper {
				.detail-sec {
					.single-user-list {
						.user-list-sec {
							.group-name {
								span {
									color: $theme-dark-headings-color !important;
								}
							}
						}
					}
				}
			}
		}

		.detail-wrapper {
			.detail-sec {
				box-shadow: $theme-dark-box-shadow !important;
				background-color: $theme-dark-card-bg !important;
				border: 1px solid $theme-dark-border-color;

				&:hover {
					.remove-btn {
					}
				}

				.remove-btn {
				}

				.header-sec {
					.main-head {
						h3 {
							color: $theme-dark-body-color !important;
						}
					}

					.sub-head {
					}
				}

				.list-sec {
					.hours-list {
					}

					.list-head {
						color: $theme-dark-body-color !important;
					}

					.list-table {
						.table {
							thead {
								tr {
									th {
									}
								}
							}

							tbody {
								tr {
									td {
										.badge {
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// End of Select Feature

	// Card View //
	.card-view {
		.inner-card-view {
			.single-card {
				background-color: $theme-dark-card-bg !important;
				border-color: $theme-dark-border-color !important;

				.upper-section {
					border-color: $theme-dark-border-color !important;

					.info-sec {
						.icon-sec {
						}
					}

					.info-detail {
						.name {
						}

						.nick-name {
							color: $theme-dark-text-muted-color !important;
						}
					}
				}

				.lower-section {
					color: $theme-dark-body-color !important;

					a {
						color: $theme-dark-body-color !important;
					}
				}
			}
		}
	}

	// End of Card View //
	.table-view {
		.ngx-datatable {
			&.bootstrap {
				&.core-bootstrap {
					.visible {
						.datatable-footer {
							background-color: $theme-dark-table-header-bg !important;
							border: 1px solid $theme-dark-border-color !important;
						}

						.datatable-body {
							datatable-selection {
								.datatable-scroll {
									.datatable-row-wrapper {
										&:nth-child(2n + 1) {
											.datatable-body-row {
												.datatable-body-cell {
													background-color: $theme-dark-card-bg !important;
												}
											}
										}

										&:nth-child(2n) {
											.datatable-body-row {
												.datatable-body-cell {
													background-color: $theme-dark-body-bg !important;
												}
											}
										}

										&:last-of-type {
											.datatable-body-row {
												.datatable-body-cell {
													border-bottom: 1px solid $theme-dark-border-color !important;
												}
											}
										}

										.datatable-body-row {
											.datatable-body-cell {
												border-color: $theme-dark-border-color !important;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// Action Bar //
	.action-bar {
		border-color: $theme-dark-border-color !important;

		.inner-section {
			background-color: $theme-dark-card-bg !important;

			.left-section {
				.subheader {
					.toggale-submenu {
						.submenu-toggle {
							border-color: $theme-dark-border-color !important;
							color: $theme-dark-body-color !important;
						}
					}
				}
			}
		}
	}

	// End of Action Bar //
	.action-section {
		border-color: $theme-dark-border-color !important;
	}

	// End of Layout pages //

	// Card Pagination //
	.card-pagination {
		box-shadow: $theme-dark-box-shadow !important;
		background-color: $theme-dark-card-bg !important;

		.pagination-inner {
			.show-entries {
				.form-group {
				}
			}

			.pagination {
				background-color: $theme-dark-pagination-bg !important;

				.prv,
				.nxt {
					// background-color: $theme-dark-body-bg !important;
				}

				.number {
					.selected {
						&::before {
						}
					}
				}
			}
		}
	}

	// End of Card Pagination //
	.main-content {
		// border: 1px solid $theme-dark-border-color !important;
		.left-sec {
			border-right: 1px solid $theme-dark-border-color !important;
			&.border-0 {
				border-right: 0px !important;
			}
			.side-pannel {
				background-color: $theme-dark-card-bg !important;

				.sidebar {
					.sidebar-nav {
						.sidebar-item {
							&:hover {
								background-color: $theme-dark-body-bg !important;
								color: $theme-dark-headings-color !important;

								p,
								span {
									color: $theme-dark-headings-color !important;
								}
							}
						}
					}
				}
			}
		}
	}

	&.bordered-layout {
		.main-content {
			border: 1px solid $theme-dark-border-color !important;

			&.border-0 {
				border: 0px !important;
			}

			.left-sec {
				// border-right: 0px !important;
				.side-pannel {
					//   border: 1px solid $theme-dark-border-color !important;
					// border-right: 0 !important;
					.sidebar {
						.card {
							border: 0px !important;

							&.border {
								border: 1px solid $theme-dark-border-color !important;
							}
						}
					}
				}
			}

			.action-bar {
				border-bottom: 1px solid $theme-dark-border-color !important;
			}

			.action-section {
				border: none !important;
			}
		}

		// Card View //
		.card-view {
			.inner-card-view {
				.single-card {
					border-color: $theme-dark-border-color !important;
				}
			}
		}

		// End of Card View //

		.card-pagination {
			border-color: $theme-dark-border-color !important;
			box-shadow: none;
		}
	}

	// NGX-datatable //
	.ngx-datatable {
		&.bootstrap {
			&.core-bootstrap {
				.visible {
					.datatable-header {
						.datatable-header-inner {
							.datatable-row-center {
								.datatable-header-cell {
									.datatable-header-cell-wrapper {
										.datatable-header-cell-label {
											color: $theme-dark-headings-color !important;
										}
									}
								}
							}
						}
					}

					.datatable-body {
						datatable-selection {
							.datatable-scroll {
								.datatable-row-wrapper {
									&:nth-child(2n + 1) {
										.datatable-body-row {
											background-color: $theme-dark-card-bg !important;
										}
									}

									&:nth-child(2n) {
										.datatable-body-row {
											background-color: $theme-dark-body-bg !important;
										}
									}

									&:last-of-type {
										.datatable-body-row {
											border-bottom: 1px solid $theme-dark-border-color !important;
										}
									}

									.datatable-body-row {
										border-color: $theme-dark-border-color !important;
									}
								}
							}
						}
					}

					.datatable-footer {
						color: $theme-dark-body-color !important;

						.page-info {
							color: $theme-dark-body-color !important;
						}
					}
				}
			}
		}
	}

	// End of NGX-datatable //

	// Default Sideout Components //
	.default-slideout {
		&.slideout-content {
			.modal-dialog {
				.modal-content {
					.modal-header {
						background-color: $theme-dark-card-bg;
						border-bottom: 1px solid $theme-dark-border-color;

						.btn-icon {
							color: $theme-dark-body-color !important;

							&:hover {
								color: $theme-dark-body-color !important;
							}
						}
					}
				}
			}
		}
	}

	// Enb of default Sideout Components//

	// Theme Option Stepper
	.bs-stepper .bs-stepper-header .step .step-trigger .bs-getstepper-box {
		border: 2px solid $theme-dark-border-color;
		color: $theme-dark-body-color;
	}

	.theme-stepper {
		&.bs-stepper {
			&.vertical {
				.left-sec {
					.side-pannel {
						.sidebar {
							.bs-stepper-header {
								.step {
									.step-btn {
										.bs-stepper-label {
											.bs-stepper-title {
												color: $theme-dark-headings-color !important;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// End of Theme Option Stepper
	// Tree View //
	.tree-main {
		background-color: $theme-dark-card-bg !important;
		border: 1px solid $theme-dark-border-color !important;
	}

	.tree-container {
		background-color: $theme-dark-card-bg !important;

		.tree-view {
			background-color: $theme-dark-card-bg !important;

			li {
				color: $theme-dark-body-color !important;

				&::before {
					background-color: $theme-dark-border-color;
				}

				.edit-input-sec {
					border-bottom: 1px solid $theme-dark-border-color !important;
				}

				.child-tree {
					.child-tree {
						.edit-input-sec {
							border-top: 1px solid $theme-dark-border-color !important;
							border-bottom: 1px solid $theme-dark-border-color !important;
						}
					}
				}

				.tree-label {
					&::after {
						color: $theme-dark-body-color !important;
						background-color: $theme-dark-border-color !important;
					}

					&::before {
						color: $theme-dark-body-color !important;
					}

					&:hover {
						color: $theme-dark-body-color !important;
					}
				}
			}
		}
	}

	// End of tree View //

	.no-filter {
		.icon-sec {
			.dark-icon {
				display: block;
			}

			.light-icon {
				display: none;
			}
		}
	}

	.accordion {
		// accordion card
		.accordion-item {
			.accordion-header {
				button {
					span {
						color: $theme-dark-headings-color !important;
					}
				}
			}
		}
	}

	.default-stepping {
		&.slideout-content {
			.modal-dialog {
				.modal-content {
					.modal-header {
						background-color: $theme-dark-card-bg !important;
						border-bottom: 1px solid $theme-dark-border-color !important;

						.btn-icon {
							color: $theme-dark-body-color !important;

							&:hover {
								color: $theme-dark-body-color !important;
							}
						}
					}
				}
			}

			.stepper-btn {
				border-top: 1px solid $theme-dark-border-color !important;
			}
		}
	}

	.table {
		thead {
			tr {
				th {
					color: $theme-dark-headings-color !important;

					.custom-checkbox {
						.custom-control-label {
							color: $theme-dark-headings-color !important;
						}
					}
				}
			}
		}
	}

	.feedback-option {
		background-color: $theme-dark-card-bg !important;
	}

	// default Modal //
	.modal {
		.modal-header {
			background-color: $theme-dark-card-bg !important;
		}
	}

	// End of default Modal //

	// Requester List //
	.search-list-sec {
		.requester-lists {
			.single-requester {
				border-top: 1px solid $theme-dark-border-color !important;
				background-color: $theme-dark-card-bg !important;

				&:last-of-type {
					border-bottom: 1px solid $theme-dark-border-color !important;
				}

				&:hover {
					background-color: $theme-dark-body-bg !important;
				}
			}
		}
	}

	// End of Requester List //

	// Add incident //
	.add-incident {
		background-color: $theme-dark-card-bg !important;

		.left-sec {
			box-shadow: $theme-dark-box-shadow !important;
		}

		.middle-sec {
			box-shadow: $theme-dark-box-shadow !important;
		}

		.right-sec {
			.side-sec {
				border-left: 1px solid $theme-dark-border-color !important;
			}
		}
	}

	// End of Add Incident //

	// KB Pagination //
	.card-pagination {
		&.kb-pagination {
			border: 1px solid $theme-dark-border-color;
			box-shadow: $theme-dark-box-shadow !important;
		}
	}

	// End of KB Pagination//
	form {
		.error {
			span,
			small,
			p {
				color: $danger !important;
			}
		}
	}

	.input-group {
		.input-group-append {
			.btn {
				&.btn-outline-secondary {
					border: 1px solid $theme-dark-input-border-color !important;
				}
			}
		}
	}

	// Time line event //
	// End of default Modal //
	.timeline-event {
		.table {
			border-top: 1px solid $theme-dark-border-color !important;

			tbody {
				&:first-of-type {
					tr {
						th,
						td {
							border-top: 0 !important;
						}
					}
				}

				&:last-of-type {
					tr {
						th {
							border: 1px solid $theme-dark-border-color !important;
							border-top: 0px !important;
							border-right: 0 !important;

							&:last-of-type {
								border-bottom: 1px solid $theme-dark-border-color !important;
							}
						}

						td {
							border: 1px solid $theme-dark-border-color !important;
							border-top: 0px !important;

							&:last-of-type {
								border-bottom: 1px solid $theme-dark-border-color !important;
							}
						}
					}
				}

				tr {
					th {
						background-color: $theme-dark-table-header-bg !important;
						color: $theme-dark-headings-color !important;
						border: 1px solid $theme-dark-border-color !important;
						border-top: 0px !important;
						border-right: 0 !important;
						border-top: 0 !important;
					}

					td {
						border: 1px solid $theme-dark-border-color !important;
						border-top: 0px !important;
						// &:last-of-type{
						//   border-bottom: 1px solid $theme-dark-border-color !important !important;
						// }
					}
				}
			}
		}
	}

	// End of Time line event //

	// Incident Edit //
	.incident-edit-card {
		border-radius: 0px !important;

		&.card {
			border: 0px !important;
		}

		.incident-edit-tab {
			.nav-tabs {
				.nav-item {
					.nav-link {
						color: $theme-dark-text-muted-color;
					}
				}
			}

			.tab-content {
				.communication-badges {
					.badge {
						&.badge-light-secondary {
							padding: 2px 6px;
							border: 1px solid $theme-dark-border-color;
							color: $theme-dark-headings-color;
							background-color: $theme-dark-card-bg;
						}
					}
				}
			}
		}
	}

	// End of Incident Edit //
	.email_box {
		border: 1px solid $theme-dark-border-color;
	}

	// categotry Attachment For incident Edit //
	.category-attachment {
		background-color: $theme-dark-card-bg !important;
		border: 1px solid $theme-dark-border-color !important;
	}

	// End categotry Attachment For incident Edit //
	.progress-track {
		background-color: $theme-dark-border-color !important;
	}

	.progress-step {
		&:not(.is-complete) {
			&::before {
				border: 1px solid $theme-dark-border-color;
				color: $theme-dark-body-color;
				background-color: $theme-dark-card-bg !important;
			}
		}
	}

	// Add Dashboard Model
	.dot-bg-sec {
		background: url(../../../../assets/images/svg/dot-dark-svg.svg) !important;
		border-color: $theme-dark-border-color !important;
		background-size: 15px !important;
	}

	.dashboard-modal {
		.dashboard-head {
			background-color: $theme-dark-card-bg !important;
			border-bottom: 1px solid $theme-dark-border-color !important;
		}

		.dashboard-body {
			background-color: $theme-dark-card-bg !important;

			.dashboard-name {
				.form-group {
					label {
						color: $theme-dark-headings-color !important;
					}
				}
			}

			.dashboard-type {
				.type-head {
					color: $theme-dark-headings-color !important;
				}

				.type-list {
					.type {
						border-color: $theme-dark-border-color !important;

						.type-inner {
							.type-head {
								color: $theme-dark-headings-color !important;
							}
						}
					}
				}
			}
		}

		.dashboard-footer {
			background-color: $theme-dark-card-bg !important;
			border-color: $theme-dark-border-color !important;
		}
	}

	// End of Add Dashboard
	// Widget Confrigration //
	.widget-grid {
		background: url(../../../../assets/images/svg/dot-dark-svg.svg) !important;
		border-color: $theme-dark-border-color !important;
		background-size: 15px !important;

		.scrollVertical {
			.gridster-column {
				border-color: $theme-dark-border-color !important;
			}

			.gridster-row {
				border-color: $theme-dark-border-color !important;
			}
		}

		gridster-item {
			background-color: $theme-dark-card-bg !important;

			.configuration-widget {
				background-color: $theme-dark-card-bg !important;
				border: 1px solid $theme-dark-border-color;

				.widget-inner {
					background-color: $theme-dark-card-bg !important;

					.widget-head {
						background-color: $theme-dark-card-bg !important;
						border-bottom: 1px solid $theme-dark-border-color !important;
					}
				}
			}
		}
	}

	// End of Widget Confrigration //
	// Assignee Dashboard Modal //
	.assignee-modal {
		.accordion {
			.accordion-item {
				border-color: $theme-dark-border-color !important;
				border: none !important;
				border-top: 1px solid $theme-dark-border-color !important;

				&:first-of-type {
					border-top: none !important;
				}
			}
		}
	}

	.icon-search {
		.form-group {
			&::before {
				filter: brightness(0.5);
			}
		}
	}

	.assignee-footer {
		border-top: 1px solid $theme-dark-border-color !important;
	}

	// Assignee Dashboard Modal //
	// Schedular Dashboard Modal //
	.schedule-main-sec {
		.schedule-head-info {
			.close-btn {
				background-color: $theme-dark-card-bg !important;
			}
		}

		.assign-sec {
			.format-sec {
				border-top: 1px solid $theme-dark-border-color !important;
			}

			.assign {
				.form-group {
					label {
						background-color: $theme-dark-card-bg !important;
						border: 1px solid $theme-dark-border-color !important;
					}
				}
			}
		}
	}

	.schedule-footer {
		border-top: 1px solid $theme-dark-border-color !important;
	}

	// End of Schedular Dashboard Modal //
	// Widget Configuration Dashboard Modal //
	.widget-body {
		.stepping-form {
			.bs-stepper {
				background-color: $theme-dark-card-bg !important;

				.bs-stepper-header {
					border-bottom: 1px solid $theme-dark-border-color !important;
				}

				.bs-stepper-content {
					.widget-form {
						.content {
							form {
								.stepper-main {
									.widget-details {
										.widget-lists {
											background-color: $theme-dark-body-bg !important;

											.widget-items {
												.widget-item {
													border: 1px solid $theme-dark-border-color !important;
													background-color: $theme-dark-card-bg !important;

													.widget-info {
														.widget-head {
															color: $theme-dark-headings-color !important;
														}
													}
												}
											}
										}
									}

									&.configuration-detail {
										.configuration-nav {
											background-color: $theme-dark-card-bg !important;

											.tab-content {
												border-top: 1px solid $theme-dark-border-color !important;

												.config {
													&.other-option {
														.label {
															color: $theme-dark-body-color !important;
														}
													}

													&.chart-type {
														.chart-options {
															.option {
																color: $theme-dark-body-color !important;
																border: 1px solid $theme-dark-border-color !important;
															}
														}
													}

													&.color-palette {
														.label {
															color: $theme-dark-body-color !important;
														}

														.palette-modal {
															background-color: $theme-dark-card-bg !important;

															.modal-head {
																border-bottom: 1px solid $theme-dark-border-color !important;
															}
														}
													}

													&.image-selector {
														.img-selector-modal {
															background-color: $theme-dark-card-bg !important;
															box-shadow: $theme-dark-box-shadow;

															.tab-content {
																.icon-list-sec {
																	border-bottom: 1px solid $theme-dark-border-color !important;

																	.icon-list {
																		.single-icon {
																			background: $theme-dark-body-bg !important;
																			color: $theme-dark-body-color !important;
																		}
																	}
																}

																.img-btn-group {
																	border-top: 1px solid $theme-dark-border-color !important;
																}
															}
														}
													}
												}

												.seperator {
													background-color: $theme-dark-body-bg !important;
												}
											}
										}

										.configuration-widget {
											background-color: $theme-dark-body-bg !important;

											.widget-inner {
												background-color: $theme-dark-card-bg !important;
												border: 1px solid $theme-dark-border-color !important;

												.widget-head {
													border-bottom: 1px solid $theme-dark-border-color !important;
													background-color: $theme-dark-card-bg !important;
												}

												.widget-main {
													background-color: $theme-dark-card-bg !important;
												}
											}
										}
									}
								}

								.stepper-btn {
								}
							}
						}
					}
				}
			}
		}
	}

	// Widget Configuration Dashboard Modal //

	// mat-table //
	.mat-table {
		&.custom-material-table {
			thead {
				background-color: $theme-dark-table-header-bg !important;

				.mat-header-row {
					.mat-header-cell {
						color: $theme-dark-headings-color !important;

						span {
							color: $theme-dark-headings-color !important;
						}

						&.dotted_line {
							&::before {
								border-right: 1px dashed $theme-dark-headings-color !important;
							}
						}
					}
				}
			}

			tbody {
				.mat-row {
					// border-top: 0px !important;
					border-top: 1px solid $theme-dark-border-color !important;

					.mat-cell {
						color: $theme-dark-headings-color !important;

						&.dotted_line {
							&::before {
								border-right: 1px dashed $theme-dark-headings-color !important;
							}
						}

						// border-top: 1px solid $theme-dark-border-color !important;
						.tree-dropdown {
							.dropdown {
								.dropdown-toggle {
									color: transparent !important;

									span.block:not(.placeholder-txt) {
										color: $theme-dark-body-color !important;
									}

									&::after {
										background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23CFD6E5' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
										color: $theme-dark-body-color !important;
									}

									&:hover {
										border-color: $theme-dark-input-border-color !important;
									}
								}
							}
						}

						.selct-feature {
							.select-sec {
								.form-group {
									&:hover {
										.ng-select {
											background-color: $theme-dark-input-bg !important;

											&.ng-select-opened {
												.ng-select-container {
													border-color: $theme-dark-input-border-color !important;
													background-color: $theme-dark-input-bg !important;
												}
											}

											.ng-select-container {
												border-color: $theme-dark-input-border-color !important;
												background-color: $theme-dark-input-bg !important;
											}
										}
									}

									.ng-select {
										background-color: $theme-dark-input-bg !important;

										&.ng-select-opened {
											.ng-select-container {
												border-color: $theme-dark-input-border-color !important;
												background-color: $theme-dark-input-bg !important;
											}
										}
									}
								}
							}
						}

						&.mat-table-sticky {
							&.mat-table-sticky-border-elem-right {
								background: linear-gradient(
									270deg,
									$theme-dark-card-bg 0%,
									rgba($color: $theme-dark-card-bg, $alpha: 0) 100%
								);
							}
						}

						&.mat_cell {
							&.summary_card {
								border-right: 1px solid $theme-dark-border-color !important;
							}
						}
					}

					&:nth-child(odd) {
						background-color: $theme-dark-card-bg !important;

						&.highlight {
							.mat-cell {
								.on_select {
									// background-color: $theme-dark-body-bg !important;
								}
							}
						}

						.mat-cell {
							.on_select {
								// background-color: $theme-dark-card-bg !important;
							}
						}

						.mat-cell {
							&.mat-table-sticky {
								&.mat-table-sticky-border-elem-right {
									// background-color: $theme-dark-body-bg !important;
									// box-shadow: $theme-dark-box-shadow;
								}
							}
						}
					}

					&:nth-child(even) {
						background-color: $theme-dark-body-bg !important;

						&.highlight {
							.mat-cell {
								.on_select {
									// background-color: $theme-dark-card-bg !important;
								}
							}
						}

						.mat-cell {
							.on_select {
								// background-color: $theme-dark-body-bg !important;
							}
						}

						.mat-cell {
							&.mat-table-sticky {
								&.mat-table-sticky-border-elem-right {
									// background-color: $theme-dark-card-bg !important;
									// box-shadow: $theme-dark-box-shadow;
								}
							}
						}
					}

					&:first-of-type {
						// border-top: none !important;
						.mat-cell {
							border-top: none !important;
						}
					}

					&:last-of-type {
						border-bottom: 1px solid $theme-dark-border-color !important;

						.mat-cell {
							// border-bottom: 1px solid $theme-dark-border-color !important;
						}
					}
				}
			}
		}
	}

	// End of mat-table //
	// custom tag
	.custom-tags-container {
		.custom-tag {
			border-color: $theme-dark-input-border-color;
			color: $theme-dark-headings-color;
		}
	}

	.btn-comment {
		.popover-comment {
			box-shadow: $theme-dark-box-shadow !important;

			&::before {
				color: $theme-dark-border-color !important;
			}
		}
	}

	// Selected column Module
	.column-select-modal {
		.modal-body {
			.column-left-sec {
				.column-list {
					.single-column {
						border: 1px solid $theme-dark-border-color !important;
						background-color: $theme-dark-card-bg !important;
						color: $theme-dark-body-color !important;
					}
				}
			}

			.column-right-sec {
				.single-selected-col {
					background-color: $theme-dark-body-bg !important;

					.column-count {
						background-color: transparent !important;
						border: 1px solid $theme-dark-border-color !important;
					}
				}
			}
		}
	}

	// End of Selected Column Module
	.badge.badge-light-dark {
		background-color: rgba(75, 75, 75, 0.25);
		color: $theme-dark-body-color !important;
	}

	// custom tag
	.custom-tags-container {
		.custom-tag {
			border-color: $theme-dark-input-border-color !important;
			color: $theme-dark-headings-color !important;
		}
	}

	// asset summary location progressbar style
	.location-life-cycle-line {
		.cycle-line {
			background-color: $theme-dark-body-bg !important;
		}
	}

	// start : custom data list
	.custom-data-list {
		background-color: $theme-dark-table-bg !important;

		.table {
			th,
			td {
				color: $theme-dark-label-color !important;
				border-color: $theme-dark-border-color !important;
			}
		}
	}

	// end : custom data list

	// modal close button
	.default-stepping.slideout-content .modal-dialog .modal-content .modal-header .btn-icon {
		color: $theme-dark-body-color;

		&:hover,
		&:focus {
			color: $theme-dark-body-color;
		}
	}

	// custom tree view component design

	.sidebar-tree-view-menu {
		.tree-node {
			.toggle-children {
				background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.44063 7.91406C1.24844 8.02812 1.00938 8.02812 0.814065 7.91718C0.620315 7.80625 0.500002 7.59843 0.500002 7.375L0.500002 0.624999C0.500002 0.400937 0.620315 0.193906 0.814065 0.0826088C1.00938 -0.0287349 1.24844 -0.0274381 1.44063 0.0859838L7.19141 3.46094C7.3825 3.57344 7.5 3.77812 7.5 4C7.5 4.22187 7.3825 4.42656 7.19141 4.53906L1.44063 7.91406Z' fill='%23b4b7bd'/%3E%3C/svg%3E");
			}

			// &.tree-node-expanded > tree-node-wrapper {
			//   .toggle-children {
			//     background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.44063 7.91406C1.24844 8.02812 1.00938 8.02812 0.814065 7.91718C0.620315 7.80625 0.500002 7.59843 0.500002 7.375L0.500002 0.624999C0.500002 0.400937 0.620315 0.193906 0.814065 0.0826088C1.00938 -0.0287349 1.24844 -0.0274381 1.44063 0.0859838L7.19141 3.46094C7.3825 3.57344 7.5 3.77812 7.5 4C7.5 4.22187 7.3825 4.42656 7.19141 4.53906L1.44063 7.91406Z' fill='%231A5AD9'/%3E%3C/svg%3E%0A");
			//   }
			// }
		}
	}

	// end custom tree view component design

	// card design for add categories side layout

	.category-properties-card {
		&:hover {
			background-color: $theme-dark-body-bg;
		}
	}

	// Custom Radio //
	.custom-card-radio {
		.custom-control-label {
			border: 1px solid $theme-dark-border-color;
		}
	}

	// End of Custom Radio //

	// Stepping form //
	.default-stepping {
		&.slideout-content {
			.modal-body {
				.bs-stepper {
					.bs-stepper-header {
						.line {
							color: $theme-dark-body-color !important;
						}

						.step {
							&:not(.active) {
								.step-trigger {
									.bs-stepper-box {
									}

									.bs-stepper-label {
										.bs-stepper-title {
											color: $theme-dark-headings-color !important;
										}

										.bs-stepper-subtitle {
											color: $theme-dark-body-color !important;
										}
									}
								}
							}

							.step-trigger {
								.bs-stepper-label {
									.bs-stepper-subtitle {
										color: $theme-dark-body-color !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// End of Stepping form //

	.main-constraint-sec {
		.roll-sec {
			&.add-new-role {
				border: 1px dashed $theme-dark-border-color !important;
			}
		}
	}

	// Custom Modal //
	.modal {
		&.custom-modal {
			.modal-content {
				.modal-header {
					background-color: $theme-dark-card-bg !important;
					border-bottom: 1px solid $theme-dark-border-color !important;

					.btn-icon {
						color: $theme-dark-body-color !important;

						&:hover {
							color: $theme-dark-headings-color !important;
						}
					}
				}

				.modal-body {
					background-color: $theme-dark-body-bg !important;
				}
			}
		}
	}

	// End of Custom Modal //

	// Tree Dropdown //
	.tree-dropdown {
		&.single-select {
			.dropdown {
				.btn {
					&.dropdown-toggle {
						span:not(.placeholder-txt) {
							color: $headings-color;
						}
					}
				}
			}

			.dropdown-container {
				.treeview-container {
					.treeview-item {
						.row-item {
							span {
								.feather {
									color: $theme-dark-body-color;
								}
							}

							.form-check-label {
								color: $theme-dark-body-color;
							}
						}
					}
				}
			}
		}

		.dropdown {
			.btn {
				&.dropdown-toggle {
					border: 1px solid $theme-dark-border-color !important;

					span:not(.placeholder-txt) {
						color: $theme-dark-body-color !important;
					}
				}
			}

			.dropdown-menu {
				.dropdown-container {
					.treeview-container {
						.treeview-item {
							.feather {
								color: $theme-dark-body-color;
							}

							.form-check-label {
								color: $theme-dark-body-color;
							}

							.form-inline {
								.feather {
									color: $theme-dark-body-color;
								}

								.form-check-label {
									color: $theme-dark-body-color;
								}
							}
						}
					}
				}
			}
		}
	}

	// End of Tree Dropdown //
	// Comman NG-select //
	.ng-select {
		.ng-dropdown-panel {
			.ng-dropdown-panel-items {
				&.scroll-host {
					@extend .browser-scrollbar;
				}

				.ng-option {
					&.ng-option-marked {
						color: $theme-dark-headings-color !important;
						small {
							color: $theme-dark-headings-color !important;
						}
						// background-color: $theme-dark-body-bg !important;
					}
				}
			}
		}
	}
	.ng-dropdown-panel {
		.ng-dropdown-panel-items {
			&.scroll-host {
				@extend .browser-scrollbar;
			}

			.ng-option {
				&.ng-option-marked {
					background-color: rgba($color: $primary, $alpha: 0.12) !important;
					color: $theme-dark-headings-color !important;
				}
			}
		}
	}

	// End of Ng-select //
	// Input Tree Dropdown //
	.input-tree-dropdown {
		.tree-dropdown {
			&.single-select {
				.dropdown {
					.dropdown-toggle {
						border-color: $theme-dark-input-border-color !important;
						color: $theme-dark-headings-color;

						&::after {
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23CFD6E5' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
						}

						span:not(.placeholder-txt) {
							color: $theme-dark-body-color;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							width: 250px;
						}
					}
				}
			}
		}
	}

	// Input Tree Dropdown //
	.single-kb {
		.kb-user {
			&::before {
				background-color: $theme-dark-border-color !important;
			}
		}
	}

	.single-interaction {
		&::before {
			background-color: $theme-dark-border-color !important;
		}
	}

	// Date picker //

	ngb-datepicker-navigation {
		.form-select {
			background-color: $theme-dark-input-bg !important;
			border-color: $theme-dark-input-border-color !important;
			color: $theme-dark-headings-color !important;
		}
	}

	.input-group-append {
		.btn {
			// color: $theme-dark-input-border-color !important;
		}
	}

	.custom-range-select-group {
		.input-group {
			.input-group-prepend {
				.input-group-text {
				}
			}

			date-range-picker {
				.selct-feature {
					.select-sec {
						.ng-select {
							background-color: $theme-dark-input-bg !important;

							&.form-control {
								background-color: $theme-dark-input-bg !important;
								border-color: $theme-dark-input-border-color !important;

								.ng-select-container {
									background-color: $theme-dark-input-bg !important;
									border-color: $theme-dark-input-border-color !important;
								}
							}
						}
					}
				}
			}
		}
	}

	// End of Datepicker //
	.date-range-inner {
		.date-range-container {
			.list-group-section {
				.list-group {
					.list-group-item {
						&.active {
							background-color: rgba($color: $primary, $alpha: 0.12) !important;
							color: $primary !important;
						}
					}
				}
			}

			.date-range-section {
				.q-datepicker {
					ngb-datepicker {
						.ngb-dp-header {
							background-color: $theme-dark-card-bg !important;

							ngb-datepicker-navigation {
								.ngb-dp-arrow {
									.btn-link {
										span {
										}
									}
								}

								.ngb-dp-month-name {
									color: $theme-dark-headings-color !important;
									background-color: #131a2e !important;
								}
							}
						}

						.ngb-dp-months {
							.ngb-dp-month {
								ngb-datepicker-month {
									.ngb-dp-week {
										background-color: $theme-dark-card-bg !important;

										&.ngb-dp-weekdays {
											.ngb-dp-weekday {
												color: $theme-dark-headings-color !important;
												background-color: $theme-dark-card-bg !important;
											}
										}

										.ngb-dp-day {
											.custom-day {
												background-color: $theme-dark-card-bg !important;

												&.range:not(.faded) {
													// border-radius: 100%
													background-color: $primary !important;
												}

												&.range {
													&.faded {
														background-color: rgba(
															$color: $primary,
															$alpha: 0.12
														) !important;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// Quill Editer Card //
	.quill-card {
		background-color: $theme-dark-body-bg !important;

		quill-editor {
			background-color: $theme-dark-card-bg !important;
		}
	}

	form {
		quill-editor {
			.ql-toolbar {
				&.ql-snow {
					.ql-picker {
						&.ql-expanded {
							.ql-picker-label {
								border: 1px solid $theme-dark-border-color !important;

								[aria-expanded="true"] {
									border: 1px solid $theme-dark-border-color !important;
								}
							}

							.ql-picker-options {
								border: 1px solid $theme-dark-border-color !important;

								[aria-hidden="false"] {
									border: 1px solid $theme-dark-border-color !important;
								}
							}
						}
					}
				}
			}

			&.error {
				.ql-container {
					color: $theme-dark-body-color !important;
					border: 1px solid $danger !important;
				}

				.quill-toolbar,
				.ql-toolbar {
					background-color: $theme-dark-input-bg;
					border-color: $theme-dark-border-color !important;

					&.ql-snow {
						.ql-picker {
							&.ql-expanded {
								.ql-picker-label {
									border: 1px solid $theme-dark-border-color !important;

									[aria-expanded="true"] {
										border: 1px solid $theme-dark-border-color !important;
									}
								}

								.ql-picker-options {
									border: 1px solid $theme-dark-border-color !important;

									[aria-hidden="false"] {
										border: 1px solid $theme-dark-border-color !important;
									}
								}
							}
						}
					}

					// toolbar color
					.ql-picker {
						color: $white !important;
					}

					.ql-stroke {
						stroke: $white !important;
					}
					button {
						color: $white !important;
					}
					.ql-fill {
						fill: $white !important;
					}

					// toolbar options colors
					.ql-picker-options,
					.ql-picker-label {
						background-color: $theme-dark-input-bg;

						.ql-active {
							color: $primary !important;
						}
					}
				}

				.ql-active {
					.ql-stroke {
						stroke: $primary !important;
					}

					.ql-fill {
						fill: $primary !important;
					}
				}

				.ql-bubble {
					.ql-toolbar {
						background: $theme-dark-border-color;
						border-radius: 2rem;
					}
				}

				.ql-container {
					border-color: $theme-dark-border-color !important;
					background-color: $theme-dark-input-bg;
				}
			}
		}
	}

	// Quill Editer Card //

	//  Workflow Canvas //
	// .workflow-canvas{
	// 	.blockelem{
	// 		border: 1px solid $theme-dark-border-color !important;
	// 		.blockydiv{
	// 			background-color: $theme-dark-border-color !important;
	// 		}
	// 		&.cond-block{
	// 			&.bg-success{
	// 				background-color: $success !important;
	// 			}
	// 			&.bg-danger{
	// 				background-color: $danger !important;
	// 			}
	// 		}
	// 	}
	// }
	//  End of Workflow Canvas //

	// Notification Bar //
	.notification-bar {
		color: $white !important;
		background-color: rgba($color: $warning, $alpha: 0.9) !important;
	}

	// End of Notification bar //

	.email-application .content-area-wrapper .email-user-list .email-media-list li.app-message {
		background-color: $theme-dark-card-bg !important;
	}

	// Custom Device popover //
	.custom-filter-popover {
		box-shadow: 0 4px 24px 0 rgba($dark-black, 0.24) !important;
	}

	// Custom Device popover //
	.custom-filter-popover {
		box-shadow: 0 4px 24px 0 rgba($dark-black, 0.24) !important;
	}

	// End of Custom Device popover //
	.all-categories {
		&:hover {
			.show-on-hover {
				// background: linear-gradient(0deg, $theme-dark-card-bg 100%, rgba($color: $theme-dark-card-bg, $alpha: 0) 100%) !important;
				background: $theme-dark-card-bg !important;
				border-radius: 2px;
				padding: 2px;
				box-shadow: $theme-dark-box-shadow !important;
			}
		}
	}

	.single-filter {
		@include max-mq(567) {
			border-bottom: 1px solid $theme-dark-border-color !important;
		}
	}

	input {
		&.form-control {
			&.transparent-bg {
				background-color: transparent !important;
			}
		}
	}

	.collapse-icon {
		[aria-expanded="true"],
		[aria-expanded="false"] {
			&:after {
				content: "";
				background-image: url(str-replace(
					str-replace($chevron-up, "currentColor", $theme-dark-headings-color),
					"#",
					"%23"
				)) !important;
			}
		}
	}

	// TinyMCA for Dark Mode //
	.tox {
		&.tox-tinymce {
			border: 2px solid $theme-dark-input-border-color !important;

			.tox-editor-container {
				.tox-editor-header {
					background-color: $theme-dark-input-bg !important;
					border-bottom: 1px solid $theme-dark-input-border-color !important;

					.tox-menubar {
						background-color: $theme-dark-input-bg !important;
						color: $theme-dark-body-color !important;

						.tox-mbtn {
							color: $theme-dark-body-color !important;

							&:hover:not(:disabled):not(.tox-mbtn--active) {
								background-color: rgba($color: $theme-dark-body-bg, $alpha: 0.45);
							}

							&.tox-tbtn--bespoke {
								background-color: $theme-dark-input-bg !important;
								color: $theme-dark-body-color !important;
							}

							&.tox-tbtn--select {
								background-color: $theme-dark-input-bg !important;
								color: $theme-dark-body-color !important;
							}

							.tox-icon {
								svg {
									fill: $theme-dark-body-color !important;
								}
							}
						}
					}

					.tox-toolbar-overlord {
						background-color: $theme-dark-input-bg !important;
						color: $theme-dark-body-color !important;

						.tox-toolbar__primary {
							background-color: $theme-dark-input-bg !important;
							color: $theme-dark-body-color !important;

							.tox-toolbar__group {
								background-color: $theme-dark-input-bg !important;

								.tox-tbtn {
									color: $theme-dark-body-color !important;

									&:hover:not(:disabled):not(.tox-mbtn--active) {
										background-color: rgba($color: $theme-dark-body-bg, $alpha: 0.45);
									}

									&.tox-tbtn--bespoke {
										background-color: $theme-dark-input-bg !important;
										color: $theme-dark-body-color !important;
									}

									&.tox-tbtn--select {
										background-color: $theme-dark-input-bg !important;
										color: $theme-dark-body-color !important;
									}

									.tox-icon {
										svg {
											fill: $theme-dark-body-color !important;
										}
									}
								}

								.tox-mbtn {
									color: $theme-dark-body-color !important;

									&:hover:not(:disabled):not(.tox-mbtn--active) {
										background-color: rgba($color: $theme-dark-body-bg, $alpha: 0.45);
									}

									&.tox-tbtn--bespoke {
										background-color: $theme-dark-input-bg !important;
										color: $theme-dark-body-color !important;
									}

									&.tox-tbtn--select {
										background-color: $theme-dark-input-bg !important;
										color: $theme-dark-body-color !important;
									}

									.tox-icon {
										svg {
											fill: $theme-dark-body-color !important;
										}
									}
								}
							}
						}
					}
				}

				.tox-sidebar-wrap {
					background-color: $theme-dark-input-bg !important;

					.tox-edit-area {
						background-color: $theme-dark-input-bg !important;

						.tox-edit-area__iframe {
							background-color: $theme-dark-input-bg !important;
							color: $theme-dark-body-color !important;
						}
					}

					.tox-sidebar {
						background-color: $theme-dark-input-bg !important;

						.tox-sidebar__slider {
							background-color: $theme-dark-input-bg !important;

							.tox-sidebar__pane-container {
								background-color: $theme-dark-input-bg !important;
							}
						}
					}
				}
			}

			.tox-statusbar {
				background-color: $theme-dark-input-bg !important;
				border-top: 1px solid $theme-dark-input-border-color !important;

				.tox-statusbar__text-container {
					color: $theme-dark-body-color !important;

					.tox-statusbar__path {
						color: $theme-dark-body-color !important;

						.tox-statusbar__path-item {
							color: $theme-dark-body-color !important;
						}
					}

					.tox-statusbar__branding {
						.tox-statusbar__branding {
							color: $theme-dark-body-color !important;

							svg {
								fill: $theme-dark-body-color !important;
							}
						}
					}
				}
			}
		}
	}

	.mce-content-body {
		color: $theme-dark-body-color !important;
	}

	.right-click-node {
		background-color: $theme-dark-card-bg !important;
		border: 1px solid $theme-dark-border-color !important;

		.detail-info {
			ul {
				li {
					color: $theme-dark-body-color !important;
				}
			}
		}

		&.show {
			display: block;
		}
	}

	.cc-mail-list {
		&.popover {
			border: 1px solid $theme-dark-border-color !important;

			> .arrow {
				&::before {
					border-bottom-color: $theme-dark-border-color !important;
				}

				&::after {
					border-bottom-color: $theme-dark-card-bg !important;
				}
			}
		}
	}

	// End of TinyMCA for Dark Mode //
	// .custom-color-list{
	// 	.bg-white{
	// 		background-color: $theme-dark-card-bg !important;
	// 	}
	// }

	.inactive-sec {
		.inactive {
			.inactive-inner {
				.inactive-info {
					h2 {
						color: $white !important;
					}

					p {
						color: $theme-dark-body-color !important;
					}

					.administratorsList {
						ul {
							li {
								color: $theme-dark-body-color !important;
							}
						}
					}
				}
			}
		}
	}

	.macros-modal {
		background-color: $theme-dark-card-bg;
		box-shadow: $theme-dark-box-shadow;
		border: 1px solid $theme-dark-border-color;

		.macros-head {
			border-bottom: 1px solid $theme-dark-border-color;
		}

		.macros-list {
			background-color: $theme-dark-card-bg;
		}

		.macros-bottom {
			background-color: $theme-dark-card-bg;
			border-top: 1px solid $theme-dark-border-color;
		}
	}

	.popover {
		&.selected-node-popover {
			.popover-body {
				border: 1px solid $theme-dark-border-color !important;
			}
		}
	}

	.network-detail-section {
		.select-path-list {
			.path-list {
				.single-path {
					.node-list {
						&::before {
							background-color: $theme-dark-border-color !important;
						}
					}
				}
			}
		}
	}

	.vis-network-section {
		#visNetwork {
			.vis-network {
				.vis-navigation {
					.vis-button {
						background-color: $theme-dark-body-bg !important;
					}
				}
			}

			.vis-tooltip {
				background-color: $theme-dark-card-bg !important;
				border: 1px solid $theme-dark-border-color !important;
			}
		}
	}

	.editable-select {
		.form-group {
			.ng-select {
				.ng-dropdown-panel {
					.ng-dropdown-panel-items {
						.ng-option {
							&:last-of-type {
								border-top: 1px solid $theme-dark-border-color !important;
							}
						}
					}
				}
			}
		}
	}

	.keybtn {
		background-color: $theme-dark-body-bg !important;
		box-shadow: inset 0 -2px 0 0 rgba($theme-dark-card-bg, 0.75) !important;
	}

	// SLA //
	.sla-left-sec {
		.sla-left-inner {
			.left-inner {
				border-bottom: 2px solid $theme-dark-border-color !important;
			}
		}
	}

	.sla-main-sec {
		.horizontal-wizard {
			.bs-stepper {
				.bs-stepper-content {
					.content {
						.divider {
							border-bottom: 2px dashed $theme-dark-border-color;
						}

						.traget-profile-sec {
							.target-profile {
								border-bottom: 2px dashed $theme-dark-border-color;
							}
						}

						.detail-wrapper {
							.detail-sec {
								border-color: $theme-dark-border-color;
								background-color: $theme-dark-card-bg;

								.header-sec {
									.main-head {
										h3 {
											color: $theme-dark-headings-color;
										}
									}
								}
							}
						}

						.card {
							.card-body {
								.custom-select-feature {
									.bottom-sec {
										.drop-btn {
											&::before {
												border-bottom: 1px dashed $theme-dark-border-color;
											}
										}
									}
								}

								.inner-sec {
									border-bottom: 2px dashed $theme-dark-border-color;

									.select-frequency {
										.select-item {
											border-left: 2px dashed $theme-dark-border-color;
											@include max-mq(600) {
												border-bottom: 2px dashed $theme-dark-border-color;
											}
										}
									}

									.content-header {
										h5 {
											color: $theme-dark-headings-color;
										}
									}

									.form-group {
										&.custom-date-picker {
											.input-group {
												.input-group-append {
													.btn {
														color: $theme-dark-headings-color;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// SLA Inner Pages //
	.profile-inner-card {
		.profile-head-sec {
			.main-head {
				h3 {
					color: $theme-dark-headings-color;
				}
			}
		}

		.profile-inner-main {
			.profile-main {
				.main-inner {
					.main-repeat-sec {
						.main-repeat-inner {
							.main-event {
								.sub-event {
									border-bottom: 2px dashed $theme-dark-border-color;

									.inner-event {
										.event-select {
											&.custom-date-picker {
												.date-input-group {
													&.disableDate {
														.ng2-flatpickr-input-container {
															.form-control {
																background-color: $theme-dark-input-disabled-bg;
															}
														}
													}

													.icon-calendar {
														color: $theme-dark-headings-color;
													}
												}

												.input-group {
													.input-group-append {
														.btn {
															color: $theme-dark-headings-color;
														}
													}
												}
											}
										}
									}
								}

								.row {
									.col-12 {
										border-bottom: 2px dashed $theme-dark-border-color;
									}
								}

								.event-head {
									color: $theme-dark-headings-color;
								}
							}
						}
					}
				}
			}
		}
	}

	.request-sec {
		.main-inner {
			&.sub-inner {
				border-top: 2px dashed $theme-dark-border-color !important;
			}

			.box-sec {
				.card {
					box-shadow: $box-shadow !important;

					.card-body {
						.other-info {
							border-bottom: 1px solid $theme-dark-border-color !important;

							.info-col {
								border-right: 1px solid $theme-dark-border-color !important;
							}
						}

						.comp-percentage {
							border-bottom: 1px solid $theme-dark-border-color !important;
						}
					}
				}
			}
		}
	}

	.request-form-sec {
		.main-inner {
			.main-repeat-sec {
				.main-repeat-inner {
					.main-event {
						.main-form {
							.form-detail {
								.sub-event {
									.row {
										.col-12 {
											.custom-date-picker {
												.input-group {
													.input-group-append {
														.btn {
															color: $theme-dark-headings-color !important;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.sla-option {
		.option-list {
			.option-item {
				&.down-item {
					.icon-btn {
						color: $theme-dark-headings-color !important;
					}
				}
			}
		}
	}

	.sla-card-view {
		.inner-card-view {
			.single-card {
				&.card {
					.card-body {
						.uppersec {
							.head-sec {
								.time-sec {
									.time-info {
										.time-txt {
											color: $theme-dark-headings-color !important;
										}

										span {
											color: $theme-dark-headings-color !important;
										}
									}
								}
							}
						}

						.lower-sec {
							.infor-sec {
								.single-infor {
									&.applied-info {
										strong,
										span {
											color: $theme-dark-headings-color !important;
										}
									}

									strong,
									span {
										color: $theme-dark-headings-color !important;
									}

									p {
										color: $theme-dark-headings-color !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// End of SLA Inner Pages //
	// ENd of SLA //

	dns-pagination {
		.datatable-footer {
			padding: 0 !important;

			.datatable-pager {
				margin: 0;
				display: flex;
				@include max-mq(600) {
					display: flex;
					justify-content: flex-end;
					margin-right: 1rem !important;
				}

				.pager {
					height: 30px;
					background-color: $pagination-bg;
					border-radius: 28px;
					display: flex !important;
					align-items: center;
					justify-content: center;

					.datatable-icon-left,
					.datatable-icon-skip,
					.datatable-icon-prev,
					.datatable-icon-right {
						font-size: 18px;
						padding: 0 3px;
						line-height: 12px;
					}

					li {
						margin: 1px 0 !important;

						&:hover {
							a {
								color: $primary;
							}
						}

						a {
							height: 20px;
							min-width: 24px;
							color: #cfd6e5;
							height: 20px;
							min-width: 24px;
							line-height: 20px;
							display: flex !important;
							align-items: center;
							justify-content: center;
							margin: 0 3px;
							font-size: 13px;

							&:hover {
								color: $primary;
							}
						}

						&.disabled {
							&:hover {
								a {
									color: #636363 !important;
								}
							}

							a {
								color: #636363 !important;
							}
						}

						&:not(.disabled) {
							&.active {
								a {
									width: 28px;
									height: 28px;
									padding: 3px;
									background-color: $primary !important;
									color: $white !important;
									border-radius: 50%;
									display: flex;
									align-items: center;
									justify-content: center;
									font-weight: bold;
									font-size: 13px;
								}
							}
						}
					}
				}
			}
		}
	}

	form {
		quill-editor {
			.ql-tooltip.ql-editing {
				background: $theme-dark-card-bg;
				color: $theme-dark-body-color;
				outline: none !important;
				border: 1px solid $theme-dark-border-color !important;
				box-shadow: none !important;

				input {
					background: $theme-dark-card-bg;
					color: $theme-dark-body-color;
					border: 1px solid $theme-dark-border-color !important;
					outline: none !important;
					box-shadow: none !important;

					&:focus {
						outline: none !important;
						box-shadow: none !important;
					}
				}
			}

			.ql-toolbar {
				&.ql-snow {
					.ql-picker {
						&.ql-expanded {
							.ql-picker-label {
								border: 1px solid $theme-dark-border-color !important;

								[aria-expanded="true"] {
									border: 1px solid $theme-dark-border-color !important;
								}
							}

							.ql-picker-options {
								border: 1px solid $theme-dark-border-color !important;

								[aria-hidden="false"] {
									border: 1px solid $theme-dark-border-color !important;
								}
							}
						}
					}
				}
				.ql-formats {
					button {
						color: $white;
					}
				}
			}

			&.error {
				.ql-container {
					color: $theme-dark-body-color !important;
					border: 1px solid $danger !important;
				}

				.quill-toolbar,
				.ql-toolbar {
					background-color: $theme-dark-input-bg;
					border-color: $theme-dark-border-color !important;

					&.ql-snow {
						.ql-picker {
							&.ql-expanded {
								.ql-picker-label {
									border: 1px solid $theme-dark-border-color !important;

									[aria-expanded="true"] {
										border: 1px solid $theme-dark-border-color !important;
									}
								}

								.ql-picker-options {
									border: 1px solid $theme-dark-border-color !important;

									[aria-hidden="false"] {
										border: 1px solid $theme-dark-border-color !important;
									}
								}
							}
						}
					}

					// toolbar color
					.ql-picker {
						color: $white !important;
					}

					.ql-stroke {
						stroke: $white !important;
					}
					button {
						color: $white !important;
					}
					.ql-fill {
						fill: $white !important;
					}

					// toolbar options colors
					.ql-picker-options,
					.ql-picker-label {
						background-color: $theme-dark-input-bg;

						.ql-active {
							color: $primary !important;
						}
					}
				}

				.ql-active {
					.ql-stroke {
						stroke: $primary !important;
					}

					.ql-fill {
						fill: $primary !important;
					}
				}

				.ql-bubble {
					.ql-toolbar {
						background: $theme-dark-border-color;
						border-radius: 2rem;
					}
				}

				.ql-container {
					border-color: $theme-dark-border-color !important;
					background-color: $theme-dark-input-bg;
				}
			}
		}
	}

	.dndDropArea {
		background: $theme-dark-card-bg;
	}

	.fc
		.fc-timegrid
		.fc-timegrid-slots
		.fc-timegrid-slot
		.fc-timegrid-slot-label-frame
		.fc-timegrid-slot-label-cushion {
		color: #a0a3bd;
	}

	.fc .fc-toolbar .fc-next-button .fc-icon,
	.fc .fc-toolbar .fc-prev-button .fc-icon {
		color: #a0a3bd;
	}

	.category-list {
		.category-item {
			color: #a0a3bd !important;
		}
	}

	// Notification //
	.notification-modal {
		&.modal-content {
			.modal-header {
				.btn-icon {
					color: $theme-dark-headings-color;
					&:hover {
						color: $theme-dark-headings-color;
					}
				}
			}
		}
	}
	// End of Notification //

	.dashboard-view {
		.dashbord-sidebar {
			&.activeSidebarPanel {
				box-shadow: 5px 0px 20px 1px $theme-dark-border-color !important;
				border-right: 1px solid $theme-dark-border-color !important;
			}
			.side-pannel {
				.sidebar {
					.sidebar-nav {
						.sidebar-item {
							.sidebar-item-value {
							}
							&:hover {
								background-color: $theme-dark-body-bg !important;
								color: $theme-dark-headings-color !important;
								p,
								span {
									color: $theme-dark-headings-color !important;
								}
							}
						}
					}
				}
			}
		}
	}
	// NGX-datatable grid skeleton //
	app-grid-skeleton {
		.sekeleton-card {
			.grid-header {
				.grid-head-txt {
					color: $theme-dark-headings-color !important;
				}
			}
		}
	}
	// NGX-datatable grid skeleton //
	.disable-sec {
		&::before {
			background-color: rgba($theme-dark-body-bg, 0.7);
		}
	}

	// Demo video modal //
	.demo-video-modal {
		&.modal {
			.modal-dialog {
				.modal-content {
					.modal-body {
						.close {
							background-color: rgba($color: $theme-dark-body-bg, $alpha: 0.65);
							color: $theme-dark-headings-color;
						}
					}
				}
			}
		}
	}
	// End of Demo video modal //
	.incident-view-sidebar {
		.sla-main-container {
			&.border {
				border: 0px !important;
			}
		}
	}
	.incident-view-detail-grid {
		.basic-details {
			.input-tree-dropdown {
				app-single-tree-dropdown {
					app-ngx-dropdown-treeview-select {
						.tree-dropdown {
							ngx-dropdown-treeview {
								.dropdown {
									.dropdown-toggle {
										&:hover {
											border-color: $theme-dark-input-border-color !important;
										}
										&:focus {
											border-color: $theme-dark-input-border-color !important;
										}
									}
								}
							}
						}
					}
				}
			}
			.ng-select {
				background-color: transparent !important;
				&:focus {
					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
				&.ng-select-opened {
					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
				&.ng-select-single {
					.ng-select-container {
						background-color: transparent !important;
						&:hover {
							border: 1px solid $theme-dark-input-border-color !important;
						}
					}
				}
			}
			input.form-control,
			textarea.form-control {
				&:hover {
					border: 1px solid $theme-dark-input-border-color !important;
				}
			}
		}
	}

	// Workspace //
	.work-space {
		.main-content {
			.workspace-left-sec {
				.side-pannel {
					.sidebar {
						.category-list {
							.sec-list-sec {
								.title-item {
									.pin-slash {
										color: $theme-dark-headings-color !important;
										&::before {
											background-color: $theme-dark-headings-color !important;
											outline: 1px solid $theme-dark-card-bg !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.channel-list {
		&:hover {
			.show-on-hover {
				background: $theme-dark-card-bg !important;
				box-shadow: $theme-dark-box-shadow;
			}
		}
	}
	// End of Workspace //
	.incident-view-sidebar {
		.modal-dialog {
			.modal-content {
				.modal-header {
					background-color: $theme-dark-body-bg !important;
				}
			}
		}
	}
	.communication_mail_form {
		.email_box {
			border: 1px solid $theme-dark-border-color !important;
		}
	}
	.compose-form {
		.mail-header {
			background: $theme-dark-card-bg !important;
		}
		.select2-primary {
			// border: none !important;
			// display: grid !important;
			// grid-template-columns: 1fr 0fr !important;
			.mail-subject {
				input.form-control {
					// border:1px solid #dedeeb ;
					border: 1px solid transparent !important;
					background: $theme-dark-card-bg !important;
					&:focus {
						border: 1px solid $theme-dark-input-border-color !important;
					}
					&:hover {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
			}
			.compose-footer-wrapper {
				border: 1px solid $theme-dark-border-color !important;
			}
		}
	}
	.mail-to-address {
		.form-group {
			.ng-select {
				.ng-select-container {
					background: $theme-dark-card-bg !important;
					.ng-value-container {
						background: $theme-dark-card-bg !important;
					}
				}
				&.ng-select-focused {
					&:hover {
						.ng-select-container {
							border: 1px solid $theme-dark-input-border-color !important;
						}
					}

					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
				&.ng-select-opened {
					&:hover {
						.ng-select-container {
							border: 1px solid $theme-dark-input-border-color !important;
						}
					}

					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
				.ng-select-container {
					border: 1px solid transparent !important;
					.ng-value-container {
						.ng-value {
							background-color: rgb(186 191 199 / 13%) !important;
							color: $black;

							.ng-value-icon {
								color: $black;
								opacity: 0;
								&:hover {
									opacity: 1;
								}
							}
						}
					}
					&:hover {
						border: $theme-dark-input-border-color !important;
						// min-width: 208px;
					}

					&:focus {
						border: $theme-dark-input-border-color !important;
					}
				}
				&:hover {
					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
				&:focus {
					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
			}
		}
	}
	.mail-cc {
		.form-group {
			.ng-select {
				.ng-select-container {
					background: $theme-dark-card-bg !important;
					.ng-value-container {
						background: $theme-dark-card-bg !important;
					}
				}
				&.ng-select-focused {
					&:hover {
						.ng-select-container {
							border: 1px solid $theme-dark-input-border-color !important;
							transition: all 0.5s ease;
						}
					}

					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
				&.ng-select-opened {
					&:hover {
						.ng-select-container {
							border: 1px solid $theme-dark-input-border-color !important;
						}
					}

					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
				.ng-select-container {
					border: 1px solid transparent !important;
					&:hover {
						border: 1px solid $theme-dark-input-border-color !important;
					}

					&:focus {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
				&:hover {
					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
				&:focus {
					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}
			}
		}
	}
	.mail-from-address {
		.ng-select {
			.ng-select-container {
				background: $theme-dark-card-bg !important;
				.ng-value-container {
					background: $theme-dark-card-bg !important;
				}
			}
			&.ng-select-focused {
				&:hover {
					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}

				.ng-select-container {
					border: 1px solid $theme-dark-input-border-color !important;
				}
			}
			&.ng-select-opened {
				&:hover {
					.ng-select-container {
						border: 1px solid $theme-dark-input-border-color !important;
					}
				}

				.ng-select-container {
					border: 1px solid $theme-dark-input-border-color !important;
				}
			}
			.ng-select-container {
				border: 1px solid transparent !important;
				&:hover {
					border: 1px solid $theme-dark-input-border-color !important;
					// min-width: 208px;
				}

				&:focus {
					border: 1px solid $theme-dark-input-border-color !important;
				}
			}
			&:hover {
				.ng-select-container {
					border: 1px solid $theme-dark-input-border-color !important;
				}
			}
			&:focus {
				.ng-select-container {
					border: 1px solid $theme-dark-input-border-color !important;
				}
			}
		}
	}
	.email-quill-editor {
		quill-editor {
			.ql-toolbar {
				border-left: none;
				border-right: none;
				border-bottom: 1px solid $theme-dark-border-color !important;
				border-radius: 0;
				background: $theme-dark-card-bg !important;
			}
			.ql-container {
				border-left: none;
				border-right: none;
			}
		}
	}
	.commnent-sec {
		.reply-sec {
			.has-reply {
				&::before {
					border-left: 1px solid $theme-dark-text-muted-color;
					border-bottom: 1px solid $theme-dark-text-muted-color;
				}
			}
		}
	}



	.border-line {
		&.date-sec {
			.date-line {
				background-color: $theme-dark-border-color !important;
			}
		}
	}

	.placeholder-txt {
		color: $theme-dark-input-placeholder-color !important;
	}

	.input-border {
		border: 1px solid $theme-dark-input-border-color !important;
	}

	.exampleTemplate {
		.light-mode {
			display: none !important;
		}
		.dark-mode {
			display: flex !important;
		}
	}

	// Common Pannel Card //
	.pannel-view-card {
		.bottom-view {
			.info-block {
				.value-block {
					ng2-flatpickr {
						&:hover {
							.ng2-flatpickr-input-container {
								.ng2-flatpickr-input {
									border: 1px solid $theme-dark-input-border-color !important;
								}
							}
						}
					}
					.ng-select {
						background-color: transparent !important;
						.ng-select-container {
							background-color: transparent !important;
							&:hover {
								border: 1px solid $theme-dark-input-border-color !important;
							}
							&:focus {
								border: 1px solid $theme-dark-input-border-color !important;
							}
						}
						&:hover {
							.ng-select-container {
								border: 1px solid $theme-dark-input-border-color !important;
							}
						}
						&:focus {
							.ng-select-container {
								border: 1px solid $theme-dark-input-border-color !important;
							}
						}
						&.ng-select-disabled {
							.ng-select-container {
								border: 1px solid $theme-dark-input-border-color !important;
							}
						}
						&.ng-select-focused {
							min-width: 208px;
							&:hover {
								.ng-select-container {
									border: 1px solid $theme-dark-input-border-color !important;
								}
							}
							.ng-select-container {
								border: 1px solid $theme-dark-input-border-color !important;
							}
						}
						&.ng-select-opened {
							&:hover {
								.ng-select-container {
									border: 1px solid $theme-dark-input-border-color !important;
								}
							}
							.ng-select-container {
								border: 1px solid $theme-dark-input-border-color !important;
							}
						}
					}
				}
			}
			.slash {
				background-color: $theme-dark-border-color !important;
			}
		}
	}
	// End of Common Pannel Card //
	// AD configuration Edit //
	.ad-configuration-edit {
		.ad-config-edit {
			.tab-content {
				.configuration-tab {
					.tab-body {
						.configuration-inner-sec {
							.configuration-grid {
								.preview-block {
									span {
										border: 1px dashed $theme-dark-input-border-color !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	// End of AD configuration Edit //
	// Start shift_management_calender
	.problem-grid-data {
		.main-content {
			border: none !important;
			&.activeSidebarPanel {
				border: none;
				.left-sec {
					border: none !important;
					width: 252px;
					// border-radius: 0px !important;
				}

				.main-sec {
					&.normalGridSection {
						//max-width: calc(100% - 240px);
						border: 1px solid $theme-dark-border-color !important;
						// border-radius: 0px !important;
					}
				}
			}
		}
	}
	.vis-content {
		background-color: $theme-dark-card-bg;
		border: 1px solid $theme-dark-border-color;
	}
	.vis-inner {
		border-top: 1px solid $theme-dark-border-color !important;
	}
	.vis-labelset .vis-label {
		border-bottom: none;
	}
	.vis-foreground {
		.vis-group {
			border-top: 1px solid $theme-dark-border-color !important;
			.vis-item {
				border-radius: 10px !important;
			}
		}
	}
	.vis-foreground .vis-group {
		border-bottom: none !important;
	}
	.vis-yesterday {
		border: 1px solid $theme-dark-border-color !important;
	}
	.vis-background {
		background-color: $theme-dark-card-bg !important;
	}
	.vis-today {
		border: 1px solid $theme-dark-border-color !important;
	}
	.vis-vertical {
		border: none;
	}
	.vis-tomorrow {
		border: 1px solid $theme-dark-border-color !important;
	}
	.vis-time-axis .vis-foreground {
		background-color: $theme-dark-card-bg;
	}
	.vis-time-axis .vis-text {
		background-color: $theme-dark-card-bg !important;
		color: $white;
		border: 1px solid $theme-dark-border-color !important;
		border-top: none !important;
	}
	.vis-top {
		border: 1px solid $theme-dark-border-color !important;
	}
	.vis-left {
		background-color: $theme-dark-card-bg !important ;
		border-right: 1px solid $theme-dark-border-color !important;
		border-top: none !important;
	}
	.vis-panel.vis-center {
		background-color: $theme-dark-card-bg !important ;
		border: none !important;
	}
	.vis-content {
		border-bottom: none;
		border-left: none;
		border-right: none;
		border-top: none;
	}
	.vis-right {
		background-color: $theme-dark-card-bg !important ;
		border: none !important;
	}
	.vis-bottom {
		background-color: $theme-dark-card-bg !important ;
		border: none !important;
	}
	.vis-major:nth-child(even) {
		background: $theme-dark-card-bg !important;
	}
	// End shift_management_calender
	// UPdate dropdown for assignee in drak mode //
	.assign-user-dropdown {
		&.ng-dropdown-panel {
			.ng-dropdown-panel-items {
				.ng-option {
					&.ng-option-marked {
						.media-body {
							h5 {
								color: $theme-dark-headings-color !important;
							}
							span {
								color: $theme-dark-headings-color !important;
							}
							p {
								color: $theme-dark-headings-color !important;
							}
							small {
								color: $theme-dark-headings-color !important;
							}
						}
						> div {
							.cell-line-height {
								p {
									color: $theme-dark-headings-color !important;
								}
								span {
									color: $theme-dark-headings-color !important;
								}
								small {
									color: $theme-dark-headings-color !important;
								}
							}
						}
						> span {
							.cell-line-height {
								p {
									color: $theme-dark-headings-color !important;
								}
								span {
									color: $theme-dark-headings-color !important;
								}
								small {
									color: $theme-dark-headings-color !important;
								}
							}
						}
					}
					> div:not(.cursor-pointer) {
						.cell-line-height {
							p {
								color: $theme-dark-headings-color !important;
							}
						}
					}
					> span {
						.cell-line-height {
							p {
								color: $theme-dark-headings-color !important;
							}
						}
					}
					.media {
						.media-body {
							h5 {
								color: $theme-dark-headings-color !important;
							}
							p {
								color: $theme-dark-headings-color !important;
							}
						}
					}
				}
			}
		}
	}
	.assign-user-dropdown {
		&.ng-select:not(.ng-select-filtered) {
			.ng-dropdown-panel {
				.ng-dropdown-panel-items {
					.ng-option {
						&.ng-option-marked {
							.media-body {
								h5 {
									color: $theme-dark-headings-color !important;
								}
								span {
									color: $theme-dark-headings-color !important;
								}
								p {
									color: $theme-dark-headings-color !important;
								}
								small {
									color: $theme-dark-headings-color !important;
								}
							}
							> div {
								.cell-line-height {
									p {
										color: $theme-dark-headings-color !important;
									}
									span {
										color: $theme-dark-headings-color !important;
									}
									small {
										color: $theme-dark-headings-color !important;
									}
								}
							}
							> span {
								.cell-line-height {
									p {
										color: $theme-dark-headings-color !important;
									}
									span {
										color: $theme-dark-headings-color !important;
									}
									small {
										color: $theme-dark-headings-color !important;
									}
								}
							}
						}
						> div:not(.cursor-pointer) {
							.cell-line-height {
								p {
									color: $theme-dark-headings-color !important;
								}
							}
						}
						> span {
							.cell-line-height {
								p {
									color: $theme-dark-headings-color !important;
								}
							}
						}
						.media {
							.media-body {
								h5 {
									color: $theme-dark-headings-color !important;
								}
								p {
									color: $theme-dark-headings-color !important;
								}
							}
						}
					}
				}
			}
		}
	}
	#txtReqAssignee {
		@extend .assign-user-dropdown;
	}
	#selRepaceAssignee {
		@extend .assign-user-dropdown;
	}
	#txtInciAssignee {
		@extend .assign-user-dropdown;
	}
	#selAssignee {
		@extend .assign-user-dropdown;
	}
	#txtChnAssignee {
		@extend .assign-user-dropdown;
	}
	#txtProbAssignee {
		@extend .assign-user-dropdown;
	}
	#txtRelAssignee {
		@extend .assign-user-dropdown;
	}
	#selOwner {
		@extend .assign-user-dropdown;
	}
	#selSubmitter {
		@extend .assign-user-dropdown;
	}
	#selRequester {
		@extend .assign-user-dropdown;
	}
	#selUser {
		@extend .assign-user-dropdown;
	}
	#selPrimeUser {
		@extend .assign-user-dropdown;
	}
	#selUsers {
		@extend .assign-user-dropdown;
	}
	#selUsrFilter {
		@extend .assign-user-dropdown;
	}
	#reporting_manager {
		@extend .assign-user-dropdown;
	}
	#selRepaceAssignee {
		@extend .assign-user-dropdown;
	}
	#selMultiAssignee {
		@extend .assign-user-dropdown;
	}
	#selServiceOwner {
		@extend .assign-user-dropdown;
	}
	#mselBzOwner {
		@extend .assign-user-dropdown;
	}
	#mselITOwner {
		@extend .assign-user-dropdown;
	}
	#mselRequesters {
		@extend .assign-user-dropdown;
	}
	#mselOwner {
		@extend .assign-user-dropdown;
	}
	#selApprovalStaff {
		@extend .assign-user-dropdown;
	}
	#selUsrLevel {
		@extend .assign-user-dropdown;
	}
	#selUsrRepMgr {
		@extend .assign-user-dropdown;
	}
	#selectFollowers {
		@extend .assign-user-dropdown;
	}
	#txtChnSelAssignGroup {
		@extend .assign-user-dropdown;
	}
	#selManager {
		@extend .assign-user-dropdown;
	}
	#txtInciClosedBy {
		@extend .assign-user-dropdown;
	}
	#to_address {
		@extend .assign-user-dropdown;
	}
	#cc_address {
		@extend .assign-user-dropdown;
	}
	#bcc_address {
		@extend .assign-user-dropdown;
	}
	#txtInciSelAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtReqClosedBy {
		@extend .assign-user-dropdown;
	}
	#txtChnClosedBy {
		@extend .assign-user-dropdown;
	}
	#txtReqSidebarAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtReqAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtChnAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtInciAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtChnSidebarAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtInciSidebarAssignGroup {
		@extend .assign-user-dropdown;
	}
	#parent {
		@extend .assign-user-dropdown;
	}
	#txtProbSidebarAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtProbAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtProbSelAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtProbClosedBy {
		@extend .assign-user-dropdown;
	}
	#txtRelSidebarAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtRelAssignGroup {
		@extend .assign-user-dropdown;
	}
	#txtReqSelAssignGroup {
		@extend .assign-user-dropdown;
	}
	// UPdate dropdown for assignee in drak mode //
	.btn-outline-tertiary {
		&.bg-body {
			background-color: $theme-dark-body-bg !important;
		}
	}
	.full-search-width {
		box-shadow: rgba(159, 159, 159, 0.12) 0px 2px 4px 0px, rgba(159, 159, 159, 0.32) 0px 2px 16px 0px !important;
	}
	.presentation-slider-img {
		background-color: $theme-dark-headings-color !important;
	}
	.slider-presentation-mode {
		&.modal {
			.modal-dialog {
				.modal-content {
					.modal-body {
						ngb-carousel {
							&.carousel {
								.carousel-indicators {
									button {
										border: 1px solid rgba($color: #fff, $alpha: 0.8);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.select-vendor-list {
		.vendor-card {
			&.vendor-radio-card {
				&.custom-control {
					&.custom-radio {
						.custom-control-label {
							.vendor-details {
								.lower-sec {
									.extra-info {
										&:not(&:last-child) {
											&::before {
												background-color: $theme-dark-border-color !important;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.linked-assets {
		.linked-assets-list {
			.asset-card {
				.asset-info {
					.asset-ip {
						&::after {
							background-color: $theme-dark-border-color !important;
						}
					}
				}
			}
		}
	}

	.no-filter-data {
		.img-sec {
			.light-theme {
				display: none !important;
			}
			.dark-theme {
				display: block !important;
			}
		}
	}
	//   Bot configuration start
	.cart-action-container {
		.chart-action-box {
			background-color: transparent !important;
		}
	}
	.input-group-append {
		span {
			border: 1px solid transparent;
			background-color: transparent !important;
			i {
				color: $primary;
			}
		}
	}
	.custom-dropdown-card {
		background-color: $theme-dark-card-bg !important;
		.card-title {
			background-color: $theme-dark-card-bg !important;
		}
	}
	.chart-message {
		background-color: $theme-dark-input-bg !important;
	}
	.chat-left {
		.chat-content {
			background-color: $theme-dark-card-bg !important;
			border: 1px solid transparent !important;
		}
	}
	//   Bot configuration end

	// consumable asset start
	.consumable-basic-details-body {
		.input {
			// border:1px solid #dedeeb ;
			border: transparent !important;
			border-color: transparent !important;
			background-color: $theme-dark-card-bg !important;
			line-height: 21px !important;
			font-size: 14px;
			outline: none;
			box-shadow: none;
			transition: all 0.5s ease;
			padding-left: 0;
			color: $text-muted;
			&:focus {
				border: 1px solid $theme-dark-border-color !important;
				transition: all 0.5s ease;
				box-shadow: none !important;
			}
			&:hover {
				border: 1px solid $theme-dark-border-color !important;
				padding-left: 1rem;
				padding-left: 1rem !important;
				transition: all 0.5s ease;
			}
		}
		.ng-select {
			min-width: auto;
			cursor: pointer;
			&.ng-select-focused {
				min-width: 208px;
				&:hover {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
				.ng-select-container {
					border: 1px solid $theme-dark-border-color !important;
					background-color: $theme-dark-card-bg !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&.ng-select-opened {
				min-width: 208px;
				&:hover {
					.ng-select-container {
						border: 1px solid $input-border-color !important;
						transition: all 0.5s ease;
						.ng-value-container {
							padding-left: 10px;
							padding-right: 5px;
							transition: all 0.5s ease;
						}
						.ng-clear-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
						.ng-arrow-wrapper {
							opacity: 1;
							visibility: visible;
							width: auto;
							-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
							transition: opacity 500ms, visibility 500ms, width 500ms;
						}
					}
				}
				.ng-select-container {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			.ng-select-container {
				border: 1px solid transparent !important;
				transition: all 0.5s ease;
				background-color: $theme-dark-card-bg !important;
				.ng-value-container {
					padding-left: 0px;
					padding-right: 0px;
					transition: all 0.5s ease;
				}
				.ng-clear-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				.ng-arrow-wrapper {
					opacity: 0;
					visibility: hidden;
					width: 0px;
					-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
					transition: opacity 500ms, visibility 500ms, width 500ms;
				}
				&:hover {
					border: 1px solid $theme-dark-border-color !important;
					transition: all 0.5s ease;
					// min-width: 208px;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
				&:focus {
					border: 1px solid $theme-dark-border-color !important;
					transition: all 0.5s ease;
					min-width: 208px;
					box-shadow: none;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&:hover {
				.ng-select-container {
					border: 1px solid $theme-dark-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 0;
						visibility: hidden;
						width: 0px;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
			&:focus {
				.ng-select-container {
					border: 1px solid $theme-dark-border-color !important;
					transition: all 0.5s ease;
					.ng-value-container {
						padding-left: 10px;
						padding-right: 5px;
						transition: all 0.5s ease;
					}
					.ng-clear-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
					.ng-arrow-wrapper {
						opacity: 1;
						visibility: visible;
						width: auto;
						-webkit-transition: opacity 500ms, visibility 500ms, width 500ms;
						transition: opacity 500ms, visibility 500ms, width 500ms;
					}
				}
			}
		}
	}
	// Consumable asset end

	// Text Enhancement start
	.textenhancementpopover {
		.header-action{
			button{
				border: 1px solid #fff;
			}
		}
		.popover-body {
			.loader-tone {
				background-color: $theme-dark-input-bg !important;
			}
		}
		.text-enhancement-body {
			.no-data-container {
				background: $theme-dark-card-bg !important;
				i {
					color: $white !important;
				}
				.contain-body {
					color: $gray-500;
				}
				.content-action {
					button {
						background: $theme-dark-card-bg !important;
						border-radius: 6px;
						color: $white !important;
						&:hover {
							color: $white !important;
							background-color: $primary !important;
						}
					}
				}
			}
			.tone-body {
				// background-color: $theme-dark-body-bg !important;
			}
			.tone-description {
				textarea {
					background-color: $theme-dark-card-bg !important;
					color: $white !important;
				}
			}
			.tone-action {
				background-color: $theme-dark-card-bg !important;
				color: $white !important;
			}
			
			.disabledToneButton{
				background-color: $theme-dark-card-bg !important;
				color: $gray-500;
			}
			.activeToneButton {
				border: 1px solid $primary !important;
				border-radius: 5px;
				background-color: $theme-dark-body-bg;
				color: $white !important;
			}
		}
		.text-enhancement-footer{
			background-color: $theme-dark-card-bg !important;
		}
	}
	// Text Enhancement End

	//   IMACD Starts
	  .basic-details-row {
		.date{
			input {
				// border:1px solid #dedeeb ;
				border: 1px solid transparent !important;
				line-height: 21px !important;
				font-size: 14px;
				outline: none;
				box-shadow: none;
				background: transparent !important;
				padding-left: 0;
				&:focus {
					border: 1px solid $input-border-color !important;
					transition: all 0.5s ease;
				}
				&:hover {
					border: 1px solid $input-border-color !important;
					padding-left: 1rem;
					padding-left: 1rem !important;
					transition: all 0.5s ease;
				}
			}
		}
		.ng-select {
			background-color: transparent !important;
			.ng-select-container{
				background-color: transparent !important;
			}
		}
	  }

	  .gatepass-container{
		.gatepass-body{
			.left-section{
				input{
					background: transparent !important;
				}
			}
		}
	}

	//   IMACD Ends
	.vertical-menu{
		vertical-menu{
			.navbar-header{
				&.bg-card{
					background: $white !important;
				}
			}
		}
	}
	
	// Task Tree View //
	.taskList-table-view {
		.groupBy-status {
			.taskList-table {
				.taskList-table-body {
					.taskList-table-row {
						.task-list-column {
							.task-body-column {
								&.main-column {
									&.fix-column {
										&.task-name {
											.task-title {
												&.editable-input {
													.edit-task-input {
														&.form-control {
															color: $theme-dark-headings-color !important;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.task-custom-datePicker{
		.due-datePicker{
			&.custom-datePicker{
				.ng2-flatpickr-input-container{
					.ng2-flatpickr-input{
						color: $theme-dark-headings-color !important;
					}
				}
			}
		}
	}
	.check-list-popcontent {
		.chacklist-item {
			.custom-checklist-checkbox {
				&.custom-checkbox {
					&.custom-control {
						.custom-control-input {
							&:disabled ~ .custom-checklist-label {
								color: $theme-dark-headings-color !important;
							}
						}
					}
				}
			}
		}
	}
	// End of tree View //

	// Add project Template //
	.right-preview-sec{
		.project-template-view{
			.dot-nav{
				background-color: $theme-dark-body-bg !important;
				span{
					background-color: $theme-dark-card-bg !important;
				}
			}
			.project-template{
				.left-menu{
					span.menu-item{
						&:not(.project-img){
							background-color: $theme-dark-body-bg !important;
						}
					}
				}
			}
			.skeleton{
				&:not(.task-status){
					background-color: $theme-dark-body-bg !important;
				}
			}
			.main-section{
				.tree-view{
					.taskList-table{
						.taskList-table-head{
							.taskList-table-row{
								.task-list-column{
									.task-head-column{
										&.task-name{
											.no-status{
												&.skeleton{
													background-color: $theme-dark-card-bg !important;
												}
											}
										}
									}
								}
	
							}
						}
					}
				}
			}
		}
	}
	.edit-task-input {
		&.form-control {
			color: $theme-dark-headings-color !important;
		}
	}
	// End of Add Project template //

}
