// ================================================================================================
// 	File Name: utilities.scss
// 	Description: Utility classes provides color, width, position & background etc..
// 				 related customization.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Sizes Variable for height and width mixins
$sizes-px: (
	// in px
	50: 50px,
	100: 100px,
	150: 150px,
	200: 200px,
	250: 250px,
	300: 300px,
	350: 350px,
	400: 405px,
	450: 455px,
	500: 500px,
	550: 550px,
	600: 600px,
	650: 650px,
	700: 700px,
	750: 750px,
	800: 800px
) !default;

$sizes-per: (
	// in %
	5: 5%,
	10: 10%,
	15: 15%,
	20: 20%,
	25: 25%,
	30: 30%,
	35: 35%,
	40: 40%,
	45: 45%,
	50: 50%,
	55: 55%,
	60: 60%,
	65: 65%,
	70: 70%,
	75: 75%,
	80: 80%,
	85: 85%,
	90: 90%,
	95: 95%
) !default;

//--------------- Text ---------------
//------------------------------------

// Font Size
//----------
//small
.contrast-txt{
	color: $dark-black !important;
}
.txt-info{
	color: $info !important;
}
.txt-success{
	color: $success !important;
}
.txt-warning{
	color: $warning !important;
}
.txt-danger{
	color: $danger !important;
}
.txt-primary{
	color: $primary !important;
}
.txt-purple{
	color: $brandPurple !important;
}
.bg-muted {
	background-color: $text-muted;
}

.bg-danger {
	background-color: $danger;
}
.bg-warning {
	background-color: $warning;
}
.bg-success {
	background-color: $success;
}
.bg-info {
	background-color: $info;
}
.bg-primary {
	background-color: $primary;
}
.primary-border {
	border: 1px solid $primary;
}

.transparent-primary {
	background-color: rgba($color: $primary, $alpha: 0.12) !important;
}
.transparent-danger {
	background-color: rgba($color: $danger, $alpha: 0.12) !important;
}
.transparent-warning {
	background-color: rgba($color: $warning, $alpha: 0.12) !important;
}
.transparent-success {
	background-color: rgba($color: $success, $alpha: 0.12) !important;
}
.transparent-info {
	background-color: rgba($color: $info, $alpha: 0.12) !important;
}
.transparent-purple {
	background-color: rgba($color: $brandPurple, $alpha: 0.12) !important;
}
.transparent-body {
	background-color: rgba($color: $body-color, $alpha: 0.12) !important;
}
.transparent-bg {
	background-color: transparent !important;
}

.bg-transparent {
	background-color: transparent !important;
}
.bg-body {
	background-color: $body-bg;
}

.bg-card{
	background-color: $card-bg;
}
.card-bg {
	background-color: $card-bg;
	&.form-control{
		background-color: $card-bg !important;
	}
}

.primary-bg {
	background-color: $primary;
}

.fw-500 {
	font-weight: 500 !important;
}
.fw-400 {
	font-weight: 400 !important;
}
.fw-300 {
	font-weight: 300 !important;
}
.fw-600 {
	font-weight: 600 !important;
}
.fw-700 {
	font-weight: 700 !important;
}
.white-color {
	color: $white !important;
}

.title-color {
	color: $title-color !important;
}

.info-color {
	color: $info !important;
}

.body-color {
	color: $body-color !important;
}

.text-muted-color {
	color: $text-muted !important;
}

.primary-color {
	color: $primary !important;
}

.reg-font {
	font-size: $size-14-px !important;
	line-height: 1.5rem !important;
}

.sm-font {
	font-size: $size-12-px !important;
	line-height: 1.5rem !important;
}

.xs-font {
	font-size: $size-10-px !important;
	line-height: 1.5rem !important;
}

.lg-font {
	font-size: $size-16-px !important;
	line-height: 1.5rem !important;
}

.xl-font {
	font-size: $size-18-px !important;
	line-height: 1.5rem !important;
	font-weight: 500;
}
.xxl-font {
	font-size: $size-20-px !important;
	line-height: 1.5rem !important;
	font-weight: 500;
}
.position-absolute-center{
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.placeholder-txt{
	color: $input-placeholder-color !important;
}
.input-border{
	border: 1px solid $input-border-color !important;
}
.d-grid{
	display: grid !important;
}
.font-small-1 {
	font-size: 0.7rem !important;

	// for Feather SVG icons as we are using base size as 1rem
	@at-root svg#{&} {
		height: 0.7rem !important;
		width: 0.7rem !important;
	}
}

.font-small-2 {
	font-size: 0.8rem !important;
	@at-root svg#{&} {
		height: 0.8rem !important;
		width: 0.8rem !important;
	}
}

.font-small-3 {
	font-size: 0.9rem !important;
	@at-root svg#{&} {
		height: 0.9rem !important;
		width: 0.9rem !important;
	}
}

.font-small-4 {
	font-size: 1rem !important;
	@at-root svg#{&} {
		height: 1rem !important;
		width: 1rem !important;
	}
}

//medium
.font-medium-1 {
	font-size: 1.1rem !important;
	@at-root svg#{&} {
		height: 1.1rem !important;
		width: 1.1rem !important;
	}
}

.font-medium-2 {
	font-size: 1.2rem !important;
	@at-root svg#{&} {
		height: 1.2rem !important;
		width: 1.2rem !important;
	}
}

.font-medium-3 {
	font-size: 1.3rem !important;
	@at-root svg#{&} {
		height: 1.3rem !important;
		width: 1.3rem !important;
	}
}

.font-medium-4 {
	font-size: 1.4rem !important;
	@at-root svg#{&} {
		height: 1.4rem !important;
		width: 1.4rem !important;
	}
}

.font-medium-5 {
	font-size: 1.5rem !important;
	@at-root svg#{&} {
		height: 1.5rem !important;
		width: 1.5rem !important;
	}
}

//large
.font-large-1 {
	font-size: 2rem !important;
	@at-root svg#{&} {
		height: 2rem !important;
		width: 2rem !important;
	}
}

.font-large-2 {
	font-size: 3rem !important;
	@at-root svg#{&} {
		height: 3rem !important;
		width: 3rem !important;
	}
}

.font-large-3 {
	font-size: 4rem !important;
	@at-root svg#{&} {
		height: 4rem !important;
		width: 4rem !important;
	}
}

.font-large-4 {
	font-size: 5rem !important;
	@at-root svg#{&} {
		height: 5rem !important;
		width: 5rem !important;
	}
}

.font-large-5 {
	font-size: 6rem !important;
	@at-root svg#{&} {
		height: 6rem !important;
		width: 6rem !important;
	}
}

// Line height
//----------
.line-height-1 {
	line-height: 1 !important;
}

.line-height-normal {
	line-height: normal !important;
}

.line-height-condensed {
	line-height: 1.5 !important;
}

.line-height-inherit {
	line-height: inherit !important;
}

// Transform
// GAP property //
	.gap-1{
		gap: $size-14-px;
	}
	.gap-2{
		gap: 2rem;
	}
	.gap-75{
		gap: $size-12-px;
	}
	.gap-50{
		gap: $size-8-px;
	}
	.gap-25{
		gap: $size-4-px;
	}
	.col-gap-1{
		column-gap: $size-14-px;
	}
	.col-gap-2{
		column-gap: 2rem;
	}
	.col-gap-50{
		column-gap: $size-8-px;
	}
	.col-gap-75{
		column-gap: $size-12-px;
	}
	.col-gap-25{
		column-gap: $size-4-px;
	}
	.row-gap-1{
		row-gap: $size-14-px;
	}
	.row-gap-2{
		row-gap: 2rem;
	}
	.row-gap-50{
		row-gap: $size-8-px;
	}
	.row-gap-75{
		row-gap: $size-12-px;
	}
	.row-gap-25{
		row-gap: $size-4-px;
	}

// End of GAP property //
//----------
.rotate-45 {
	transform: rotate(45deg);
}

.rotate-45-inverse {
	transform: rotate(-45deg);
}

.rotate-90 {
	transform: rotate(90deg);
}

.rotate-90-inverse {
	transform: rotate(-90deg);
}

.rotate-180 {
	transform: rotate(180deg);
}

.rotate-180-inverse {
	transform: rotate(-180deg);
}

//-------------- Borders --------------
//-------------------------------------

// Border width - 2px
.border-2 {
	border-width: 2px !important;
}

.border-top-2 {
	border-top-width: 2px !important;
}

.border-bottom-2 {
	border-bottom-width: 2px !important;
}

.border-left-2 {
	border-left-width: 2px !important;
}

.border-right-2 {
	border-right-width: 2px !important;
}

// Border width - 3px
.border-3 {
	border-width: 3px !important;
}

.border-top-3 {
	border-top-width: 3px !important;
}

.border-bottom-3 {
	border-bottom-width: 3px !important;
}

.border-left-3 {
	border-left-width: 3px !important;
}

.border-right-3 {
	border-right-width: 3px !important;
}

// Shapes radius : Round / Square
.round {
	@include border-radius(1.5rem);
}

.square {
	@include border-radius(0);
}

.border-radius-6{
	@include border-radius(6px);
}
.border-topleft-redius6{
	border-top-left-radius: 6px !important;
}
.border-bottomleft-radius6{
	border-bottom-left-radius: 6px !important;
}
.border-topright-radius6{
	border-top-right-radius: 6px !important;
}
.border-bottomright-radius6{
	border-bottom-right-radius: 6px !important;
}
//-------------- Sizing --------------
//------------------------------------

// Width && Height
.fit {
	max-width: 100% !important;
}

.half-width {
	width: 50% !important;
}

.full-width {
	width: 100% !important;
}

.full-height {
	height: 100% !important;
}

// height & width in PX
@each $name, $size in $sizes-px {
	.height-#{$name} {
		height: $size !important;
	}

	.width-#{$name} {
		width: $size !important;
	}
}

// height & width in %
@each $name, $size in $sizes-per {
	.height-#{$name}-per {
		height: $size !important;
	}

	.width-#{$name}-per {
		width: $size !important;
	}
}

//------------- Position -------------
//------------------------------------
.position-top-0 {
	top: 0;
}

.position-right-0 {
	right: 0;
}

.position-bottom-0 {
	bottom: 0;
}

.position-left-0 {
	left: 0;
}

//------------- Overflow -------------
//------------------------------------
.overflow-visible {
	overflow: visible;
}

.overflow-scroll {
	overflow: scroll;
}

.overflow-x-scroll {
	overflow: scroll;
}

.overflow-y-scroll {
	overflow: scroll;
}
.overflow-hidden{
	overflow: hidden !important;
}

//-------------- Cursor --------------
//------------------------------------
.cursor-pointer {
	cursor: pointer;
}

.cursor-grab-pointer{
	cursor: grab;
	&:focus,&:focus-visible,&:active{
		cursor: grabbing;
	}
}
.cursor-move {
	cursor: move;
}

.cursor-default {
	cursor: default !important;
}

.cursor-progress {
	cursor: progress;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

//-------------- Z-index --------------
//-------------------------------------
.zindex-1 {
	z-index: 1 !important;
}

.zindex-2 {
	z-index: 2 !important;
}

.zindex-3 {
	z-index: 3 !important;
}

.zindex-4 {
	z-index: 4 !important;
}

.zindex-0 {
	z-index: 0 !important;
}

.zindex-minus-1 {
	z-index: -1 !important;
}

.zindex-minus-2 {
	z-index: -2 !important;
}

.zindex-minus-3 {
	z-index: -3 !important;
}

.zindex-minus-4 {
	z-index: -4 !important;
}

//------------ Box Shadow ------------
//------------------------------------
.box-shadow-0 {
	box-shadow: none !important;
}

.box-shadow-1 {
	box-shadow: 0 2px 5px 0 rgba($black, 0.16), 0 2px 10px 0 rgba($black, 0.12);
}

.box-shadow-2 {
	box-shadow: 0 8px 17px 0 rgba($black, 0.2), 0 6px 20px 0 rgba($black, 0.19);
}

.box-shadow-3 {
	box-shadow: 0 12px 15px 0 rgba($black, 0.24), 0 17px 50px 0 rgba($black, 0.19);
}

.box-shadow-4 {
	box-shadow: 0 16px 28px 0 rgba($black, 0.22), 0 25px 55px 0 rgba($black, 0.21);
}

.box-shadow-5 {
	box-shadow: 0 27px 24px 0 rgba($black, 0.2), 0 40px 77px 0 rgba($black, 0.22);
}

.box-shadow-6 {
	box-shadow: 0 4px 8px 0 rgba($black, 0.12), 0 2px 4px 0 rgba($black, 0.08);
}

//-------------- Outline --------------
//-------------------------------------
.outline-none {
	outline: none !important;
}

//-------------- Lists --------------
//-----------------------------------
.list-style-inside {
	list-style: inside;
}

.list-style-circle {
	list-style: circle;
}

.list-style-square {
	list-style: square;
}

//--------- Background Image ---------
//------------------------------------
.bg-cover {
	background-size: cover !important;
}

.background-repeat {
	background-repeat: repeat !important;
}

.background-no-repeat {
	background-repeat: no-repeat !important;
}

//-------------- Icons --------------
//-----------------------------------
.icon-bg-circle {
	color: $white;
	padding: 0.5rem;
	border-radius: 50%;
}

.icon-left {
	margin-right: 0.5rem;
}

.icon-right {
	margin-right: 0.5rem;
}

// Used for blockUI
.blockOverlay {
	z-index: 1050 !important;
}

.blockElement,
.blockPage {
	z-index: 1051 !important;
}

.hidden {
	display: none;
	visibility: hidden;
}

a {
	&:hover {
		cursor: pointer;
	}
}

//Tables
//--------------------------
.table-middle {
	td {
		vertical-align: middle;
	}
}

//SVG
//--------------------------
.fill-current {
	fill: currentColor;
}

//Cursor
//--------------------------
.pointer-events-none {
	pointer-events: none;
}

.card-body {
	padding: 1rem !important;
}

// Flex Comman Height //
.flex-auto-height {
	display: flex !important;
	flex-direction: column !important;
	height: 100% !important;
}

.flex-1 {
	flex: 1% !important;
}

.flex-initial {
	flex: initial !important;
}

// Flex comman Height //
// Button //
.btn {
	font-size: $size-14-px;
	letter-spacing: 0.4px;
	font-weight: 500;
	line-height: 1;
	padding: 10px 24px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	&.round{
		border-radius: 1.5rem !important;
	}

	@include max-mq(767){
		font-size: $size-12-px;
		padding: 7px 20px;
		img,
		svg,
		.fa-light,
		i {
			margin-right: 4px;
		}
	}
	&.btn-white {
		background-color: $card-bg !important;
	}

	img,
	svg,
	.fa-light,
	i {
		margin-right: 9px;
	}

	&.btn-lg {
		font-size: $size-16-px;
		padding: 12px 24px;
		line-height: 18px;
		@include max-mq(767){
			font-size: $size-14-px;
			padding: 7px 20px;
	
			img,
			svg,
			.fa-light,
			i {
				margin-right: 4px;
			}
		}
	}

	&.btn-sm {
		font-size: $size-12-px;
		padding: 7px 20px;

		img,
		svg,
		.fa-light,
		i {
			margin-right: 8px;
		}
	}

	&.btn-xs {
		font-size: $size-10-px;
		padding: 4px 12px;

		img,
		svg,
		.fa-light,
		i {
			margin-right: 5px;
		}
	}

	&.btn-icon {
		padding: 0px !important;
		width: 36px !important;
		height: 36px !important;
		@include max-mq(767){
			width: 28px !important;
			height: 28px !important;

			img,
			svg,
			.fa-light,
			i {
				margin-right: 0 !important;
			}
		}
		img,
		svg,
		.fa-light,
		i {
			margin-right: 0 !important;
		}

		&.btn-lg {
			width: 44px !important;
			height: 44px !important;

			@include max-mq(767){
				width: 28px !important;
				height: 28px !important;
	
				img,
				svg,
				.fa-light,
				i {
					margin-right: 0 !important;
				}
			}
			img,
			svg,
			.fa-light,
			i {
				margin-right: 0 !important;
			}
		}

		&.btn-sm {
			width: 28px !important;
			height: 28px !important;

			img,
			svg,
			.fa-light,
			i {
				margin-right: 0 !important;
			}
		}

		&.btn-xs {
			width: 20px !important;
			height: 20px !important;

			img,
			svg,
			.fa-light,
			i {
				margin-right: 0 !important;
			}
		}
	}
}

// End of Button //

// Form Input Components //
.input-group.is-invalid:not(.bootstrap-touchspin):focus-within,
.input-group.error:not(.bootstrap-touchspin):focus-within {
	border-color: $danger !important;
	box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;
}

.input-group {
	&.is-invalid {
		ngx-intl-tel-input {
			.form-control {
				border-color: $danger !important;
				&:disabled{
					background-color: $input-disabled-bg !important;
				}
				&:focus {
					box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;
				}
			}
		}

		&:not(.bootstrap-touchspin) {
			&.focus-within {
				border-color: $danger !important;
				box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;

				.form-control {
					&.is-invalid {
						&:focus {
							border-color: $danger !important;
							box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;
						}
					}
					&:disabled{
						background-color: $input-disabled-bg !important;
					}
					&.error {
						background-image: none !important;
						padding-right: 0rem !important;

						&:focus {
							border-color: $danger !important;
							box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;
						}
					}
				}
			}
		}

		&.focus-within {
			border-color: $danger !important;
			box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;
		}

		// &:focus{
		// 	border-color: $danger !important;
		// 	box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;
		// }
	}
}

.form-control {
	height: 36px;
	// background-color: transparent !important;
	&.is-invalid {
		background-image: none !important;
		padding-right: 1rem !important;

		&:focus {
			border-color: $danger !important;
			box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;
		}
	}
	&:disabled{
		background-color: $input-disabled-bg !important;
	}
	&.error {
		background-image: none !important;
		padding-right: 0rem !important;

		&:focus {
			border-color: $danger !important;
			box-shadow: 0 0 0 0.2rem rgba($color: $danger, $alpha: 0.25) !important;
		}
	}

	&.lg-form-control {
		height: 44px !important;
	}
	&.xl-form-control {
		height: 48px !important;
	}
	&.sm-form-control {
		height: 28px !important;
		font-size: $size-12-px !important;
	}
}

textarea {
	&.form-control {
		min-height: 36px;
		// height: auto !important;
		&:disabled{
			background-color: $input-disabled-bg !important;
		}
		&.lg-form-control {
			min-height: 44px;

			.ng-select-container {
				min-height: 44px !important;
			}
		}
		&.xl-form-control {
			min-height: 48px;

			.ng-select-container {
				min-height: 48px !important;
			}
		}
		&.sm-form-control {
			min-height: 28px;
			font-size: $size-12-px !important;

			.ng-select-container {
				min-height: 28px !important;
			}
		}
	}
}

.ng-select {
	border-radius: 0.357rem;
	background-color: transparent !important;
	&.no-padding-select{
		div, span, input{
			padding-top: 0px;
		}
	}
	&.ng-select-multiple {
		.ng-select-container {
			.ng-value-container {
				.ng-value {
					line-height: normal !important;
					font-size: $size-12-px !important;

					.ng-value-label {
						padding: 2px 5px !important;
						word-break: break-all !important;
						white-space: normal !important;
	
					}
				}
			}
		}
	}
	.ng-dropdown-panel{
		background-color: $card-bg !important;
	}
	&.ng-select-disabled {
		.ng-select-container {
			background-color: $input-disabled-bg !important;
		}
	}
	&.form-control {
		min-height: 36px;
		background-color: transparent !important;
		height: auto !important;
		&.ng-select-multiple {
			.ng-select-container {
				.ng-value-container {
					.ng-value {
						line-height: normal !important;
						font-size: $size-12-px !important;
						white-space: break-spaces; // if length is more
						overflow-wrap: anywhere; // if length is more without space
					
						.ng-value-label {
							padding: 2px 5px !important;
							word-break: break-all !important;
							white-space: normal !important;
		
						}
					}
				}
			}
		}
		&.ng-select-disabled {
			.ng-select-container {
				background-color: $input-disabled-bg !important;
			}
		}
		&.lg-form-control {
			min-height: 44px;
			height: auto !important;

			.ng-select-container {
				min-height: 44px !important;
				height: auto !important;
			}

			&.ng-select-focused {
				min-height: 44px !important;

				.ng-select-container {
					min-height: 44px !important;
				}
			}
		}
		&.xl-form-control {
			min-height: 48px;
			height: auto !important;

			.ng-select-container {
				min-height: 48px !important;
				height: auto !important;
			}

			&.ng-select-focused {
				min-height: 48px !important;

				.ng-select-container {
					min-height: 48px !important;
				}
			}
		}
		&.sm-form-control {
			min-height: 28px;
			height: auto !important;

			.ng-select-container {
				min-height: 28px !important;
				height: auto !important;
				font-size: $size-12-px !important;

				.ng-value-container {
					.ng-value {
						line-height: normal !important;
						font-size: $size-12-px !important;
						white-space: break-spaces; // if length is more
						overflow-wrap: anywhere; // if length is more without space

						.ng-value-icon {
							line-height: 13px !important;
						}

						.ng-value-label {
							padding: 1px 5px !important;
						}
					}
				}
			}

			&.ng-select-focused {
				min-height: 28px !important;

				.ng-select-container {
					min-height: 28px !important;
				}
			}
		}

		&.lg-form-control {
			.ng-select-container {
				.ng-value-container {
					.ng-input {
						top: 10px;
					}
				}
			}
		}

		&.ng-select-focused {
			min-height: 36px !important;

			.ng-select-container {
				min-height: 36px !important;
			}
		}

		padding: 0;
		border: none;

		.ng-select-container {
			min-height: 36px !important;
			background-color: transparent !important;
			border: 1px solid $input-border-color !important;

			.ng-value-container {
				.ng-value {
					line-height: normal !important;
					font-size: $size-14-px !important;
					.ng-value-label {
						padding: 2px 5px !important;
					}
					.ng-value-icon{
						line-height: 1;
					}
				}
			}
		}
	}

	min-height: 36px;
	height: auto !important;
	&.lg-form-control {
		min-height: 44px;
		height: auto !important;

		.ng-select-container {
			min-height: 44px;
			height: auto !important;
		}
	}

	&.sm-form-control {
		min-height: 28px;
		height: auto !important;

		.ng-select-container {
			min-height: 28px;
			height: auto !important;
			font-size: $size-12-px !important;

			.ng-value-container {
				.ng-placeholder{
					top: 4px !important;
					font-size: $size-12-px;
				}
				.ng-value {
					line-height: normal !important;
					font-size: $size-12-px !important;

					.ng-value-icon {
						line-height: 13px !important;
					}

					.ng-value-label {
						padding: 1px 5px !important;
					}
				}
			}
		}
	}

	&.lg-form-control {
		.ng-select-container {
			.ng-value-container {
				.ng-input {
					top: 10px;
				}
			}
		}
	}
	&.xl-form-control {
		.ng-select-container {
			.ng-value-container {
				.ng-input {
					top: 10px;
				}
			}
		}
	}
	&.ng-select-focused {
		min-height: auto !important;

		.ng-select-container {
			min-height: auto !important;
		}
	}

	padding: 0;
	border: none;
	&.ng-select-filtered{
		.ng-select-container{
			.ng-value-container{
				.ng-input{
					input{
						cursor: text !important;
						caret-color: $title-color !important;
					}
				}
			}
		}
	}
	.ng-select-container {
		min-height: 36px !important;
		background-color: transparent !important;
		border: 1px solid $input-border-color !important;
	}
}

// End of Form Input Components //
.text-decoration-line-through{
	text-decoration: line-through !important;
}

.row-reverse{
	display: flex;
	flex-direction: row-reverse;
}

.white-img{
    img{
        filter: brightness(0) invert(1) !important;
    }
}
.flex-center {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}
.grid-col-2{
	grid-template-columns: repeat(2, 1fr);
}
.grid-col-3{
	grid-template-columns: repeat(3, 1fr);
}
.all-col{
	grid-column: -1/1;
}
.first-two-col{
	grid-column: -1/2;
}
.last-two-col{
	grid-column: 2/-1;
}
.disable-sec{
	position: relative;
	&::before{
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: rgba($white, 0.7);
		// backdrop-filter: blur(2px);
		cursor: not-allowed;
		z-index: 9;
	}
}
.btn-outline-tertiary{
	&.bg-body{
		background-color: $body-bg !important;
	}
}
.underline-txt{
	text-decoration: underline !important;
}
.sm-dropdown{
	.dropdown-menu{
		.dropdown-item{
			font-size: $size-12-px;
			padding: 7px 20px;
		}
	}
}
.full-width-sideout{
	width: calc(100% - 105px) !important;
}
.edit-task-input {
	&.form-control {
		color: $title-color;
		font-weight: 500;
		padding: 0;
		border: 0px;
		height: auto;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		background-color: transparent !important;
		&:read-only {
			background-color: transparent !important;
			cursor: not-allowed;
		}
		&:focus-visible {
			outline: none;
		}
		&:focus {
			box-shadow: none;
			&:valid {
				box-shadow: none;
			}
		}
	}
}
.select-edit-onHover{
	&.ng-select{
		&.form-control {
			cursor: pointer;
			.ng-select-container{
				border: 1px solid transparent !important;
				transition: border 0.5s ease;
				.ng-value-container{
					padding-left: 0px;
					transition: padding-left 0.4s ease;
				}
				.ng-clear-wrapper{
					opacity: 0;
					pointer-events: none;
					transition: opacity 0.35s ease;
				}
				.ng-arrow-wrapper{
					opacity: 0;
					pointer-events: none;
					transition: opacity 0.35s ease;
				}
			}
			&:hover{
				.ng-select-container{
					// box-shadow: none;
					border: 1px solid $input-border-color !important;
					transition: border 0.5s ease;
					.ng-value-container{
						padding-left: 7px;
						transition: padding-left 0.4s ease;
					}
					.ng-clear-wrapper{
						opacity: 1;
						pointer-events: all;
						transition: opacity 0.35s ease;
					}
					.ng-arrow-wrapper{
						opacity: 1;
						pointer-events: all;
						transition: opacity 0.35s ease;
					}
				}
			}
			&.ng-select-focused{
				.ng-select-container{
					border: 1px solid $input-border-color !important;
					transition: border 0.5s ease;
					.ng-value-container{
						padding-left: 7px;
						transition: padding-left 0.4s ease;
					}
					.ng-clear-wrapper{
						opacity: 1;
						pointer-events: all;
						transition: opacity 0.35s ease;
					}
					.ng-arrow-wrapper{
						opacity: 1;
						pointer-events: all;
						transition: opacity 0.35s ease;
					}
				}
			}
			&.ng-select-opened{
				.ng-select-container{
					border: 1px solid $input-border-color !important;
					transition: border 0.5s ease;
					.ng-value-container{
						padding-left: 7px;
						transition: padding-left 0.4s ease;
					}
					.ng-clear-wrapper{
						opacity: 1;
						pointer-events: all;
						transition: opacity 0.35s ease;
					}
					.ng-arrow-wrapper{
						opacity: 1;
						pointer-events: all;
						transition: opacity 0.35s ease;
					}
				}
			}
		}
	}
}
.avatar-group {
	&.team-group{
		.avatar{
			&.transparent-primary{
				.avatar-content{
					@extend .transparent-primary
				}
			}
			&.bg-primary{
				.avatar-content{
					background-color: $primary !important;
					i{
						width: auto !important;
						height: auto !important;
					}
				}
			}
		}
	}
}
.txt-ellipsis{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}